import * as React from "react";
// Customizable Area Start
import { withStyles } from "@material-ui/core";
import HpOrderManagementController, { Props } from "../HpOrderManagementController";

// Customizable Area End

export class HPProfileView extends HpOrderManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = {
}

export default withStyles(styles)(HPProfileView);
// Customizable Area End