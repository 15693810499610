// Customizable Area Start
import React from "react";
import PatientOrderManagementController, { Props } from "./PatientOrderManagementController";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Box, ThemeProvider, Typography, createTheme, withStyles } from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { Calender, noData, ProfileIcon, filterIcons } from "./assets";
import OrderManagementFilter from "../../filteritems/src/OrderManagementFilter.web";
import SidebarNotificationWrapper from "../../notifications/src/Notifications.web";

const theme = createTheme({
    palette: {
        primary: {
            contrastText: "#fff",
            main: "#0000ff",
        }
    }
});
// Customizable Area End

export class PatientOrderManagement extends PatientOrderManagementController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    async componentDidMount() {
        this.fetchUserProfileDatas()
    }

    noDataFound = (classes: any) => {
        return (
            <>
                {
                    this.state.loading ? (
                        <>
                            <Box className={classes.noData}>Loading...</Box>
                        </>) : (
                        <>
                            <Box className={classes.noDataDashboardContainer}>
                                <img
                                    src={noData}
                                    width={60}
                                    height={60}
                                    className={classes.closeIcon}
                                />
                                <Box className={classes.noData}>No results found</Box>
                            </Box>
                        </>)
                }
            </>
        )
    }

    isProfile = (profile_image:any) => {
        if(profile_image === null){
            return ProfileIcon
        }else{
            return profile_image
        }
    }

    isTitle = (profileType:string) => {
        const title = profileType === "doctor" ? "Dr. " : ""
        return title
    }

    openCallenderTitle = (classes:any) => {
        return (
            <>{this.state.isOpen && (<Box className={classes.selectBox}>Select Week</Box>)}</>
        )
    }

    handelFilter = (classes:any) => {
        return (
            <Box>
                <Box
                    className={this.state.showPopup ? classes.hpFilterIconSelected : classes.hpFilterIcon}
                >
                    <img data-test-id="hpToggleButton" src={filterIcons} onClick={this.togglePopup} />
                </Box>
                <Box>
                    {this.state.showPopup && (
                        <OrderManagementFilter navigation={this.props.navigation}
                            closePopup={this.togglePopup} id={""} selectedFilters={this.state.selectedFilters} onChangeFilter={this.onChangeFilter} />
                    )}
                </Box>
            </Box>
        )
    }

    handelContainerHeight = () => {
        return this.state.isOpen ? "445px" : ""
    }

    handelSeeAllBtnColor = () => {
        return this.state.managementData?.length > 0 ? "#3780E0" : "#808080"
    }



    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        // Customizable Area End
        return (
            // Customizable Area Start
            <>
                <ThemeProvider theme={theme}>
                    <Box className={classes.orderManagementContainer}>
                        <SidebarNotificationWrapper {...this.props} >
                            <Box>
                                {this.state.showAllData ? (
                                    <Box className={classes.orderManagementAvailableContainer}>
                                        <Box className={classes.headerContainer}>
                                            <Box className={classes.orderHeading}>
                                                <ArrowBackIosIcon
                                                    className={classes.calendarBackBtn}
                                                    data-test-id="showData"
                                                    onClick={this.handelShowAllData}
                                                />
                                                <Typography variant="h4" className={classes.profileHeading} style={{ textTransform: "capitalize" }}>{this.state.selectedFilters} Bookings</Typography>
                                            </Box>
                                        </Box>
                                        <div data-test-id='scroll-div' style={{ height: '550px', paddingRight:"55px",overflow: 'auto', scrollbarColor: "#d9d5d5 white", scrollbarWidth: "thin" as "thin", }} ref={this.scrollBookingContainerRef} onScroll={this.onHandelScroll}>
                                        <Box className={classes.allBookingContainer} style={{ width: "590px" }} data-test-id="cardContainer">
                                            {this.state.managementData.map((data: any, index: number) => (
                                                <Box key={index}>
                                                    <Box className={classes.bookingContainer}>
                                                        <Box className={classes.bookingWrapper}>
                                                            <Box className={classes.imageContainer}>
                                                                <img data-test-id="seeProfile" src={this.isProfile(data.attributes.profile_image)} className={classes.profileSize} onClick={() => this.openProfile(data.attributes.profile_type, data.attributes.id)} />
                                                            </Box>
                                                            <Box className={classes.bookingsInfoSection}>
                                                                <Box>
                                                                    <Typography variant="h6" className={classes.nameLabel}>{this.isTitle(data.attributes.profile_type)} {data.attributes.name}</Typography>
                                                                    <Typography variant="h6" className={classes.profileType}>{data.attributes.profile_type}</Typography>
                                                                </Box>
                                                                <Box>
                                                                {this.state.selectedFilters !== "past" ?(<Typography variant="h6" data-test-id="start_time" className={classes.time}>{data.attributes.start_time}</Typography>) :
                                                                    (
                                                                        <>
                                                                            <Typography variant="h6" data-test-id="pastStatus" className={classes.status}>{data.attributes.status}</Typography>
                                                                            <Typography variant="h6" data-test-id="pastReview" className={classes.review}>Give a review</Typography>
                                                                        </>
                                                                    )}
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            ))}
                                        </Box>
                                        </div>
                                    </Box>
                                ) :
                                    (
                                        <Box className={classes.orderManagementAvailableContainer}>
                                            <Box className={classes.headerContainer}>
                                                <Box className={classes.orderHeading}>
                                                    <ArrowBackIosIcon
                                                        className={classes.calendarBackBtn}
                                                        data-test-id="calendarBackBtn"
                                                    />
                                                    <Typography variant="h4" style={{ fontFamily: "SF Pro Text Bold", fontSize: "28px", color: "#2929292" }}>Book Now</Typography>
                                                </Box>
                                                {this.handelFilter(classes)}
                                            </Box>
                                            <Box>
                                                <Box className={classes.orderFieldContainer}>
                                                    <Typography variant="h4" className={classes.orderDateLabel}>Set Date</Typography>
                                                    <Box className={classes.orderCustomName}>
                                                        <span className={classes.customDateInputName}>
                                                            <DatePicker
                                                                onChange={this.handleCustomDateChange}
                                                                monthsShown={2}
                                                                popperClassName={classes.popperScheduleClassName}
                                                                wrapperClassName={classes.datepickerScheduleClassFullWidth}
                                                                calendarClassName={classes.orderDatepickerClass}
                                                                formatWeekDay={nameOfDay => nameOfDay.slice(0, 1)}
                                                                className={classes.dateField}
                                                                minDate={new Date()}
                                                                dateFormat="Select date"
                                                                placeholderText="Select date"
                                                                startOpen={true}
                                                                shouldCloseOnSelect={false}
                                                                open={this.state.isOpen}
                                                                data-test-id="select-schedule-date"
                                                                onInputClick={this.toggleDatePicker}
                                                                value={this.newFormatDate(this.state.date)}
                                                            />
                                                        </span>
                                                    </Box>
                                                    {this.openCallenderTitle(classes)}
                                                </Box>
                                            </Box>
                                            <Box className={classes.newOrderBookingMainContainer} style={{ marginTop: this.handelContainerHeight()}}>
                                                <Box className={classes.newOrderBookingHeadingContainer}>
                                                    <Typography variant="h6" className={classes.titleBookingType} style={{ textAlign: "center", textTransform: "capitalize", caretColor: "transparent" }}>{this.state.selectedFilters} Bookings</Typography>
                                                    <Typography data-test-id="seelAll" variant="h6" className={classes.titleSeeAll} style={{ caretColor: "transparent", cursor: "pointer", color: this.handelSeeAllBtnColor(), fontSize: "18px", fontFamily: "SF Pro Text Medium" }} onClick={this.handelShowAllData}>
                                                        See All
                                                    </Typography>
                                                </Box>
                                                <Box className={classes.allBookingContainer} data-test-id="cardContainer">
                                                    {this.state.managementData?.length > 0 ? (
                                                        this.state.managementData.slice(0, 3).map((data: any, index: number) => (
                                                            <Box key={index}>
                                                                <Box className={classes.bookingContainer} data-test-id="subContainer">
                                                                    <Box className={classes.bookingWrapper} data-test-id="bookingWrapper">
                                                                        <Box className={classes.imageContainer}>
                                                                            <img data-test-id={`profile${index}`}  src={this.isProfile(data.attributes.profile_image)} className={classes.profileSize} onClick={() => this.openProfile(data.attributes.profile_type, data.attributes.id)} />
                                                                        </Box>
                                                                        <Box className={classes.bookingsInfoSection}>
                                                                            <Box>
                                                                                <Typography variant="h6" data-test-id="name" className={classes.nameLabel}>{this.isTitle(data.attributes.profile_type)} {data.attributes.name}</Typography>
                                                                                <Typography variant="h6" data-test-id="role" className={classes.profileType}>{data.attributes.profile_type}</Typography>
                                                                            </Box>
                                                                            <Box>
                                                                                {this.state.selectedFilters !== "past" ?
                                                                                    (<Typography variant="h6" data-test-id="time" className={classes.time}>{data.attributes.start_time}</Typography>) :
                                                                                    (
                                                                                        <>
                                                                                            <Typography variant="h6" data-test-id="pastBookingStatus1" className={classes.status}>{data.attributes.status}</Typography>
                                                                                            <Typography variant="h6" data-test-id="pastBookingReview1" className={classes.review}>Give a review</Typography>
                                                                                        </>)}
                                                                            </Box>
                                                                        </Box>
                                                                    </Box>
                                                                </Box>
                                                            </Box>
                                                        ))
                                                    ) : (
                                                        this.noDataFound(classes)
                                                    )}
                                                </Box>
                                            </Box>
                                        </Box>
                                    )}
                            </Box>
                        </SidebarNotificationWrapper>
                    </Box>
                </ThemeProvider>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const styles = {
    orderManagementContainer: {
        display: "flex",
    },
    orderManagementAvailableContainer: {
        padding: "40px 50px",
        width: "100%",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column" as "column",
        justifyContent: "flex-start",
        alignItems: "flex-start" as "flex-start",
        gap: "20px",
        boxSizing:'border-box' as const
    },
    headerContainer: {
        width: "85%",
        display: "flex",
        alignItems: "center" as "center",
        justifyContent: "space-between",
        caretColor: "transparent"
    },
    orderHeading: {
        display: "flex",
        alignItems: "center" as "center",
        justifyContent: "flex-start",
        gap: "25px",
    },
    calendarBackBtn: {
        display: "flex",
        alignItems: "center",
        fontSize: "22px",
        justifyContent: "center",
        borderRadius: "8px",
        border: "1px solid #E9E9E9",
        padding: "13px 9px 13px 17px",
    },
    orderFieldContainer: {
        width: "94%",
        margin: "15px 0px 20px"
    },
    orderDateLabel: {
        fontSize: "18px",
        fontFamily: "SF Pro Text Regular",
        margin: "0px 0px 15px",
        textAlign: "left" as "left",
        caretColor: "transparent",
        color: "#292929",
        width: "100px"
    },
    orderCustomName: {
        display: "flex",
        alignItems: "center",
    },
    customDateInputName: {
        width: "100%",
        borderRadius: "8px",
        "& input": {
            color: "#292929",
            fontSize: "18px",
            background: `url(${Calender}) left / contain no-repeat`,
            backgroundPosition: "12px",
            backgroundSize: "25px",
            width: "382px",
            height: "58px",
            border: "1px solid #808080",
            display: "flex",
            borderRadius: "8px",
            fontFamily: "SF Pro Text Regular",
            caretColor: "transparent"
        },
        "& ::-webkit-input-placeholder": {
            color: "#808080",
        },
        "&:focus": {
            border: "1px solid #292929",
        },
    },
    datepickerScheduleClassFullWidth: {
        width: "100%",
        color: "#292929",
        border: "none",
    },
    orderDatepickerClass: {
        "& .react-datepicker__day-name": {
            color: "#808080",
            padding: "11px",
            fontSize: "16px",
            fontFamily: "SF Pro Text Medium",
            width: "25px",
        },
        "& .react-datepicker__day--selected": {
            border: "none !important",
            width: "25px",
            color: "#ffffff !important",
            backgroundColor: "#3780E0"
        },
        "& .react-datepicker__day--outside-month": {
            backgroundColor: "transparent !important",
        },
        "& .react-datepicker__day": {
            padding: "11px !important",
            color: "#292929",
            fontSize: "16px",
            fontFamily: "SF Pro Text Medium",
            width: "25px"
        },
        "& .react-datepicker__day--disabled": {
            width: "25px",
            color: "#808080 !important"
        },
        "& .react-datetime-picker__wrapper": {
            border: "none",
        },
        "& .react-datepickerClass__month": {
            margin: "0px !important"
        },
        "& .react-datepickerClass-wrapper": {
            display: "flex !important",
        },
        "& .react-datepickerClass-popper": {
            transform: "translate(90px, 209px) !important",
        },
        "& .react-datepickerClass__day": {
            height: "1rem !important",
            width: "1.3rem !important",
        },
        "& .react-datepickerClass__day-name": {
            width: "1.3rem !important",
            height: "1rem !important",
        },
        "& .react-datepickerClass__month-container": {
            margin: "0px 0px 5px !important",
        },
        "& .react-datepickerClass__day-names": {
            caretColor: "transparent",
            marginBottom: "-1px !important",
        },
        "& .react-datepickerClass__navigation": {
            height: "22px !important",
            caretColor: "transparent",
            width: "22px !important",
            margin: "5px -2px !important",
        },
        "& .react-datepickerClass__day--keyboard-selected": {
            color: "#000 !important",
            caretColor: "transparent",
            backgroundColor: "white !important",
        },
        "& .react-datepickerClass__day--today": {
            color: "#1d5d90 !important",
            caretColor: "transparent",
            backgroundColor: "white !important",
            fontWeight: "bold !important",
        },
        "& .react-datepickerClass__day:hover": {
            color: "#ccc !important",
            caretColor: "transparent",
            backgroundColor: "white !important",
        },
        "& .react-datepicker__triangle": {
            display: "none !important"
        },
        "& .react-datepicker__navigation--next": {
            right: "30px",
            borderRadius: "6px",
            top: "30px",
            backgroundColor: "#292929",
            width: "28px",
            height: "28px",
        },
        "& .react-datepicker__navigation--previous": {
            left: "30px",
            borderRadius: "6px",
            backgroundColor: "#292929",
            width: "28px",
            height: "28px",
            top: "30px"
        },
        "& .react-datepicker__navigation-icon::after": {
            borderColor: "#ffffff !important",
            top: "7px !important",
            borderWidth: "2px 2px 0 0",
            borderRadius: "2px !important",
        },
        "& .react-datepicker__navigation-icon::before": {
            borderRadius: "2px !important",
            borderWidth: "2px 2px 0 0",
            borderColor: "#fff !important",
            top: "7px !important",
        },
        "& .react-datepicker__month-container": {
            padding: "10px",
        },
        "& .react-datepicker__current-month": {
            fontSize: "16px",
            fontFamily: "SF Pro Text Medium",
            color: "#292929",
            margin: "15px auto",
        },
        "& .react-datepicker__day--keyboard-selected": {
            border: "none !important",
            backgroundColor: "#3780E0",
            width: "25px",
            color: "#ffffff !important",
            caretColor: "transparent"
        },
        "& .react-datepicker__header": {
            border: "none !important",
            backgroundColor: "#fff !important",
        },
        "& .react-datepicker__day:hover": {
            width: "25px",
        },
        "& .react-datepicker__day.react-datepicker__day--030.react-datepicker__day--selected.react-datepicker__day--outside-month": {
            backgroundColor: "transparent !important",
            "&:focus": { backgroundColor: "transparent !important" },
        }
    },
    dateField: {
        "& .react-datepicker-ignore-onclickoutside": {
            border: "none !important",
            borderColor: "transparent !important"
        },
        paddingTop: "15px",
        paddingRight:"0px",
        paddingBottom: "15px",
        paddingLeft: "60px",
        display: "flex",
        justifyContent: "center",
        border: "1px solid #808080",
        borderRadius: "8px",
        cursor: "pointer",
        "&:focus-visible": {
            border: "2px solid #292929",
            outline: "none",
        },
        "&:focus": {
            border: "2px solid #292929",
            outline: "none",
        },
        "&:hover": {
            border: "1px solid #292929",
            outline: "none",
        },
        ".react-datepicker__input-container input::placeholder": {
            opacity: 1,
            color: "#808080",
            fontSize: "18px",
        },
        fontSize: "18px",
        color: "#292929",
        "& input": {
            color: "#292929",
        }
    },
    selectBox: {
        height: "55px",
        backgroundColor: "rgb(235, 249, 255)",
        fontSize: "18px",
        fontFamily: "Sf Pro Text Semibold",
        width: "217%",
        maxWidth: "774px",
        margin: "15px 0px 0px",
        alignItems: "center" as "center",
        display: "flex",
        paddingLeft: "26px",
        color: "#014866",
        borderTopLeft: "1px solid #aca8a840 !important",
        borderTopRight: "1px solid #aca8a840 !important",
        borderTopLeftRadius: "8px",
        borderTopRightRadius: "8px",
    },
    newOrderBookingMainContainer: {
        minHeight: "25vh",
        width: "85%",
    },
    newOrderBookingHeadingContainer: {
        display: "flex",
        justifyContent: "space-between",
        paddingTop: "20px",
        alignItems: "center",
    },
    titleBookingType: {
        fontSize: "22px",
        fontFamily: "SF Pro Text Bold",
        color: "#292929",
    },
    bookingContainer: {
        marginTop: "20px",
        maxWidth: "590px"
    },
    bookingWrapper: {
        display: "flex",
        padding: "15px 0px 15px 15px",
        background: "#FFFFFF",
        border: "1px solid rgba(179, 179, 179, 0.18)",
        boxShadow: "0px 2px 40px rgba(186, 186, 186, 0.13)",
        borderRadius: "8px",
        gap: "15px",

    },
    imageContainer: {
        width: "85px",
        height: "85px",
    },
    profileSize: {
        width: "85px",
        borderRadius: "50%",
        height: "85px",
    },
    bookingsInfoSection: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        alignItems: "flex-start",
        marginRight: "5px"
    },
    nameLabel: {
        fontSize: "18px",
        caretColor: "transparent",
        fontFamily: "SF Pro Text Medium",
        color: "#000000",
        overflow: "hidden" as "hidden",
        whiteSpace: "nowrap" as "nowrap",
        textOverflow: "ellipsis" as "ellipisis",
        width: "150px",
        marginTop: "15px",
        "@media (max-width:1000px)": {
            fontSize: "18px",
        },
    },
    time: {
        fontSize: "16px",
        caretColor: "transparent",
        fontFamily: "SF Pro Text Bold",
        color: "#3780E0",
        marginTop: "15px",
        marginRight: "30px",
        textAlign: "right" as "right"
    },
    profileType: {
        fontSize: "16px",
        caretColor: "transparent",
        fontFamily: "SF Pro Text Regular",
        color: "#AC8E68",
        textTransform: "capitalize" as "capitalize"
    },
    status: {
        fontSize: "16px",
        fontFamily: "SF Pro Text Medium",
        color: "#30D158",
        marginTop: "15px",
        marginRight: "30px",
        textAlign: "right" as "right",
        textTransform: "capitalize" as "capitalize"
    },
    review: {
        fontSize: "16px",
        fontFamily: "SF Pro Text Regular",
        color: "#808080",
        marginRight: "30px",
        textAlign: "right" as "right"
    },
    hpFilterIconSelected: {
        width: "18px",
        height: "18px",
        display: "flex",
        justifyContent: "center",
        backgroundColor: "white",
        zIndex: 1000,
        padding: "13px",
        borderRadius: "8px",
        position: "absolute" as "absolute",
        left: "63%",
        caretColor: "transparent"
    },
    hpFilterIcon: {
        width: "18px",
        height: "18px",
        display: "flex",
        justifyContent: "center",
        backgroundColor: "white",
        zIndex: 1000,
        padding: "13px",
        borderRadius: "8px",
        caretColor: "white",
        margin: "0px -73% 0px 0px"
    },
    noDataDashboardContainer: {
        width: "362px",
        display: "block",
        margin: "50px auto"
    },
    closeIcon: {
        display: "flex",
        margin: "auto"
    },
    noData: {
        fontSize: "20px",
        fontFamily: "SF Pro Text Medium",
        color: "#808080",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        margin: "60px auto"
    },
    profileHeading: {
        width: "100%",
        fontSize: "28px",
        fontFamily: "SF Pro Text Bold",
        color: "#292929",
        caretColor: "transparent"
    },
    popperScheduleClassName:{
        "& .react-datepicker":{
            fontFamily: "SF Pro Text Regular !important",
            fontSize: "18px !important",
            borderBottomRightRadius: "8px",
            borderTopLeftRadius: "0px",
            borderTopRightRadius: "0px",
            marginTop: "60px !important",
            borderBottom: "1px solid #aca8a840 !important",
            borderRight: "1px solid #aca8a840 !important",
            borderLeft: "1px solid #aca8a840 !important",
            borderTop: "1px solid transparent !important",
            borderBottomLeftRadius: "8px",
        }
    },
}

export default withStyles(styles)(PatientOrderManagement);
// Customizable Area End
