import React from "react";
// Customizable Area Start
import SchedulingController, { Props, shiftsData, ayahShiftsData } from "./SchedulingController";
import {
    Box,
    Button,
    Dialog,
    ThemeProvider,
    Typography,
    createTheme,
    withStyles,
} from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import '../../../web/src/fonts/stylesheet.css';
import Switch from '@material-ui/core/Switch';
import SidebarNotificationWrapper from "../../notifications/src/Notifications.web";

const theme = createTheme({
    palette: {
        primary: {
            contrastText: "#fff",
            main: "#0000ff",
        }
    }
});

const AntSwitch = withStyles((theme) => ({
    root: {
        width: "46px",
        height: "25px",
        padding: "2px",
        display: 'flex',
        marginRight: (props: any) => (props.leaveSwitch ? "0px" : "25px"),
    },
    switchBase: {
        padding: 2,
        transform: 'translate(4px, 2.92px)',
        '& + $track': {
            opacity: 0.4,
            backgroundColor: theme.palette.grey[500],

            thumb: {
                transform: "translateX(40px)"
            }
        },
        '&$checked': {
            transform: 'translate(23px, 2.9px)',
            color: theme.palette.common.white,
            '& + $track': {
                opacity: 1,
                backgroundColor: "#02A405",
            },
        },
    },
    thumb: {
        width: "16px",
        height: "16px",
        boxShadow: 'none',
    },
    track: {
        border: `1px solid ${theme.palette.grey[500]}`,
        borderRadius: "16px",
        opacity: 1,
        backgroundColor: theme.palette.common.white,
    },
    checked: {},
    disabled: {},
}))(Switch);


type textAlignType = "left";
type FlexDirectionProperty = "column";
type FlexWrap = "wrap";
type TextTransformCapitalizeProperty = "capitalize";
type PositionRelativeProperty = "relative";

// Customizable Area End

export class Scheduling extends SchedulingController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start

        // Customizable Area End
    }
    // Customizable Area Start

    MessagePopup = (title: string, state: string) => {
        return (
        <Dialog open={!!state}>
            <>
            <Box data-test-id="schedulingError" style={webStyles.customContainer}>
                <Box style={{ padding: "0px 10px" }}>
                <p style={webStyles.dialogHeading}>{title}</p>
                <p style={webStyles.paragraph}>{state}</p>
                <button
                    style={webStyles.popupButton}
                    onClick={() =>
                    this.setState({ errorMessage: "", successMessage: "" })
                    }
                >
                    OK
                </button>
                </Box>
            </Box>
            </>
        </Dialog>
        );
    };
    // Customizable Area End

    render() {
        // Customizable Area Start

        // Customizable Area End
        return (
            // Customizable Area Start
            <>
                <ThemeProvider theme={theme}>
                    <Box style={webStyles.container}>
                        <SidebarNotificationWrapper hideNotification {...this.props} >
                            <Box>
                                <Box style={webStyles.availbleContainer}>
                                    <Box style={webStyles.HeaderContainer}>
                                        <Box style={webStyles.heading}>
                                        <ArrowBackIosIcon
                                            style={webStyles.BackButton}
                                            data-test-id="calendarBackBtn"
                                        />
                                        <Typography variant="h6" style={webStyles.calendarTitle}>
                                            Edit Availability
                                        </Typography>

                                        </Box>
                                        <Button style={webStyles.leaveBtn}>
                                            <Typography variant="h6" style={webStyles.leaveTitle}>Leave</Typography> 
                                            <AntSwitch
                                                        leaveSwitch={true}
                                                        checked={this.state.leaveToggle}
                                                        onChange={() =>
                                                            this.UpdateLeaveAvailability()
                                                        }
                                                        name={`leave-toggle`}
                                                    />
                                        </Button>
                                    </Box>
                                
                                    <Box style={webStyles.shiftsContainer}>
                                        {(this.state.kind === "ayah" ? ayahShiftsData : shiftsData).map((shift, index) => (
                                        <Box key={index} style={webStyles.shiftsBox}>
                                                <Box style={webStyles.shiftAvailableHeader}>
                                                    <Typography style={webStyles.shiftsTitle} variant="h6">{shift.title}</Typography>
                                                    <AntSwitch
                                                        leaveSwitch={false}
                                                        data-test-id="leave-toggle"
                                                        checked={this.state.checked[index]?.checked}
                                                        onChange={(event: any) =>
                                                            this.toggleShiftsCheck(index, event.target.checked)
                                                        }
                                                        name={`shift-toggle-${index}`}
                                                    />
                                                </Box>
                                                <Box style={webStyles.shiftsContent}>
                                                    <Typography style={webStyles.fieldLabel} variant="h6">
                                                        Timing
                                                    </Typography>
                                                    <Typography style={webStyles.shiftTiming} variant="h6">
                                                        {`${shift.start_time} - ${shift.end_time}`}
                                                    </Typography>
                                                    <Typography style={webStyles.fieldLabel} variant="h6">
                                                        Repeats On
                                                    </Typography>
                                                    <Box style={webStyles.daysBox}>
                                                        {this.state.checked[index]?.days.map((day: any, dayIndex: any) => (
                                                            <Typography
                                                                key={dayIndex}
                                                                onClick={() => this.toggleDaySelection(index, dayIndex)}
                                                                style={day.selected ? webStyles.activeDays : webStyles.days}
                                                                variant="h6"
                                                            >
                                                                {day.day}
                                                            </Typography>
                                                        ))}
                                                    </Box>
                                                </Box>
                                            </Box>
                                        ))}
                                    </Box>
                                    <Box>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            data-test-id="confirmAvailability"
                                            style={this.state.disableConfirm ? webStyles.disabledConfirmButton : webStyles.confirmButton}
                                            onClick={()=>this.state.disableConfirm ? {} : this.handleConfirm()}
                                        >
                                            {this.state.loading ? "Loading..." : "Confirm"}
                                        </Button>
                                    </Box>
                                </Box>
                            </Box>
                        </SidebarNotificationWrapper>
                    </Box>
                    {this.MessagePopup('Error', this.state.errorMessage)}
                    {this.MessagePopup('Success', this.state.successMessage)}
                </ThemeProvider>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const webStyles = {
    container: {
        display: "flex",
    },
    availbleContainer: {
        margin: "40px 50px",
        width: "70%",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column" as FlexDirectionProperty,
        justifyContent: "flex-start",
        alignItems: "flex-start",
        gap: "20px",
    },
    HeaderContainer: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        marginBottom: "25px",
        justifyContent: "space-between",
    },
    heading: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        gap: "25px",
    },
    BackButton: {
        display: "flex",
        alignItems: "center",
        fontSize: "22px",
        justifyContent: "center",
        borderRadius: "8px",
        border: "1px solid #E9E9E9",
        padding: "12px 8px 12px 16px",
    },
    calendarTitle: {
        fontFamily: "SF Pro Text Bold",
        fontSize: "28px",
        fontWeight: 600,
        lineHeight: "33.41px",
    },
    profileHeading: {
        width: "100%",
        fontWeight: 600,
        fontSize: "25px",
        marginBottom: "45px",
        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
        caretColor: "transparent"
    },
    setDateTitle: {
        fontFamily: "SF Pro Text Regular",
        fontSize: "18px",
        fontWeight: 400,
        lineHeight: "21.48px",
        color: "#292929",
        marginTop: "15px"
    },
    shiftsContainer: {
        display: "flex",
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        gap: "35px",
        flexWrap: "wrap" as FlexWrap,
        width: "100%"
    },
    shiftsBox: {
        minWidth: "calc(50% - 60px)",
        border: "1px solid rgba(206, 206, 206, 0.5)",
        borderRadius: "22px",
        display: "flex",
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
        padding: "25px 16px 20px",
        flexDirection: "column" as FlexDirectionProperty,
        boxShadow: "0px 2px 24px 0px #98989838",
    },
    shiftAvailableHeader: {
        display: "flex",
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        width: "100%",
        minHeight: "30px",
        paddingBottom: "8px",
        borderBottom: "1px solid #F0F0F0",
        marginRight: "25px",
    },
    shiftsTitle: {
        fontFamily: 'SF Pro Text Medium',
        fontSize: '20px',
        fontWeight: 500,
        lineHeight: '23.87px',
        letterSpacing: '-0.6753246784210205px',
        color: '#292929',
    },
    shiftsContent: {
        padding: "10px 0",
        display: "flex",
        gap: "10px",
        flexDirection: "column" as FlexDirectionProperty,
    },
    fieldLabel: {
        fontFamily: 'SF Pro Text Regular',
        fontSize: '18px',
        fontWeight: 400,
        lineHeight: '21.48px',
        color: '#808080',
        marginTop: "20px",
    },
    shiftTiming: {
        fontFamily: 'SF Pro Text Medium',
        fontSize: '18px',
        fontWeight: 500,
        lineHeight: '21.48px',
        color: '#292929',
    },
    daysBox: {
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: '22px',
        marginTop: "2px"
    },
    days: {
        fontFamily: 'SF Pro Text Medium',
        fontSize: '16px',
        fontWeight: 500,
        lineHeight: '19.09px',
        letterSpacing: '-0.5142857432365417px',
        color: '#7A7A7A',
        width: '27px',
        height: '33px',
        background: '#8080802D',
        borderRadius: "8px",
        display: "flex",
        justifyContent: 'center',
        alignItems: 'center',
        cursor: "pointer"
    },
    activeDays: {
        fontFamily: 'SF Pro Text Medium',
        fontSize: '16px',
        fontWeight: 500,
        lineHeight: '19.09px',
        letterSpacing: '-0.5142857432365417px',
        color: '#FFFFFF',
        background: '#3780E0',
        width: '27px',
        height: '33px',
        borderRadius: "8px",
        display: "flex",
        justifyContent: 'center',
        alignItems: 'center',
        cursor: "pointer"
    },
    confirmButton: {
        width: "224px",
        height: "56px",
        backgroundColor: "#014866",
        color: "#fff",
        border: "none",
        borderRadius: "10px",
        padding: "16px",
        cursor: "pointer",
        margin: "35px auto",
        fontFamily: 'SF Pro Text Bold',
        fontSize: '18px',
        fontWeight: 600,
        lineHeight: "21.48px",
        textTransform: "capitalize" as TextTransformCapitalizeProperty,
        "&:hover": {
            backgroundColor: "#014866",
            color: "#fff"
        }
    },
    disabledConfirmButton: {
        width: "224px",
        height: "56px",
        backgroundColor: "#014866",
        opacity: 0.5,
        color: "#fff",
        border: "none",
        borderRadius: "10px",
        padding: "16px",
        cursor: "not-allowed",
        margin: "35px auto",
        fontFamily: 'SF Pro Text Bold',
        fontSize: '18px',
        fontWeight: 600,
        lineHeight: "21.48px",
        textTransform: "capitalize" as TextTransformCapitalizeProperty,
    },
    leaveBtn: {
        border: "none",
        borderRadius: "10px",
        padding: "8px 20px 8px 20px",
        textTransform: "capitalize" as TextTransformCapitalizeProperty,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        maxWidth: 'fit-content',
        marginRight: "20px",
        boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.2)"
    },
    leaveTitle: {
        fontFamily: 'SF Pro Text Medium',
        fontSize: '18px',
        fontWeight: 500,
        color: "292929",
        marginRight: "8px"
    },
    notAvailable: {
        fontFamily: 'SF Pro Text Medium',
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '16.71px',
        color: "#FF3333",
        opacity: 0.4
    },
    calendarContainer: {
        position: "relative" as PositionRelativeProperty,
        width: "calc(100% - 90px)",
        minHeight: "fit-content",
        top: 0,
        left: 0,
    },
    selectWeekTitle: {
        fontFamily: 'SF Pro Text Bold',
        fontSize: 18,
        fontWeight: 600,
        lineHeight: '21.48px',
        letterSpacing: -0.5785714387893677,
        color: '#014866',
        background: '#EBF9FF',
        padding: '20px 25px',
        borderRadius: '8px 8px 0 0'
    },      
    popupButton: {
        backgroundColor: "rgb(33, 150, 243)",
        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
        border: "none",
        color: "white",
        padding: "7px 5px 3px",
        marginBottom: "15px",
        borderRadius: "3px",
        fontWeight: 600,
        cursor: "pointer",
        caretColor: "transparent",
        "&.MuiButton-root:hover": {
          backgroundColor: "rgb(33, 150, 243)",
          color: "white"
        }
    },
    dialogHeading: {
        width: "100%",
        color: "black",
        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
        fontSize: "28px",
        fontWeight: 700,
        height: "8px",
        marginTop: "15px",
        caretColor: "white"
    },
    paragraph: {
        width: "100%",
        color: "black",
        fontFamily: "Roboto, Helvetica, Arial, sans-serif",
        fontSize: "16px",
        height: "8px",
        margin: "30px 0px",
        caretColor: "white",
        padding: "5px 0 15px",
    },
    customContainer: {
        display: "block",
        maxWidth: "550px",
        width: "500px",
        height: "auto",
        textAlign: "left" as textAlignType,
        borderRadius: "5px"
    },
};

export default withStyles(webStyles)(Scheduling);
// Customizable Area End

