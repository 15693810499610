import * as React from "react";
// Customizable Area Start
import { withStyles, Box, Grid, ThemeProvider, createTheme, Typography, TextField } from "@material-ui/core";
import HpOrderManagementController, { Props } from "../HpOrderManagementController";
import Sidebar from "../../../../components/src/Sidebar.web";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff"
    }
  }
});

// Customizable Area End

export class HealthcareViewPersonnelPrescription extends HpOrderManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <ThemeProvider theme={theme}>
          <Grid container className={classes.righHPtWrapperView}>
            <Grid item xs={1}>
              <Sidebar />
            </Grid>
            <Grid item xs={11} className={classes.righHPtWrapper}>
              <Box>
                <Box className={classes.headerHPContainerView}>
                  <Box className={classes.orderHeadingView}>
                    <ArrowBackIosIcon
                      className={classes.headingIconView}
                      data-test-id="backToPreviousScreen"
                      onClick={() => this.backToPrevPage()}
                    />
                    <Typography variant="h4" className={classes.profileHPHeadingView}>Prescription OverView</Typography>
                  </Box>
                </Box>
                <Grid container >
                  <Grid item xl={12} md={12} sm={12} xs={12}>
                    <Box className={classes.inputContainerView}>
                      <Typography variant="h4" className={classes.homeCureHeadingView}>HomeCure</Typography>
                      <Box className={classes.seperationLineView} />
                      <Box style={{ width: "100%" }}>
                        <Box>
                          <TextField
                            variant="outlined"
                            placeholder="Hospital Name"
                            className={classes.inputNameFieldsView}
                            value="ABCD Hospital"
                          />
                        </Box>
                      </Box>
                      <Box style={{ width: "100%" }}>
                        <Box>
                          <TextField
                            variant="outlined"
                            placeholder="City"
                            className={classes.inputCityFieldsView}
                            value="Chennai"
                          />
                        </Box>
                      </Box>
                    </Box>
                    <Grid container spacing={4}>
                      <Grid item xl={4} md={4} sm={12} xs={12}>
                        <Typography variant="h4" className={classes.homecureLabel}>Heathcare Personnel Details</Typography>
                        <Box className={classes.containerBackground}>
                          <Box>
                            <Typography variant="h4" className={classes.homecureLabel}>Heathcare Personnel Details</Typography>
                            <Typography variant="h4" className={classes.text}>Dr. Mahesh</Typography>
                          </Box>
                          <Box>
                            <Typography variant="h4" className={classes.homecureLabel}>Degree</Typography>
                            <Box style={{ display: "flex", flex: "wrap", gap: "10px" }}>
                              <Typography variant="h4" className={classes.text} style={{ background: "#fff", padding: "4px 6px", borderRadius: "8px" }}>M.sc. Cardiology</Typography>
                              <Typography variant="h4" className={classes.text} style={{ background: "#fff", padding: "4px 6px", borderRadius: "8px" }}>MBBS</Typography>
                            </Box>
                          </Box>
                          <Box style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                            <Box style={{ width: "50%" }}>
                              <Typography variant="h4" className={classes.homecureLabel}>Date</Typography>
                              <Typography variant="h4" className={classes.text}>DD/MM/YYYY</Typography>
                            </Box>
                            <Box style={{ width: "50%" }}>
                              <Typography variant="h4" className={classes.homecureLabel}>Time</Typography>
                              <Typography variant="h4" className={classes.text}>HH:MM</Typography>
                            </Box>
                          </Box>
                          <Box>
                            <Typography variant="h4" className={classes.homecureLabel}>Consult Type</Typography>
                            <Typography variant="h4" className={classes.text}>Video Call</Typography>
                          </Box>
                          <Box>
                            <Typography variant="h4" className={classes.homecureLabel}>Consult ID</Typography>
                            <Typography variant="h4" className={classes.text}>1809</Typography>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xl={8} md={8} sm={12} xs={12}>
                        <Typography variant="h4" className={classes.homecureLabel}>Patient Detials</Typography>
                        <Box className={classes.containerBackground}>
                          <Grid container spacing={4}>
                            <Grid item xl={6} md={6} sm={12} xs={12}>
                              <Box>
                                <Typography variant="h4" className={classes.homecureLabel}>Patient Name</Typography>
                                <Typography variant="h4" className={classes.text}>Jhon</Typography>
                              </Box>
                              <Box style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                                <Box style={{ width: "50%" }}>
                                  <Typography variant="h4" className={classes.homecureLabel}>Age</Typography>
                                  <Typography variant="h4" className={classes.text}>24</Typography>
                                </Box>
                                <Box style={{ width: "50%" }}>
                                  <Typography variant="h4" className={classes.homecureLabel}>Gender</Typography>
                                  <Typography variant="h4" className={classes.text}>Male</Typography>
                                </Box>
                              </Box>
                              <Box className={classes.seperationLine} />
                              <Box>
                                <Typography variant="h4" className={classes.homecureLabel}>Vitals</Typography>
                              </Box>
                              <Box style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                                <Box style={{ width: "50%" }}>
                                  <Typography variant="h4" className={classes.homecureLabel}>Blood Pressure</Typography>
                                  <Typography variant="h4" className={classes.text}>109 / 80 mmHg</Typography>
                                </Box>
                                <Box style={{ width: "50%" }}>
                                  <Typography variant="h4" className={classes.homecureLabel}>Pulse</Typography>
                                  <Typography variant="h4" className={classes.text}>109 / minute</Typography>
                                </Box>
                              </Box>
                              <Box style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                                <Box style={{ width: "50%" }}>
                                  <Typography variant="h4" className={classes.homecureLabel}>Height</Typography>
                                  <Typography variant="h4" className={classes.text}>108 cm</Typography>
                                </Box>
                                <Box style={{ width: "50%" }}>
                                  <Typography variant="h4" className={classes.homecureLabel}>Weight</Typography>
                                  <Typography variant="h4" className={classes.text}>70 kg</Typography>
                                </Box>
                              </Box>
                              <Box>
                                <Typography variant="h4" className={classes.homecureLabel}>Chief Complaints</Typography>
                                <Typography variant="h4" className={classes.text}>Headache</Typography>
                              </Box>
                            </Grid>
                            <Grid item xl={6} md={6} sm={12} xs={12}>
                              <Box>
                                <Typography variant="h4" className={classes.homecureLabel}>Chief Complaints</Typography>
                                <Typography variant="h4" className={classes.text}>Headache</Typography>
                              </Box>
                              <Box className={classes.seperationLine} />
                              <Box>
                                <Typography variant="h4" className={classes.homecureLabel}>Details</Typography>
                              </Box>
                              <Box>
                                <Typography variant="h4" className={classes.homecureLabel}>Line 1</Typography>
                                <Typography variant="h4" className={classes.text}>Detail 1</Typography>
                              </Box>
                              <Box>
                                <Typography variant="h4" className={classes.homecureLabel}>Line 2</Typography>
                                <Typography variant="h4" className={classes.text}>Detail 2</Typography>
                              </Box>
                              <Box>
                                <Typography variant="h4" className={classes.homecureLabel}>Line 3</Typography>
                                <Typography variant="h4" className={classes.text}>Detail 3</Typography>
                              </Box>
                            </Grid>
                          </Grid>
                        </Box>
                      </Grid>
                    </Grid>
                    <Grid container spacing={4}>
                      <Grid item xl={4} md={4} sm={12} xs={12}>
                        <Typography variant="h4" className={classes.homecureLabel}>Diagnosis</Typography>
                        <Box className={classes.containerHPBackground} data-test-id="diagnosis">
                          <Box>
                            <Typography variant="h4" className={classes.homecureLabel} data-test-id="line1">Line 1</Typography>
                            <Typography variant="h4" className={classes.text} data-test-id="mention1">Mention here</Typography>
                          </Box>
                          <Box>
                            <Typography variant="h4" className={classes.homecureLabel} data-test-id="line2">Line 2</Typography>
                            <Typography variant="h4" className={classes.text} data-test-id="mention2">Mention here</Typography>
                          </Box>
                          <Box>
                            <Typography variant="h4" className={classes.homecureLabel} data-test-id="line3">Line 3</Typography>
                            <Typography variant="h4" className={classes.text} data-test-id="mention3">Mention here</Typography>
                          </Box>
                          <Box className={classes.seperationLine} data-test-id="line"/>
                          <Box>
                            <Typography variant="h4" className={classes.homecureLabel} data-test-id="diagnosistTest">Diagnosist Test</Typography>
                            <Typography variant="h4" className={classes.text} data-test-id="cbc">Complete Blood Test(CBC)</Typography>
                            <Typography variant="h4" className={classes.text} style={{ marginTop: "10px" }} data-test-id="prolactin">Prolactin</Typography>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xl={4} md={4} sm={12} xs={12}>
                        <Typography variant="h4" className={classes.homecureLabel}>Medicines</Typography>
                        <Box className={classes.containerHPBackground}>
                          <Typography variant="h4" className={classes.homecureLabel}>Tablets</Typography>
                          <Box>
                            <Typography variant="h4" className={classes.homecureLabel}>Name of the Medicine</Typography>
                            <Typography variant="h4" className={classes.text}>Paracitamol</Typography>
                          </Box>
                          <Box style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                            <Box style={{ width: "50%" }}>
                              <Typography variant="h4" className={classes.homecureLabel}>Dosage</Typography>
                              <Typography variant="h4" className={classes.text}>1 tablet</Typography>
                            </Box>
                            <Box style={{ width: "50%" }}>
                              <Typography variant="h4" className={classes.homecureLabel}>Duration</Typography>
                              <Typography variant="h4" className={classes.text}>1 week</Typography>
                            </Box>
                          </Box>
                          <Box style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                            <Box style={{ width: "50%" }}>
                              <Typography variant="h4" className={classes.homecureLabel}>Repeat</Typography>
                              <Typography variant="h4" className={classes.text}>Every 2 days</Typography>
                            </Box>
                            <Box style={{ width: "50%" }}>
                              <Typography variant="h4" className={classes.homecureLabel}>Time of the day</Typography>
                              <Typography variant="h4" className={classes.text}>Morning and Night</Typography>
                            </Box>
                          </Box>
                          <Box>
                            <Typography variant="h4" className={classes.homecureLabel}>To be taken</Typography>
                            <Typography variant="h4" className={classes.text}>Afterfood</Typography>
                          </Box>
                        </Box>
                      </Grid>
                      <Grid item xl={4} md={4} sm={12} xs={12}>
                        <Typography variant="h4" className={classes.homecureLabel}>General Advices</Typography>
                        <Box className={classes.containerHPBackground}>
                          <Box>
                            <Typography variant="h4" className={classes.homecureLabel}>Line 1</Typography>
                            <Typography variant="h4" className={classes.text}>Mention here</Typography>
                          </Box>
                          <Box>
                            <Typography variant="h4" className={classes.homecureLabel}>Line 2</Typography>
                            <Typography variant="h4" className={classes.text}>Mention here</Typography>
                          </Box>
                          <Box>
                            <Typography variant="h4" className={classes.homecureLabel}>Line 3</Typography>
                            <Typography variant="h4" className={classes.text}>Mention here</Typography>
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                    <Typography variant="h4" className={classes.signatureLabel}>Signature</Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </ThemeProvider>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = {
  righHPtWrapperView: {
    overflowX: "hidden" as "hidden",
    height: "100%",
  },
  righHPtWrapper: {
    height: "100%",
    padding: "50px",
    overflowY: "scroll" as "scroll",
    scrollbarColor: "#fff #fff",
    scrollbarWidth: "thin" as "thin",
    "& ::-webkit-scrollbar-button": {
      display: "none"
    },
  },
  profileHPHeadingView: {
    width: "100%",
    caretColor: "transparent",
    fontSize: "28px",
    fontFamily: "SF Pro Text Bold",
    color: "#292929",
  },
  headerHPContainerView: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  orderHeadingView: {
    display: "flex",
    alignItems: "center",
    marginBottom: "40px",
    justifyContent: "flex-start",
    gap: "25px",
  },
  headingIconView: {
    display: "flex",
    alignItems: "center",
    fontSize: "22px",
    padding: "13px 9px 13px 17px",
    justifyContent: "center",
    borderRadius: "8px",
    border: "1px solid #E9E9E9",
  },
  inputNameFieldsView: {
    width: "100%",
    fontSize: "14px",
    borderRadius: "8px",
    "& .MuiOutlinedInput-root": {
      borderTopLeftRadius: "8px",
      borderBottomLeftRadius: "8px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#fff !important"
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderWidth: "1px",
      borderColor: "#fff !important"
    },
    "& .MuiInputBase-root": {
      color: "#292929",
      fontFamily: "SF Pro Text Regular",
      background: "white !important",
      fontSize: "14px",
      border: "none"
    },
    "& input": {
      fontSize: "14px",
      border: "none",
      color: "#292929",
      fontFamily: "SF Pro Text Regular",
      "&::placeholder": {
        opacity: 1,
        fontSize: "14px",
        fontFamily: "SF Pro Text Regular",
        color: "#9D9D9D !important",
      }
    },
    fontFamily: "SF Pro Text Regular"
  },
  inputCityFieldsView:{
    width: "100%",
    borderRadius: "8px",
    fontSize: "14px",
    "& .MuiInputBase-root":{
      color: "#292929",
      fontSize: "14px",
      border: "none",
      fontFamily: "SF Pro Text Regular",
      background: "white !important",
    },
    "& .MuiOutlinedInput-root": {
      borderTopRightRadius: "8px",
      borderBottomRightRadius: "8px",
      borderLeft: "1px solid #DBDBDB",
      borderBottomLeftRadius: "0px",
      borderTopLeftRadius: "0px"
    },
    "& input": {
      fontSize: "14px",
      fontFamily: "SF Pro Text Regular",
      color: "#292929",
      border: "none",
      "&::placeholder": {
        fontSize: "14px",
        opacity: 1,
        color: "#9D9D9D !important",
        fontFamily: "SF Pro Text Regular",
      }
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#fff !important",
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderWidth: "1px",
      borderColor: "#fff !important",
    },
    fontFamily: "SF Pro Text Regular"
  },
  inputContainerView: {
    display: "flex",
    background: "#EBF3FC",
    alignItems: "center" as "center",
    alignContent: "center" as "center",
    padding: "15px",
    marginBottom: "30px",
    width: "60%"
  },
  homeCureHeadingView: {
    fontSize: "28px",
    fontFamily: "SF Pro Text Bold",
    color: "#014866",
    marginRight: "10px",
    '@media (max-width: 600px)': {
      display: "none"
    },
    caretColor: "transparent"
  },
  seperationLineView: {
    borderBottom: "6px solid #02A405",
    width: "176px",
    transform: "rotate(90deg)",
    borderBottomRightRadius: "3px",
    borderBottomLeftRadius: "3px",
    borderTopLeftRadius: "3px",
    borderTopRightRadius: "3px",
    marginRight: "5px",
    '@media (max-width: 600px)': {
      display: "none"
    }
  },
  homecureLabel: {
    fontFamily: "SF Pro Text Medium",
    fontSize: "16px",
    color: "#292929",
    caretColor: "transparent",
    margin: "15px auto 8px"
  },
  signatureLabel: {
    fontFamily: "SF Pro Text Medium",
    fontSize: "16px",
    color: "#292929",
    caretColor: "transparent",
    margin: "25px auto 8px"
  },
  containerBackground: {
    background: "#f1f0f0",
    minHeight: "375px",
    borderRadius: "8px",
    padding: "20px",
    marginTop: "20px"
  },
  containerHPBackground: {
    background: "#f1f0f0",
    minHeight: "300px",
    borderRadius: "8px",
    padding: "20px",
    marginTop: "20px"
  },
  text: {
    color: "#9D9D9D !important",
    fontSize: "14px",
    fontFamily: "SF Pro Text Regular",
  },
  dateTimeContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between"
  },
  seperationLine: {
    background: "#DDD",
    border: "none",
    width: "100%",
    margin: "10% auto auto",
    height: "1px"
  }
}

export default withStyles(styles)(HealthcareViewPersonnelPrescription);
// Customizable Area End