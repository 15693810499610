import * as React from "react";
// Customizable Area Start
import { withStyles, Box, Typography, Grid, ThemeProvider, createTheme } from "@material-ui/core";
import CustomisableUserProfilesController, {
    Props
} from "./CustomisableUserProfilesController";
import { styles } from "./styles/CustomisableUserProfiles";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SidebarNotificationWrapper from "../../notifications/src/Notifications.web";

const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff"
        }
    }
});
// Customizable Area End

export class CustomisableAppointmentViewProfile extends CustomisableUserProfilesController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start 
    async componentDidMount() {
        this.getappointmentList();
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        let timeSlots = this.generateTimeSlots();
        const bookedSlots = this.findBookingsForSlots(timeSlots);
        // Customizable Area End
        return (
            // Customizable Area Start
            <>
                <ThemeProvider theme={theme}>
                    <Box className={classes.leftWrapper}>
                        <SidebarNotificationWrapper {...this.props} >
                            <Box className={classes.rightWrapper}>
                                <Box>
                                    <Box className={classes.healthDetailsSubContainer}>
                                        <Box
                                            className={classes.hospitalEditBackButtons}
                                            data-test-id="hospitalBackBtnBox"
                                            onClick={this.backTopHpProfile}
                                        >
                                            <ArrowBackIosIcon
                                                data-test-id="hospitalBackBtn"
                                                className={classes.arrowIcon}
                                                onClick={this.backTopHpProfile}
                                            />
                                        </Box>
                                        <Box className={classes.editHospitalProfile}>
                                            <Typography variant="h6" className={classes.registerAppointmentHeading}>
                                                Doctor's Availability
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Box className={classes.boxNewContainer}>
                                        <Typography className={classes.registerNewHpDateLabelNew}>
                                            Select Date
                                        </Typography>
                                        <Box className={classes.customDatePickerContainer}>
                                            <span className={classes.dateInputNew} style={{ marginTop: '10px' }}>
                                                <DatePicker
                                                    data-test-id="date"
                                                    popperPlacement="bottom-start"
                                                    popperModifiers={[
                                                        { name: 'offset', options: { offset: [60, 0] } },
                                                        { name: 'preventOverflow', options: { rootBoundary: 'viewport', tether: false, altAxis: true } },
                                                    ]}
                                                    calendarClassName={classes.datepickerClassNew}
                                                    onChange={this.handleDateChange}
                                                    className={classes.dateField}
                                                    dateFormat="dd/MM/yyyy"
                                                    selected={this.state.date}
                                                    placeholderText="DD/MM/YYYY"
                                                    value={this.newFormatDate(this.state.date)}
                                                    minDate={new Date()}
                                                />
                                            </span>
                                        </Box>
                                        <Box style={{marginTop:"40px"}}>
                                            <>
                                                <Box className={classes.scheduler}>
                                                    <Box className={classes.timeSlots}>
                                                        {bookedSlots.map((appointment:any, index:any) => (
                                                            <Grid
                                                                container
                                                                key={index}
                                                                style={{display:"fiex"}}
                                                            >
                                                                <Grid item xs={1} style={{maxWidth:"80px",height:"90px"}}>
                                                                    <Box className={this.renderTimeSlotClasses(appointment,classes)}>{this.renderTimeSlot(appointment,index)}</Box>
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xs={11}
                                                                    className={this.appointBookingStyle(appointment)}
                                                                    style={{ marginTop:"10px" , borderBottom: appointment.length-1 === index ? "2px dashed #D8D8D8 !important " : "none !important"}}
                                                                >
                                                                    {appointment.status !== "Not Available" ? appointment.name : "Not Available"}
                                                                    <Box style={{fontFamily:"SF Pro Text Regular",fontSize:"16px",color:appointment.status === "accepted" ? "#104866" : "#808080",marginTop:"8px"}}>{(appointment.status === "pending" || appointment.status === "accepted" || appointment.status === "completed") ? appointment.slotDuration : ""}</Box>
                                                                </Grid>
                                                            </Grid>
                                                        ))}
                                                    </Box>
                                                </Box>
                                            </>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </SidebarNotificationWrapper>
                    </Box>
                </ThemeProvider>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export default withStyles(styles)(CustomisableAppointmentViewProfile);
// Customizable Area End