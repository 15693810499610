// Customizable Area Start
import React from "react";
import HpOrderManagementController, { Props } from "../HpOrderManagementController";
import { Box, ThemeProvider, Typography, createTheme, withStyles, Grid, Button } from "@material-ui/core";
import Sidebar from "../../../../components/src/Sidebar.web";
import { prescriptionIcon, noData, ProfileIcon } from "./../assets";

const theme = createTheme({
    palette: {
        primary: {
            contrastText: "#fff",
            main: "#0000ff",
        }
    }
});
// Customizable Area End

export class HealthcarePersonnelOrderManagement extends HpOrderManagementController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    noDataFound = (classes: any) => {
        return (
            <>
                {
                    this.state.loading ? (
                        <>
                            <Box className={classes.noData}>Loading...</Box>
                        </>) : (
                        <>
                            <Box className={classes.noDataDashboardContainer}>
                                <img
                                    src={noData}
                                    width={60}
                                    height={60}
                                    className={classes.closeIcon}
                                />
                                <Box className={classes.noData}>No results found</Box>
                            </Box>
                        </>)
                }
            </>
        )
    }

    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        // Customizable Area End
        return (
            // Customizable Area Start
            <>
                <ThemeProvider theme={theme}>
                    <Grid container className={classes.orderHPManagementContainer}>
                        <Grid item xs={1}>
                            <Sidebar />
                        </Grid>
                        <Grid item xs={11}>
                            <Box className={classes.orderHpManagementAvailableContainer}>
                                <Box className={classes.headerHpContainer}>
                                    <Box className={classes.orderHpHeading}>
                                        <Typography variant="h4" className={classes.profileHPHeading} style={{ textTransform: "capitalize" }}>Bookings</Typography>
                                    </Box>
                                </Box>
                                <Box className={classes.prescriptionContainer} data-test-id="moveToPrescriptionScreen" onClick={this.moveToPrescriptionScreen}>
                                    <Box>
                                        <img src={prescriptionIcon} width={56} height={56} className={classes.ePrescriotionImg} />
                                        <Typography className={classes.ePrescriotion}>Create E-Prescription</Typography>
                                    </Box>
                                </Box>
                                <Box className={classes.newOrderBookingMainContainer}>
                                    <Box className={classes.newOrderBookingHeadingContainer}>
                                        <Typography variant="h6" className={classes.titleBookingType} style={{ textAlign: "center", textTransform: "capitalize", caretColor: "transparent" }}>Upcoming Bookings</Typography>
                                        <Typography data-test-id="seeHPAll" variant="h6" className={classes.titleSeeAll}>
                                            See All
                                        </Typography>
                                    </Box>
                                    <Box className={classes.allBookingContainer} data-test-id="cardContainerUpcoming">
                                        {this.state.appointmentUpcoming.map((data: any, index: number) => (
                                            <Box key={index} data-test-id="upcomingIndex">
                                                <Box className={classes.bookingContainer} data-test-id="subContainerUpcoming">
                                                    <Box className={classes.bookingWrapper} data-test-id="bookingWrapperUpcoming">
                                                        <Box className={classes.imageContainer} data-test-id="imageContainerUpcoming">
                                                            <img data-test-id={`profile${index}`} src={ProfileIcon} className={classes.profileSize} />
                                                        </Box>
                                                        <Box className={classes.bookingsInfoSection}>
                                                            <Box>
                                                                <Typography variant="h6" data-test-id="nameUpcoming" className={classes.nameLabel}>{data.attributes.name}</Typography>
                                                                <Typography variant="h6" data-test-id="roleUpcoming" className={classes.healthDetails}>Health Details</Typography>
                                                            </Box>
                                                            <Box>
                                                                <Box>
                                                                    <Typography variant="h6" data-test-id="timeUpcoming" className={classes.time_duration}>{data.attributes.time_duration}</Typography>
                                                                    <Typography variant="h6" data-test-id="roleUpcoming" className={classes.time}>{data.attributes.time}</Typography>
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                                <Box className={classes.bookingBtnContainer}>
                                                </Box>
                                            </Box>
                                        ))}
                                    </Box>
                                </Box>
                                <Box className={classes.newOrderBookingMainContainer}>
                                    <Box className={classes.newOrderBookingHeadingContainer}>
                                        <Typography variant="h6" className={classes.titleBookingType} style={{ textAlign: "center", textTransform: "capitalize", caretColor: "transparent" }}>Past Bookings</Typography>
                                        <Typography data-test-id="seeHPAll" variant="h6" className={classes.titleSeeAll} style={{ caretColor: "transparent", cursor: "pointer", color: "#808080", fontSize: "18px", fontFamily: "SF Pro Text Medium" }}>
                                            See All
                                        </Typography>
                                    </Box>
                                    <Box className={classes.allBookingContainer} data-test-id="cardContainer">
                                        {this.state.appointmentPast.map((data: any, index: number) => (
                                                <Box key={index} className={classes.wrapper}>
                                                    <Box className={classes.bookingContainer} data-test-id="subContainer">
                                                        <Box className={classes.bookingWrapper} data-test-id="bookingWrapper">
                                                            <Box className={classes.imageContainer}>
                                                                <img data-test-id={`profile${index}`} src={ProfileIcon} className={classes.profileSize} />
                                                            </Box>
                                                            <Box className={classes.bookingsInfoSection}>
                                                                <Box>
                                                                    <Typography variant="h6" data-test-id="name" className={classes.nameLabel}>{data.attributes.name}</Typography>
                                                                    <Typography variant="h6" data-test-id="role" className={classes.healthDetails}>Health Details</Typography>
                                                                </Box>
                                                                <Box>
                                                                    <Box>
                                                                        <Typography variant="h6" data-test-id="time" className={classes.time_duration}>{data.attributes.time_duration}</Typography>
                                                                        <Typography variant="h6" data-test-id="role" className={classes.time}>{data.attributes.time}</Typography>
                                                                    </Box>
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                    <Box className={classes.bookingBtnContainer}>
                                                        <Button className={classes.statusBtn}>Completed</Button>
                                                    </Box>
                                                </Box>
                                            )
                                        )}
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </ThemeProvider>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const styles = {
    orderHPManagementContainer: {
        display: "flex",
    },
    orderHpManagementAvailableContainer: {
        margin: "40px 50px",
        width: "70%",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column" as "column",
        justifyContent: "flex-start",
        alignItems: "flex-start" as "flex-start",
        gap: "20px",
        '@media (max-width: 790px)': {
            width: "100%"
        }
    },
    newOrderBookingMainContainer: {
        minHeight: "25vh",
        width: "85%",
    },
    newOrderBookingHeadingContainer: {
        display: "flex",
        justifyContent: "space-between",
        paddingTop: "20px",
        alignItems: "center",
    },
    titleBookingType: {
        fontSize: "22px",
        fontFamily: "SF Pro Text Bold",
        color: "#292929",
        '@media (max-width: 650px)': {
            fontSize: "18px"
        }
    },
    titleSeeAll: {
        caretColor: "transparent",
        cursor: "pointer",
        color: "#808080",
        fontSize: "18px",
        fontFamily: "SF Pro Text Medium",
        '@media (max-width: 650px)': {
            fontSize: "16px"
        }
    },
    headerHpContainer: {
        width: "85%",
        display: "flex",
        alignItems: "center" as "center",
        justifyContent: "space-between",
        caretColor: "transparent"
    },
    orderHpHeading: {
        display: "flex",
        alignItems: "center" as "center",
        justifyContent: "flex-start",
        gap: "25px",
    },
    profileHPHeading: {
        width: "100%",
        fontSize: "28px",
        fontFamily: "SF Pro Text Bold",
        color: "#292929",
        caretColor: "transparent"
    },
    prescriptionContainer: {
        width: "223px",
        height: "85px",
        borderRadius: "8px",
        border: "1px solid #292929",
        padding: "5%"
    },
    ePrescriotion: {
        fontFamily: "SF Pro Text Bold",
        fontSize: "20px",
        color: "#292929",
        textAlign: "center" as "center"
    },
    ePrescriotionImg: {
        display: "flex",
        margin: "auto auto 10px"
    },
    wrapper: {
        display: "flex",
    },
    bookingContainer: {
        marginTop: "20px",
        maxWidth: "590px",
        width: "75%",
        minWidth: "256px",
        '@media (max-width: 950px)': {
            width: "50%"
        },
        '@media (max-width: 490px)': {
            minWidth: "230px"
        },
    },
    bookingBtnContainer: {
        margin: "20px auto 0px",
        width: "25%"
    },
    bookingWrapper: {
        display: "flex",
        padding: "15px 0px 15px 15px",
        background: "#FFFFFF",
        border: "1px solid rgba(179, 179, 179, 0.18)",
        boxShadow: "0px 2px 40px rgba(186, 186, 186, 0.13)",
        borderRadius: "8px",
        gap: "15px",

    },
    imageContainer: {
        width: "85px",
        height: "85px",
    },
    profileSize: {
        width: "85px",
        borderRadius: "50%",
        height: "85px",
        "@media (max-width:1070px)": {
            width: "75px",
            height: "75px",
        },
    },
    bookingsInfoSection: {
        display: "flex",
        justifyContent: "space-between",
        width: "100%",
        alignItems: "flex-start",
        marginRight: "5px",
        '@media (max-width: 950px)': {
            display: "block !important",
        }
    },
    nameLabel: {
        fontSize: "20px",
        caretColor: "transparent",
        fontFamily: "SF Pro Text Bold",
        color: "#292929",
        overflow: "hidden" as "hidden",
        whiteSpace: "nowrap" as "nowrap",
        textOverflow: "ellipsis" as "ellipisis",
        width: "150px",
        marginTop: "15px",
        "@media (max-width:1070px)": {
            fontSize: "15px",
        },
    },
    healthDetails: {
        fontSize: "16px",
        caretColor: "transparent",
        fontFamily: "SF Pro Text Regular",
        color: "#3780E0",
        marginTop: "5px",
        marginRight: "30px",
        textAlign: "right" as "right",
        '@media (max-width: 1070px)': {
            fontSize: "14px"
        },
        '@media (max-width: 950px)': {
            fontSize: "14px",
            textAlign: "left" as "left",
        }
    },
    time_duration: {
        fontSize: "18px",
        caretColor: "transparent",
        fontFamily: "SF Pro Text Medium",
        color: "#808080",
        marginTop: "15px",
        marginRight: "30px",
        textAlign: "right" as "right",
        '@media (max-width: 1070px)': {
            fontSize: "14px"
        },
        '@media (max-width: 950px)': {
            fontSize: "14px",
            textAlign: "left" as "left",
            marginTop: "0px",
        }
    },
    time: {
        fontSize: "18px",
        caretColor: "transparent",
        fontFamily: "SF Pro Text Medium",
        color: "#808080",
        marginTop: "5px",
        marginRight: "30px",
        textAlign: "right" as "right",
        '@media (max-width: 1070px)': {
            fontSize: "14px"
        },
        '@media (max-width: 950px)': {
            fontSize: "14px",
            textAlign: "left" as "left",
        }
    },
    statusBtn: {
        caretColor: "transparent",
        backgroundColor: "#30D158",
        color: "#FFFFFF",
        border: "none",
        borderRadius: "8px",
        width: "179px",
        height: "45px",
        cursor: "pointer",
        fontSize: "18px",
        fontFamily: "SF Pro Text Bold",
        marginTop: "33px",
        "&:hover": {
            backgroundColor: "#30D158",
            color: "#fff"
        },
        textTransform: "capitalize" as "capitalize",
        float: "right" as "right",
        '@media (max-width: 1250px)':
        {
            width: "125px"
        },
        '@media (max-width: 870px)': {
            fontSize: "14px",
            width: "100px"
        },
        '@media (max-width: 490px)': {
            fontSize: "13px",
            width: "90px"
        },
    },
}

export default withStyles(styles)(HealthcarePersonnelOrderManagement);
// Customizable Area End
