Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.dashboardGetUrl = "/dashboard/dashboards";
exports.dashboarContentType = "application/json";
exports.dashboarApiMethodType = "GET";
exports.dashboardHost = "<calculated when request is sent>";
exports.dashboarUserAgent = "PostmanRuntime/7.26.5";
exports.dashboarAccept = "*/*";
exports.dashboarAcceptEncoding = "gzip, deflate, br";
exports.dashboarConnection = "keep-alive";
exports.dashboartoken = "";
exports.labelTitleText = "dashboard";
exports.labelBodyText = "dashboard Body";
exports.service = "patient_services";
exports.ayahDashboardDetailsEndPoint = "healthcare_personnels/healthcare_personnel_dashboard";
exports.ayahDashboardTotalBookingDetailsEndPoint = "healthcare_personnels/total_booking";
exports.ayahDashboardUpcomingBookingDetailsEndPoint = "healthcare_personnels/upcoming_bookings";
exports.ayahDashboardPaitentHealthDetailsEndPoint = "healthcare_personnels/patient_health_details";
exports.updateHealthcarePersonnelBooking = "healthcare_personnels/update_healthcare_personnel_booking";
exports.ayahDashboardPastBookingDetailsEndPoint = "healthcare_personnels/past_bookings";
exports.patchApiMethodType = "PATCH";
exports.doctorSearchEndPoint = "hospital_doctors";
exports.patientAdvanceSearchEndPoint = "patient_search";
exports.HospitalDashboardEndPoint = "dashboards/hospital";
exports.HospitalDashboard ={
  TOTAL_APPOINTMENTS : "Total Appointments",
  TODAYS_APPOINTMENTS : "Today's Appointments",
  TOTAL_BED_BOOKED : "Total Beds Booked",
  AVAILABLE_BEDS : "Available Beds",
  ISOLATION_BEDS:"Isolation Beds",
  BABIES:"Babies",
  ICU_BEDS:"ICU Beds",
  OPTHALMOLOGIST:"Opthalmologist"
};
exports.PlaceholderSearchDoctors = "Search Doctors"
exports.medicDashboardGetApi = "dashboards/medic_dashboard";
exports.updateMedicHealthcarePersonnelBooking = "medic_reservations/update_medic_booking";
exports.medicsDashboardPaitentHealthDetailsEndPoint = "medic_reservations/patient_health_details";
exports.medicsDashboardUpcomingBookingEndPoint = "medic_reservations/upcoming_bookings";
exports.medicsDashboardPastBookingEndPoint = "medic_reservations/past_bookings";
exports.putApiMethodType = "PUT";
exports.medicDashboardTotalBookingDetailsEndPoint = "medic_reservations/total_booking";
exports.PlaceholderSearchDoctors = "Search Doctors";
exports.userType = {
  Patient:"patient",
  HealthcarePersonnel:"healthcare_personnel"
};
exports.AvailableTimeSlots = "Available time slots";
exports.NoBedsAvailable = "No bed available";
exports.graphApi = "dashboards/healthcare_personnel";
exports.caretakerGraphApi = "dashboards/caretaker_personnel/";
exports.InvalidUserMessage = "Please login as a valid user type";
exports.TokenHasExpired = 'Token has Expired'
exports.UserRole = {
  Ayah:"ayah",
  Nurse:"nurse"
}
// Customizable Area End