// App.js - WEB
import React, { Component } from "react";
import { View } from "react-native";
import firebase from "firebase"
import { connect } from "react-firebase"

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from "../../blocks/info-page/src/InfoPageBlock";
import AlertBlock from "../../blocks/alert/src/AlertBlock.web";
import UserRole from "../../blocks/rolesandpermissions/src/UserRole.web";
import MobileAccountLoginBlock from "../../blocks/mobile-account-login/src/MobileAccountLoginBlock.web";
import OTPInputAuth from "../../blocks/otp-input-confirmation/src/OTPInputAuth.web";
import OTPVerified from "../../blocks/otp-input-confirmation/src/OTPVerified.web";
import PhoneNumberRegistration from "../../blocks/email-account-registration/src/PhoneNumberRegistration.web";
import EmailAccountRegistration from "../../blocks/email-account-registration/src/EmailAccountRegistration.web";
import EmailVerified from "../../blocks/email-account-registration/src/EmailVerified.web";
import PatientRegisterDetails from "../../blocks/email-account-registration/src/PatientRegisterDetails.web";
import PatientHealthDetails from "../../blocks/email-account-registration/src/PatientHealthDetails.web";
import RegistrationSuccess from "../../blocks/email-account-registration/src/RegistrationSuccess.web";
import HospitalRegisterDetails from "../../blocks/email-account-registration/src/HospitalRegisterDetails.web";
import HospitalRegistrationSuccess from "../../blocks/email-account-registration/src/HospitalRegistrationSuccess";
import ConfirmEmail from "../../blocks/email-account-registration/src/ConfirmEmail.web";
import HealthCarePersonalRegistrationType from "../../blocks/email-account-registration/src/HealthCarePersonalRegistrationType.web";
import HealthCarePersonalRegistration from "../../blocks/email-account-registration/src/HealthCarePersonalRegistration.web";
import HealthCarePersonnelAyahRegistration from "../../blocks/email-account-registration/src/HealthCarePersonnelAyahRegistration.web";
import CustomisableViewUserProfile from "../../blocks/customisableuserprofiles/src/CustomisableViewUserProfile.web";
import CustomisableEditUserProfile from "../../blocks/customisableuserprofiles/src/CustomisableEditUserProfile.web";
import CustomisableHealthDetailsUserProfiles from "../../blocks/customisableuserprofiles/src/CustomisableHealthDetailsUserProfiles.web";
import HealthCarePersonnelRegistration from "../../blocks/email-account-registration/src/HealthCarePersonnelRegistration.web";
import Dashboard from "../../blocks/dashboard/src/Dashboard.web";
import CustomisableHPViewUserProfile from "../../blocks/customisableuserprofiles/src/CustomisableHPViewUserProfile.web";
import CustomisableViewHospitalProfile from "../../blocks/customisableuserprofiles/src/CustomisableViewHospitalProfile.web";
import CustomisableEditHospitalProfile from "../../blocks/customisableuserprofiles/src/CustomisableEditHospitalProfile.web";
import CustomisableEditHealthcarePersonnel from "../../blocks/customisableuserprofiles/src/CustomisableEditHealthcarePersonnel.web";
import Reservations2Booking from '../../blocks/reservations2/src/Reservations2Booking.web';
import Reservations2ViewHospitalBooking from '../../blocks/reservations2/src/Reservations2ViewHospitalBooking.web';
import Reservations2HospitalBooking from "../../blocks/reservations2/src/Reservations2HospitalBooking.web";
import Reservations2NurseBooking from '../../blocks/reservations2/src/Reservations2NurseBooking.web';
import Reservations2MedicBooking from '../../blocks/reservations2/src/Reservations2MedicBooking.web';
import Scheduling from "../../blocks/scheduling/src/Scheduling.web";
import Reservations2NurseProfile from '../../blocks/reservations2/src/Reservations2NurseProfile.web';
import Reservations2NurseProfileReviews from '../../blocks/reservations2/src/Reservations2NurseProfileReviews.web';
import Reservations2ViewNurseBooking from '../../blocks/reservations2/src/Reservations2ViewNurseBooking.web';
import LocationView from "../../blocks/location/src/LocationView.web"
import Filter from "../../blocks/filteritems/src/Filter.web";
import SchedulingCalendar from "../../blocks/scheduling/src/SchedulingCalendar.web";
import Reservations2DoctorBooking from "../../blocks/reservations2/src/Reservations2DoctorBooking.web";
import Reservations2ViewDoctorBooking from "../../blocks/reservations2/src/Reservations2ViewDoctorBooking.web";
import CustomisableManageHospital from '../../blocks/customisableuserprofiles/src/CustomisableManageHospital.web';
import CustomisableHospitalManagementViewUserProfile from "../../blocks/customisableuserprofiles/src/CustomisableHospitalManagementViewUserProfile.web";
import CustomisableAppointmentViewProfile from "../../blocks/customisableuserprofiles/src/CustomisableAppointmentViewProfile.web";
import HelpCenter from '../../blocks/customisableuserprofiles/src/HelpCenter.web'
import DeactivateProfile from '../../blocks/customisableuserprofiles/src/DeactivateProfile.web'
import HomeCureAboutus from '../../blocks/customisableuserprofiles/src/HomeCureAboutus.web'
import HomeCureTermsAndConditions from '../../blocks/customisableuserprofiles/src/HomeCureTermsAndConditions.web'
import HomeCurePrivacyPolicy from '../../blocks/customisableuserprofiles/src/HomeCurePrivacyPolicy.web'
import PatientOrderManagement from '../../blocks/ordermanagement/src/PatientOrderManagement.web'
import UserProfile from '../../blocks/ordermanagement/src/UserProfile.web'
import HospitalBookingManagement from '../../blocks/ordermanagement/src/HospitalBookingManagement.web'
import HPUserProfile  from '../../blocks/ordermanagement/src/HPUserProfile.web'
import HealthcareViewPersonnelPrescription  from '../../blocks/ordermanagement/src/components/HealthcareViewPersonnelPrescription.web'
import HealthcarePersonnelOrderManagement from "../../blocks/ordermanagement/src/components/HealthcarePersonnelOrderManagement.web"
import HealthcarePersonnelPrescription from "../../blocks/ordermanagement/src/components/HealthcarePersonnelPrescription.web"
import PatientPrescriptionView from "../../blocks/ordermanagement/src/components/PatientPrescriptionView.web"
import HPProfileView from "../../blocks/ordermanagement/src/components/HPProfileView.web"

const routeMap = {
  DefaultDashboardPage:{
    component: UserRole,
    path: "/",
    exact:true
  },
  InfoPage: {
    component: InfoPage,
    path: "/InfoPage"
  },

  AlertWeb: {
    component: AlertBlock,
    path: "*/AlertWeb",
    modal: true
  },

  UserRole: {
    component: UserRole,
    path: "/UserRole",
  },
  MobileAccountLoginBlock: {
    component: MobileAccountLoginBlock,
    path: "/MobileAccountLogin",
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: "/OTPInputAuth"
  },
  OTPVerified: {
    component: OTPVerified,
    path: "/OTPVerified"
  },
  PhoneNumberRegistration: {
    component: PhoneNumberRegistration,
    path: "/PhoneNumberRegistration"
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: "/EmailAccountRegistration"
  },
  EmailVerified: {
    component: EmailVerified,
    path: "/EmailVerified"
  },
  PatientRegisterDetails: {
    component: PatientRegisterDetails,
    path: "/PatientRegisterDetails"
  },
  PatientHealthDetails: {
    component: PatientHealthDetails,
    path: "/PatientHealthDetails"
  },
  RegistrationSuccess: {
    component: RegistrationSuccess,
    path: "/RegistrationSuccess"
  },
  HospitalRegisterDetails: {
    component: HospitalRegisterDetails,
    path: "/HospitalRegisterDetails"
  },
  HospitalRegistrationSuccess: {
    component: HospitalRegistrationSuccess,
    path: "/HospitalRegistrationSuccess"
  },
  ConfirmEmail: {
    component: ConfirmEmail,
    path: "/ConfirmEmail"
  },
  HealthCarePersonalRegistrationType: {
    component: HealthCarePersonalRegistrationType,
    path: "/HealthCarePersonalRegistrationType"
  },
  HealthCarePersonalRegistration: {
    component: HealthCarePersonalRegistration,
    path: "/HealthCarePersonalRegistration"
  },
  HealthCarePersonnelAyahRegistration:{
    component: HealthCarePersonnelAyahRegistration,
    path: "/HealthCarePersonnelAyahRegistration"
  },
  CustomisableViewUserProfile: {
    component: CustomisableViewUserProfile,
    path: "/Profile",
  },
  CustomisableEditUserProfile: {
    component: CustomisableEditUserProfile,
    path: "/EditProfile",
  },
  CustomisableHealthDetailsUserProfiles: {
    component: CustomisableHealthDetailsUserProfiles,
    path: "/EditHealthDetails",
  },
  HealthCarePersonnelRegistration:{
    component: HealthCarePersonnelRegistration,
    path: "/HealthCarePersonnelRegistration"
  },
  Dashboard:{
    component: Dashboard,
    path: "/Dashboard"
  },
  CustomisableViewHospitalProfile:{
    component: CustomisableViewHospitalProfile,
    path: "/HospitalProfile"
  },
  CustomisableEditHospitalProfile:{
    component: CustomisableEditHospitalProfile,
    path: "/UpdateHospitalProfile"
  },
  CustomisableManageHospital:{
    component:CustomisableManageHospital,
    path:'/hospital-manage'
  },
  CustomisableHPViewUserProfile:{
    component: CustomisableHPViewUserProfile,
    path: "/HealthcarePersonneProfile"
  },
  CustomisableEditHealthcarePersonnel:{
    component: CustomisableEditHealthcarePersonnel,
    path: "/UpdateHealthcarePersonneProfile"
  },
  HelpCenter:{
    component: HelpCenter,
    path: '/HelpCenter'
  },
  DeactivateProfile:{
    component:DeactivateProfile,
    path: '/DeactivateProfile'
  },
  HomeCureAboutus:{
    component:HomeCureAboutus,
    path: '/HomeCureAboutussettings'
  },
  HomeCurePrivacyPolicy:{
    component: HomeCurePrivacyPolicy,
    path: '/HomeCurePrivacyPolicy'
  },
  HomeCureTermsAndConditions:{
    component: HomeCureTermsAndConditions,
    path: '/HomeCureTermsAndConditions'
  },
  Reservations2Booking:{
    component: Reservations2Booking,
    path: "/PatientHospitalBooking"
  },
  Reservations2NurseProfile: {
    component: Reservations2NurseProfile,
    path: "/ViewHpProfile"
  },
  Reservations2NurseProfile: {
    component: Reservations2NurseProfile,
    path: "/ViewHpProfile/:navigationBarTitleText"
  },
  Reservations2NurseProfileReviews: {
    component: Reservations2NurseProfileReviews,
    path: "/hpReviews/:navigationBarTitleText"
  },
  Reservations2ViewNurseBooking: {
    component: Reservations2ViewNurseBooking,
    path: "/bookHp/:navigationBarTitleText"
  },
  Reservations2ViewHospitalBooking:{
    component: Reservations2ViewHospitalBooking,
    path: "/ViewHospitalBooking/:navigationBarTitleText"
  },
  Reservations2HospitalBooking:{
    component: Reservations2HospitalBooking,
    path: "/bookHospital/:navigationBarTitleText"
  },
  Reservations2NurseBooking:{
    component: Reservations2NurseBooking,
    path: "/PatientHpBooking"
  },
  Reservations2MedicBooking:{
    component: Reservations2MedicBooking,
    path: "/PatientMedicBooking"
  },
  Scheduling:{
    component: Scheduling,
    path: "/Scheduling"
  },
  LocationView:{
    component: LocationView,
    path: "/LocationView"
  },
  Filter:{
    component: Filter,
    path: "/Filter"
  },
  SchedulingCalendar:{
    component: SchedulingCalendar,
    path: "/SchedulingCalendar"
  },
  Reservations2DoctorBooking:{
    component: Reservations2DoctorBooking,
    path: "/DoctorBooking"
  },
  Reservations2ViewDoctorBooking:{
    component: Reservations2ViewDoctorBooking,
    path: "/MedicViewBooking/:navigationBarTitleText"
  },
  HospitalBookingManagement:{
    component:HospitalBookingManagement,
    path:'/manage-hospital-reservation'
  },
  CustomisableHospitalManagementViewUserProfile:{
    component: CustomisableHospitalManagementViewUserProfile,
    path: "/healthcareProfile/:navigationBarTitleText"
  },
  CustomisableAppointmentViewProfile: {
    component: CustomisableAppointmentViewProfile,
    path: "/CustomisableAppointmentViewProfile"
  },
  Home: {
    component: HomeScreen,
    path: "/Homescreen",
    exact: true
  },
  PatientOrderManagement: {
    component: PatientOrderManagement,
    path: "/PatientOrderManagement"
  },
  UserProfile: {
    component: UserProfile,
    path: "/UserProfile/:navigationBarTitleText"
  },
  HPUserProfile: {
    component: HPUserProfile,
    path: "/HpProfile/:navigationBarTitleText"
  },
  HealthcareViewPersonnelPrescription: {
    component: HealthcareViewPersonnelPrescription,
    path: "/HealthcareViewPersonnel"
  },
  HealthcarePersonnelOrderManagement:{
    component: HealthcarePersonnelOrderManagement,
    path: "/HpBooking"
  },
  HealthcarePersonnelPrescription:{
    component: HealthcarePersonnelPrescription,
    path: "/editPrescription"
  },
  PatientPrescriptionView:{
    component: PatientPrescriptionView,
    path: "/prescription"
  }, 
  HPProfileView:{
    component: HPProfileView,
    path: "/viewProfile"
  }, 
};

const firebaseAPI = firebase.initializeApp({
  apiKey: "AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4",
  authDomain: "rnmasterapp-c11e9.firebaseapp.com",
  databaseURL: "https://rnmasterapp-c11e9.firebaseio.com",
  projectId: "rnmasterapp-c11e9",
  storageBucket: "rnmasterapp-c11e9.appspot.com",
  messagingSenderId: "649592030497",
  appId: "1:649592030497:web:7728bee3f2baef208daa60",
  measurementId: "G-FYBCF3Z2W3"
});

class App extends Component {
   
  render() {

    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent("APP_Loaded");
    
    return (
      <View style={{ maxWidth: '3840px', height:"auto", margin: "auto" }}>
        {/* <TopNav /> */}
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;
