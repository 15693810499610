import * as React from "react";
// Customizable Area Start
import PricingEngineController, { Props } from "./PricingEngineController";
import { InputAdornment, TextField } from "@material-ui/core";
// Customizable Area End

class FeeEngine extends PricingEngineController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End
  
  render() {
    // Customizable Area Start
    const {
      classes,
      price,
      hpDocDetailsErr,
      showAyahErrorField,
      isValidNumber,
      handlehpChange,
      placeholderText,
      type
    } = this.props;

    // Customizable Area End
    return (
      // Customizable Area Start
      <TextField
        variant="outlined"
        data-test-id="hpPrice"
        className={showAyahErrorField(hpDocDetailsErr, classes,type as string)}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end"> 
              <div
                style={{
                  fontFamily: "SF Pro Text Regular",
                  fontSize: "14px",
                  color: "#808080",
                }}
              >
                {placeholderText}
              </div>
            </InputAdornment>
          )
        }}
        value={price ? `₹ ${price}` : ""}
        placeholder="Enter price"
        onChange={(event) => {
          const inputValue = event.target.value?.replace("₹ ", "");
          if ((isValidNumber(inputValue) || inputValue === "") && type) {
              handlehpChange(type, inputValue);
          }
        }}
      />
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default FeeEngine;
// Customizable Area End
