export const searchIcon = require("../assets/searchIcon.png");
export const SOSImage = require("../assets/SOS.png");
export const OffIcon = require("../assets/off.png");
export const OnImage = require("../assets/on.png");
export const RightIcon = require("../assets/rightIcon.png");
export const GreenRightIcon = require("../assets/greenRight.png");
export const BackIcon = require("../assets/back.png");
export const TeleconsultationIcon = require("../assets/Teleconsultation.png");
export const HomeVisitIcon = require("../assets/HomeVisit.png");
export const CallingIcon = require("../assets/call-calling.png");
export const HomeIcon = require("../assets/HomeIcon.png");
export const NextArrow = require("../assets/NextArrow.png");
export const PrevArrow = require("../assets/PrevArrow.png");
export const DownArrow = require("../assets/downArrow.png");
export const NoData = require("../assets/closeBtn.png");
export const UserDefaultIcon = require("../assets/user_icon.png");
export const Homevisit = require("../assets/Bitmap.png");
export const Hospitalvisit = require("../assets/hospital.png");
export const FilterIcon = require('../assets/setting-4.png')