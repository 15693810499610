import * as React from "react";
// Customizable Area Start
import {
  withStyles,
  Box,
  Typography,
  Grid,
  Button,
  TextField,
  FormHelperText,
  InputAdornment,
  Checkbox,
  SvgIcon,
  Dialog,
  DialogContent,
  createTheme,
  ThemeProvider,
  ClickAwayListener,
  CardContent,
  Card
} from "@material-ui/core";
import CustomisableUserProfilesController, {
  Props
} from "./CustomisableUserProfilesController";
import { styles } from "./styles/CustomisableUserProfiles";
import CloseIcon from "@material-ui/icons/Close";
import {
  Notification,
  DownArrow,
  UpwardArrow,
  Setting,
  Edit,
  camera,
  gallery,
  otp_success,
  EmailIcon,
  verifiedEmail
} from "./assets";
import Sidebar from "../../../components/src/Sidebar.web";
import Webcam from "react-webcam";
import CountDownTimer from "../../../components/src/CountDownTimer.web";
import SidebarNotificationWrapper from "../../notifications/src/Notifications.web";
import HospitalProfileWrapper from "./HospitalProfileWrapper.web";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff"
    }
  }
});

const selectGender = [
  { key: 1, value: "Male" },
  { key: 2, value: "Female" },
  { key: 3, value: "Other" }
];

const CustomsCheckboxIconUnChecked = () => (
  <SvgIcon
    width="16"
    height="16"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.5" y="0.5" width="15" height="15" rx="1.5" stroke="#014866" />
  </SvgIcon>
);

const CustomsCheckboxIconChecked = () => (
  <SvgIcon
    width="16"
    height="16"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.5" y="0.5" width="15" height="15" rx="1.5" stroke="#014866" />
    <path
      d="M3.75 7.99992L6.58 10.8299L12.25 5.16992"
      stroke="#014866"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </SvgIcon>
);

const videoConstraints = {
  width: 450,
  facingMode: "environment"
};
// Customizable Area End

export class CustomisableEditUserProfile extends CustomisableUserProfilesController {
    constructor(props: Props) {
        super(props);
    }

    // Customizable Area Start
    async componentDidMount() {
        await this.fetchUserProfileDatas();
    }

    showErrorField = (
        error: string | undefined,
        classes: any,
        indexKey?: string
    ) => {
        if (indexKey == "gender") {
        return error
            ? classes.errorRegisterGenderInputFields
            : classes.registerGenderInputFields;
        }
        return error
        ? classes.errorRegisterInputFields
        : classes.registerInputFields;
    };

    errorMessage = (error: string | undefined, inputError: any) => {
        return error ? (
        <FormHelperText className={inputError}>{error}</FormHelperText>
        ) : null;
    };

    getFontWeightStyle = (selectedValue: string, targetValue: string) => {
        return { fontWeight: selectedValue === targetValue ? 600 : 500 };
    };

    generateResendComponent=(text: string, testId: string) => (
        <Typography   
        style={{
            color:
            !this.state.isResendActive || this.state.resendCounter === 5 ? "#292929"  :  "#014866",
            cursor:this.state.resendCounter === 5 || !this.state.isResendActive
            ? "not-allowed"
            : "pointer",
            opacity:this.state.resendCounter === 5 || !this.state.isResendActive
            ? 0.5
            : 1,
            fontSize: "16px",
            fontFamily: "SF Pro Text Medium",
            textTransform: "none",
            fontWeight: 500,    
            caretColor: "transparent"
        }}
        onClick={() => 
            {
            console.log(text);
            }
        }
        variant="h6"
        data-test-id={testId}
        >{text}</Typography>
    );
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        const { errors } = this.state;
        // Customizable Area End
        return (
        // Customizable Area Start
        <>
            <ThemeProvider theme={theme}>
            <Box className={classes.leftWrapper}>
                <SidebarNotificationWrapper hideNotification {...this.props}>
                    <Box className={classes.rightWrapper}>
                    <Box>
                        <HospitalProfileWrapper {...this.props} profilePageTitle="Edit Profile" profilePicture={this.state.profilePicture} openProfileSelectModal={this.handleOpenDialogs} >
                        
                        <Grid container className={classes.editProfileFormContainer}>
                        <Grid item xl={4} md={4} sm={6} xs={12}>
                            <Box className={classes.textFieldContainer}>
                            <Typography
                                variant="h6"
                                className={classes.registerLabel}
                            >
                                Name<span className={classes.required}>*</span>
                            </Typography>
                            <TextField
                                variant="outlined"
                                data-test-id="name"
                                className={this.showErrorField(
                                errors.user_name,
                                classes
                                )}
                                placeholder="Enter your name"
                                value={this.state.user_name}
                                onChange={event =>
                                this.handleChanges(
                                    "user_name",
                                    event.target.value.trimStart()
                                )
                                }
                            />
                            {this.errorMessage(errors.user_name, classes.inputErrors)}
                            </Box>
                            <Box className={classes.textFieldContainer}>
                            <Typography
                                variant="h6"
                                className={classes.registerLabel}
                            >
                                Age<span className={classes.required}>*</span>
                            </Typography>
                            <TextField
                                variant="outlined"
                                data-test-id="age"
                                className={this.showErrorField(errors.userAge, classes)}
                                placeholder="Enter age"
                                value={this.state.userAge}
                                onChange={event =>
                                this.handleChanges(
                                    "userAge",
                                    event.target.value.trimStart()
                                )
                                }
                            />
                            {this.errorMessage(errors.userAge, classes.inputErrors)}
                            </Box>
                            <Box className={classes.textFieldContainer}>
                            <Typography
                                variant="h6"
                                className={classes.registerLabel}
                            >
                                Family Member Name
                            </Typography>
                            <TextField
                                variant="outlined"
                                data-test-id="familyMemberName"
                                className={this.showErrorField(
                                errors.familyMemberName,
                                classes
                                )}
                                placeholder="Enter family member name"
                                value={this.state.familyMemberName}
                                onChange={event =>
                                this.handleChanges(
                                    "familyMemberName",
                                    event.target.value.trimStart()
                                )
                                }
                            />
                            {this.errorMessage(
                                errors.familyMemberName,
                                classes.inputErrors
                            )}
                            </Box>
                        </Grid>
                        <Grid item xl={4} md={4} sm={6} xs={12}>
                            <Box className={classes.textFieldContainer}>
                            <Typography
                                variant="h6"
                                className={classes.registerLabel}
                            >
                                Address<span className={classes.required}>*</span>
                            </Typography>
                            <TextField
                                variant="outlined"
                                data-test-id="address"
                                className={this.showErrorField(errors.address, classes)}
                                placeholder="Enter street name here"
                                value={this.state.address}
                                onChange={event =>
                                this.handleChanges(
                                    "address",
                                    event.target.value.trimStart()
                                )
                                }
                            />
                            {this.errorMessage(errors.address, classes.inputErrors)}
                            </Box>
                            <ClickAwayListener
                            data-test-id="showGenderDropdown"
                            onClickAway={() => this.handleClickOutsideEditBLock()}
                            >
                            <Box className={classes.textFieldContainer} id="gender">
                                <Typography
                                variant="h6"
                                className={classes.registerLabel}
                                style={{ textAlign: "left" }}
                                >
                                Gender<span className={classes.required}>*</span>
                                </Typography>
                                <Box className={classes.selectCheckbox}>
                                <TextField
                                    variant="outlined"
                                    data-test-id="profileGender"
                                    className={this.showErrorField(
                                    errors.gender,
                                    classes,
                                    "gender"
                                    )}
                                    placeholder="Enter gender"
                                    value={this.state.gender}
                                    InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                        <img
                                            src={DownArrow}
                                            alt="DownArrow"
                                            className={classes.downArrowIcon}
                                        />
                                        </InputAdornment>
                                    ),
                                    readOnly: true
                                    }}
                                    onClick={this.openGenderDropdowns}
                                />
                                <Box>
                                    {this.state.showDropdown && (
                                    <Box className={classes.selectFieldDropdown}>
                                        <Box className={classes.dropdownContent}>
                                        <Box className={classes.dropdownText}>
                                            Enter gender
                                            <img
                                            data-test-id="hideGenderDropdown"
                                            src={UpwardArrow}
                                            alt="hideGenderDropdown"
                                            className={classes.upArrowIcon}
                                            onClick={this.openGenderDropdowns}
                                            />
                                        </Box>
                                        <Box>
                                            {selectGender.map(
                                            (data: any, index: number) => (
                                                <Box
                                                className={
                                                    classes.patientSelectOption
                                                }
                                                key={index}
                                                style={this.getFontWeightStyle(
                                                    this.state.gender,
                                                    data.value
                                                )}
                                                >
                                                <Checkbox
                                                    data-test-id={`selectGender${index}`}
                                                    className={classes.checkbox}
                                                    checked={
                                                    this.state.gender === data.value
                                                    }
                                                    icon={
                                                    <CustomsCheckboxIconUnChecked />
                                                    }
                                                    checkedIcon={
                                                    <CustomsCheckboxIconChecked />
                                                    }
                                                    onChange={() =>
                                                    this.handleGenderCheckboxChanges(
                                                        data.value
                                                    )
                                                    }
                                                />
                                                {data.value}
                                                </Box>
                                            )
                                            )}
                                        </Box>
                                        </Box>
                                    </Box>
                                    )}
                                </Box>
                                {this.errorMessage(
                                    errors.gender,
                                    classes.inputErrors
                                )}
                                </Box>
                            </Box>
                            </ClickAwayListener>
                            <Box className={classes.textFieldContainer}>
                            <Typography
                                variant="h6"
                                className={classes.registerLabel}
                            >
                                Family Member Relation
                            </Typography>
                            <TextField
                                variant="outlined"
                                data-test-id="familyMemberRelation"
                                className={this.showErrorField(
                                errors.familyMemberRelation,
                                classes
                                )}
                                placeholder="Enter family member relation"
                                value={this.state.familyMemberRelation}
                                onChange={event =>
                                this.handleChanges(
                                    "familyMemberRelation",
                                    event.target.value.trimStart()
                                )
                                }
                            />
                            {this.errorMessage(
                                errors.familyMemberRelation,
                                classes.inputErrors
                            )}
                            </Box>
                        </Grid>
                        <Grid item xl={4} md={4} sm={6} xs={12}>
                            <Box className={classes.textFieldContainer}>
                            <Typography
                                variant="h6"
                                className={classes.registerLabel}
                            >
                                Phone Number<span className={classes.required}>*</span>
                            </Typography>
                            <TextField
                                variant="outlined"
                                data-test-id="phone"
                                className={this.showErrorField(
                                errors.phoneNumber,
                                classes
                                )}
                                placeholder="Enter your Phone number here"
                                InputProps={{ inputProps: { maxLength: 10 } }}
                                value={this.state.phoneNumber}
                                onChange={event =>
                                this.handleChanges(
                                    "phoneNumber",
                                    event.target.value.trimStart()
                                )
                                }
                            />
                            {this.errorMessage(
                                errors.phoneNumber,
                                classes.inputErrors
                            )}
                            </Box>
                            <Box className={classes.textFieldContainer}>
                            <Typography
                                variant="h6"
                                className={classes.registerLabel}
                            >
                                Email Address<span className={classes.required}>*</span>
                            </Typography>
                            <TextField
                                variant="outlined"
                                data-test-id="email"
                                className={this.showErrorField(errors.email, classes)}
                                placeholder="Enter your Email Address here"
                                value={this.state.email}
                                type="email"
                                onChange={event =>
                                this.handleChanges(
                                    "email",
                                    event.target.value.trimStart()
                                )
                                }
                            />
                            {this.errorMessage(errors.email, classes.inputErrors)}
                            </Box>
                            <Box className={classes.textFieldContainer}>
                            <Typography
                                variant="h6"
                                className={classes.registerLabel}
                            >
                                Family Member Phone Number
                            </Typography>
                            <TextField
                                variant="outlined"
                                data-test-id="familyMemberPhone"
                                className={this.showErrorField(
                                errors.familyMemberPhone,
                                classes
                                )}
                                placeholder="Enter family member number"
                                value={this.state.familyMemberPhone}
                                InputProps={{ inputProps: { maxLength: 10 } }}
                                onChange={event =>
                                this.handleChanges(
                                    "familyMemberPhone",
                                    event.target.value.trimStart()
                                )
                                }
                            />
                            {this.errorMessage(
                                errors.familyMemberPhone,
                                classes.inputErrors
                            )}
                            </Box>
                        </Grid>
                        <Grid item xl={12} md={12} sm={12} xs={12}>
                            <Box>
                            <Button
                                data-test-id="saveButton"
                                variant="contained"
                                color="primary"
                                className={classes.saveButtons}
                                onClick={this.handleSubmits}
                            >
                                Submit
                            </Button>
                            </Box>
                        </Grid>
                        </Grid>
                        </HospitalProfileWrapper>
                        <Dialog
                        open={this.state.openDialog}
                        className={
                            this.state.enableWeb
                            ? classes.webDialogBox
                            : classes.dialogBox
                        }
                        onClose={this.handleCloseDialog}
                        >
                        <Box className={classes.dialogTitleConatiner}>
                            <Typography className={classes.dialogTitle}>
                            Upload Profile Imagefe
                            </Typography>
                            <CloseIcon
                            data-test-id="closeDocDialog"
                            style={{ cursor: "pointer" }}
                            onClick={this.handleCloseDialog}
                            />
                        </Box>
                        <DialogContent>
                            {this.state.enableWeb ? (
                            <>
                                {this.state.url ? (
                                <>
                                    <Box className={classes.dialogBoxWeb}>
                                    <img
                                        data-test-id="capturedImage"
                                        src={this.state.url}
                                    />
                                    </Box>
                                    <Box className={classes.capturedBtnContainer}>
                                    <Typography
                                        className={classes.button}
                                        data-test-id="saveCapturePhoto"
                                    >
                                        Save
                                    </Typography>
                                    <Typography
                                        className={classes.button}
                                        onClick={this.closeWebCamera}
                                    >
                                        Cancel
                                    </Typography>
                                    </Box>
                                </>
                                ) : (
                                <>
                                    <Box className={classes.dialogBoxWeb}>
                                    <Webcam
                                        ref={this.webCameraRef}
                                        audio={false}
                                        screenshotFormat="image/jpeg"
                                        videoConstraints={videoConstraints}
                                    />
                                    </Box>
                                    <Box
                                    className={classes.captureBtnContainer}
                                    data-test-id="CapturePhoto"
                                    onClick={this.capturePhoto}
                                    >
                                    <Typography className={classes.button}>
                                        Capture
                                    </Typography>
                                    </Box>
                                </>
                                )}
                            </>
                            ) : (
                            <>
                                <Box className={classes.dialogBoxIcons}>
                                <img
                                    data-test-id="openWebToUpload"
                                    src={camera}
                                    className={classes.camera}
                                />
                                <img
                                    data-test-id="openPopupToUpload"
                                    src={gallery}
                                    className={classes.gallery}
                                    onClick={this.handleUploadProfiles}
                                />
                                <input
                                    type="file"
                                    ref={this.webCameraRef}
                                    className={classes.imageInput}
                                    onChange={this.handleUploadProfileImages}
                                />
                                </Box>
                                <Box className={classes.dialogBoxText}>
                                <Typography className={classes.uploadCameraText}>
                                    Take Photo
                                </Typography>
                                <Typography className={classes.uploadText}>
                                    Gallery
                                </Typography>
                                </Box>
                            </>
                            )}
                            {this.errorMessage(
                            this.state.UploadImageError,
                            classes.profileError
                            )}
                        </DialogContent>
                        </Dialog>
                        <Dialog open={this.state.patientSuccess}>
                        <Box className={classes.customContainer}>
                            <Box style={{ padding: "0px 10px" }}>
                            <p className={classes.heading}>Success</p>
                            <p className={classes.paragraph}>
                                Profile updated successfully.
                            </p>
                            <button
                                className={classes.popupButton}
                                onClick={this.handelSuccess}
                            >
                                OK
                            </button>
                            </Box>
                        </Box>
                        </Dialog>
                    </Box>
                    <Dialog className={classes.otpDialogBoxNew}
                    open={this.state.otpSend}>
                        <Card className={ classes.otpNewTransparentCard }>
                        <CardContent
                            className={classes.otpCardContentNew}  style={{flexDirection: "column"}}>
                            <Box className={classes.profileCaredWrapper}>
                            <Typography style={{ textAlign: "center" }}
                                gutterBottom
                                variant="h6"
                                className={classes.otpWelcomeTitle}
                            >
                                <span className={classes.otpText}>OTP</span>{" "} Verification
                            </Typography>
                            <CloseIcon
                                data-test-id="closeDialogNew"
                                style={{  marginTop: "10px",cursor: "pointer",caretColor: "transparent", }}
                                onClick={() => this.closeOtpPopup("paitent", "phone")}
                            />
                            </Box>
                            <Typography
                            className={classes.cardSubtitle}
                            style={{ textAlign: "center" }}
                            variant="h6"
                            gutterBottom
                            >Enter OTP</Typography>
                            <Typography
                                className={classes.cardInputLabel}
                                variant="h6"
                                style={{ textAlign: "center" }}
                            >
                            An 4- Digit code has been sent to {"\n"}+91{""}{`******${this.state.phoneNumber?.toString().slice(6, 10)}`}
                            </Typography>
                            <Box className={classes.otpUpdateContainer}>
                            {this.state.phoneOTP.map((value: any, index: any) => (
                                <TextField
                                    variant="outlined"
                                    key={index}
                                    className={
                                        this.state.isError ? classes.inputErrorFieldVerifyOtp
                                        : classes.inputFieldOtpVerify
                                    }
                                    size="small"
                                    id={`updateOtpInput${index}`}
                                    data-test-id={`updateOtpInput${index}`}
                                    value={value}
                                    onChange={event => this.handleOTPChange(index, event)}
                                    style={{
                                    height: "45px",
                                    margin: "5px auto 0px",
                                    width: "50px",
                                    }}
                                    inputProps={{
                                    style: {textAlign: "center"},
                                    maxLength: 1,
                                    }}
                                />
                            ))}
                            </Box>
                            <Box className={classes.errorMessages}>
                            {this.state.isError && 
                            (
                                <FormHelperText className={classes.otpErrors}>{this.state.errorMessage}</FormHelperText>
                            )}
                            </Box>
                            <CountDownTimer isResendActive={this.state.isResendActive}
                            times={this.state.resendCounter}
                            toggleResend={this.toggleResend}
                            />
                            <Box
                            display="flex"
                            justifyContent="space-between"
                            flexDirection="row"
                            padding="8px 0px"
                            >
                            {this.generateResendComponent("Resend OTP",
                            "resendOTP")}

                            {this.generateResendComponent(
                                "Get OTP by Call",
                                "resendOTPByCall"
                            )}
                            </Box>
                            <Button
                            variant="contained"
                            className={classes.verifyOTPNew}
                            onClick={() => this.verifyOTPData()}
                            style={{ textTransform: "capitalize" }}
                            color="primary"
                            data-test-id="updateVerifyOtpBtn"
                            >
                            Verify
                            </Button>
                        </CardContent>
                        </Card>
                    </Dialog>
                    <Dialog
                        open={this.state.emailSend}
                        className={classes.otpDialogBoxNew}
                    >
                        <Card className={classes.transparentOtpCards}>
                        <CardContent
                            className={classes.cardOtpContents}
                            style={{ flexDirection: "column" }}
                        >
                            <Box className={classes.confimPopup}>
                            <CloseIcon
                                data-test-id="closeDialogEmail"
                                style={{ cursor: "pointer", caretColor: "transparent" }}
                                onClick={() => this.closeOtpPopup("paitent", "email")}
                            />
                            </Box>
                            <img
                            src={EmailIcon}
                            alt="email Verified"
                            className={classes.emailConfirmOtpLogo}
                            style={{ objectFit: "contain" }}
                            />
                            <Typography
                            variant="h6"
                            className={classes.confirmEmailOtpHeading}
                            style={{ textAlign: "center" }}
                            >
                            Confirm your email address
                            </Typography>
                            <Typography
                            variant="h6"
                            className={classes.confirmOtpEmailText}
                            style={{ textAlign: "center" }}
                            >
                            We sent a confirmation email to {this.state.email}
                            </Typography>
                            <Box className={classes.hospitalSuccessMessageContainer}>
                            <Typography
                                data-test-id="textMessage1"
                                variant="h6"
                                className={classes.confirmEmailOtpSubText}
                                style={{ textAlign: "center" }}
                            >
                                Check your email and click on the
                            </Typography>
                            <Typography
                                data-test-id="textMessage2"
                                variant="h6"
                                className={classes.confirmEmailOtpSubText}
                                style={{ textAlign: "center" }}
                            >
                                confirmation link to continue.
                            </Typography>
                            </Box>
                            <Button
                            variant="contained"
                            color="primary"
                            data-test-id="moveToNextPage"
                            className={classes.successOtpBtns}
                            onClick={this.confirmEmail}
                            style={{ textTransform: "capitalize" }}
                            >
                            Resend
                            </Button>
                        </CardContent>
                        </Card>
                    </Dialog>
                    <Dialog open={this.state.confimEmail}
                    className={classes.otpDialogBoxNew}>
                        <Card className={classes.transparentOtpCards} >
                        <CardContent
                            className={classes.cardOtpContents}
                            style={{ flexDirection: "column" }}>
                            <img src={verifiedEmail} className={classes.emailVerified}
                            alt="email Verified" style={{ objectFit: "contain" }}
                            />
                            <Typography
                                className={classes.emailMessage}
                                variant="h6"
                                style={{ textAlign: "center" }}
                            >
                            Your Email Address Verified
                            </Typography>
                            <Typography
                            className={classes.successEmailMessage}
                            variant="h6"
                            style={{ textAlign: "center" }}
                            >
                            Successfully
                            </Typography>
                            <Button color="primary"
                            data-test-id="moveToNextPage"
                            variant="contained"
                            className={classes.successOtpBtns}
                            onClick={() => this.otpSuccessEmail("paitent")}
                            style={{ textTransform: "capitalize" }}
                            >
                            Done
                            </Button>
                        </CardContent>
                        </Card>
                    </Dialog>
                    <Dialog
                        open={!!this.state.otpVerified}
                        className={classes.otpDialogBoxNew}
                    >
                        <Card
                        data-test-id="otpCardContainer"
                        className={classes.otpNewTransparentCard}
                        >
                        <CardContent
                            className={classes.otpCardContentNew}
                            style={{ flexDirection: "column" }}
                        >
                            <img
                            src={otp_success}
                            alt="PhoneVerified"
                            className={classes.OtpSuccessLogo}
                            style={{ objectFit: "contain" }}
                            />
                            <Typography
                            variant="h6"
                            className={classes.OtpSuccessMessage}
                            style={{ textAlign: "center" }}
                            >
                            Phone Verified Successfully
                            </Typography>
                            <Button
                            data-test-id="moveToNextPagePatient"
                            variant="contained"
                            color="primary"
                            className={classes.successOtpBtn}
                            onClick={() => this.otpSuccess("paitent")}
                            style={{ textTransform: "capitalize" }}
                            >
                            Done
                            </Button>
                        </CardContent>
                        </Card>
                    </Dialog>
                    </Box>
                </SidebarNotificationWrapper>
            </Box>
            </ThemeProvider>
        </>
        // Customizable Area End
        );
    }
}

// Customizable Area Start
export default withStyles(styles)(CustomisableEditUserProfile);
// Customizable Area End