import React from "react";
// Customizable Area Start
import {
  Box,
  createTheme,
  InputAdornment,
  TextField,
  ThemeProvider,
  Card,
  Typography,
  Button,
  Chip,
  TableContainer,
  Table,
  TableRow,
  TableBody,
  TableCell,
  Checkbox,
  SvgIcon,
  withStyles,
} from "@material-ui/core";
import Reservation2Controller, { Props } from "./Reservations2Controller";
import { searchIcons, bannerImg, filterIcons, Profile, location, NoData } from "./assets";
import { OverflowXProperty, PositionValue, floatType, objectFitType, textTransform, filterDataType, textAlignType, filterReviewDataType } from "./types";
import CloseIcon from '@material-ui/icons/Close';
import '../../../web/src/fonts/stylesheet.css';
import { Link } from "react-router-dom";
import { getStorageData } from "../../../framework/src/Utilities";
import SidebarNotificationWrapper from "../../notifications/src/Notifications.web";

const theme = createTheme({
  palette: {
    primary: {
      contrastText: "#fff",
      main: "#0000ff",
    }
  }
});

const bedData: filterDataType[] = [
  { id: "1", type: "bed",attributes: { name: "ICU" }},
  { id: "2", type: "bed",attributes: { name: "Ward" }},
  { id: "3", type: "bed",attributes: { name: "Dialysis Bed" }},
];

const raitingList: filterReviewDataType[] = [
  { id: "5", name: "5 Star", stars: 5 },
  { id: "4", name: "4 Star & Above", stars: 4 },
  { id: "3", name: "3 Star & Above", stars: 3 },
  { id: "2", name: "2 Star & Above", stars: 2 },
  { id: "1", name: "1 Star & Above", stars: 1 },
];
// Customizable Area End
export class Reservations2Booking extends Reservation2Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  async componentDidMount() {
    let prevFilters= await getStorageData('filters') as string;
    let parseFilters= JSON.parse(prevFilters);

    if (parseFilters) {
      const { selectedFilter,
        selectedInsuranceName,
        selectedBed,
        selectedInsurances,
        selectedInsuranceNameOld,
        selectedBedOld,
        selectedInsurancesOld,
        } = parseFilters
      this.setState((prevState) => ({
        ...prevState,
        selectedFilter,
        selectedInsuranceName,
        selectedBed,
        selectedInsurances,
        selectedInsuranceNameOld,
        selectedBedOld,
        selectedInsurancesOld,
      })); 
    }
    
    try {
      await Promise.all([this.getHospitalList(), this.getInsuranceList()]);
    } catch (errors) {
      this.showAlert("Error fetching data:", "Something went wrong");
    }
  }

  async componentDidUpdate(prevProps: Props, prevState: any) {
    // Check if currentPage has been updated
    const { currentPage, selectedFilter } = this.state;
    if (prevState.currentPage !== currentPage || prevState.selectedFilter !== selectedFilter) {
        try {
          if (!this.state.isDataEmpty) { await this.getHospitalList(); }
        } catch (error) {
            this.showAlert("Error fetching hospital data:", "Something went wrong");
        }
    }
  }

  onScroll = () => {
    const element = document.getElementById("test");
    
    if (element) {
      if (element.scrollHeight - element.scrollTop === element.clientHeight) {
        this.setState(prevState => ({
          perPage: Math.max(10, prevState.perPage),
          currentPage: Math.max(1, prevState.currentPage + 1)
        }));
     }
    }
  };

  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <ThemeProvider theme={theme}>
          <Box className={this.props.classes.sidebarContainer}>
            <SidebarNotificationWrapper {...this.props}>
              <Box className={this.props.classes.availbleContainer}>
                <Box className={this.props.classes.searchbarContainr}>
                  <Box style={{width:"100%"}}>
                    <TextField
                      data-test-id="search"
                      variant="outlined"
                      placeholder="Search Hospitals..."
                      className={this.props.classes.searchHospital}
                      onChange={(e)=>this.setState({searchHospital:e.target.value})}
                      onKeyPress={(event)=>this.searchHospitalByName(event)}
                      InputProps={{
                        classes: { input: this.props.classes.input},
                        startAdornment: (
                          <InputAdornment position="start">
                            <img
                              src={searchIcons}
                              alt="search"
                              className={this.props.classes.searchIcon}
                            />
                          </InputAdornment>
                        ),
                        style: {
                          borderRadius: "8px",
                          color:"#999999 !important",
                          fontFamily:"SF Pro Text Regular !important",
                          fontSize:"16px !important",
                        }
                      }}
                    />
                  </Box>
                  <Box>
                    <Box
                      style={this.state.showPopup?webStyles.filterIconSelected:webStyles.filterIcon}
                    >
                      <img data-test-id="toggleButton" src={filterIcons} onClick={this.togglePopup} />
                    </Box>
                    <Box>
                      {this.state.showPopup && (
                        <>
                          <Box className={this.props.classes.dialogOverlay}></Box>
                          <Box className={this.props.classes.dialogContainer}>
                          <Box style={this.getContentStyle(this.state.filterType)}>
                              <Box style={{display:"flex", width:"100%"}}>
                                <Box className={this.props.classes.filterNameAlignment}>
                                  <Box data-test-id="Insurance" style={this.state.filterType==="insurance"?webStyles.selectedFilterType:webStyles.filterList} onClick={()=>this.handelFilterType("insurance")}>
                                    Insurance
                                  </Box>
                                  <Box data-test-id="BedType" style={this.state.filterType==="bed"?webStyles.selectedFilterType:webStyles.filterList} onClick={()=>this.handelFilterType("bed")}>
                                    Bed Type
                                  </Box>
                                </Box>
                                <Box className={this.props.classes.filterNameRightAlignment}>
                                <Box className={this.props.classes.clearBtn}onClick={this.clearFilter} data-test-id="clearFilter">Clear All</Box>
                                <Box className={this.props.classes.tablePopoverContainer}>
                                <TableContainer className={this.props.classes.tablecontainer}>
                                <Table>
                                  <TableBody>
                                    {this.state.filterType==="insurance" && this.state.insuranceList?.map((insurance: filterDataType, index: number) => (
                                      <TableRow key={index}>
                                        <TableCell style={this.getRowStyles(index, false)}>
                                          <Checkbox
                                            data-test-id={`selectInsurance${index}`}
                                            style={{padding: "5px 25px 5px 0px",color:"white"}}
                                            checked={this.state.selectedInsurances?.includes(parseInt(insurance.id))}
                                            onChange={() => this.handleInsuranceCheckboxChange(parseInt(insurance.id),insurance?.attributes.name)}
                                            icon={<SvgIcon fill="none" width="16" height="16" viewBox="0 0 18 18"  xmlns="http://www.w3.org/2000/svg">
                                            <rect x="0.5" y="0.5" width="15" height="15" rx="1.5" stroke="#014866"/>
                                            </SvgIcon>}
                                            checkedIcon={<SvgIcon viewBox="0 0 18 18" fill="none" height="16" width="16"  xmlns="http://www.w3.org/2000/svg">
                                              <rect x="0.5" y="0.5" rx="1.5" width="15" height="15" stroke="#014866" />
                                              <path d="M3.75 7.99992L6.58 10.8299L12.25 5.16992" stroke="#014866" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                            </SvgIcon>}
                                          />
                                        </TableCell>
                                        <TableCell style={this.getSelectedField(index, this.state.selectedInsurances.includes(parseInt(insurance.id)))}>
                                          {insurance?.attributes.name}
                                        </TableCell>
                                      </TableRow>
                                    ))}
                                    {this.state.filterType==="bed" && bedData.map((bedName: filterDataType, index: number) => (
                                      <TableRow key={index}>
                                        <TableCell style={this.getRowStyles(index, false)}>
                                          <Checkbox
                                            data-test-id={`selectedBed${index}`}
                                            style={{padding: "5px 25px 5px 0px",color:"white"}}
                                            checked={this.state.selectedBed?.includes(bedName?.attributes.name)}
                                            onChange={() => this.handleBedsCheckboxChange(bedName?.attributes.name)}
                                            icon={<SvgIcon width="16" height="16" viewBox="0 0 18 18"  fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect x="0.5" y="0.5" width="15" height="15" rx="1.5" stroke="#014866"/>
                                            </SvgIcon>}
                                            checkedIcon={<SvgIcon viewBox="0 0 18 18" height="16"  fill="none" width="16"  xmlns="http://www.w3.org/2000/svg">
                                            <rect x="0.5" y="0.5" rx="1.5" width="15" height="15" stroke="#014866" />
                                            <path d="M3.75 7.99992L6.58 10.8299L12.25 5.16992" stroke="#014866" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                          </SvgIcon>}
                                          />
                                        </TableCell>
                                        <TableCell style={this.getSelectedField(index, this.state.selectedBed.includes(bedName?.attributes.name))}>
                                          {bedName?.attributes.name}
                                        </TableCell>
                                      </TableRow>
                                    ))}
                                  </TableBody>
                                </Table>
                                </TableContainer>  
                                    <Button
                                      variant="contained"
                                      data-test-id="applyFilter"
                                      color="primary"
                                      className={this.props.classes.applyBtn}
                                      onClick={()=>{this.closeFilterPopup()}}
                                    >
                                      Apply
                                    </Button>     
                                </Box>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        </>
                      )}
                    </Box>
                  </Box>
                </Box>
                <Box id="test" onScroll={this.onScroll}  className={this.props.classes.listContainer}>
                <Box className={this.props.classes.bannerImageContainer}>
                  <img
                    className={this.props.classes.bannerImg}
                    src={bannerImg}
                  />
                </Box>
                <Box className={this.props.classes.bannerImageContainer}>
                  <Box>
                    <Typography variant="h6" className={this.props.classes.upcommingBookingText}>
                      Available Hospitals
                    </Typography>
                  </Box>
                  <Box>
                    {this.state.selectedFilter.length > 0 && this.state.selectedFilter.map((name: string, index: number) => (
                      <Chip
                        key={index}
                        data-test-id={`deleteFilter${index}`}
                        label={name}
                        onDelete={() => this.removeSelected(index,name)}
                        deleteIcon={<CloseIcon style={{ color: "white", width:"20px" }} fontSize="small" />}
                        className={this.props.classes.chip}
                      />
                    ))}
                  </Box>
                  <Box>
                  {this.state.hospitalList?.length > 0 ? (
                    <Box>
                      {this.state.hospitalList?.map((item: any, index: number) => (
                        <Box key={index} className={this.props.classes.availableHospitalList}>
                          <Box>
                          <Card className={this.props.classes.cardContainer}>
                            <Box className={this.props.classes.card}>
                              <Box className={this.props.classes.profileContainer}>
                                  <img
                                    src={item.attributes.profile_image_url?.url ? item.attributes.profile_image_url.url : Profile}
                                    data-test-id={`viewProfile${index}`}
                                    className={this.props.classes.bookingIcons}
                                  />
                                </Box>
                                <Box className={this.props.classes.userDetails}>
                                  <Typography variant="h6" className={this.props.classes.userName}>
                                    {item?.attributes?.name} 
                                  </Typography>
                                  <Box  className={this.props.classes.addressContainer}>
                                    <img
                                      src={location}
                                      className={this.props.classes.location}
                                    />
                                    <Typography variant="h6" className={this.props.classes.userType}>
                                    {item?.attributes?.address?.data?.attributes?.address}, {item?.attributes?.address?.data?.attributes?.city}
                                    </Typography>
                                  </Box>
                                </Box>
                              </Box>
                            </Card>
                          </Box>
                          <Box className={this.props.classes.btnContainer}>
                            <Button
                              variant="contained"
                              color="primary"
                              data-test-id={`viewDetails${index}`}  
                              className={this.props.classes.viewBtn}
                              onClick={() => this.navigateToHospitalProfile(parseInt(item.id))}
                            >
                              View Details
                            </Button>
                          </Box>
                        </Box>
                      ))}
                    </Box>)
                      : 
                      (
                        <>
                        {
                          this.state.loading ? (
                            <>
                              <Box className={this.props.classes.noDataText}>Loading...</Box>
                            </>) : (
                            <>
                              <Box className={this.props.classes.noDataContainer}>
                                <img
                                  src={NoData}
                                  width={60}
                                  height={60}
                                  className={this.props.classes.noDataIcons}
                                />
                                <Box className={this.props.classes.noDataText}>No results found</Box>
                                <Box className={this.props.classes.subText} >Try adjusting or clearing your filtes to</Box>
                                <Box className={this.props.classes.subText} >display better results.</Box>
                              </Box>
                            </>)
                        }</>
                      )}
                  </Box>
                </Box>
                </Box>
              </Box>
            </SidebarNotificationWrapper>
          </Box>
        </ThemeProvider>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyles = {
  container: {
    display: "flex",
    width: "100%"
  },
  availbleContainer: {
    padding: "40px 50px",
    width: "100%",
    height:"100vh",
    boxSizing:'border-box' as const
  },
  availableHospitalList: {
    display: "flex",
    width: "100%"
  },
  card:{
    display: "flex", 
    justifyContent: "space-between", 
    alignItems: "center", 
    margin: "auto"
  },
  searchbarContainr: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%"
  },
  searchHospital: {
    width: "90%",
    margin: "15px 0px",
    "& .MuiInputBase-root": {
      fontFamily: "SF Pro Text Regular !important",
      fontSize: "16px !important",
      color: "#292929 !important",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "8px !important",
    },
    '&::placeholder': {
      fontFamily: "SF Pro Text Regular !important",
      fontSize: "16px !important",
      color: '#999999 !important',
      opacity: 1,
    },
    '&::-webkit-input-placeholder': {
      fontFamily: "SF Pro Text Regular !important",
      fontSize: "16px !important",
      color: '#999999 !important',
      opacity: 1,
    },
  },
  searchIcon: {
    width: "25px",
    height: "25px",
    objectFit: "cover" as objectFitType,
    display: "flex",
    justifyContent: "center",
    margin: "0px 15px 0px 0px"
  },
  sidebarContainer: {
    height: "100vh",
    overflowX: "hidden" as OverflowXProperty,
  },
  textBox: {
    display: "flex",
    margin: "30px"
  },
  bannerImg: {
    width: "100%",
    objectFit: "cover" as objectFitType,
    display: "block",
    borderRadius: "8px",
    margin: "8% 0% 5%"
  },
  filterIconSelected: {
    width: "18px",
    height: "18px",
    display: "flex",
    justifyContent: "center",
    margin: "20px auto 0px -45px",
    backgroundColor: "white",
    zIndex: 1000,
    padding: "13px",
    borderRadius: "8px",
    position:"absolute" as PositionValue,
    caretColor:"white"
  },
  filterIcon: {
    width: "18px",
    height: "18px",
    display: "flex",
    justifyContent: "center",
    margin: "20px auto 0px -45px",
    backgroundColor: "white",
    zIndex: 1000,
    padding: "13px",
    borderRadius: "8px",
    caretColor:"white"
  },
  dialogOverlay: {
    position: "fixed" as PositionValue,
    top: 0,
    right: 0,
    width: "91.7%",
    height: "100%",
    backgroundColor: "#000000b0",
    zIndex: 999,
  },
  dialogContainer: {
    position: "fixed" as PositionValue,
    top: "10% !important",
    width: "60%",
    zIndex: 1000,
    padding: "0px 20px",
    borderRadius: "8px",
  },
  upcommingBookingText: {
    fontSize: "20px",
    color: "#292929",
    fontFamily: "SF Pro Text Semibold",
    marginBottom: "15px",
    caretColor:"transparent"
  },
  headerContainer: {
    display: "flex",
    justifyContent: "space-between"
  },
  cardContainer: {
    width: "538px",
    padding: "5px 3px",
    boxShadow: "none !important",
    marginBottom:"25px",
    borderRadius:"10px",
    alignItems:"center",
    border:"1px solid rgba(128, 128, 128, 0.13)"
  },
  pastBooking: {
    width: "88px",
    display:"flex"
  },
  bookingStatus: {
    fontFamily: "SF Pro Text Medium",
    fontSize: "14px",
    color: "#014866",
    marginTop: "-37px",
    caretColor:"transparent"
  },
  bookingIcons: {
    width: "85px",
    height: "85px",
    margin: "15px 25px 15px 30px",
    borderRadius: "50px",
    cursor: "pointer",
    caretColor:"transparent"
  },
  profileContainer: {
    width: "25%"
  },
  userDetails: {
    width:"75%"
  },
  userName: {
    width: "92%",
    marginTop: "0px",
    marginBottom: "0px",
    fontFamily: "'SF Pro Text Bold'",
    fontSize: "20px",
    color: "#292929",
    lineHeight: "25px",
    caretColor: "transparent",
    textOverflow: "ellipsis",
    overflow: "hidden",
    maxHeight: "50px",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 2,
    display: "-webkit-box"
  } as any,
  userType: {
    margin: "5px 0px 0px",
    fontFamily: "SF Pro Text Medium",
    fontSize: "14px",
    color: "#808080",
    lineHeight:"21px",
    textOverflow: "ellipsis",
    overflow: "hidden",
    height: "20px",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 1,
    display: "-webkit-box",
    maxWidth:'200px'
  } as any,
  viewBtn: { 
    caretColor:"transparent",
    backgroundColor: "#014866",
    color: "#fff",
    border: "none",
    borderRadius: "8px",
    width:"170px",
    height:"52px",
    cursor: "pointer",
    fontSize: "18px",
    fontFamily:"SF Pro Text Bold",
    margin: "34px 20px 10px 0px",
    "&:hover": {
      backgroundColor: "#014866",
      color: "#fff"
    },
    textTransform: "capitalize" as textTransform,
    float:"right" as floatType,
    '@media (max-width:1250px)': {
      width: "160px !important",
    },
  },
  bannerImageContainer:{
    width:"91%"
  },
  addressContainer:{
    display:"flex",
    gap:"3px"
  },
  location:{
    width:"21px",
    height:"23px",
    marginTop:"5px",
    marginRight:"5px"
  },
  chip: 
  {
    "& .MuiChip-label":{
      paddingLeft: "8px !important",
      paddingRight: "8px !important"
    },
    margin: "4px 25px 40px 0px",
    alignItems: "center",
    padding: "20px 0px",
    fontSize: "14px",
    fontFamily:"SF Pro Text Regular",
    backgroundColor: "#FF0000 !important",
    color: "white",
    borderRadius: "7px",
    height:"34px",
    caretColor:"transparent",
    "& .MuiChip-deletable:focus":{
        color: "white",
        borderRadius: "7px",
        caretColor:"transparent",
        backgroundColor: "#FF0000 !important",
    },
    "& .MuiChip-deleteIcon":{
      width:"20px",
      height:"20px",
      fontFamily:"SF Pro Text Regular",
    },
  },
  totalReview:{
    fontFamily: "SF Pro Text Medium",
    fontSize: "14px",
    marginTop: "-34px",
    marginLeft: "4px",
    color: "#3780E0",
    caretColor:"transparent"
  },
  tablePopoverContainer: {
    maxHeight:'450px', 
    overflow:'scroll' as OverflowXProperty,
    scrollbarColor:"#d9d5d5 #fff",
    overflowY:"scroll" as OverflowXProperty,
    scrollbarWidth: "thin" as "thin",
    marginRight:"15px",
    '&::-webkit-scrollbar': {
      backgroundColor: 'white',
      color:"white"
    },
    '&::-webkit-scrollbar-button': {
      backgroundColor: 'white',
      display:"none",
      color:"white"
    },
    '::-webkit-scrollbar-thumb':{
      backgroundColor: 'white',
      color:"white"
    },
    '::-webkit-scrollbar-track':{
      backgroundColor: 'white',
      color:"white"
    },
    '@media (max-height: 700px)': {
      maxHeight:'450px',
    },
    '@media (max-height: 660px)': {
      maxHeight:'395px',
    },
    '@media (max-height: 659px)': {
      maxHeight:'376px',
    },
  },
  tablecontainer: {
    width: "100%",
    margin: "5px auto"
  },
  filterList:{
    cursor:"pointer",
    fontSize: "18px",
    fontFamily: "SF Pro Text Semibold",
    color: "#808080",
    background: "transparent",
    marginRight:"20px",
    padding: "16px 25px",
    borderRadius: "8px",
    marginBottom: "45px",
    caretColor:"transparent",
    width:"85px",
    display:"flex"
  },
  filterNameAlignment:{
    width: "155px",
    borderRight: "1px solid #eee9e9",
    marginRight: "20px",
    paddingTop: "32px",
    caretColor:"transparent"
  },
  filterNameRightAlignment:{
    width: "100%",
  },
  clearBtn:{
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    fontSize: "14px",
    color: "rgb(55, 128, 224)",
    textAlign:"right" as textAlignType,
    marginBottom:"15px",
    caretColor:"transparent",
    cursor:"pointer"
  },
  applyBtn:{
    backgroundColor: "#014866",
    color: "#FFFFFF",
    border: "medium none",
    borderRadius: "10px",
    padding: "8px 23px",
    cursor: "pointer",
    fontFamily:"SF Pro Text Bold",
    fontSize: "18px",
    margin: "25px 15px 10px 0px",
    textTransform: "capitalize" as textTransform,
    float: "left" as floatType,
    width: "130px",
    height:"45px",
    caretColor:"transparent",
    "&:hover": {
      backgroundColor: "#014866",
      color: "#FFFFFF",
    },
  },
  selectedFilterType:{
    cursor:"pointer",
    fontSize: "18px",
    fontFamily: "SF Pro Text Semibold",
    color: "rgb(55, 128, 224)",
    background: "#C3D9F6",
    marginRight:"20px",
    padding: "16px 25px",
    borderRadius: "8px",
    marginBottom: "45px",
    caretColor:"transparent",
    width:"85px",
    display:"flex"
  },
  starRating:{
    width:"22px",
    height:"22px",
  },
  showRating:{
    display:"flex", 
    gap:"10px", 
    marginRight:"20px", 
    padding:"3px 0px"
  },
  noDataContainer:{
    width:"362px",
    display:"block",
    margin:"110px auto 0px"
  },
  noDataIcons:{
    display:"flex",
    margin:"auto"
  },
  noDataText:{
    fontSize:"20px",
    fontFamily:"SF Pro Text Medium",
    color:"#808080", 
    width:"100%",
    display:"flex",
    justifyContent:"center",
    margin:"8px auto"
  },
  subText:{
    fontSize:"20px",
    fontFamily:"SF Pro Text Medium",
    color:"#808080", 
    width:"100%",
    display:"flex",
    justifyContent:"center", 
    margin:"auto"
  },
  btnContainer:{
    marginLeft:"60px",
    '@media (max-width:1250px)': {
      marginLeft:"32px"
    },
    '@media (max-width:1230px)': {
      marginLeft:"26px"
    },
  },
  listContainer:{
    height: '550px', 
    overflowY: 'scroll' as any,
    scrollbarColor:"#d9d5d5 #fff",
    scrollbarWidth: "thin" as "thin",
    '@media (max-height: 600px)': {
      maxHeight:'500px',
    },
    '@media (max-height: 700px)': {
      maxHeight:'605px',
    },
    '@media (max-height: 800px)': {
      maxHeight:'650px',
    },
  },
  input: {
    '&::placeholder': {
      color:"#808080",
      fontFamily:"SF Pro Text Regular",
      fontSize:"16px",
    },
    '&::-webkit-input-placeholder': {
      color:"#808080 !important",
      fontFamily:"SF Pro Text Regular",
      fontSize:"16px !important",
      opacity: 1,
    },
  },
};

export default withStyles(webStyles)(Reservations2Booking);
// Customizable Area End

