import React from "react";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
export const configJSON = require("./config");
import * as Yup from "yup";
import { PrescriptionValue } from "./types";
import { getStorageData } from "../../../framework/src/Utilities";

export interface Props {
    navigation: any;
    id: string;
    classes: any;
}

interface S {
    loading: boolean;
    activeStep: number;
    hideShowDropDown:boolean;
    selectedDiagnosis:any;
    consultType: string;
    errors: Partial<PrescriptionValue>;
    hospitalName: string;
    city:string;
    hpName:string;
    degree: string;
    date: string;
    time: string;
    age: string;
    consultId:string;
    patientName:string;
    bloodPressure:string;
    height:string;
    weight:string;
    pulse:string;
    temperature:string;
    selectedComplaints:any;
    detail1:string;
    detail2:string;
    detail3:string; 
    diagnosis1:string;
    diagnosis2:string;
    diagnosis3:string;
    generalAdvice1:string;
    generalAdvice2:string;
    generalAdvice3:string; 
    gender:string;
    bloodPressureDecared:boolean;
    pulseDeclared:boolean;
    temperatureDeclared:boolean;
    heightDeclared:boolean
    weightDeclared:boolean;
    medicineType:any;
    medicineName:string;
    repeatType:any;
    dayType:any;
    medicineTakenTime:any;
    dosageCount: number;
    durationCount:number;
    appointmentUpcoming:any;
    appointmentPast:any
    steps:any;
    title:any;
    gendeOption:any;
    diagnostic:any;
    durations:any;
    medicineOptions:any;
    diseasesList:any;
    hideDiseasesShowDropDown:boolean;
  }

interface SS {
    id: number;
}

export default class HpOrderManagementController extends BlockComponent<
    Props,
    S,
    SS
> {
    getDiseasesApiCallId: string = "";
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
          getName(MessageEnum.AccoutLoginSuccess),
          getName(MessageEnum.RestAPIResponceMessage),
      ];

        this.state = {
            loading: true,
            activeStep: 0,
            hideShowDropDown:false,
            selectedDiagnosis:[],
            consultType:"video_call",
            errors:{},
            hospitalName: "",
            city:"",
            hpName:"",
            degree: "",
            date: "",
            time: "",
            age: "",
            consultId:"",
            patientName:"",
            bloodPressure:"",
            height:"",
            weight:"",
            pulse:"",
            temperature:"",
            selectedComplaints:[],
            detail1:"",
            detail2:"",
            detail3:"", 
            diagnosis1:"",
            diagnosis2:"",
            diagnosis3:"",
            generalAdvice1:"",
            generalAdvice2:"",
            generalAdvice3:"", 
            gender:"Female",
            bloodPressureDecared:false,
            pulseDeclared:false,
            temperatureDeclared:false,
            heightDeclared:false,
            weightDeclared:false,
            medicineType:[],
            medicineName:"",
            repeatType:[],
            dayType:[],
            medicineTakenTime:[],
            dosageCount: 0,
            durationCount: 0,
            appointmentUpcoming: [
              {
                  "id": "1",
                  "type": "hp_managements",
                  "attributes": {
                      "id": 1,
                      "name": "Dorothy Nelson",
                      "profile_image": null,
                      "time": "4th Apr 2023",
                      "time_duration": "8 am - 8:45 am"
                  }
              },
              {
                  "id": "2",
                  "type": "hp_managements",
                  "attributes": {
                      "id": 2,
                      "name": "Dorothy Nelson",
                      "profile_image": null,
                      "time": "4th Apr 2023",
                      "time_duration": "8 am - 8:45 am"
                  }
              }
            ],
            appointmentPast: [
              {
                  "id": "1",
                  "type": "hp_managements",
                  "attributes": {
                      "id": 1,
                      "name": "Dorothy Nelson",
                      "profile_image": null,
                      "time": "Wednesday",
                      "time_duration": "8 am - 8:45 am"
                  }
              }
            ],
            steps : [
              { label: 'Doctor Details' },
              { label: 'Patient Details' },
              { label: 'Diagonsis' },
              { label: 'Advice' },
            ],
            title : [
              {
                value: 'Dr.',
                label: 'Dr.',
              },
              {
                value: 'Ms.',
                label: 'Ms.',
              },
              {
                value: 'Mrs.',
                label: 'Mrs.',
              },
              {
                value: 'Mr.',
                label: 'Mr.',
              },
              {
                value: 'Mr.',
                label: 'Mr.',
              },
            ],
            gendeOption : [
              {
                value: 'Male.',
                label: 'Male.',
              },
              {
                value: 'Female',
                label: 'Female',
              },
              {
                value: 'Other',
                label: 'Other',
              },
            ],
            diagnostic : [
              {
                id: 1,
                name: 'Complete Blood Count (CBC)',
              },
              {
                id: 2,
                name: 'Prolactin',
              },
              {
                id: 3,
                name: 'Urea',
              },
              {
                id: 4,
                name: 'Creatinine',
              },
            ],
            durations: [
              {
                value: 'Day.',
                label: 'Day',
              },
              {
                value: 'Week',
                label: 'Week',
              },
              {
                value: 'Month',
                label: 'Month',
              },
            ],
            medicineOptions: [
              { id: "tablets", label: "Tablets" },
              { id: "injection", label: "Injection" },
              { id: "capsule", label: "Capsule" },
              { id: "syrup", label: "Syrup" },
            ],
            diseasesList:[],
            hideDiseasesShowDropDown:false
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }


    async receive(from: string, message: Message) {
      runEngine.debugLog("Message Recived", message);

      if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
          this.handleHPResponse(message);
      }
  }

  handleHPResponse = (message: Message) => {
      const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.getDiseasesApiCallId) {
          if (!responseJson.errors && !responseJson.error) {
            this.setState({ diseasesList: responseJson })
          } else {
            this.showAlert("Error", responseJson.errors ? responseJson.errors.message : responseJson.error.message);
          }
      }
  }

    moveToPrescriptionScreen = () => {
      const message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(getName(MessageEnum.NavigationTargetMessage), "HealthcarePersonnelPrescription");
      message.addData(
          getName(MessageEnum.NavigationPropsMessage),
          this.props
      );
      this.send(message);
    }

    backToHpOrderManagementScreen = () => {
      if(this.state.activeStep === 0){
      const message = new Message(getName(MessageEnum.NavigationMessage));
      message.addData(getName(MessageEnum.NavigationTargetMessage), "HealthcarePersonnelOrderManagement");
      message.addData(
          getName(MessageEnum.NavigationPropsMessage),
          this.props
      );
      this.send(message);
     }else{
      this.setState({activeStep:this.state.activeStep -1})
     }
    }

    showPrescriptionForm = () => {
      if(this.state.activeStep <= 2){
        this.setState({activeStep:this.state.activeStep+1})
      }
    }

    closeDropdown = () => {
      this.setState({ hideShowDropDown: false })
    }
    
    hideShowDropDown = () => {
      this.setState({ hideShowDropDown: !this.state.hideShowDropDown })
    }

    handleCheckboxChange = (id: number) => {
      this.setState((prevState: any) => {
        const { selectedDiagnosis } = prevState;
        if (selectedDiagnosis.includes(id)) {
          return {
            selectedDiagnosis: selectedDiagnosis.filter((item: number) => item !== id),
          };
        } else {
          return {
            selectedDiagnosis: [...selectedDiagnosis, id],
          };
        }
      });
    };

    setConsultType = (type:string) => {
      this.setState({consultType:type})
    }

    userPrescriptionValidationSchemas = Yup.object().shape({
      hospitalName: Yup.string()
        .required("Hospital name is required")
        .matches(/^\S.*$/, 'Hospital name is required')
        .matches(/^[a-zA-Z\s]+$/, "Enter valid hospital name"),  
      city: Yup.string()
          .required("City name is required")
          .matches(/^\S.*$/, 'City is required')
          .matches(/^[a-zA-Z\s]+$/, "Enter valid city"),    
      hpName: Yup.string()
          .required("Healthcare personnel name is required")
          .matches(/^\S.*$/, 'Healthcare personnel name is required')
          .matches(/^[a-zA-Z\s]+$/, "Enter valid healthcare personnel"),  
      gender: Yup.string()
          .required("Gender is required")
          .matches(/^[a-zA-Z\s]+$/, "Gender is required"),      
      degree: Yup.string()
          .required("Degree is required")
          .matches(/^\S.*$/, 'Degree name is required')
          .matches(/^[a-zA-Z\s]+$/, "Enter valid degree"),  
      date: Yup.string()
          .required("Date is required")
          .matches(/^\S.*$/, 'Date is required')
          .matches(
            /^(0[1-9]|[12]\d|3[01])\/(0[1-9]|1[0-2])\/\d{4}$/,
            'Date must be in the format dd/mm/yyyy'
          ),
      time: Yup.string()
          .required("Time is required")
          .matches(/^\S.*$/, 'Time is required')
          .matches(
            /^(0\d|1\d|2[0-3]):([0-5]\d)$/,
          'Time must be in the format hh:mm (24-hour format)'
      ), 
      consultId: Yup.string()
        .required("Consult Id is required")
        .matches(/^\S.*$/, 'Consult Id is required')
        .matches(/^\d{1,6}$/, "Consult Id must be a number with up to 6 digits"),
      patientName: Yup.string()
        .required("Patient name is required")
        .matches(/^\S.*$/, 'Patient name is required')
        .matches(/^[a-zA-Z\s]+$/, "Enter valid patient name"),            
      age: Yup.string()
        .required("Age is required")
        .matches(/^(?:\d{1,2}|1[01]\d|110)$/, "Invalid age")
        .test('is-valid-name', 'Invalid age', function (value) {
          if (value > 110) {
            return false
          }else{
            return true
          }
      }).matches(/^\S.*$/, 'Age is required'),
      medicineName: Yup.string()
        .required("Medicine name is required")
        .matches(/^\S.*$/, 'Medicine name is required')
        .matches(/^[a-zA-Z\s]+$/, "Enter valid medicine"),    
      bloodPressure: Yup.string()
      .notRequired(), 
      height: Yup.string()
      .notRequired(), 
      weight: Yup.string()
      .notRequired(), 
      pulse: Yup.string()
      .notRequired(), 
      temperature: Yup.string()
      .notRequired(), 
      detail1: Yup.string()
      .notRequired(), 
      detail2: Yup.string()
      .notRequired(), 
      detail3: Yup.string()
      .notRequired(),  
      diagnosis1: Yup.string()
      .notRequired(), 
      diagnosis2: Yup.string()
      .notRequired(), 
      diagnosis3: Yup.string()
      .notRequired(), 
      generalAdvice1: Yup.string()
      .notRequired(), 
      generalAdvice2: Yup.string()
      .notRequired(), 
      generalAdvice3: Yup.string()
      .notRequired(),  
    });


    handlePrescriptionChanges = (field: keyof PrescriptionValue, value: any) => {
      try {
        value = value?.replace(/ {2,}/g,' ');
        const fieldValues: Partial<PrescriptionValue> = {
          [field]: value,
        };
        this.userPrescriptionValidationSchemas.validateSyncAt(field, fieldValues as PrescriptionValue);
  
        this.setState((prevState) => ({
          ...prevState,
          errors: { ...prevState.errors, [field]: "" },
          [field]: value
        }));     
      } catch (errs) {
        if (errs instanceof Yup.ValidationError) {
          const errorMessage = errs.message;
  
          this.setState((prevState) => ({
            ...prevState,
            errors: { ...prevState.errors, [field] : errorMessage },
            [field] : value,
          }));
        }
      }
    };

    handelDeclaredBy = (field:keyof S,value:boolean) => {
      this.setState((prevState) => ({
        ...prevState,
        [field]: value
      }));
    }

    handleSelectedNames = (diagnostic:any) => {
     const selectedName = diagnostic.filter((item:any) => this.state.selectedDiagnosis.includes(item.id)).map((item:any) => item.name).join(", ");
     return selectedName;
    } 

    handelSetMedicine = (type: string) => {
      this.setState((prevState: any) => {
        const { medicineType } = prevState;
        if (medicineType.includes(type)) {
          return {
            medicineType: medicineType.filter((item: string) => item !== type),
          };
        } else {
          return {
            medicineType: [...medicineType, type],
          };
        }
      });
    };

  handleSetRepeatCheckboxRepeat = (type: string) => {
    this.setState((prevState: any) => {
      const fieldArray = prevState.repeatType;
      if (fieldArray.includes(type)) {
        return {
          repeatType: fieldArray.filter((item: any) => item !== type),
        };
      } else {
        return {
          repeatType: [...fieldArray, type],
        };
      }
    });
  };

  handleSetDayTypeCheckbox = (type: string) => {
    this.setState((prevState: any) => {
      const fieldArray = prevState.dayType;
      if (fieldArray.includes(type)) {
        return {
          dayType: fieldArray.filter((item: any) => item !== type),
        };
      } else {
        return {
          dayType: [...fieldArray, type],
        };
      }
    });
  };

  handlemedicineTakenTimeCheckbox = (type: string) => {
    this.setState((prevState: any) => {
      const fieldArray = prevState.medicineTakenTime;
      if (fieldArray.includes(type)) {
        return {
          medicineTakenTime: fieldArray.filter((item: any) => item !== type),
        };
      } else {
        return {
          medicineTakenTime: [...fieldArray, type],
        };
      }
    });
  };

  incrementDoseCount = () => {
    this.setState({dosageCount: this.state.dosageCount +1});
  }

  incrementDurationCount = () => {
    this.setState({durationCount: this.state.durationCount +1});
  }

  decrementDoseCount = () => {
    if(this.state.dosageCount > 0){
      this.setState({dosageCount: this.state.dosageCount - 1});
    }
  }

  decrementDurationCount = () => {
    if(this.state.durationCount > 0){
      this.setState({durationCount: this.state.durationCount - 1});
    }
  }

  getdiseasesList = async() => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getDiseasesApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.diseasesAPI
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.listOfOrdersMethod
    );
    
    const authToken = await getStorageData("authToken")
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: authToken,
    };

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleChipDelete = (id: number) => {
    this.setState((prevState: any) => ({
      selectedComplaints: prevState.selectedComplaints.filter((item: number) => item !== id),
    }));
  };
     
   hideShowDiseasesDropDown = () => {
    this.setState({ hideDiseasesShowDropDown: !this.state.hideDiseasesShowDropDown })
  }

  closeDiseasesDropdown = () => {
    this.setState({ hideDiseasesShowDropDown: false })
  }
  
  handleDiseasesCheckboxChange = (id: number) => {
    this.setState((prevState: any) => {
      const { selectedComplaints } = prevState;
      if (selectedComplaints.includes(id)) {
        return {
          selectedComplaints: selectedComplaints.filter((item: number) => item !== id),
        };
      } else {
        return {
          selectedComplaints: [...selectedComplaints, id],
        };
      }
    });
  };

  backToPrevPage = async() => {
    const profileType = await getStorageData("profileType") 
    const userNavigationId = await getStorageData("userNavigationId") 
    let page = "HPUserProfile";
    if(profileType==="hospital"){
      page = "UserProfile"
    } 
    const navigateToProfile = new Message(getName(MessageEnum.NavigationMessage));
    navigateToProfile.addData(getName(MessageEnum.NavigationTargetMessage), page);
    navigateToProfile.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    navigateToProfile.addData(getName(MessageEnum.NavigationScreenNameMessage), userNavigationId);
    this.send(navigateToProfile);
  }
}