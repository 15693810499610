Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "reservations2";
exports.labelBodyText = "reservations2 Body";

exports.btnExampleTitle = "CLICK ME";
exports.getBedsAPI = "bed_types";
exports.getInsurancesAPI = "insurances";
exports.getHospitalInfoAPI = 'hospital_bookings/hospital_info';
exports.getHospital= "hospital_bookings/available_hospitals";
exports.makePaymentAPI = "ccavenue/initiate_payment";
exports.bookHospitalAPI = "hospital_bookings";
exports.patchAPiMethod = "PATCH";
exports.postApiMethod = "POST";
exports.doctorApiMethod = "medic_reservations/available_medics";
exports.medicReservation = "medic_reservations/medic_availabilities";
exports.createMedicReservation = "medic_reservations"
exports.regex = "/\s+-\s+/g";
exports.PriceMode = {
  Online : "online",
  Offline : "offline"
};
exports.ProfileType = {
  Doctor:"doctor"
};
exports.KeyEnter = "Enter";
exports.PriceMode = "priceMode";
exports.Page = "page";
exports.AvailableCareTakerEndPoint = "/caretaker_reservations/available_caretakers";
exports.NurseReservationProfilePath = "Reservations2NurseProfile";
exports.NurseReservationProfileReviewsPath = "Reservations2NurseProfileReviews";
exports.Reservations2DoctorBooking = "Reservations2DoctorBooking";
exports.Reservations2NurseBooking = "Reservations2NurseBooking";
exports.TEN = 10;
exports.Appointment = "appointment";
exports.Loading = "Loading...";
// Customizable Area End