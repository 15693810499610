// Customizable Area Start
import * as React from "react";
import { Grid, Box, withStyles, Typography, TextField, Button, FormHelperText, InputAdornment, Checkbox, SvgIcon, Chip, Dialog, ClickAwayListener, DialogContent, Card, CardContent } from "@material-ui/core";
import { styles } from "./styles/PhoneNumberRegistration";
import EmailAccountRegistrationController, { Props } from "./EmailAccountRegistrationController";
import { homecureTransparent, pexelsKarolina, MiscGreen, MiscGray, DownArrow, UpwardArrow, ImageIcons,gallery, camera, Edit, EmailIcon } from "./assets";
import CloseIcon from '@material-ui/icons/Close';
import Webcam from "react-webcam"
import CountDownTimer from "../../../components/src/CountDownTimer.web";
import { otp_success, verifiedEmail } from "../../customisableuserprofiles/src/assets";
import LocationView from "../../location/src/LocationView.web";
import FeeEngine from "../../pricingengine/src/FeeEngine.web";

const genderSelectOptions = [
  { key: 1, value: "Male" },
  { key: 2, value: "Female" },
  { key: 3, value: "Other" },
];

const hpContainerStyle = {
  backgroundSize: "cover",
  backgroundRepeat: "no-repeat",
  backgroundImage: `url(${pexelsKarolina})`,
  backgroundPosition: "center",
  padding: "10px"
}

const videoHPConstraint = {
  width: 450,
  facingMode:"environment"
}
// Customizable Area End

export class HealthCarePersonnelRegistration extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    try {
        if(this.props.userLogin){
          await Promise.all([this.getLanguagesList(),this.getDegreeList()]);
          await this.fetchDoctorData();

        }else{
        const hpData = await this.getHpData();
        this.setHpDate(hpData)
        await Promise.all([this.getLanguagesList(), this.getDegreeList()]);
        }
      }catch (errors) {
        this.showAlert("Error fetching data:", "Something went wrong");
      }
  }

  showErrorField = (error: string | undefined, classes: any, keyNumber?: string) => {
    if (keyNumber == "language") {
      return (error ? classes.languageBoxError : classes.languageBox)
    }
    if (keyNumber == "college") {
      return (error ? classes.clgErrorRegisterInputFields : classes.clgRegisterInputFields)
    }
    if (keyNumber == "degree") {
      return (error ? classes.errorRegisterDegreeInputFields : classes.registerDegreesInputFields)
    }
    return (error ? classes.errorRegisterInputFields : classes.registerInputFields)
  };

  errorHpMessage = (error: string | undefined, inputError: any) => {
    return (error ? <FormHelperText className={inputError}>{error}</FormHelperText> : null)
  };

  getHpClassName = (classes: any, error: string | undefined) => {
    const { isUploadDoc } = this.state;
    if (isUploadDoc) return error ? classes.registrationTextFieldError : classes.registrationTextField
    return (error ? classes.hpUploadErrorRegisterInputFields : classes.hpUploadRegisterInputFields)
  }

  getHpPlaceholder = () => {
    const { isUploadDoc } = this.state;
    if (!isUploadDoc) return "Upload registration certificate";
    return "";
  }

  getHpInputProps = (classes: any) => {
    const { isUploadDoc, image } = this.state;
    if (isUploadDoc) {
      return (
        <img
          className={classes.uploadImage}
          src={image}
          alt="Preview"
          style={{ objectFit: "cover" }}
        />
      );
    }
    return null;
  };

  selectHpLanguageText = (selectedLanguages: any, classes: any) => {
    return (
      <>
        {selectedLanguages.length === 0 && (
          <span className={classes.selectLanguagePlaceholder}>Enter languages</span>
        )}
      </>
    )
  }

  getHpFontWeightStyle = (selectedValue: any, targetValue: string, keyNumber?:string) => {
    if(keyNumber==="language") {
      const matchedLanguage = selectedValue.some((language: string) => language === targetValue);
      return { fontWeight: matchedLanguage ? 600 : 500 };
    }  
    return { fontWeight: selectedValue === targetValue ? 600 : 500 };
  };


  callDegreeCollgeDetails  = (index: number, classes: any, eduDetail: { degree: string, college: string, degreeCursor: boolean, collegeCursor: boolean }) => {
    const educationDetails = this.state.educationDetails;
    const lastEducationDetails = educationDetails.length - 1;
    const noError = this.state.hpDocDetailsErr.degree === '' && this.state.hpDocDetailsErr.college === ''
    const newEducationDetail = { degree: "", college: "", degreeCursor: false, collegeCursor: false };
    if (noError && eduDetail.degree !== "" && eduDetail.college !== "" && eduDetail.degreeCursor && eduDetail.collegeCursor && lastEducationDetails === index) {
      const updatedDetails = [...educationDetails, newEducationDetail];
      this.setState((prevState) => ({
        educationDetails: updatedDetails,
        degree: "",
        college: ""
      }));
    }
    let degreeConcate = classes.degreeConcate;
    if(educationDetails.length - 2 === index){
      degreeConcate = classes.degreeConcateLast
    }
    return (
      <>
        {eduDetail.degreeCursor && eduDetail.collegeCursor && eduDetail.degree !== "" && eduDetail.college !== "" && (
          <Box className={classes.hpSelectCheckbox}>
            <Box
              data-test-id={`hpNewDegree${index}`}
              className={degreeConcate}
              onClick={() => this.openEditable(index)}
            >
            {eduDetail.degree.trim()}&nbsp;<span style={{ color: "#808080 !important", fontFamily:"SF Pro Text Regular !important"  }}>from</span>&nbsp;{eduDetail.college.trim()}
            </Box>
          </Box>
        )}
      </>
    )
  }

  showNurseSelectErrorField = (error: string | undefined, classes: any, keyNumber?: string) => {
    return (error ? classes.hpErrorRegisterInputFields : classes.hpRegisterInputFields)
  };

  errorMessage = (classes:any, state:string,messageTitle:string) => {
    return (
      <><Dialog open={!!state} data-test-id="popup">
          <Box className={classes.customContainer}>
            <Box style={{ padding: "0px 10px" }}>
              <p className={ classes.heading }>{messageTitle}</p>
              <p className={ classes.paragraph }>{state}</p>
              <Button style={{
                backgroundColor: "rgb(33, 150, 243)",
                borderRadius: "3px",
                marginBottom: "15px",
                border: "none",
                color: "#fff",
                padding: "5px",
                marginTop:"10px",
                fontFamily:"SF Pro Text Regular"
              }} onClick={this.closHPPopup}>OK</Button>
            </Box>
          </Box>
        </Dialog></>
    )
  }

  hpRoleLanguageContainer = (selectedLanguage:any,classes:any) => {
    return selectedLanguage.length>0 ? classes: "";
  }  
  profileHeading = this.props.userLogin ? "Edit Profile" : "Register";
  showHpProfile = this.props.userLogin ? "blothis.state.isEditing && this.state.editableIndexck" : "none";
  showHpText = !this.props.userLogin ? "block" : "none";

  enableHPDialogBox = (enableWeb:any,classes:any) => {
    const showDialog =  enableWeb ? classes.webDialogBox : classes.dialogBox;
    return showDialog;
  } 

  isEducationErrorExist = (hpDocDetailsErr:any) => {
   return !hpDocDetailsErr.degree && !hpDocDetailsErr.college
  }

  showMessage = (language:any,openLanguageDropdown:any,classes:any) => {
    if(!language && !openLanguageDropdown){
      return (
        <>
          <Typography variant="h6" className={classes.languageText}>
            (Please select a maximum of 4 languages)
          </Typography>
        </>
      );
    }
  }

  showhpConnectHospitalFields= (longTermBooking:boolean,classes:any) =>{
    return longTermBooking ? classes.hpConnectHospitalFields : classes.hpConnectHospitalFieldsGray
  }

  showhpConnectHospitalIcons= (longTermBooking:boolean) =>{
    return longTermBooking ? MiscGreen : MiscGray
  }

  mendatoryField = (kind: string, classes: any) => {
    if (kind === "Nurse") {
        return <span className={classes.required}>*</span>;
    } else {
        return null
    }
  }

  registrationCertificateHeading = (kind:string) => {
    if(kind==="Nurse"){
      return `Registration Certificate`
    }else{
      return `Registration / Degree Certificate`
    }
  }

  degreePlaceholder = (kind: string) => {
    if (kind === "Nurse") {
        return "Enter degree"
    } else {
      return "Enter degree name"
    }
  }

  generateHPResendComponent = (text: string, testId: string) => (
    <Typography
      data-test-id={testId}
      variant="h6"
      style={{color:this.state.resendCounter === 5 || !this.state.isResendActive? "#292929": "#014866",
        cursor:
          this.state.resendCounter === 5 || !this.state.isResendActive ? "not-allowed" : "pointer",
        opacity:
          this.state.resendCounter === 5 || !this.state.isResendActive ? 0.5 : 1,
        caretColor: "transparent",fontSize: "16px",
        fontFamily: "SF Pro Text Medium",
        textTransform: "none",
        fontWeight: 500,
      }}
    >
      {text}</Typography>
  );

  inputErrorVerifyOtp = (classes:any,isError:boolean) => {
    return  isError ? classes.inputErrorFieldVerifyOtp : classes.inputFieldOtpVerify;
  }

  showOTPError= (isError:boolean,errorMessage:string,style:any) => {
    return (
      isError && ( <FormHelperText className={style}>{errorMessage} </FormHelperText>
      )
    );
  };

  handleNurseFees = (classes: any) => {
    let placeholder = "/ Home Visit"
    if (this.state.kind === "Nurse") {
      placeholder = "/ Morning / Evening shift"
    }
    return (
      <>
        <Box data-test-id="price">
          <Typography variant="h6" className={classes.registerLabel} style={{ textAlign: "left" }}>
            Fees<span className={classes.required}>*</span>
          </Typography>
          <FeeEngine
            navigation={undefined}
            id="" 
            placeholderText={placeholder}
            isValidNumber={this.isValidNumber}
            hpDocDetailsErr={this.state.hpDocDetailsErr.price}
            showAyahErrorField={this.showErrorField}
            handlehpChange={this.handlehpChange}
            classes={classes}
            price={this.state.price}
            type="price"
            />
          {this.errorHpMessage(this.state.hpDocDetailsErr.price, classes.inputErrors)}
        </Box>
      </>
    );
}

  handleOnlineConsultationeFees = (classes: any) => {
    if (this.state.kind == "Nurse") {
      return (
        <>
          <Box data-test-id="nightShiftFee" style={{ marginTop: "20px" }}>
            <FeeEngine
              navigation={undefined}
              id="" type="nightFee"
              isValidNumber={this.isValidNumber} placeholderText="/ Night shift"
              hpDocDetailsErr={this.state.hpDocDetailsErr.nightFee} showAyahErrorField={this.showErrorField}
              handlehpChange={this.handlehpChange} price={this.state.nightFee}
              classes={classes}
            />
            {this.errorHpMessage(this.state.hpDocDetailsErr.nightFee, classes.inputErrors)}
          </Box>
        </>
      );
    }else {
      return ( 
      <>
        <Box style={{ marginTop: "20px" }} data-test-id="onLineConsultationFee">
          <FeeEngine
            navigation={undefined}
            id="" type="onlineConsultationPrice"
            isValidNumber={this.isValidNumber} placeholderText="/ Online Consultation"
            hpDocDetailsErr={this.state.hpDocDetailsErr.onlineConsultationPrice} showAyahErrorField={this.showErrorField}
            handlehpChange={this.handlehpChange} price={this.state.onlineConsultationPrice}
            classes={classes}
          />
          {this.errorHpMessage(this.state.hpDocDetailsErr.onlineConsultationPrice, classes.inputErrors)}
        </Box>
      </>

       
      );
    }
  }

  handleNurseLongTermFees = (classes: any) => {
    if (this.state.kind === "Nurse" && this.state.longTermBooking) {
      return (
        <>
          <Box data-test-id="longTermPrice">
            <FeeEngine
              id="" 
              navigation={undefined}
              classes={classes}
              price={this.state.longTermPrice}
              placeholderText="/ 24hrs(long term shift)"
              isValidNumber={this.isValidNumber}
              hpDocDetailsErr={this.state.hpDocDetailsErr.longTermPrice}
              showAyahErrorField={this.showErrorField}
              handlehpChange={this.handlehpChange}
              type="longTermPrice"
              />
            {this.errorHpMessage(this.state.hpDocDetailsErr.longTermPrice, classes.inputErrors)}
          </Box>
        </>
      );
    }
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    const { hpDocDetailsErr } = this.state;
    // Customizable Area End

    return (
      // Customizable Area Start
      <>
      {
        !this.state.showGoogleMap ?
        (
        <Grid container id="ayha-container">
          <>
            <Grid item xl={4} md={4} sm={12} xs={12} className={`${classes.fixedGrid} ${classes.gridContainer}`}>
              <Box
                data-test-id="heathcarePersonnelLogoContainer"
                component="div"
                sx={{
                  height: "100%"
                }}
                className={classes.container}
                style={hpContainerStyle}
              >
                <Box className={classes.homeCureLogosContainer}>
                  <img
                    data-test-id="ayah-logo"
                    src={homecureTransparent}
                    alt="HeathcarePersonnelLogo"
                    className={classes.ayahHomecureLogos}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid data-test-id="healthcarePersonnelRegistration" item className={classes.gridContainer} xl={8} md={8} sm={12} xs={12}>
              <Box className={classes.patientRegistrationContainer}>
                <Box className={classes.patientLabelContainer}>
                  <Typography data-test-id="hpRegister" variant="h6" className={classes.registrationHeading}>
                    {this.profileHeading}
                  </Typography>
                  <Box data-test-id="setLocation" className={classes.setLocationContainer}
                    onClick={this.openSetLocationPage}
                  >
                    <Typography variant="h6" data-test-id="setLocation" className={classes.setLocation} onClick={this.openSetLocationPage}>
                      Set Location
                    </Typography>
                  </Box>
                </Box>
                <Box style={{display:this.showHpProfile}}>
                <Box className={classes.editIconsConatiners}>
                    <Box data-test-id="cistomProfile">
                        <img className={classes.avatarEdit} onClick={() => this.backToProfileView()} src={this.state.profilePicture} />
                        <img data-test-id="editProfile" className={classes.avatarEditIcons} src={Edit} onClick={this.handleOpenDialogs}/>
                    </Box>
                </Box>
                </Box>
                <Box style={{display:this.showHpText}}>
                <Typography data-test-id="hpText" className={classes.patientHeading} variant="h6">
                  Let's get you all set up so you can verify and manage your account.
                </Typography>
                </Box>
                <Grid container spacing={4}>
                 {this.state.kind==="Nurse" && (
                    <Grid item xl={12} md={12} sm={12} xs={12}>
                    <Box style={{ width: "48%"}}>
                      <TextField
                        variant="outlined"
                        data-test-id="hpLongTermBooking"
                        className={this.showhpConnectHospitalFields(this.state.longTermBooking,classes)}
                        placeholder="Long Term Booking"
                        onClick={this.hpLongTermBookingChange}
                        InputProps={{
                          endAdornment: (<InputAdornment position="end">
                            <img
                              src={this.showhpConnectHospitalIcons(this.state.longTermBooking)}
                              alt="Misc"
                              className={classes.connectIcon}
                            />
                          </InputAdornment>),
                          readOnly: true
                        }}
                      />
                    </Box>
                  </Grid>
                  )
                 }
                  <Grid item xl={12} md={12} sm={12} xs={12} className={classes.degreeContainerSpacing}>
                    <Box>
                      <Typography variant="h6" className={classes.registerLabel} style={{ textAlign: "left" }}>
                        Degree<span className={classes.required}>*</span>
                      </Typography>
                    </Box>
                  </Grid>
                  <Grid item xl={12} md={12} sm={12} xs={12} className={classes.degreeContainerSpacing}>
                    {this.state.educationDetails.map((eduDetail: any, index: number) => (
                        <Box key={index} data-test-id={`eduDropdown${index}`}  id={`eduDropdown${index}`}>
                        {this.callDegreeCollgeDetails(index, classes, eduDetail)}
                        </Box>
                    ))}
                      {this.state.isEditing && (
                        <>
                        <Box className={classes.degClgBox} data-test-id="degTextField">
                          <ClickAwayListener onClickAway={this.closeDegreeDropdown} data-test-id={`closeDegreeDropdown${parseInt(this.state.editableIndex)}`}>
                            <Box data-test-id={`degTextField${parseInt(this.state.editableIndex)}`} className={classes.degColTextField}>
                              <Box className={classes.hpSelectCheckbox}>
                                <TextField
                                  variant="outlined"
                                  data-test-id={`hpDegreeupdates${parseInt(this.state.editableIndex)}`}
                                  className={this.showErrorField(hpDocDetailsErr.degree, classes, "degree")}
                                  placeholder={this.degreePlaceholder(this.state.kind)}
                                  value={this.state.educationDetails[parseInt(this.state.editableIndex)].degree}
                                  onClick={this.editOpenDegreeDropdown}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <img
                                          src={DownArrow}
                                          alt="DownArrow"
                                          className={classes.hpDownArrowIcon}
                                        />
                                      </InputAdornment>
                                    ),
                                    readOnly: true
                                  }}
                                  onBlur={() => 
                                    this.callOnBlur(this.state.educationDetails,this.state.editableIndex)}
                                />
                                <>
                                  {this.state.editOpenDegreeDropdown  && (
                                    <Box className={classes.hpSelectFieldDropdown}>
                                      <Box className={classes.hpDropdownContent}>
                                        <Box className={classes.dropdownText}>
                                          Enter degree name
                                          <img
                                            data-test-id="hideHospitalDropdown"
                                            src={UpwardArrow}
                                            alt="UpwardArrow"
                                            className={classes.hpUpArrowIcon}
                                          />
                                        </Box>
                                        <Box className={classes.hpSelectFieldSize}>
                                          {this.state.degreeList.map((degree: any, index: number) => (
                                            <Box className={classes.hpSelectOption} key={degree.id}>
                                              <Checkbox
                                                data-test-id={`selectDegree${index}`}
                                                className={classes.checkbox}
                                                checked={this.state.educationDetails.some((eduDetail: any) => eduDetail.degree === degree.attributes.name)}
                                                onChange={() => this.handleEducationDegreeChange(parseInt(this.state.editableIndex), degree.attributes.name)}
                                                icon={<SvgIcon width="16" height="16" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <rect x="0.5" y="0.5" width="15" height="15" rx="1.5" stroke="#014866"/>
                                                </SvgIcon>}
                                                checkedIcon={ <SvgIcon width="16" height="16" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <rect x="0.5" y="0.5" width="15" height="15" rx="1.5" stroke="#014866" />
                                                <path d="M3.75 7.99992L6.58 10.8299L12.25 5.16992" stroke="#014866" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                                </SvgIcon> }
                                                disabled={this.isDegreeAlreadySelected(degree.attributes.name,parseInt(this.state.editableIndex))}
                                              />
                                              {degree.attributes.name}
                                            </Box>
                                          ))}
                                        </Box>
                                      </Box>
                                    </Box>
                                  )}
                                </>
                              </Box>
                            </Box>
                          </ClickAwayListener>
                          <Box data-test-id="clgsTextField" className={classes.degColTextField}>
                            <TextField
                              variant="outlined"
                              className={this.showErrorField(hpDocDetailsErr.college, classes)}
                              data-test-id={`hpCollegeupdates${parseInt(this.state.editableIndex)}`}
                              placeholder="Enter college name"
                              value={this.state.educationDetails[parseInt(this.state.editableIndex)].college}
                              onBlur={() => this.hpUpdateNurseCollegeName()}
                              onChange={(event) =>this.handleEducationCollegeChange(parseInt(this.state.editableIndex), event.target.value.trimStart())}
                            />
                          </Box>
                        </Box>
                        </>
                        )}
                      <Box data-test-id="degTextField" className={classes.degClgBox}>
                        <ClickAwayListener onClickAway={this.closeNewDegreeDropdown} data-test-id={`closeDegreeDropdown${this.state.educationDetails.length-1}`}>
                          <Box data-test-id={ `degTextField${this.state.educationDetails.length-1}` } className={ classes.degColTextField }>
                          <Box className={classes.hpSelectCheckbox}>
                            <TextField
                              variant="outlined"
                              data-test-id={`hpDegree${this.state.educationDetails.length-1}`}
                              placeholder={this.degreePlaceholder(this.state.kind)}
                              className={this.showErrorField(hpDocDetailsErr.degree, classes, "degree")}
                              value={this.state.degree}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <img
                                      src={DownArrow}
                                      alt="DownArrow"
                                      className={classes.hpDownArrowIcon}
                                    />
                                  </InputAdornment>
                                ),
                                readOnly: true
                              }}
                              onBlur={() => this.hpUpdateNurseDegree()}
                              onClick={this.openDegreeDropdown}
                            />
                            <>
                              {this.state.openDegreeDropdown && (
                                <Box className={classes.hpSelectFieldDropdown}>
                                  <Box className={classes.hpDropdownContent}>
                                    <Box className={classes.dropdownText}>
                                      Enter degree name
                                      <img
                                        data-test-id="hideHospitalDropdown"
                                        src={UpwardArrow}
                                        alt="UpwardArrow"
                                        className={classes.hpUpArrowIcon}
                                        onClick={this.openDegreeDropdown}
                                      />
                                    </Box>
                                    <Box className={classes.hpSelectFieldSize}>
                                      {this.state.degreeList.map((degree: any, index: number) => (
                                        <Box className={classes.hpSelectOption} key={degree.id}>
                                            <Checkbox
                                              data-test-id={`selectDegree${this.state.educationDetails.length-1}`}
                                              className={classes.checkbox}
                                              checked={this.state.educationDetails.some((eduDetail:any)=> eduDetail.degree === degree.attributes.name)}
                                              onChange={()=>this.handleEducationDegreeChange(this.state.educationDetails.length-1,degree.attributes.name)}
                                              icon={<SvgIcon width="16" viewBox="0 0 18 18" height="16"  fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <rect x="0.5" y="0.5" width="15" height="15" rx="1.5" stroke="#014866"/>
                                              </SvgIcon>}checkedIcon={ <SvgIcon width="16" height="16" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <rect x="0.5" y="0.5" width="15" height="15" rx="1.5" stroke="#014866" />
                                                <path d="M3.75 7.99992L6.58 10.8299L12.25 5.16992" stroke="#014866" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                              </SvgIcon>}
                                              disabled={this.isDuplicateDegree([...this.state.educationDetails], degree.attributes.name, 'degree', index)}
                                            />
                                            {degree.attributes.name }
                                        </Box>
                                      ))}</Box>
                                  </Box>
                                </Box>
                              )}
                            </>
                          </Box>
                        </Box>
                        </ClickAwayListener>
                        <Box className={classes.degColTextField} data-test-id="clgsTextField">
                            <TextField
                                data-test-id={`hpCollege${this.state.educationDetails.length-1}`}
                                className={this.showErrorField(hpDocDetailsErr.college, classes)}
                                variant="outlined"
                                placeholder="Enter college name"
                                onChange={(event) => this.handleEducationCollegeChange(this.state.educationDetails.length-1, event.target.value.trimStart())}
                                value={this.state.college}
                                onBlur={() => {
                                  const updatedEducationDetail=[...this.state.educationDetails];
                                  updatedEducationDetail[this.state.educationDetails.length-1].collegeCursor = true;
                                  this.setState({ educationDetails: updatedEducationDetail });
                                }}
                            />
                        </Box>
                      </Box>
                  </Grid>
                  <Grid item xl={6} md={6} sm={6} xs={12} className={classes.degreeContainerSpacing}>
                    <>
                      {this.errorHpMessage(hpDocDetailsErr.degree, classes.inputErrors)}
                    </>
                  </Grid>
                  <Grid item xl={6} md={6} sm={6} xs={12} className={classes.degreeContainerSpacing}>
                    <>
                      {this.errorHpMessage(hpDocDetailsErr.college, classes.inputErrors)}
                      {( this.isEducationErrorExist(this.state.hpDocDetailsErr)) && (
                        <Box className={classes.addDegreeField}>
                          <div data-test-id="clickAddBtn" className={classes.link} style={{ textDecoration: "none", display: "flex" }} onClick={this.addEducationDetails}>
                            + Add
                          </div>
                        </Box>
                      )}
                    </>
                  </Grid>
                  <Grid item xl={6} md={6} sm={6} xs={12} data-test-id="" className={classes.hospitalContainerSpacing}>
                    <ClickAwayListener   data-test-id="language"
                    onClickAway={this.closeLanguageDropdown}>
                      <Box id="languageContainer">
                      <Typography variant="h6" className={classes.registerLabel} style={{ textAlign: "left" }}>
                        Languages Known<span className={classes.required}>*</span>
                      </Typography>
                      <Box className={classes.hpSelectCheckbox} data-test-id="">
                        <Box data-test-id="hpLanguage" className={this.showErrorField(hpDocDetailsErr.language, classes, "language")} onClick={this.openLanguageDropdown}>
                        <Box className={this.hpRoleLanguageContainer(this.state.selectedLanguages,classes.patientLanguageBox)}>
                          {this.state.selectedLanguages.map((languageId: any, index: number)  =>  {
                            const language = this.state.languagesList.find((language: any) => language.id === languageId);
                            return (
                              <Chip
                                data-test-id={`removeLanguage${index}`}
                                key={language.id}
                                label={language.attributes.name}
                                onDelete={() => this.removeSelectedLanguage(language.id)}
                                deleteIcon={<CloseIcon fontSize="small" />}
                                className={classes.langIcon}
                              />
                            );
                          })}
                          </Box>
                          {this.selectHpLanguageText(this.state.selectedLanguages, classes)}
                          <img
                            className={classes.hpLangDownArrowIcon}
                            src={DownArrow}
                            alt="DownArrow"
                            style={{ marginLeft: 'auto' }}
                          />
                        </Box>
                        {this.showMessage(hpDocDetailsErr.language,this.state.openLanguageDropdown,classes)}
                        <>
                          {this.state.openLanguageDropdown && (
                            <Box className={classes.hpSelectFieldDropdown}>
                              <Box className={classes.hpLangDropdownContent}>
                                <Box className={classes.langdropdownText}>
                                  <img
                                    data-test-id="hideLanguageDropdown"
                                    src={UpwardArrow}
                                    alt="UpwardArrow"
                                    className={classes.hpUpArrowIcon}
                                    onClick={this.openLanguageDropdown}
                                  />
                                </Box>
                                <Box data-test-id="hpLangSelectFieldSize" className={classes.hpLangSelectFieldSize}>
                                  {this.state.languagesList.map((language: any, index: number) => (
                                    <Box className={classes.hpSelectOption} key={language.id} style={this.getHpFontWeightStyle(this.state.selectedLanguages, language.id, "language")}>
                                      <Checkbox
                                        data-test-id={`selectLanguage${index}`}
                                        className={classes.checkbox}
                                        icon={<SvgIcon width="16" height="16"  viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect x="0.5" y="0.5" width="15" height="15" rx="1.5" stroke="#014866"/>
                                        </SvgIcon>}
                                        checkedIcon={<SvgIcon width="16"  height="16" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <rect x="0.5" y="0.5" width="15" height="15" rx="1.5" stroke="#014866" />
                                        <path d="M3.75 7.99992L6.58 10.8299L12.25 5.16992" stroke="#014866" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                      </SvgIcon>}
                                        checked={this.state.selectedLanguages.includes(language.id)}
                                        onChange={() => this.handleLanguagesCheckboxChange(language.id)}
                                      />{language.attributes.name}
                                    </Box>
                                  ))}
                                </Box>
                              </Box>
                            </Box>
                          )}
                        </>
                      </Box>
                      {this.errorHpMessage(hpDocDetailsErr.language, classes.ayahInputErrors)}
                     </Box>
                    </ClickAwayListener>
                  </Grid>  
                  <Grid item xl={6} md={6} sm={6} xs={12} className={classes.mobileHospitalContainerSpacing}>
                  
                    <Box data-test-id="years-of-experience">
                      <Typography variant="h6" className={classes.registerLabel} style={{ textAlign: "left" }}>
                        Years of Experience<span className={classes.required}>*</span>
                      </Typography>
                      <TextField
                        variant="outlined"
                        data-test-id="hpExperience"
                        className={this.showErrorField(hpDocDetailsErr.yearofExp, classes)}
                        placeholder="Enter experience"
                        value={this.state.yearofExp}
                        onChange={(event) => this.handlehpChange("yearofExp", event.target.value.trimStart())}
                      />
                      {this.errorHpMessage(hpDocDetailsErr.yearofExp, classes.inputErrors)}
                    </Box>
                  </Grid>  
                  <Grid item xl={6} md={6} sm={6} xs={12} className={classes.hospitalContainerSpacing} data-test-id="">
                    <Box className={classes.ayahAddressDetailsContainer}>
                      <Box>
                        <Box data-test-id="age">
                          <Typography variant="h6" className={classes.registerLabel} style={{ textAlign: "left" }}>
                            Age<span className={classes.required}>*</span>
                          </Typography>
                          <TextField
                            variant="outlined"
                            data-test-id="hpAge"
                            placeholder="Enter age"
                            value={this.state.age}
                            className={this.showErrorField(hpDocDetailsErr.age, classes)}
                            onChange={(event) => this.handlehpChange("age", event.target.value.trimStart())}
                          />
                          {this.errorHpMessage(hpDocDetailsErr.age, classes.inputErrors)}
                        </Box>
                        <Box data-test-id="hpCityContainer">
                          <Typography variant="h6" className={classes.registerLabel} style={{ textAlign: "left" }}>
                            City<span className={classes.required}>*</span>
                          </Typography>
                          <TextField
                            variant="outlined"
                            data-test-id="hpCity"
                            className={this.showErrorField(hpDocDetailsErr.city, classes)}
                            placeholder="Enter city"
                            value={this.state.city}
                            onChange={(event) => this.handlehpChange("city", event.target.value.trimStart())}
                          />
                          {this.errorHpMessage(hpDocDetailsErr.city, classes.inputErrors)}
                        </Box>
                      </Box>
                      <Box>
                      <ClickAwayListener  data-test-id="closeGenderDropdown" onClickAway={this.closeGenderDropdown}>
                          <Box id="genderContainer">
                          <Typography variant="h6" className={classes.registerLabel} style={{ textAlign: "left" }}>
                            Gender<span className={classes.required}>*</span>
                          </Typography>
                          <Box data-test-id="gender" className={classes.hpSelectCheckbox}>
                            <TextField
                              variant="outlined"
                              data-test-id="hpGender"
                              placeholder="Enter gender"
                              value={this.state.gender}
                              className={this.showNurseSelectErrorField(hpDocDetailsErr.gender, classes)}
                              onClick={this.openDropdown}
                              InputProps={{
                                endAdornment: (<InputAdornment position="end">
                                  <img
                                    src={DownArrow}
                                    alt="DownArrow"
                                    className={classes.hpDownArrowIcon}  
                                  />
                                </InputAdornment>),
                                readOnly: true
                              }}
                            />
                            <Box data-test-id="genderDropdown">
                              {this.state.showDropdown && (
                                <Box className={classes.hpSelectFieldDropdown}>
                                  <Box className={classes.hpDropdownContent}>
                                    <Box className={classes.dropdownText}>
                                      Enter gender
                                      <img
                                        data-test-id="hideGenderDropdown"
                                        src={UpwardArrow}
                                        alt="hideGenderDropdown"
                                        className={classes.hpUpArrowIcon}
                                        onClick={this.openDropdown}
                                      />
                                    </Box>
                                    <Box>
                                      {genderSelectOptions.map((data: any, index: number) => (
                                        <Box className={classes.hpSelectOption} key={index} style={this.getHpFontWeightStyle(this.state.gender, data.value)}>
                                          <Checkbox
                                            data-test-id={`selectGender${index}`}
                                            className={classes.checkbox}
                                            checked={this.state.gender === data.value}
                                            icon={<SvgIcon width="16" height="16" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect x="0.5" y="0.5" width="15" height="15" rx="1.5" stroke="#014866"/>
                                            </SvgIcon>}
                                            checkedIcon={<SvgIcon width="16" height="16" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <rect x="0.5" y="0.5" width="15" height="15" rx="1.5" stroke="#014866" />
                                            <path d="M3.75 7.99992L6.58 10.8299L12.25 5.16992" stroke="#014866" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                          </SvgIcon>}
                                            onChange={() => this.handleHpGenderCheckboxChange(data.value)}
                                          />{data.value}
                                        </Box>
                                      ))}
                                    </Box>
                                  </Box>
                                </Box>
                              )}
                            </Box>
                            {this.errorHpMessage(hpDocDetailsErr.gender, classes.inputErrors)}
                          </Box>
                          </Box>
                       </ClickAwayListener> 
                        <Box data-test-id="hpDistrictContainer">
                          <Typography variant="h6" className={classes.registerLabel} style={{ textAlign: "left" }}>
                            District<span className={classes.required}>*</span>
                          </Typography>
                          <TextField
                            variant="outlined"
                            data-test-id="hpDistrict"
                            className={this.showErrorField(hpDocDetailsErr.district, classes)}
                            placeholder="Enter district"
                            value={this.state.district}
                            onChange={(event) => this.handlehpChange("district", event.target.value.trimStart())}
                          />
                          {this.errorHpMessage(hpDocDetailsErr.district, classes.inputErrors)}
                        </Box>
                      </Box>
                    </Box>                  
                    {
                      this.props.userLogin &&
                      <>
                        <Box>
                          <Typography variant="h6" className={classes.registerLabel} style={{ textAlign: "left" }}>
                          Email Address<span className={classes.required}>*</span>
                          </Typography>
                          <TextField
                            variant="outlined"
                            data-test-id="hpEmail"
                            className={this.showErrorField(hpDocDetailsErr.email, classes)}
                            placeholder="Enter Email"
                            value={this.state.email}
                            onChange={(event) => this.handlehpChange("email", event.target.value.trimStart())}
                          />
                          {this.errorHpMessage(hpDocDetailsErr.email, classes.inputErrors)}
                        </Box>
                      </>
                    }
                    <Box>
                      <Typography variant="h6" className={classes.registerLabel} style={{ textAlign: "left" }}>
                        Registration No.{this.mendatoryField(this.state.kind,classes)}
                      </Typography>
                      <TextField
                        variant="outlined"
                        data-test-id="hpregistrationNumber"
                        className={this.showErrorField(hpDocDetailsErr.registrationNumber, classes)}
                        placeholder="Enter registration no."
                        value={this.state.registrationNumber}
                        onChange={(event) => this.handlehpChange("registrationNumber", event.target.value.trimStart())}
                      />
                      {this.errorHpMessage(hpDocDetailsErr.registrationNumber, classes.inputErrors)}
                    </Box>
                    {this.handleNurseFees(classes)}
                    <Box style={{ marginTop: "20px" }}>
                      {this.handleNurseLongTermFees(classes)}
                    </Box>
                    <Box>
                      {this.handleOnlineConsultationeFees(classes)}
                    </Box>
                    <Button
                      variant="contained"
                      data-test-id="submit"
                      color="primary"
                      className={classes.registerDesktopButtons}
                      style={{ textTransform: "capitalize" }}
                      onClick={this.handleHpSubmit}
                    >
                      Submit
                    </Button>
                  </Grid>
                  <Grid item xl={6} md={6} sm={6} xs={12} className={classes.mobileHospitalContainerSpacing}>
                    <Box data-test-id="pincode" >
                      <Typography variant="h6" className={classes.registerLabel} style={{ textAlign: "left" }}>
                        Pin Code<span className={classes.required}>*</span>
                      </Typography>
                      <TextField
                        variant="outlined"
                        data-test-id="hpPincode"
                        className={this.showErrorField(hpDocDetailsErr.pincode, classes)}
                        placeholder="6 digit Pin Code"
                        value={this.state.pincode}
                        onChange={(event) => this.handlehpChange("pincode", event.target.value.trimStart())}
                      />
                      {this.errorHpMessage(hpDocDetailsErr.pincode, classes.inputErrors)}
                    </Box>
                    <Box data-test-id="address">
                      <Typography variant="h6" className={classes.registerLabel} style={{ textAlign: "left" }}>
                        Address<span className={classes.required}>*</span>
                      </Typography>
                      <TextField
                        variant="outlined"
                        data-test-id="hpAddress"
                        className={this.showErrorField(hpDocDetailsErr.address, classes)}
                        placeholder="Enter house no. , street name"
                        value={this.state.address}
                        onChange={(event) => this.handlehpChange("address", event.target.value.trimStart())}
                      />
                      {this.errorHpMessage(hpDocDetailsErr.address, classes.inputErrors)}
                    </Box>
                    {
                      this.props.userLogin &&
                      <Box>
                      <Typography variant="h6" className={classes.registerLabel} style={{ textAlign: "left" }}>
                        Phone Number<span className={classes.required}>*</span>
                      </Typography>
                      <TextField
                        variant="outlined"
                        data-test-id="hpNumber"
                        InputProps={{inputProps: { maxLength: 10 } }}
                        className={this.showErrorField(hpDocDetailsErr.phoneNumber, classes)}
                        placeholder="Enter Phone Number"
                        value={this.state.phoneNumber}
                        onChange={(event) => this.handlehpChange("phoneNumber", event.target.value.trimStart())}
                      />
                       {this.errorHpMessage(hpDocDetailsErr.phoneNumber, classes.inputErrors)}
                    </Box>
                    }
                    <Box>
                      <Typography data-test-id="regCertificate" variant="h6" className={classes.registerLabel} style={{ textAlign: "left" }}>
                        {this.registrationCertificateHeading(this.state.kind)}<span className={classes.required}>*</span>
                      </Typography>
                      {!this.state.isUploadDoc &&
                        <TextField
                          id="uploadDoc"
                          data-test-id="uploadDoc"
                          className={this.getHpClassName(classes, hpDocDetailsErr.uploadDoc)}
                          placeholder={this.getHpPlaceholder()}
                          variant="outlined"
                          value=""
                          style={{caretColor:"white !important"}}
                          onClick={this.handleButtonClick}
                          InputProps={{
                            startAdornment: (<InputAdornment position="start">
                              <img
                                src={ImageIcons}
                                alt="Preview"
                                className={classes.ayahDocIcon}
                              />
                            </InputAdornment>)
                          }}
                        />}
                      <Box onClick={this.handleButtonClick}>
                        <Typography variant="h6" style={{ caretColor:"transparent",textAlign: "left" }}>
                          {this.getHpInputProps(classes)}
                        </Typography>
                      </Box>
                      {this.errorHpMessage(hpDocDetailsErr.uploadDoc, classes.inputErrors)}
                      <input
                        type="file"
                        ref={this.fileInputRef}
                        style={{ display: "none" }}
                        onChange={this.handleUploadDoc}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Dialog data-test-id="nurseDialog" className={classes.otpDialogBoxNew} open={this.state.otpSend}>
              <Card 
                className={classes.otpNewTransparentCard}>
                <CardContent
                  data-test-id="otpNewTransparentCard" className={classes.otpCardContentNew} style={{flexDirection: "column"}}>
                  <Box 
                    className={classes.profileCaredWrapper}>
                    <Typography
                      className={classes.otpWelcomeTitle}
                      style={{ textAlign: "center" }}
                      variant="h6"
                      gutterBottom
                    >
                      <span className={classes.otpText}>OTP</span>{" "} Verification
                    </Typography>
                    <CloseIcon
                      style={{
                        cursor: "pointer", caretColor: "transparent", marginTop: "10px"
                      }}
                      data-test-id="closeDialog"
                      onClick={() => 
                        this.closeOtpPopup("hospital", "phone")
                      }
                    />
                  </Box>
                  <Typography
                    variant="h6" className={classes.cardSubtitle} gutterBottom style={{ textAlign: "center" }}>
                    Enter OTP</Typography>
                  <Typography
                    className={classes.cardInputLabel}
                    variant="h6"
                    style={{ textAlign: "center" }}>
                    An 4- Digit code has been sent to {"\n"}+91{""}
                    {`******${this.state.phoneNumber?.toString().slice(6, 10)}`} </Typography>
                  <Box className={classes.otpUpdateContainer}>
                    {this.state.phoneOTP.map((value: any, index: any) => 
                      (
                        <TextField
                          key={index}
                          onChange={event => 
                            this.handleOTPChange(index, event)}
                          className={this.inputErrorVerifyOtp(classes,this.state.isError)}
                          variant="outlined"
                          size="small"
                          id={`updateOtpInput${index}`}
                          data-test-id={`updateOtpInput${index}`}
                          value={value}
                          style={{
                            width: "50px",
                            margin: "5px auto 0px",
                            height: "45px"
                          }}
                          inputProps={{
                            style: {  textAlign: "center" },
                            maxLength: 1,
                          }}
                        />
                    ))}
                  </Box>
                  <Box className={classes.errorMessages}>
                      {this.showOTPError(this.state.isError,this.state.errorMessage,classes.otpErrors)}
                  </Box>
                  <CountDownTimer
                    times={ this.state.resendCounter }
                    toggleResend={ this.toggleResend }
                    isResendActive={ this.state.isResendActive }
                  />
                  <Box
                    display="flex"
                    padding="8px 0px"
                    flexDirection="row"
                    justifyContent="space-between"
                  >
                    {this.generateHPResendComponent(
                      "Resend OTP", "resendOTP"
                    )}

                    {this.generateHPResendComponent(
                      "Get OTP by Call", "resendOTPByCall"
                    )}
                  </Box>
                  <Button
                    color="primary"
                    className={classes.verifyOTPNew}
                    data-test-id="updateVerifyOtpBtn"
                    variant="contained"
                    onClick={() => this.verifyOTPData()}
                    style={{ textTransform: "capitalize" }}
                  > Verify
                  </Button> 
                </CardContent>
              </Card>
            </Dialog>
            <Dialog
              className={classes.otpDialogBoxNew}
              open={ this.state.emailSend }
            >
              <Card className={classes.transparentOtpCards}>
                <CardContent className={classes.cardOtpContents}
                  style={{ flexDirection: "column" }}
                >
                  <Box className={classes.confimPopup}>
                    <CloseIcon
                      data-test-id="closeDialogtest"
                      style={{
                        caretColor: "transparent",
                        cursor: "pointer",
                      }}
                      onClick={() => 
                        this.closeOtpPopup("hospital", "email")}
                    />
                  </Box>
                  <img
                    src={EmailIcon}
                    style={{ objectFit: "contain" }}
                    alt="email Verified"
                    className={classes.emailConfirmOtpLogo}
                  />
                  <Typography
                    variant="h6"
                    style={{ textAlign: "center" }}
                    className={classes.confirmEmailOtpHeading}
                  >
                    Confirm your email address
                  </Typography>
                  <Typography
                    variant="h6"
                    className={classes.confirmOtpEmailText}
                    style={{ textAlign: "center" }}
                  >
                    We sent a confirmation email to {this.state.email}
                  </Typography>
                  <Box className={classes.hospitalSuccessMessageContainer}>
                    <Typography
                      data-test-id="textMessage1"
                      style={{ textAlign: "center" }}
                      variant="h6"
                      className={classes.confirmEmailOtpSubText}
                    > Check your email and click on the
                    </Typography>
                    <Typography
                      data-test-id="textMessage2"
                      style={{ textAlign: "center" }}
                      variant="h6"
                      className={classes.confirmEmailOtpSubText}
                    >confirmation link to continue.
                    </Typography>
                  </Box>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={ this.confirmEmail }
                    data-test-id="emailSend"
                    className={classes.successOtpBtns}
                    style={{ textTransform: "capitalize" }}>
                    Resend
                  </Button>
                </CardContent>
              </Card>
            </Dialog>
            <Dialog
              className={classes.otpDialogBoxNew}
              open={this.state.confimEmail}
            >
              <Card className={classes.transparentOtpCards}>
                <CardContent
                  style={{ flexDirection: "column" }}
                  className={classes.cardOtpContents}>
                  <img
                    src={verifiedEmail}
                    className={classes.emailVerified}
                    alt="email Verified"
                    style={{ objectFit: "contain" }}
                  />
                  <Typography
                    variant="h6"
                    style={{ textAlign: "center" }}
                    className={classes.emailMessage}
                  >Your Email Address Verified</Typography>
                  <Typography
                    variant="h6" className={classes.successEmailMessages}
                    style={{ textAlign: "center" }}
                  >Successfully
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => this.otpSuccessEmail("hospital")}
                    style={{ textTransform: "capitalize" }}
                    data-test-id="confimEmails"
                    className={classes.successOtpBtns}
                  > Done
                  </Button>
                </CardContent>
              </Card>
            </Dialog>
            <Dialog
              className={classes.otpDialogBoxNew}
              open={!!this.state.otpVerified}>
              <Card
                data-test-id="otpCardContainer" className={classes.otpNewTransparentCard}
              >
                <CardContent className={classes.otpCardContentNew}
                  style={{ flexDirection: "column" }}>
                  <img
                    className={classes.OtpSuccessLogo}
                    src={otp_success}
                    alt="PhoneVerified"
                    style={{ objectFit: "contain" }}
                  />
                  <Typography
                    className={classes.OtpSuccessMessage}
                    variant="h6"
                    style={{ textAlign: "center" }}
                  >Phone Verified Successfully</Typography>
                  <Button
                    data-test-id="otpVerifieds"
                    className={classes.successOtpBtn}
                    variant="contained"
                    color="primary"
                    style={{ textTransform: "capitalize" }}
                    onClick={() => this.otpSuccess("hospital")}
                  > Done
                  </Button>
                </CardContent>
              </Card>
            </Dialog>

            <Dialog
              open={this.state.openDialog}
              className={this.enableHPDialogBox(this.state.enableWeb,classes)}
              onClose={this.handleCloseDialog}
            >
              <Box className={classes.dialogTitleConatiner}>
                <Typography className={classes.dialogTitle}>
                  Upload Profile Image</Typography>
                <CloseIcon data-test-id="closeHPDocDialog" style={{ cursor: "pointer" }} 
                  onClick={this.handleCloseDialog} />
              </Box>
              <DialogContent data-test-id="HPContainer">
                <Box data-test-id="enablewebHP">
                    <Box data-test-id="iconConatiner">
                      <Box data-test-id="customContainer" className={classes.dialogBoxIcons} >
                        <img data-test-id="openHPWebToUpload" className={classes.camera} src={camera}/>
                        <img data-test-id="openHPPopupToUpload" className={classes.gallery} src={gallery} onClick={this.handleHpUploadProfiles}/>
                        <input type="file" className={classes.imageInput} ref={this.webCameraRef} onChange={this.handleUploadImage}/>
                      </Box>
                      <Box className={classes.dialogBoxText}>
                        <Typography data-test-id="takeHPPhoto" className={classes.uploadCameraText}>Take Photo</Typography>
                        <Typography data-test-id="galleryHP" className={classes.uploadText}>Gallery</Typography>
                      </Box>
                    </Box>
                  {this.errorHpMessage(this.state.UploadImageError, classes.profileError)}
                </Box>
              </DialogContent>
            </Dialog>
          </>
          {this.errorMessage(classes,this.state.healthcarePersonnelError,this.state.messageType)}
        </Grid>
        )
        :
        (
          <>
            <LocationView id="" navigation="undefined" setAddress={this.setAddressFromChild} closeSetLocationPage={this.closeSetLocationPage} setAddressFieldsByMap={this.setAddressFieldsByMap} showGoogleMap={this.state.showGoogleMap} longitude={this.state.longitude} latitude={this.state.latitude} />
          </>
        )
      }
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
export default withStyles(styles)(HealthCarePersonnelRegistration);
// Customizable Area End
