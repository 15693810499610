Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.httpGetMethod = "GET";
exports.httpPostMethod = "POST";
exports.httpPutMethod = "PUT";
exports.searchApiContentType = "application/json";
exports.getSearchApiEndPoint = "search/users";
exports.errorTitle = "Error";
exports.getCustomizableProfileEndPoint = "bx_block_profile/profile/current_user_profile";
exports.updateCustomizableProfileEndPoint = "bx_block_profile/profile/update_user_profile";
exports.getCustomizableProfileFieldsEndPoint = "bx_block_profile/profile/custom_user_profile_fields";
exports.getProfile = "patient_profile_details";
exports.updateProfile = "update_patient_profile";
exports.uploadProfile = "upload_patient_image";
exports.getDiseasesAPIEndPoint = "diseases";
exports.getHealthcarePersonnelDetails = "healthcare_personnel_profile_details";
exports.getHospitalProfile = "profile_details";
exports.updatePatientProfile = "update_profile";
exports.uploadHospitalProfile = "upload_hospital_image";
exports.fetchDepartments = "departments";
exports.fetchInsurances = "insurances";
exports.verifications = "verifications";
exports.otpVerification = "verifications/verify_otp";
exports.getFields = "/";
exports.rowBedData = [
  {
      id: 1,
      bed: "General Bed",
      count: 50,
      price: 2080
  },
  {
      id: 2,
      bed: "General Bed",
      count: 50,
      price: 2080
  },
  {
      id: 3,
      bed: "General Bed",
      count: 50,
      price: 2080
  }
];
exports.TableTitles = {
  Select:"Select",
  Beds:"Beds",
  Count:"Count",
  Price:"Price",
  TotalBeds:"Total Beds",
  Manage:"Manage"
};
exports.ManagePageButtons = {
  Beds:"Beds",
  HealthcarePersonnel:"Healthcare Personnel",
  ViewDetails:"View Details"
};
exports.ManagePageViewType = {
  bed:"bed",
  healthcareperson:"healthcare-person"
};

exports.getMangementAPI = {
  allBed: "hospitals/all_beds",
  allHealthcareData: "hospitals/list_healthcare_personnel",
  deactiveHospital: "hospitals/toggle_beds_availability",
  removeHp: "hospitals/remove_healthcare_personnel",
  hpProfile: "caretaker_reservations/healthcare_personnel_info?healthcare_personnel_id=",
  updateBed: "beds/batch_update_beds",
  appointmentApi: "hospitals/availability_details?healthcare_personnel_id="
};
exports.httpPatchMethod = "PATCH";
exports.settingsItems = {
  Settings:"Settings",
  Manage : "Manage",
  AboutUs:"About Us",
  TermsConditions:"Terms and Conditions",
  PrivacyPolicy:"Privacy Policy",
  Help:"Help"
};
exports.ButtonLabel = {
  DeactivateAccount:"Deactivate Account",
  Logout : "Logout",
  Yes : "Yes",
  No : "No",
  Done : 'Done'
}
exports.settingsPageName = {
  manage: 'hospital-manage',
  aboutUs: 'about-us',
  termsCondition :'terms&condition',
  privacyPolicy: 'privacy-policy',
  help:'help'
};
exports.PageTitle = {
  AboutUs:'About Us',
  TermsAndConditions:'Terms and Conditions',
  PrivacyPolicy:'Privacy Policy',
  HelpCenter:'Help Center',
  DeactivateAccount:"Deactivate your account",
  Manage:"Manage"
};
exports.TokenHasExpired = 'Token has Expired';
exports.HelpCenterEndPoint = "help_center_inquiry";
exports.PrivacyPolicyUpdateEndPoint = 'privacy_policy?privacy_policy=';
exports.TermsAndConditionsUpdateEndPoint = 'terms_condition?terms_and_conditions=';
exports.DeactivateAccountEndPoint = "deactivate_account";
exports.DeactivatePageInfoEndPoint ='deactivation_information';
exports.TermsAndConditionsEndPoint  = 'terms_condition';
exports.PrivacyPolicyEndPoint = 'privacy_policy';
exports.AboutUsEndPoint = "about_us";
// Customizable Area End
