import * as React from "react";
// Customizable Area Start
import { withStyles, Box, Typography, Grid, ThemeProvider, createTheme, Button } from "@material-ui/core";
import PatientOrderManagementController, { Props } from "./PatientOrderManagementController";
import Sidebar from "../../../components/src/Sidebar.web";
import { ProfileIcon } from "./assets";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff"
        }
    }
});
// Customizable Area End

export class HPUserProfile extends PatientOrderManagementController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        this.fetchHPData()
    }

    showPrescriptionBtn = () => {
        const classes = this.props.classes;
        return(
            <Button data-test-id="viewPrescription" className={this.state.hpStatus ? classes.prescriptionBooking :classes.prescriptionBtnHide} onClick={() => this.moveToPrescriptionScreen(this.props.navigation.getParam("navigationBarTitleText"))}>Prescription</Button>
        )
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        const { hpList } = this.state;
        // Customizable Area End
        return (
            // Customizable Area Start
            <>
                <ThemeProvider theme={theme}>
                    <Grid container className={classes.leftHPWrapper}>
                        <Grid item xs={1}>
                            <Sidebar />
                        </Grid>
                        <Grid item xs={11} className={classes.righHPtWrapper}>
                            <Box>
                            <Box className={classes.headerHPContainer}>
                                    <Box className={classes.orderHeading}>
                                        <ArrowBackIosIcon
                                            className={classes.calendarHPBackBtn}
                                            data-test-id="calendarHPBackBtn"
                                            onClick={this.backToOrderManagementScreen}
                                        />
                                        <Typography variant="h4" className={classes.profileHPHeading}>View Details</Typography>
                                    </Box>
                                </Box>
                                <Grid container>
                                    <Grid item xl={4} md={6} sm={6} xs={12}>
                                        <Box className={classes.topHPDetailsLeftContainer}>
                                            <Box className={classes.profileHPContainer}>
                                                <img className={classes.avatarhp} src={hpList.profile_image ===  null ? ProfileIcon : hpList.profile_image}
                                                />
                                            </Box>
                                            <Box className={classes.profileDetailsContainer}>
                                                <Typography variant="h6" className={classes.userName}>{this.checkIfProfileIsDoctor}{hpList.name}</Typography>
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid item xl={8} md={6} sm={6} xs={12}></Grid>
                                    <Grid item xl={6} md={8} sm={8} xs={12}>
                                        <Box>
                                           <Box className={classes.btnHPContainer}>
                                                {this.showPrescriptionBtn()}
                                                <Button className={classes.prescriptionBooking}>
                                                    Video Call
                                                </Button>
                                            </Box>
                                            <Box>
                                                <Box className={classes.subContainer}>
                                                    <Typography className={classes.userDetailsHPLabel}>
                                                        Age
                                                    </Typography>
                                                    <Typography className={classes.userDetailsColon}>
                                                        :
                                                    </Typography>
                                                    <Typography className={classes.userDetails} style={{textTransform:"lowercase"}}>
                                                       {hpList.age} y/o
                                                    </Typography>
                                                </Box>
                                                <Box className={classes.subContainer}>
                                                    <Typography className={classes.userDetailsHPLabel}>
                                                        Gender
                                                    </Typography>
                                                    <Typography className={classes.userDetailsColon}>
                                                        :
                                                    </Typography>
                                                    <Typography className={classes.userDetails}>
                                                       {hpList.gender}
                                                    </Typography>
                                                </Box>
                                                <Box className={classes.subContainer}>
                                                    <Typography className={classes.userDetailsHPLabel}>
                                                        Service Booked
                                                    </Typography>
                                                    <Typography className={classes.userDetailsColon}>
                                                        :
                                                    </Typography>
                                                    <Typography className={classes.userDetails}>
                                                       {hpList.gender}
                                                    </Typography>
                                                </Box>
                                                <Box className={classes.subContainer}>
                                                    <Typography className={classes.userDetailsHPLabel}>
                                                        Date
                                                    </Typography>
                                                    <Typography className={classes.userDetailsColon}>
                                                        :
                                                    </Typography>
                                                    <Typography className={classes.userDetails}>
                                                       {hpList.date}
                                                    </Typography>
                                                </Box>
                                                {this.state.hpStatus &&
                                                    (<Box className={classes.subContainer}>
                                                        <Typography className={classes.userDetailsHPLabel}>
                                                            Time
                                                        </Typography>
                                                        <Typography className={classes.userDetailsColon}>
                                                            :
                                                        </Typography>
                                                        <Typography className={classes.userDetails}>
                                                            {hpList.time}
                                                        </Typography>
                                                    </Box>)}
                                                <Box className={classes.subContainer}>
                                                    <Typography className={classes.userDetailsHPLabel}>
                                                        Consulation Fee
                                                    </Typography>
                                                    <Typography className={classes.userDetailsColon}>
                                                        :
                                                    </Typography>
                                                    <Typography className={classes.userDetails}>
                                                        Rs. {hpList.amount}.00
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                            <Box className={classes.btnContainer}>
                                <Button className={classes.cancelBooking} data-test-id="bookAgain" onClick={() => this.bookAgain(this.props.navigation.getParam("navigationBarTitleText"))}>
                                    Book Again
                                </Button>
                            </Box>
                        </Grid>
                    </Grid>
                </ThemeProvider>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
const styles = {
    leftHPWrapper: {
        overflowX: "hidden" as "hidden",
        height: "100%",
    },
    righHPtWrapper: {
        height: "100%",
        padding:"50px",
        overflowY: "scroll" as "scroll",
        scrollbarColor: "#fff #fff",
        scrollbarWidth: "thin" as "thin",
        "& ::-webkit-scrollbar-button": {
            display: "none"
        },
    },
    profileHPHeading: {
        width: "100%",
        caretColor: "transparent",
        fontSize: "28px",
        fontFamily: "SF Pro Text Bold",
        color: "#292929",
      },
    topHPDetailsLeftContainer:{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "0px 0px 0px 0px",
        fontFamily: "SF Pro Text Regular",
    },
    profileHPContainer:{
        width: "25%",
        [theme.breakpoints.down("sm")]: {
          width: "50%"
        },
        [theme.breakpoints.down("md")]: {
          width: "40%"
        },
    },
    avatarhp:{
        borderRadius: "50%",
        border: "1px solid #eeeaea",
        backgroundColor: "#eeeaea",
        width: "155px",
        height: "155px",
    },
    profileDetailsContainer:{
        width: "75%",
        [theme.breakpoints.down("xl")]: {
          paddingLeft: "30px"
        },
        [theme.breakpoints.down("sm")]: {
          width: "50%",
          paddingLeft: "0px"
        },
        [theme.breakpoints.down("md")]: {
          width: "70%",
          paddingLeft: "10px"
        },
    },
    userName:{
        color: "#292929",
        fontFamily: "SF Pro text Bold",
        fontSize: "24px",
        caretColor: "transparent",
    }, 
    subContainer:{
        display: "flex",
        fontFamily: "SF Pro Text Regular",
        padding: "22px 0px 0px",
        width: "100%",
        justifyContent: "space-between",
        alignItems: "start",
    },
    userDetailsHPLabel:{
        fontSize: "18px",
        "@media (max-width:1300px)": {
            width: "50% !important"
        },
        caretColor: "transparent",
        fontFamily: "SF Pro Text Medium",
        width: "45%",
    },
    userDetailsColon:{
        caretColor: "transparent",
        width: "8%",
        fontSize: "18px",
        fontFamily: "SF Pro Text Medium",
        color: "#808080",
    },
    userDetails:{
        caretColor: "transparent",
        fontSize: "18px",
        paddingRight: "217px",
        fontFamily: "SF Pro Text Regular",
        color: "#717171",
        width: "75%",
        textTransform: "capitalize" as "capitalize",
    },
    headerHPContainer: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    },
    orderHeading: {
        display: "flex",
        alignItems: "center",
        marginBottom:"40px",
        justifyContent: "flex-start",
        gap: "25px",
    },
    calendarHPBackBtn: {
        display: "flex",
        alignItems: "center",
        fontSize: "22px",
        padding: "13px 9px 13px 17px",
        justifyContent: "center",
        borderRadius: "8px",
        border: "1px solid #E9E9E9",
    },
    cancelBooking: {
        border: "none",
        color: "#FFFFFF",
        width:"200px",
        backgroundColor: "#014866",
        fontSize:"18px",
        fontFamily:"SF PRO Text Bold",
        height:"56px",
        alignItem:"center" as "center",
        alignContent:"center" as "center",
        marginBottom: "15px",
        borderRadius: "8px",
        caretColor: "transparent",
        "&.MuiButton-root:hover": {
            backgroundColor: "#014866",
            color: "#FFFFFF",
        },
        textTransform: "capitalize" as "capitalize",
        cursor: "pointer",
    },
    prescriptionBooking: {
        border: "1px solid #014866",
        color: "#014866",
        caretColor: "transparent",
        backgroundColor: "#FFFFFF",
        fontSize:"18px",
        fontFamily:"SF PRO Text Bold",
        width:"200px",
        height:"56px",
        alignItem:"center" as "center",
        alignContent:"center" as "center",
        marginBottom: "15px",
        borderRadius: "8px",
        cursor: "pointer",
        "&.MuiButton-root:hover": {
            backgroundColor: "#FFFFFF",
            color: "#014866",
        },
        textTransform: "capitalize" as "capitalize"
    },
    prescriptionBtnHide: {
        border: "1px solid #DFDFDF",
        alignItem:"center" as "center",
        alignContent:"center" as "center",
        marginBottom: "15px",color: "#FFFFFF",
        caretColor: "transparent",
        backgroundColor: "#DFDFDF",
        fontSize:"18px",
        fontFamily:"SF PRO Text Bold",
        width:"200px",
        height:"56px",
        borderRadius: "8px",
        cursor: "pointer",
        "&.MuiButton-root:hover": {
            backgroundColor: "#DFDFDF",
            color: "#FFFFFF",
        },
        textTransform: "capitalize" as "capitalize"
    },
    btnContainer: {
        display: "flex",
        marginTop: "100px",
        gap: "25px",
    },
    btnHPContainer: {
        display: "flex",
        marginTop: "40px",
        gap: "25px",
    }
}

export default withStyles(styles)(HPUserProfile);
// Customizable Area End