import * as React from "react";
// Customizable Area Start
import { withStyles, Box, Typography, Grid, ThemeProvider, createTheme, Stepper, Step, StepLabel, SvgIcon, TextField, MenuItem, Checkbox, FormControlLabel, Button, InputAdornment, ClickAwayListener, FormHelperText, Chip } from "@material-ui/core";
import HpOrderManagementController, { Props } from "../HpOrderManagementController";
import Sidebar from "../../../../components/src/Sidebar.web";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { AddBtn, DownArrow, HomecureLogo, Signature, SubtractBtn, UpwardArrow } from "./../assets";
import CloseIcon from '@material-ui/icons/Close';

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff"
    }
  }
});

const SliderBlueCircle = () => (
  <SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/SvgIcon">
    <circle cx="10" cy="10" r="9.5" fill="white" stroke="#4D6EFF" />
    <circle cx="10" cy="10" r="7" fill="#4D6EFF" />
  </SvgIcon>
);

const SliderGrayCircle = () => (
  <SvgIcon width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/SvgIcon">
    <circle cx="10" cy="10" r="9.5" fill="#efefef" stroke="#adabab" />
    <circle cx="10" cy="10" r="7" fill="#efefef" />
  </SvgIcon>
);

const CustomsCheckboxIconChecked = () => (
  <SvgIcon width="16" height="16" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="15" height="15" rx="1.5" stroke="#014866" />
    <path d="M3.75 7.99992L6.58 10.8299L12.25 5.16992" stroke="#014866" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
  </SvgIcon>
);

const CustomsCheckboxIconUnChecked = () => (
  <SvgIcon width="16" height="16" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="15" height="15" rx="1.5" stroke="#014866" />
  </SvgIcon>
);


// Customizable Area End

export class HealthcarePersonnelPrescription extends HpOrderManagementController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.getdiseasesList()
  }
  getIcon = (index: number) => (index <= this.state.activeStep ? <SliderBlueCircle /> : <SliderGrayCircle />);

  errorMessage = (error: string | undefined, inputError: any) => {
    return error ? (
      <FormHelperText className={inputError}>{error}</FormHelperText>
    ) : null;
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
        <ThemeProvider theme={theme}>
          <Grid container className={classes.leftHPWrapper}>
            <Grid item xs={1}>
              <Sidebar />
            </Grid>
            <Grid item xs={11} className={classes.righHPtWrapper}>
              <Box>
                <Box className={classes.headerHPContainer}>
                  <Box className={classes.orderHeading}>
                    <ArrowBackIosIcon
                      className={classes.calendarHPBackBtn}
                      data-test-id="backToPrescriptionScreen"
                      onClick={this.backToHpOrderManagementScreen}
                    />
                    <Typography variant="h4" className={classes.profileHPHeading}>Prescription</Typography>
                  </Box>
                </Box>
                <Box className={classes.stepperContainer}>
                  <Stepper activeStep={this.state.activeStep} alternativeLabel classes={{ root: classes.stepperClasses }}>
                    {this.state.steps.map((step: any, index: number) => (
                      <Step key={index}>
                        <StepLabel StepIconComponent={() => this.getIcon(index)}>{step.label}</StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                </Box>
                {this.state.activeStep === 0 &&
                  (<Grid container >
                    <Grid item xl={8} md={8} sm={12} xs={12}>
                      <Box className={classes.inputContainer}>
                        <Typography variant="h4" className={classes.homeCureHeading}>HomeCure</Typography>
                        <Box className={classes.seperationLine} />
                        <Box style={{ width: "100%" }}>
                          <Box>
                            <TextField
                              variant="outlined"
                              data-test-id="hospitalName"
                              placeholder="Hospital Name"
                              className={classes.inputNameFields}
                              onChange={event => this.handlePrescriptionChanges("hospitalName", event.target.value.trimStart())}
                              value={this.state.hospitalName}
                            />
                          </Box>
                          <Box>
                            {this.errorMessage(this.state.errors.hospitalName, classes.inputErrors)}
                          </Box>
                        </Box>
                        <Box style={{ width: "100%" }}>
                          <Box>
                            <TextField
                              variant="outlined"
                              data-test-id="city"
                              placeholder="City"
                              className={classes.inputCityFields}
                              onChange={event => this.handlePrescriptionChanges("city", event.target.value.trimStart())}
                              value={this.state.city}
                            />
                          </Box>
                          <Box>
                            {this.errorMessage(this.state.errors.city, classes.inputErrors)}
                          </Box>
                        </Box>
                      </Box>
                      <Grid container spacing={5}>
                        <Grid item xl={6} md={6} sm={12} xs={12}>
                          <Box className={classes.containerSpace}>
                            <Typography variant="h4" className={classes.homecureLabel}>Heathcare Personnel Name</Typography>
                            <Box style={{ display: "flex" }}>
                              <TextField
                                variant="outlined"
                                select
                                defaultValue="Dr."
                                className={classes.inputTitleFields}
                              >
                                {this.state.title.map((option: any) => (
                                  <MenuItem className={classes.menuItem} key={option.value} value={option.value}>
                                    {option.label}
                                  </MenuItem>
                                ))}
                              </TextField>
                              <TextField
                                variant="outlined"
                                placeholder="Name"
                                data-test-id="hpName"
                                className={classes.inputTitleNameFields}
                                onChange={event => this.handlePrescriptionChanges("hpName", event.target.value.trimStart())}
                                value={this.state.hpName}
                              />
                            </Box>
                            {this.errorMessage(this.state.errors.hpName, classes.inputErrors)}
                          </Box>
                          <Box className={classes.containerSpace}>
                            <Box style={{ display: "flex", gap: "5%" }}>
                              <Box>
                                <Typography variant="h4" className={classes.homecureLabel}>Date</Typography>
                                <TextField
                                  variant="outlined"
                                  placeholder="DD/MM/YYYY"
                                  className={classes.inputDegreeNameFields}
                                  data-test-id="date"
                                  onChange={event => this.handlePrescriptionChanges("date", event.target.value.trimStart())}
                                  value={this.state.date}
                                />
                                {this.errorMessage(this.state.errors.date, classes.inputErrors)}
                              </Box>
                              <Box>
                                <Typography variant="h4" className={classes.homecureLabel}>Time</Typography>
                                <TextField
                                  variant="outlined"
                                  placeholder="HH:MM"
                                  className={classes.inputDegreeNameFields}
                                  data-test-id="time"
                                  onChange={event => this.handlePrescriptionChanges("time", event.target.value.trimStart())}
                                  value={this.state.time}
                                />
                                {this.errorMessage(this.state.errors.time, classes.inputErrors)}
                              </Box>
                            </Box>
                          </Box>
                          <Box className={classes.containerSpace}>
                            <Typography variant="h4" className={classes.homecureLabel}>Consult Type</Typography>
                            <Box style={{ display: "flex", gap: "5%" }}>
                              <FormControlLabel
                                className={classes.consultType}
                                data-test-id="video_call"
                                control={<Checkbox checked={this.state.consultType === "video_call"} className={classes.checkboxWhite} color="primary" icon={<CustomsCheckboxIconUnChecked />} checkedIcon={<CustomsCheckboxIconChecked />} />}
                                label={"Video Call"}
                                onClick={() => this.setConsultType("video_call")}
                              />
                              <FormControlLabel
                                className={classes.consultType}
                                data-test-id="home_visit"
                                control={<Checkbox checked={this.state.consultType === "home_visit"} className={classes.checkboxWhite} color="primary" icon={<CustomsCheckboxIconUnChecked />} checkedIcon={<CustomsCheckboxIconChecked />} />}
                                label={"Home Visit"}
                                onClick={() => this.setConsultType("home_visit")}
                              />
                            </Box>
                          </Box>
                        </Grid>
                        <Grid item xl={6} md={6} sm={12} xs={12}>
                          <Box className={classes.containerSpace}>
                            <Typography variant="h4" className={classes.homecureLabel}>Degree Name</Typography>
                            <TextField
                              variant="outlined"
                              placeholder="Degree Name"
                              className={classes.inputDegreeNameFields}
                              data-test-id="degree"
                              onChange={event => this.handlePrescriptionChanges("degree", event.target.value.trimStart())}
                              value={this.state.degree}
                            />
                            {this.errorMessage(this.state.errors.degree, classes.inputErrors)}
                          </Box>
                          <Box className={classes.containerSpace}>
                            <Typography variant="h4" className={classes.homecureLabel}>Consult ID</Typography>
                            <TextField
                              variant="outlined"
                              placeholder="ID"
                              className={classes.inputDegreeNameFields}
                              data-test-id="consultId"
                              value={this.state.consultId}
                              onChange={event => this.handlePrescriptionChanges("consultId", event.target.value.trimStart())}
                            />
                            {this.errorMessage(this.state.errors.consultId, classes.inputErrors)}
                          </Box>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>)}
                {this.state.activeStep === 1 &&
                  (
                    <Box>
                      <Grid container >
                        <Grid item xl={8} md={8} sm={12} xs={12}>
                          <Grid container spacing={5}>
                            <Grid item xl={6} md={6} sm={12} xs={12}>
                              <Box className={classes.containerSpace}>
                                <Typography variant="h4" className={classes.homecureLabel}>Patient Name</Typography>
                                <TextField
                                  variant="outlined"
                                  placeholder="Name"
                                  className={classes.inputDegreeNameFields}
                                  data-test-id="patientName"
                                  onChange={event => this.handlePrescriptionChanges("patientName", event.target.value.trimStart())}
                                  value={this.state.patientName}
                                />
                                {this.errorMessage(this.state.errors.patientName, classes.inputErrors)}
                              </Box>
                            </Grid>
                            <Grid item xl={6} md={6} sm={12} xs={12}>
                              <Box className={classes.containerSpace}>
                                <Box style={{ display: "flex", gap: "5%" }}>
                                  <Box style={{ width: "50%" }}>
                                    <Typography variant="h4" className={classes.homecureLabel}>Age</Typography>
                                    <TextField
                                      variant="outlined"
                                      placeholder="Enter age"
                                      className={classes.inputDegreeNameFields}
                                      data-test-id="age"
                                      onChange={event => this.handlePrescriptionChanges("age", event.target.value.trimStart())}
                                      value={this.state.age}
                                    />
                                    {this.errorMessage(this.state.errors.age, classes.inputErrors)}
                                  </Box>
                                  <Box style={{ width: "50%" }}>
                                    <Typography variant="h4" className={classes.homecureLabel}>Gender</Typography>
                                    <TextField
                                      variant="outlined"
                                      placeholder="Select"
                                      className={classes.inputDegreeNameFields}
                                      select
                                      defaultValue="Female"
                                      data-test-id="gender"
                                      value={this.state.gender}
                                      onChange={event => this.handlePrescriptionChanges("gender", event.target.value.trimStart())}
                                    >
                                      {this.state.gendeOption.map((option: any) => (
                                        <MenuItem key={option.value} className={classes.menuItem} value={option.value}>
                                          {option.label}
                                        </MenuItem>
                                      ))}
                                    </TextField>
                                  </Box>
                                </Box>
                              </Box>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid item xl={8} md={8} sm={12} xs={12}>
                          <Box>
                            <Typography variant="h4" className={classes.homecureLabel}>Vitals</Typography>
                          </Box>
                          <Grid container className={classes.vitalContainer}>
                            <Grid item xl={6} md={6} sm={12} xs={12} className={classes.rightContainer}>
                              <Box>
                                <Typography variant="h4" className={classes.homecureBoldVitalLabel}>Blood Pressure</Typography>
                                <TextField
                                  variant="filled"
                                  placeholder="xxx/yyy"
                                  className={classes.inputVitalFields}
                                  InputProps={{
                                    endAdornment: (
                                      <InputAdornment position="end">
                                        <div style={{ fontFamily: "SF Pro Text Regular", fontSize: "14px", color: "#808080", marginTop: "14px" }}> mmHg</div>
                                      </InputAdornment>
                                    )
                                  }}
                                  data-test-id="bloodPressure"
                                  onChange={event => this.handlePrescriptionChanges("bloodPressure", event.target.value.trimStart())}
                                  value={this.state.bloodPressure}
                                />
                                <FormControlLabel
                                  className={classes.declaredBy}
                                  control={<Checkbox checked={this.state.bloodPressureDecared} className={classes.checkboxWhite} color="primary" icon={<CustomsCheckboxIconUnChecked />} checkedIcon={<CustomsCheckboxIconChecked />} />}
                                  label={"Declared by patient"}
                                  data-test-id="bloodPressureDecared"
                                  onClick={() => this.handelDeclaredBy("bloodPressureDecared", !this.state.bloodPressureDecared)}
                                />
                              </Box>
                              <Box>
                                <Typography variant="h4" className={classes.homecureBoldVitalLabel}>Weight</Typography>
                                <Box style={{ display: "flex" }}>
                                  <TextField
                                    placeholder="xxx"
                                    className={classes.inputVitalFields}
                                    variant="filled"
                                    data-test-id="weight"
                                    InputProps={{ endAdornment: (<InputAdornment position="end"><div style={{ fontFamily: "SF Pro Text Regular", fontSize: "14px", color: "#808080", marginTop: "14px" }}>kg</div></InputAdornment>) }}
                                    onChange={event => this.handlePrescriptionChanges("weight", event.target.value.trimStart())}
                                    value={this.state.weight}
                                  />
                                </Box>
                                <FormControlLabel
                                  className={classes.declaredBy}
                                  control={<Checkbox checked={this.state.weightDeclared} className={classes.checkboxWhite} color="primary" icon={<CustomsCheckboxIconUnChecked />} checkedIcon={<CustomsCheckboxIconChecked />} />}
                                  label={"Declared by patient"}
                                  data-test-id="weightDeclared"
                                  onClick={() => this.handelDeclaredBy("weightDeclared", !this.state.weightDeclared)}
                                />
                              </Box>
                              <Box>
                                <Typography variant="h4" className={classes.homecureBoldVitalLabel}>Temperature</Typography>
                                <TextField
                                  variant="filled"
                                  placeholder="xxx"
                                  data-test-id="temperature"
                                  className={classes.inputVitalFields}
                                  InputProps={{ endAdornment: (<InputAdornment position="end"><div style={{ fontFamily: "SF Pro Text Regular", fontSize: "14px", color: "#808080", marginTop: "14px" }}>celcius</div></InputAdornment>) }}
                                  onChange={event => this.handlePrescriptionChanges("temperature", event.target.value.trimStart())}
                                  value={this.state.temperature}
                                />
                                <FormControlLabel
                                  className={classes.declaredBy}
                                  control={<Checkbox checked={this.state.temperatureDeclared} className={classes.checkboxWhite} color="primary" icon={<CustomsCheckboxIconUnChecked />} checkedIcon={<CustomsCheckboxIconChecked />} />}
                                  label={"Declared by patient"}
                                  data-test-id="temperatureDeclared"
                                  onClick={() => this.handelDeclaredBy("temperatureDeclared", !this.state.temperatureDeclared)}
                                />
                              </Box>
                            </Grid>
                            <Grid item xl={6} md={6} sm={12} xs={12} className={classes.leftContainer}>
                              <Box>
                                <Typography variant="h4" className={classes.homecureBoldVitalLabel}>Height</Typography>
                                <TextField
                                  variant="filled"
                                  className={classes.inputVitalFields}
                                  placeholder="xxx"
                                  data-test-id="height"
                                  InputProps={{ endAdornment: (<InputAdornment position="end"><div style={{ fontFamily: "SF Pro Text Regular", fontSize: "14px", color: "#808080", marginTop: "14px" }}>cm</div></InputAdornment>) }}
                                  onChange={event => this.handlePrescriptionChanges("height", event.target.value.trimStart())}
                                  value={this.state.height}
                                />
                                <FormControlLabel
                                  className={classes.declaredBy}
                                  control={<Checkbox checked={this.state.heightDeclared} className={classes.checkboxWhite} color="primary" icon={<CustomsCheckboxIconUnChecked />} checkedIcon={<CustomsCheckboxIconChecked />} />}
                                  label={"Declared by patient"}
                                  data-test-id="heightDeclared"
                                  onClick={() => this.handelDeclaredBy("heightDeclared", !this.state.heightDeclared)}
                                />
                              </Box>
                              <Box>
                                <Typography variant="h4" className={classes.homecureBoldVitalLabel}>Pulse</Typography>
                                <TextField
                                  placeholder="xxx"
                                  variant="filled"
                                  className={classes.inputVitalFields}
                                  data-test-id="pulse"
                                  InputProps={{ endAdornment: (<InputAdornment position="end"><div style={{ fontFamily: "SF Pro Text Regular", fontSize: "14px", color: "#808080", marginTop: "14px" }}>/ minute</div></InputAdornment>) }}
                                  onChange={event => this.handlePrescriptionChanges("pulse", event.target.value.trimStart())}
                                  value={this.state.pulse}
                                />
                                <FormControlLabel
                                  className={classes.declaredBy}
                                  control={<Checkbox checked={this.state.pulseDeclared} className={classes.checkboxWhite} color="primary" icon={<CustomsCheckboxIconUnChecked />} checkedIcon={<CustomsCheckboxIconChecked />} />}
                                  label={"Declared by patient"}
                                  data-test-id="pulseDeclared"
                                  onClick={() => this.handelDeclaredBy("pulseDeclared", !this.state.pulseDeclared)}
                                />
                              </Box>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xl={4} md={4} sm={12} xs={12}>
                          <Box>
                            <Typography variant="h4" className={classes.homecureLabel}>Chief Complaints</Typography>
                            <Grid container className={classes.chiefContainer}>
                              <Grid item xl={12} md={12} sm={12} xs={12}>
                                <ClickAwayListener data-test-id="diseasesDropdown" onClickAway={this.closeDiseasesDropdown}>
                                  <Box>
                                    <Box className={classes.hpSelectCheckbox}>
                                      <>
                                        {!this.state.hideDiseasesShowDropDown ? (
                                          <>
                                            <TextField
                                              variant="outlined"
                                              className={classes.inputChiefFields}
                                              placeholder={this.state.selectedComplaints.length > 0 ? "" : "Mention here"}
                                              data-test-id="openDiseasesDropdown"
                                              onClick={this.hideShowDiseasesDropDown}
                                              InputProps={{
                                                startAdornment: (
                                                  <Box
                                                    style={{
                                                      display: "flex",
                                                      flexWrap: "wrap",
                                                      gap: "4px",
                                                      maxHeight: "90px", 
                                                      overflow: "auto", 
                                                      alignItems: "center",
                                                      scrollbarColor:"transparent transparent",
                                                      marginTop:"5px",
                                                      marginBottom:"5px",
                                                      width:"100%"
                                                    }}
                                                  >
                                                    {this.state.diseasesList
                                                      .filter((item: any) => this.state.selectedComplaints.includes(item.id))
                                                      .map((item: any, index: number) => (
                                                        <Chip
                                                          key={index}
                                                          label={item.name}
                                                          data-test-id={`remove${index}`}
                                                          onDelete={() => this.handleChipDelete(item.id)}
                                                          size="small"
                                                          className={classes.chip}
                                                          deleteIcon={<CloseIcon fontSize="small" />}
                                                        />
                                                      ))}
                                                  </Box>
                                                ),
                                                endAdornment: (
                                                  <InputAdornment position="end">
                                                    <img
                                                      src={DownArrow}
                                                      alt="DownArrow"
                                                      className={classes.hpDownArrowIcon}
                                                    />
                                                  </InputAdornment>
                                                ),
                                                readOnly: true,
                                              }}
                                            />
                                          </>
                                        ) : (
                                          <Box className={classes.hpSelectFieldDropdown}>
                                            <Box className={classes.hpDropdownContent}>
                                              <Box className={classes.dropdownText}>
                                                Select
                                                <img
                                                  data-test-id="hideDiseasescDropdown"
                                                  src={UpwardArrow}
                                                  alt="UpwardArrow"
                                                  className={classes.hpUpArrowIcon}
                                                  onClick={this.hideShowDiseasesDropDown}
                                                />
                                              </Box>
                                              <Box className={classes.hpSelectFieldSize}>
                                                {this.state.diseasesList.map((data: any, index: number) => (
                                                  <Box
                                                    className={classes.hpSelectOption}
                                                    key={index}
                                                    style={{
                                                      fontFamily: this.state.selectedComplaints.includes(data.id)
                                                        ? "SF Pro Text Medium"
                                                        : "SF Pro Text Regular",
                                                    }}
                                                  >
                                                    <Checkbox
                                                      data-test-id={`selectedComplaints${index}`}
                                                      className={classes.checkbox}
                                                      checked={this.state.selectedComplaints.includes(data.id)}
                                                      onChange={() => this.handleDiseasesCheckboxChange(data.id)}
                                                      icon={<CustomsCheckboxIconUnChecked />}
                                                      checkedIcon={<CustomsCheckboxIconChecked />}
                                                    />
                                                    {data.name}
                                                  </Box>
                                                ))}
                                              </Box>
                                            </Box>
                                          </Box>
                                        )}
                                      </>
                                    </Box>
                                  </Box>
                                </ClickAwayListener>
                              </Grid>
                            </Grid>
                          </Box>
                          <Box>
                            <Typography variant="h4" className={classes.homecureLabel} style={{ marginTop: "20px" }}>Details</Typography>
                            <Grid container className={classes.detailContainer}>
                              <Grid item xl={12} md={12} sm={12} xs={12}>
                                <Box>
                                  <Typography variant="h4" className={classes.homecureDetailsLabel}>Line 1</Typography>
                                  <TextField variant="filled" placeholder="Mention here" className={classes.inputDetailsFields} data-test-id="line1"
                                    onChange={event => this.handlePrescriptionChanges("detail1", event.target.value.trimStart())}
                                    value={this.state.detail1} />
                                </Box>
                                <Box>
                                  <Typography variant="h4" className={classes.homecureDetailsLabel}>Line 2</Typography>
                                  <TextField data-test-id="line2" variant="filled" placeholder="Mention here" className={classes.inputDetailsFields}
                                    onChange={event => this.handlePrescriptionChanges("detail2", event.target.value.trimStart())}
                                    value={this.state.detail2} />
                                </Box>
                                <Box>
                                  <Typography variant="h4" className={classes.homecureDetailsLabel}>Line 3</Typography>
                                  <TextField data-test-id="line3" variant="filled" placeholder="Mention here" className={classes.inputDetailsFields}
                                    onChange={event => this.handlePrescriptionChanges("detail3", event.target.value.trimStart())}
                                    value={this.state.detail3} />
                                </Box>
                                <Box>
                                  <Typography variant="h4" className={classes.message}>(Can add as many as 10 lines by passing enter)</Typography>
                                </Box>
                              </Grid>
                            </Grid>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  )}
                {this.state.activeStep === 2 &&
                  (
                    <Box>
                      <Grid container >
                        <Grid item xl={4} md={4} sm={12} xs={12}>
                          <Box>
                            <Typography variant="h4" className={classes.diagnosisTitle}>Diagolosis</Typography>
                          </Box>
                          <Grid container className={classes.detailContainer}>
                            <Grid item xl={12} md={12} sm={12} xs={12}>
                              <Box>
                                <Typography variant="h4" className={classes.homecureDetailsLabel}>Line 1</Typography>
                                <TextField
                                  variant="filled"
                                  placeholder="Mention here"
                                  className={classes.inputDetailsFields}
                                  data-test-id="diagnosis1"
                                  onChange={event => this.handlePrescriptionChanges("diagnosis1", event.target.value.trimStart())}
                                  value={this.state.diagnosis1} />
                              </Box>
                              <Box>
                                <Typography variant="h4" className={classes.homecureDetailsLabel}>Line 2</Typography>
                                <TextField
                                  variant="filled"
                                  placeholder="Mention here"
                                  data-test-id="diagnosis2"
                                  className={classes.inputDetailsFields}
                                  onChange={event => this.handlePrescriptionChanges("diagnosis2", event.target.value.trimStart())}
                                  value={this.state.diagnosis2} />
                              </Box>
                              <Box>
                                <Typography variant="h4" className={classes.homecureDetailsLabel}>Line 3</Typography>
                                <TextField
                                  variant="filled"
                                  placeholder="Mention here"
                                  data-test-id="diagnosis3"
                                  className={classes.inputDetailsFields}
                                  onChange={event => this.handlePrescriptionChanges("diagnosis3", event.target.value.trimStart())}
                                  value={this.state.diagnosis3} />
                              </Box>
                              <Box>
                                <Typography variant="h4" className={classes.message}>(Can add as many as 10 lines by passing enter)</Typography>
                              </Box>
                            </Grid>
                          </Grid>
                          <Box>
                            <Typography variant="h4" className={classes.diagnosisTest}>Diagolostic Test</Typography>
                          </Box>
                          <Grid container>
                            <Grid item xl={12} md={12} sm={12} xs={12}>
                              <ClickAwayListener data-test-id="diagolosticDropdown" onClickAway={this.hideShowDropDown}>
                                <Box>
                                  <Box className={classes.hpSelectCheckbox}>
                                    <>
                                      {!this.state.hideShowDropDown ? (
                                        <>
                                          <TextField
                                            variant="outlined"
                                            className={classes.hpRegisterInputField}
                                            placeholder="Select"
                                            data-test-id="openDropdown"
                                            onClick={this.hideShowDropDown}
                                            value={this.handleSelectedNames(this.state.diagnostic)}
                                            InputProps={{
                                              endAdornment: (
                                                <InputAdornment position="end">
                                                  <img
                                                    src={DownArrow}
                                                    alt="DownArrow"
                                                    className={classes.hpDownArrowIcon}
                                                  />
                                                </InputAdornment>
                                              ),
                                              readOnly: true
                                            }}
                                          /></>
                                      ) : (
                                        <Box className={classes.hpSelectFieldDropdown}>
                                          <Box className={classes.hpDropdownContent}>
                                            <Box className={classes.dropdownText}>
                                              Select
                                              <img
                                                data-test-id="hideDiagolosticDropdown"
                                                src={UpwardArrow}
                                                alt="UpwardArrow"
                                                className={classes.hpUpArrowIcon}
                                                onClick={this.hideShowDropDown}
                                              />
                                            </Box>
                                            <Box className={classes.hpSelectFieldSize}>
                                              {this.state.diagnostic.map((data: any, index: number) => (
                                                <Box
                                                  className={classes.hpSelectOption}
                                                  key={index}
                                                  style={{
                                                    fontFamily: this.state.selectedDiagnosis.includes(data.id)
                                                      ? "SF Pro Text Medium"
                                                      : "SF Pro Text Regular",
                                                  }}
                                                >
                                                  <Checkbox
                                                    data-test-id={`selectedDiagnosis${index}`}
                                                    className={classes.checkbox}
                                                    checked={this.state.selectedDiagnosis.includes(data.id)}
                                                    onChange={() => this.handleCheckboxChange(data.id)}
                                                    icon={<CustomsCheckboxIconUnChecked />}
                                                    checkedIcon={<CustomsCheckboxIconChecked />}
                                                  />
                                                  {data.name}
                                                </Box>
                                              ))}
                                            </Box>
                                          </Box>
                                        </Box>
                                      )}
                                    </>
                                  </Box>
                                </Box>
                              </ClickAwayListener>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>
                  )}
                {this.state.activeStep === 3 && (
                  <Grid container spacing={4}>
                    <Grid item xl={5} md={5} sm={12} xs={12}>
                      <Box>
                        <Typography variant="h4" className={classes.medicinesTitle}>Medicines</Typography>
                      </Box>
                      <Box style={{ display: "flex", flexWrap: "wrap" }}>
                        {this.state.medicineOptions.map((option: any) => (
                          <Box key={option.id} className={classes.vitalBox}>
                            <Box>
                              <Checkbox
                                data-test-id={`${option.id}Checkbox`}
                                checked={this.state.medicineType.includes(option.id)}
                                className={classes.checkbox}
                                color="primary"
                                icon={<CustomsCheckboxIconUnChecked />}
                                checkedIcon={<CustomsCheckboxIconChecked />}
                                onChange={() => this.handelSetMedicine(option.id)}
                              />
                            </Box>
                            <Box>
                              <Typography variant="h4" className={classes.presDetails}>
                                {option.label}
                              </Typography>
                            </Box>
                          </Box>
                        ))}
                      </Box>
                      <Grid container className={classes.detailContainer}>
                        <Grid item xl={12} md={12} sm={12} xs={12}>
                          <Box>
                            <Typography variant="h4" className={classes.medicicneNameTitle}>Name of the medecine</Typography>
                            <TextField
                              variant="outlined"
                              placeholder="Type here"
                              className={classes.inputDegreeNameFields}
                              value={this.state.medicineName}
                              data-test-id="medicineName"
                              onChange={event => this.handlePrescriptionChanges("medicineName", event.target.value.trimStart())}
                            />
                            {this.errorMessage(this.state.errors.medicineName, classes.inputErrors)}
                          </Box>
                          <Box style={{ display: "flex", justifyContent: "space-between", marginTop: "20px" }}>
                            <Box>
                              <Typography variant="h4" className={classes.doseDurationTitle}>Dosage</Typography>
                              <Box style={{ display: "flex" }}>
                                <Box className={classes.subBtnContainer}>
                                  <img
                                    src={SubtractBtn}
                                    style={{ width: "8px" }}
                                    data-test-id="decrementDoseCount"
                                    onClick={this.decrementDoseCount}
                                  />
                                </Box>
                                <Typography variant="h4" className={classes.tabletText}>{this.state.dosageCount} tablet</Typography>
                                <Box className={classes.addBtnContainer}>
                                  <img
                                    src={AddBtn}
                                    data-test-id="incrementDoseCount"
                                    onClick={this.incrementDoseCount}
                                    style={{ width: "9px", height: "9px" }}
                                  />
                                </Box>
                              </Box>
                            </Box>
                            <Box>
                              <Box style={{ display: "flex", gap: "15px", height: "30px" }}>
                                <Typography variant="h4" className={classes.doseDurationTitle}>Duration</Typography>
                                <TextField
                                  variant="outlined"
                                  select
                                  defaultValue="Week"
                                  className={classes.inputDurationFields}
                                >
                                  {this.state.durations.map((option: any) => (
                                    <MenuItem key={option.value} value={option.value} className={classes.menuItem}>
                                      {option.label}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              </Box>
                              <Box style={{ display: "flex" }}>
                                <Box className={classes.subBtnContainer}>
                                  <img
                                    src={SubtractBtn}
                                    style={{ width: "8px" }}
                                    data-test-id="decrementDurationCount"
                                    onClick={this.decrementDurationCount}
                                  />
                                </Box>
                                <Typography variant="h4" className={classes.tabletText}>{this.state.durationCount} tablet</Typography>
                                <Box className={classes.addBtnContainer}>
                                  <img
                                    src={AddBtn}
                                    data-test-id="incrementDurationCount"
                                    style={{ width: "9px", height: "9px" }}
                                    onClick={this.incrementDurationCount}
                                  />
                                </Box>
                              </Box>
                            </Box>

                          </Box>
                          <Box>
                            <Typography variant="h4" className={classes.doseTimeDurationTitle}>Repeat</Typography>
                          </Box>
                          <Box style={{ display: "flex" }}>
                            <Box style={{ display: "flex", marginRight: "20px" }}>
                              <Box>
                                <Checkbox checked={this.state.repeatType.includes("Everyday")} data-test-id="RepeatEveryDay" className={classes.checkbox} color="primary" icon={<CustomsCheckboxIconUnChecked />} checkedIcon={<CustomsCheckboxIconChecked />} onClick={() => this.handleSetRepeatCheckboxRepeat('Everyday')} />
                              </Box>
                              <Box>
                                <Typography variant="h4" className={classes.timeDurationText}>Everyday</Typography>
                              </Box>
                            </Box>
                            <Box style={{ display: "flex", marginRight: "20px" }}>
                              <Box>
                                <Checkbox checked={this.state.repeatType.includes("Every_days")} data-test-id="RepeatEveryDays" className={classes.checkbox} color="primary" icon={<CustomsCheckboxIconUnChecked />} checkedIcon={<CustomsCheckboxIconChecked />} onClick={() => this.handleSetRepeatCheckboxRepeat('Every_days')} />
                              </Box>
                              <Box>
                                <Typography variant="h4" className={classes.timeDurationText}>Every_days</Typography>
                              </Box>
                            </Box>
                          </Box>
                          <Box>
                            <Typography variant="h4" className={classes.doseTimeDurationTitle}>Time of the day</Typography>
                          </Box>
                          <Box style={{ display: "flex", flexWrap: "wrap" }}>
                            <Box style={{ display: "flex", marginRight: "20px" }}>
                              <Box>
                                <Checkbox checked={this.state.dayType.includes("Morning")} data-test-id="morningTime" className={classes.checkbox} color="primary" icon={<CustomsCheckboxIconUnChecked />} checkedIcon={<CustomsCheckboxIconChecked />} onClick={() => this.handleSetDayTypeCheckbox("Morning")} />
                              </Box>
                              <Box>
                                <Typography variant="h4" className={classes.timeDurationText}>Morning</Typography>
                              </Box>
                            </Box>
                            <Box style={{ display: "flex", marginRight: "20px" }}>
                              <Box>
                                <Checkbox checked={this.state.dayType.includes("Noon")} data-test-id="noonTime" className={classes.checkbox} color="primary" icon={<CustomsCheckboxIconUnChecked />} checkedIcon={<CustomsCheckboxIconChecked />} onClick={() => this.handleSetDayTypeCheckbox("Noon")} />
                              </Box>
                              <Box>
                                <Typography variant="h4" className={classes.timeDurationText}>Noon</Typography>
                              </Box>
                            </Box>
                            <Box style={{ display: "flex", marginRight: "20px" }}>
                              <Box>
                                <Checkbox checked={this.state.dayType.includes("Evening")} data-test-id="eveningTime" className={classes.checkbox} color="primary" icon={<CustomsCheckboxIconUnChecked />} checkedIcon={<CustomsCheckboxIconChecked />} onClick={() => this.handleSetDayTypeCheckbox("Evening")} />
                              </Box>
                              <Box>
                                <Typography variant="h4" className={classes.timeDurationText}>Evening</Typography>
                              </Box>
                            </Box>
                            <Box style={{ display: "flex", marginRight: "20px" }}>
                              <Box>
                                <Checkbox checked={this.state.dayType.includes("Night")} data-test-id="nightTime" className={classes.checkbox} color="primary" icon={<CustomsCheckboxIconUnChecked />} checkedIcon={<CustomsCheckboxIconChecked />} onClick={() => this.handleSetDayTypeCheckbox("Night")} />
                              </Box>
                              <Box>
                                <Typography variant="h4" className={classes.timeDurationText}>Night</Typography>
                              </Box>
                            </Box>
                          </Box>
                          <Box>
                            <Typography variant="h4" className={classes.doseTimeDurationTitle}>To be taken</Typography>
                          </Box>
                          <Box style={{ display: "flex", flexWrap: "wrap" }}>
                            <Box style={{ display: "flex", marginRight: "15px" }}>
                              <Box>
                                <Checkbox checked={this.state.medicineTakenTime.includes("Before Food")} data-test-id="beforeFood" className={classes.checkbox} color="primary" icon={<CustomsCheckboxIconUnChecked />} checkedIcon={<CustomsCheckboxIconChecked />} onClick={() => this.handlemedicineTakenTimeCheckbox("Before Food")} />
                              </Box>
                              <Box>
                                <Typography variant="h4" className={classes.timeDurationText}>Before Food</Typography>
                              </Box>
                            </Box>
                            <Box style={{ display: "flex", marginRight: "15px" }}>
                              <Box>
                                <Checkbox checked={this.state.medicineTakenTime.includes("After Food")} data-test-id="afterFood" className={classes.checkbox} color="primary" icon={<CustomsCheckboxIconUnChecked />} checkedIcon={<CustomsCheckboxIconChecked />} onClick={() => this.handlemedicineTakenTimeCheckbox("After Food")} />
                              </Box>
                              <Box>
                                <Typography variant="h4" className={classes.timeDurationText}>After Food</Typography>
                              </Box>
                            </Box>
                            <Box style={{ display: "flex", marginRight: "15px" }}>
                              <Box>
                                <Checkbox checked={this.state.medicineTakenTime.includes("Before Sleep")} data-test-id="beforeSleep" className={classes.checkbox} color="primary" icon={<CustomsCheckboxIconUnChecked />} checkedIcon={<CustomsCheckboxIconChecked />} onClick={() => this.handlemedicineTakenTimeCheckbox("Before Sleep")} />
                              </Box>
                              <Box>
                                <Typography variant="h4" className={classes.timeDurationText}>Before Sleep</Typography>
                              </Box>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                      <Box className={classes.addBtn}>
                        + Add
                      </Box>
                    </Grid>
                    <Grid item xl={5} md={5} sm={12} xs={12}>
                      <Box>
                        <Typography variant="h4" style={{ margin: "50px auto 12px", fontSize: "18px", fontFamily: "SF Pro Text Medium", color: "#292929" }}>General Advices</Typography>
                        <Grid container className={classes.detailContainer}>
                          <Grid item xl={12} md={12} sm={12} xs={12}>
                            <Box>
                              <Typography variant="h4" data-test-id="adviceLine1" className={classes.homecureDetailsLabel}>Line 1</Typography>
                              <TextField variant="filled" placeholder="Mention here" data-test-id="adviceText1" className={classes.inputDetailsFields} onChange={event => this.handlePrescriptionChanges("generalAdvice1", event.target.value.trimStart())} value={this.state.generalAdvice1} />
                            </Box>
                            <Box>
                              <Typography variant="h4" data-test-id="adviceLine2" className={classes.homecureDetailsLabel}>Line 2</Typography>
                              <TextField variant="filled" data-test-id="adviceText2" placeholder="Mention here" className={classes.inputDetailsFields} onChange={event => this.handlePrescriptionChanges("generalAdvice2", event.target.value.trimStart())} value={this.state.generalAdvice2} />
                            </Box>
                            <Box>
                              <Typography variant="h4" data-test-id="adviceLine3" className={classes.homecureDetailsLabel}>Line 3</Typography>
                              <TextField variant="filled" data-test-id="adviceText3" placeholder="Mention here" className={classes.inputDetailsFields} onChange={event => this.handlePrescriptionChanges("generalAdvice3", event.target.value.trimStart())} value={this.state.generalAdvice3} />
                            </Box>
                          </Grid>
                        </Grid>
                        <Box>
                          <Typography variant="h4" className={classes.addMoreContainer}>(Can add as many as 50 lines by passing enter)</Typography>
                        </Box>
                        <Grid container className={classes.signatureContainer}>
                          <Grid item xl={12} md={12} sm={12} xs={12}>
                            <img src={Signature} style={{ display: "flex", margin: 'auto' }} />
                          </Grid>
                        </Grid>
                      </Box>
                      <Box>
                        <Typography variant="h4" className={classes.signatureText}>Signature</Typography>
                      </Box>
                      <Box>
                        <img src={HomecureLogo} style={{ display: "flex", margin: '10px auto' }} />
                      </Box>
                    </Grid>
                  </Grid>
                )}
                <Box style={{ marginTop: "10%" }}>
                  {this.state.activeStep === 3 ? (
                    <Button className={classes.submitBtn}>Submit</Button>
                  ) : (
                    <Button className={classes.nextBtn} data-test-id="moveToNextPage" onClick={this.showPrescriptionForm}>Next</Button>
                  )}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </ThemeProvider>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const styles = {
  leftHPWrapper: {
    overflowX: "hidden" as "hidden",
    height: "100%",
  },
  righHPtWrapper: {
    height: "100%",
    padding: "50px",
    overflowY: "scroll" as "scroll",
    scrollbarColor: "#fff #fff",
    scrollbarWidth: "thin" as "thin",
    "& ::-webkit-scrollbar-button": {
      display: "none"
    },
  },
  profileHPHeading: {
    width: "100%",
    caretColor: "transparent",
    fontSize: "28px",
    fontFamily: "SF Pro Text Bold",
    color: "#292929",
  },
  headerHPContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  orderHeading: {
    display: "flex",
    alignItems: "center",
    marginBottom: "40px",
    justifyContent: "flex-start",
    gap: "25px",
  },
  calendarHPBackBtn: {
    display: "flex",
    alignItems: "center",
    fontSize: "22px",
    padding: "13px 9px 13px 17px",
    justifyContent: "center",
    borderRadius: "8px",
    border: "1px solid #E9E9E9",
  },
  stepper: {
    "& .MuiStep-horizontal": {
      paddingTop: "0px",
      paddingBottom: "0px"
    },
    "& .MuiStepper-root": {
      padding: "0px !important"
    }
  },
  inputNameFields: {
    width: "100%",
    fontSize: "14px",
    borderRadius: "8px",
    "& .MuiInputBase-root": {
      color: "#292929",
      fontSize: "14px",
      fontFamily: "SF Pro Text Regular",
      background: "white !important",
      border: "none"
    },
    "& input": {
      fontSize: "14px",
      color: "#292929",
      fontFamily: "SF Pro Text Regular",
      border: "none",
      "&::placeholder": {
        opacity: 1,
        color: "#9D9D9D !important",
        fontSize: "14px",
        fontFamily: "SF Pro Text Regular",
      }
    },
    "& .MuiOutlinedInput-root": {
      borderTopLeftRadius: "8px",
      borderBottomLeftRadius: "8px",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#fff !important"
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderWidth: "1px",
      borderColor: "#fff !important"
    },

    fontFamily: "SF Pro Text Regular"
  },
  inputCityFields: {
    width: "100%",
    fontSize: "14px",
    borderRadius: "8px",
    "& .MuiInputBase-root": {
      color: "#292929",
      fontSize: "14px",
      fontFamily: "SF Pro Text Regular",
      background: "white !important",
      border: "none"
    },
    "& input": {
      fontSize: "14px",
      color: "#292929",
      fontFamily: "SF Pro Text Regular",
      border: "none",
      "&::placeholder": {
        opacity: 1,
        color: "#9D9D9D !important",
        fontSize: "14px",
        fontFamily: "SF Pro Text Regular",
      }
    },
    "& .MuiOutlinedInput-root": {
      borderTopRightRadius: "8px",
      borderBottomRightRadius: "8px",
      borderLeft: "1px solid #DBDBDB",
      borderBottomLeftRadius: "0px",
      borderTopLeftRadius: "0px"
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#fff !important"
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderWidth: "1px",
      borderColor: "#fff !important"
    },

    fontFamily: "SF Pro Text Regular"
  },
  inputContainer: {
    display: "flex",
    background: "#EBF3FC",
    alignItems: "center" as "center",
    alignContent: "center" as "center",
    padding: "15px",
    marginBottom: "30px"
  },
  homeCureHeading: {
    fontSize: "28px",
    fontFamily: "SF Pro Text Bold",
    color: "#014866",
    marginRight: "10px",
    '@media (max-width: 600px)': {
      display: "none"
    },
    caretColor: "transparent"
  },
  seperationLine: {
    borderBottom: "6px solid #02A405",
    width: "176px",
    transform: "rotate(90deg)",
    borderBottomRightRadius: "3px",
    borderBottomLeftRadius: "3px",
    borderTopLeftRadius: "3px",
    borderTopRightRadius: "3px",
    marginRight: "5px",
    '@media (max-width: 600px)': {
      display: "none"
    }
  },
  stepConnector: {
    top: "12px",
    left: "calc(-94% + 20px)",
    right: "calc(88% + 20px)",
    '@media (max-width: 920px)': {
      left: "calc(-85% + 20px) !important"
    },
    '@media (max-width: 530px)': {
      left: "calc(-80% + 20px) !important",
      right: "calc(75% + 20px) !important",
    }
  },
  stepLabel: {
    display: "flex",
    alignItems: "flex-start",
  },
  stepperClasses: {
    padding: "0 !important",
    "& .MuiStepConnector-alternativeLabel": {
      top: "12px",
      left: "calc(-92% + 20px)",
      right: "calc(92% + 20px)",
      position: "absolute",
    },
    "& .MuiStepLabel-root": {
      display: "flex",
      alignItems: "flex-start" as "flex-start"
    },
    "& .MuiStepLabel-label.MuiStepLabel-alternativeLabel": {
      textAlign: "left" as "left",
      fontFamily: "SF Pro Text Medium",
      fontSize: "14px",
      caretColor: "transparent"
    }
  },
  homecureLabel: {
    fontFamily: "SF Pro Text Medium",
    fontSize: "16px",
    color: "#292929",
    caretColor: "transparent",
    marginBottom: "20px"
  },
  homecureVitalLabel: {
    fontFamily: "SF Pro Text Medium",
    fontSize: "16px",
    marginTop: "15px",
    color: "#292929",
  },
  homecureBoldVitalLabel: {
    fontFamily: "SF Pro Text Semibold",
    fontSize: "16px",
    marginTop: "15px",
    color: "#292929",
  },
  menuItem: {
    "& .MuiMenuItem-root": {
      fontFamily: "SF Pro Text Regular",
      caretColor: "transparent !imporatnt",
    },
    "& .MuiListItem-button:hover": {
      caretColor: "transparent !imporatnt",
    }
  },
  inputTitleFields: {
    width: "50%",
    fontSize: "14px",
    borderRadius: "8px",
    "& .MuiInputBase-root": {
      color: "#292929",
      fontSize: "14px",
      fontFamily: "SF Pro Text Regular",
      background: "white !important",
      border: "none",
      caretColor: "transparent"
    },
    "& input": {
      fontSize: "14px",
      color: "#292929",
      fontFamily: "SF Pro Text Regular",
      border: "none",
      "&::placeholder": {
        opacity: 1,
        color: "#9D9D9D !important",
        fontSize: "14px",
        fontFamily: "SF Pro Text Regular",
      }
    },
    "& .MuiOutlinedInput-root": {
      borderTopLeftRadius: "8px",
      borderBottomLeftRadius: "8px",
      borderTopRightRadius: "0px",
      borderBottomRightRadius: "0px",
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderWidth: "1px",
      borderColor: "#3f51b5 !important",
    },

    fontFamily: "SF Pro Text Regular"
  },
  inputDurationFields: {
    width: "100%",
    fontSize: "14px",
    borderRadius: "8px",
    "& .MuiInputBase-root": {
      color: "#014866",
      fontSize: "14px",
      fontFamily: "SF Pro Text Regular",
      background: "transparent !important",
      border: "none"
    },
    "& input": {
      fontSize: "14px",
      color: "#014866",
      fontFamily: "SF Pro Text Regular",
      border: "none",
      "&::placeholder": {
        opacity: 1,
        color: "#9D9D9D !important",
        fontSize: "14px",
        fontFamily: "SF Pro Text Regular",
      }
    },
    "& .MuiOutlinedInput-notchedOutline": { border: "none" },
    "& .MuiSelect-select:focus": {
      backgroundColor: "transparent !important"
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
      height: "42px",
      borderColor: "transparent !important",
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderWidth: "1px",
      borderColor: "transparent !important",
    },

    fontFamily: "SF Pro Text Regular"
  },
  inputTitleNameFields: {
    width: "100%",
    fontSize: "14px",
    borderRadius: "8px",
    "& .MuiInputBase-root": {
      color: "#292929",
      fontSize: "14px",
      fontFamily: "SF Pro Text Regular",
      background: "white !important",
      border: "none"
    },
    "& input": {
      fontSize: "14px",
      color: "#292929",
      fontFamily: "SF Pro Text Regular",
      border: "none",
      "&::placeholder": {
        opacity: 1,
        color: "#9D9D9D !important",
        fontSize: "14px",
        fontFamily: "SF Pro Text Regular",
      }
    },
    "& .MuiOutlinedInput-root": {
      borderTopRighttRadius: "8px",
      borderBottomRightRadius: "8px",
      borderTopLeftRadius: "0px",
      borderBottomLeftRadius: "0px",
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderWidth: "1px",
      borderColor: "#3f51b5 !important",
    },

    fontFamily: "SF Pro Text Regular"
  },
  inputDegreeNameFields: {
    width: "100%",
    borderRadius: "8px",
    fontSize: "14px",
    "& .MuiInputBase-root": {
      color: "#292929",
      fontFamily: "SF Pro Text Regular",
      fontSize: "14px",
      border: "none",
      background: "white !important",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderWidth: "1px",
      borderColor: "#3f51b5 !important",
    },
    "& input": {
      fontSize: "14px",
      fontFamily: "SF Pro Text Regular",
      color: "#292929",
      border: "none",
      "&::placeholder": {
        opacity: 1,
        fontSize: "14px",
        color: "#9D9D9D !important",
        fontFamily: "SF Pro Text Regular",
      }
    },
    fontFamily: "SF Pro Text Regular"
  },
  inputTimeFields: {
    width: "100%",
    borderRadius: "8px",
    fontSize: "14px",
    "& .MuiInputBase-root": {
      color: "#292929",
      fontSize: "14px",
      border: "none",
      fontFamily: "SF Pro Text Regular",
      background: "white !important",
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderWidth: "1px",
      borderColor: "#3f51b5 !important",
    },
    "& input": {
      fontSize: "14px",
      color: "#292929",
      border: "none",
      fontFamily: "SF Pro Text Regular",
      "&::placeholder": {
        opacity: 1,
        fontSize: "14px",
        fontFamily: "SF Pro Text Regular",
        color: "#9D9D9D !important",
      }
    },
    fontFamily: "SF Pro Text Regular"
  },
  containerSpace: {
    marginBottom: "30px"
  },
  checkboxWhite: {
    color: "#fff !important",
    marginLeft: "2px !important",
  },
  checkbox: {
    color: "#fff !important",
    padding: "9px 15px 9px 0px"
  },
  consultType: {
    width: "100%",
    "& .MuiTypography-body1":
    {
      fontFamily: "SF Pro Text Regular",
      fontSize: "14px",
      color: "#292929",
      caretColor: "transparent"
    }
  },
  declaredBy: {
    width: "100%",
    marginTop: "5px",
    "& .MuiTypography-body1":
    {
      fontFamily: "SF Pro Text Regular",
      fontSize: "12px",
      color: "#292929"
    }
  },
  nextBtn: {
    width: "120px",
    height: "52px",
    textTransform: 'capitalize' as 'capitalize',
    fontSize: '18px',
    borderRadius: '8px',
    fontFamily: 'SF Pro Text Bold',
    backgroundColor: '#014866',
    color: '#fff',
    "&:hover": {
      backgroundColor: "#014866",
      color: "#fff"
    },
  },
  submitBtn: {
    width: "200px",
    height: "56px",
    textTransform: 'capitalize' as 'capitalize',
    fontSize: '18px',
    borderRadius: '8px',
    fontFamily: 'SF Pro Text Bold',
    backgroundColor: '#014866',
    color: '#fff',
    "&:hover": {
      backgroundColor: "#014866",
      color: "#fff"
    },
  },
  vitalContainer: {
    width: "100%",
    background: "#f1f0f0",
    minHeight: "357px",
    borderRadius: "8px",
    padding: "20px"
  },
  chiefContainer: {
    width: "100%",
    background: "#f1f0f0",
    minHeight: "52px",
    borderRadius: "8px",
    padding: "5px 5px"
  },
  detailContainer: {
    width: "100%",
    background: "#f1f0f0",
    minHeight: "246px",
    borderRadius: "8px",
    padding: "20px"
  },
  signatureContainer: {
    width: "100%",
    background: "#f1f0f0",
    minHeight: "121px",
    borderRadius: "8px",
    padding: "20px"
  },
  rightContainer: {
    borderRight: "1px solid #d7d5d5",
    paddingRight: "5%",
    '@media (max-width: 600px)': {
      border: "none",
      paddingRight: "0%",
    }
  },
  leftContainer: {
    paddingLeft: "5%",
    '@media (max-width: 600px)': {
      paddingLeft: "0px"
    }
  },
  inputVitalFields: {
    width: "100%",
    fontSize: "14px",
    borderRadius: "8px",
    "& .MuiInputBase-root": {
      color: "#292929",
      fontSize: "14px",
      fontFamily: "SF Pro Text Regular",
      background: "transparent !important",
      border: "none"
    },
    "& .MuiFilledInput-input": {
      padding: "27px 0px 10px 0px !important"
    },
    "& input": {
      fontSize: "14px",
      color: "#292929",
      fontFamily: "SF Pro Text Regular",
      border: "none",
      "&::placeholder": {
        opacity: 1,
        color: "#9D9D9D !important",
        fontSize: "14px",
        fontFamily: "SF Pro Text Regular",
      }
    },
    fontFamily: "SF Pro Text Regular"
  },
  inputChiefFields: {
    width: "100%",
    fontSize: "14px",
    borderRadius: "8px",
    "& .MuiInputBase-root": {
      color: "#292929",
      fontSize: "14px",
      fontFamily: "SF Pro Text Regular",
      background: "transparent !important",
      border: "none"
    },
    "& input": {
      fontSize: "14px",
      color: "#292929",
      fontFamily: "SF Pro Text Regular",
      border: "none",
      "&::placeholder": {
        opacity: 1,
        color: "#9D9D9D !important",
        fontSize: "14px",
        fontFamily: "SF Pro Text Regular",
      }
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderWidth: "1px",
      borderColor: "transparent !important"
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent !important"
    },
    fontFamily: "SF Pro Text Regular",
    "& .MuiFilledInput-underline::before": {
      borderBottom: "2px solid transparent"
    },
    "& .MuiFilledInput-underline::after": {
      borderBottom: "2px solid transparent"
    }
  },
  homecureDetailsLabel: {
    fontFamily: "SF Pro Text Regular",
    fontSize: "14px",
    marginTop: "15px",
    color: "#808080",
  },
  inputDetailsFields: {
    width: "100%",
    fontSize: "12px",
    borderRadius: "8px",
    "& .MuiInputBase-root": {
      color: "#292929",
      fontSize: "12px",
      fontFamily: "SF Pro Text Regular",
      background: "transparent !important",
      border: "none"
    },
    "& .MuiFilledInput-input": {
      padding: "27px 0px 10px 0px !important"
    },
    "& input": {
      fontSize: "12px",
      color: "#292929",
      fontFamily: "SF Pro Text Regular",
      border: "none",
      "&::placeholder": {
        opacity: 1,
        color: "#292929 !important",
        fontSize: "12px",
        fontFamily: "SF Pro Text Regular",
      }
    },
    fontFamily: "SF Pro Text Regular"
  },
  message: {
    fontFamily: "SF Pro Text Regular",
    fontSize: "11px",
    color: "#808080",
    margin: "15px auto 10px"
  },
  diagnosisMessage: {
    fontFamily: "SF Pro Text Regular",
    fontSize: "14px",
    color: "#292929",
    margin: "15px auto 10px"
  },
  hpRegisterInputField: {
    width: "100%",
    fontSize: "14px",
    caretColor: "transparent",
    backgroundColor: "#f1f0f0",
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderWidth: "1px",
      borderColor: "#3f51b5 !important"
    },
    fontFamily: "SF Pro Text Regular",
    "& .MuiInputBase-input": {
      cursor: "pointer"
    },
    marginBottom: "10px",
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
      cursor: "pointer"
    },
    "& input": {
      fontSize: "14px",
      color: "#292929",
      caretColor: "transparent",
      fontFamily: "SF Pro Text Regular",
      "&::placeholder": {
        opacity: 1,
        color: "#808080 !important",
        fontSize: "14px",
        fontFamily: "SF Pro Text Regular",
        caretColor: "transparent"
      }
    }
  },
  hpDownArrowIcon: {
    cursor: "pointer",
    width: "18px",
    height: "15px",
    marginRight: "6px",
    objectFit: "cover" as "cover"
  },
  hpUpArrowIcon: {
    width: "18px",
    height: "15px",
    cursor: "pointer",
    objectFit: "cover" as "cover"
  },
  hpSelectFieldDropdown: {
    width: "100%",
    borderRadius: "8px",
    backgroundColor: "#f1f0f0",
    marginTop: "10px",
  },
  hpDropdownContent: {
    padding: "10px 20px"
  },
  dropdownText: {
    display: "flex",
    justifyContent: "space-between",
    padding: "10px 3px 15px 0px",
    color: "#808080",
    cursor: "pointer",
    borderBottom: "1px solid #ccc",
    transition: "background-color 0.3s ease",
    fontSize: "14px",
    caretColor: "transparent",
    fontFamily: "SF Pro Text Regular"
  },
  hpSelectFieldSize: {
    scrollbarColor: "#d9d5d5 #fff",
    scrollbarWidth: "thin" as "thin",
    overflowY: "scroll" as "scroll",
    "& ::-webkit-scrollbar-button": {
      display: "none"
    },
    maxHeight: "158px",
    minHeight: "158px"
  },
  hpSelectOption: {
    padding: "5px 10px 5px 0px",
    cursor: "pointer",
    borderBottom: "1px solid #ccc",
    width: "70%",
    transition: "background-color 0.3s ease",
    fontFamily: "Sf Pro Text Medium",
    fontSize: "16px",
    display: "flex",
    alignItems: "center",
    color: "#292929"
  },
  subBtnContainer: {
    alignItems: "center" as "center",
    display: "flex",
    border: "1px solid #014866",
    padding: "3px",
    height: "8px",
    marginTop: "12px",
    marginRight: "10px"
  },
  addBtnContainer: {
    height: "8px",
    marginLeft: "10px",
    border: "1px solid #014866",
    padding: "3px",
    marginTop: "12px"
  },
  stepperContainer: {
    width: '100%', margin: "20px auto 45px"
  },
  diagnosisTitle: {
    fontFamily: "SF Pro Text Medium",
    fontSize: "18px",
    color: "#292929",
    marginBottom: "20px"
  },
  diagnosisTest: {
    fontFamily: "SF Pro Text Medium",
    fontSize: "18px",
    color: "#292929",
    marginBottom: "20px",
    marginTop: "20px"
  },
  medicinesTitle: {
    fontFamily: "SF Pro Text Medium",
    fontSize: "18px",
    color: "#292929",
    marginBottom: "20px"
  },
  vitalBox: {
    display: "flex",
    marginRight: "20px"
  },
  presDetails: {
    fontFamily: "SF Pro Text Regular",
    fontSize: "14px",
    color: "#292929",
    marginTop: "10px"
  },
  medicicneNameTitle: {
    fontFamily: "SF Pro Text Regular",
    fontSize: "16px",
    color: "#808080",
    margin: "10px 0px 15px"
  },
  doseDurationTitle: {
    fontFamily: "SF Pro Text Regular",
    fontSize: "16px",
    color: "#808080",
    marginTop: "10px"
  },
  tabletText: {
    fontFamily: "SF Pro Text Regular",
    fontSize: "16px",
    color: "#292929",
    margin: "10px 0px 10px"
  },
  doseTimeDurationTitle: {
    fontFamily: "SF Pro Text Regular",
    fontSize: "16px",
    color: "#808080",
    marginTop: "14px",
    marginBottom: "5px"
  },
  timeDurationText: {
    fontFamily: "SF Pro Text Regular",
    fontSize: "14px",
    color: "#292929",
    marginTop: "10px"
  },
  addBtn: {
    fontFamily: "SF Pro Text Regular",
    fontSize: "14px",
    color: "#014866",
    textAlign: "right" as "right",
    marginTop: "15px"
  },
  addMoreContainer: {
    margin: "20px 0px",
    fontSize: "14px",
    fontFamily: "SF Pro Text Regular",
    color: "#292929"
  },
  signatureText: {
    margin: "25px 0px",
    fontSize: "18px",
    fontFamily: "SF Pro Text Medium",
    color: "#292929",
    display: "flex",
    justifyContent: "center"
  },
  inputErrors: {
    fontSize: "12px",
    fontFamily: "SF Pro Text Regular",
    color: "#FF0000",
    caretColor: "transparent",
    marginLeft: "5px"
  },
  chip: {
    "& .MuiChip-deleteIcon": {
      color: "#000000 !important"
    },
    "&.MuiChip-label": {
      paddingLeft: "8px !important",
      paddingRight: "8px !important",
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: "ellipsis"
    },
    "& .MuiChip-sizeSmall": {
      height: "35px"
    },
    borderRadius: "6px",
    margin: "1px 4px",
    alignItems: "center",
    padding: "7px 8px",
    fontSize: "14px",
    backgroundColor: "#e2ecf0",
    color: "#014866",
    fontFamily:"SF Pro Text Regular",
  },
}

export default withStyles(styles)(HealthcarePersonnelPrescription);
// Customizable Area End