import * as React from "react";
// Customizable Area Start
import { withStyles, Box, Typography, ThemeProvider, createTheme, Divider, IconButton } from "@material-ui/core";
import CustomisableUserProfilesController, {
    configJSON,
    Props
} from "./CustomisableUserProfilesController";
import { styles } from "./styles/CustomisableUserProfiles";
import { Notification,Setting, Edit } from "./assets"
import CustomStyledButton from "../../../components/src/CustomStyledButton.web";
import Loader from "../../../components/src/Loader.web";
import SidebarNotificationWrapper from "../../notifications/src/Notifications.web";

const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff"
        }
    }
});
// Customizable Area End

export class CustomisableHPViewUserProfile extends CustomisableUserProfilesController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        await this.fetchDepartmentsList();
        await this.fetchDoctorDetails();
    }

    showDepartment = (userType:string,connectHospital:boolean) => {
        return userType === 'doctor' && connectHospital
    }

    showLabel = (userType:string) => {
        if(userType==='ayah'){
            return "Aadhar Card No."
        }else{
            return "Registration No."
        }
    }

    renderPopover = () => {
        const settingsItems = [
            { id: 'goto-about', page: configJSON.settingsPageName.aboutUs ,label: configJSON.settingsItems.AboutUs},
            { id: 'termsandcondition', label: configJSON.settingsItems.TermsConditions, page: configJSON.settingsPageName.termsCondition },
            { id: 'privacy-policy', label: configJSON.settingsItems.PrivacyPolicy, page: configJSON.settingsPageName.privacyPolicy },
            { id: 'help', page: configJSON.settingsPageName.help, label: configJSON.settingsItems.Help },
        ];
        const boundingRect = this.state.settingPopoverEl?.getBoundingClientRect();
        return this.state.settingPopoverEl && <>
            <div style={webStyle.overlay} data-test-id='overlay' onClick={this.closeSettingsPopover}></div>
            <Box data-test-id='popover' style={{ ...webStyle.filterModalWrapper, top: `${boundingRect && boundingRect.bottom + window.scrollY - 180}px`,right: `${window.innerWidth - Number(boundingRect && boundingRect.right) -70 }px`}}>
                <Box style={webStyle.settingsOuterWrapper} >
                    <Box style={webStyle.settingsInnerWrapper}>
                        <Typography style={webStyle.settingsTitle}>{configJSON.settingsItems.Settings} </Typography>
                        {settingsItems.map((item, index) => (
                            <React.Fragment key={item.id}>
                                <Typography
                                    data-test-id={item.id}
                                    style={webStyle.settingsItem}
                                    onClick={this.navigateFromSettings(item.page)}
                                >
                                    {item.label}
                                </Typography>
                                {index < settingsItems.length - 1 && <Divider style={webStyle.divider} />}
                            </React.Fragment>
                        ))}
                        <Box style={webStyle.actionButtonWrapper} >
                            <CustomStyledButton
                                isSecondary
                                onClick={this.navigateFromSettings('deactivate')}
                                data-test-id="deactivate"
                                label={configJSON.ButtonLabel.DeactivateAccount}
                            />
                            <CustomStyledButton
                                onClick={this.handleLogout}
                                data-test-id="logout"
                                label={configJSON.ButtonLabel.Logout}
                            />
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        const { district, postalCode, address, city } = this.state;
        const fullHPAddress = [address, city, district, postalCode];
        const hpAddress = fullHPAddress.filter(com => com).join(', ');
        const userType = this.state.user_type
        const department = userType === "doctor" ? this.state.departmentsList.filter((item:any) => item.id == this.state.departmentId): "";
        // Customizable Area End
        return (
            // Customizable Area Start
            <>
                <ThemeProvider theme={theme}>
                        <Box style={{overflowX:'auto'}} className={classes.leftWrapper}>
                            <SidebarNotificationWrapper hideNotification {...this.props} >
                                <Box style={webStyle.rightWrapper}>
                                    <Box style={{padding:'56px 60px 150px 60px',boxSizing:'border-box'}} className={classes.root}>
                                        <Box>
                                            <Typography variant="h6" className={classes.profileHeading}>
                                                Profile
                                            </Typography>
                                        </Box>
                                        <Box>
                                            <Box sx={{display:'flex',alignItems:'center',justifyContent:'space-between'}}>
                                                <Box>
                                                    <Box className={classes.topHPDetailsLeftContainer}>
                                                        <Box style={{width:'auto'}} className={classes.profileContainer}>
                                                            <img
                                                                className={classes.avatar}
                                                                src={this.state.profilePicture}
                                                            />
                                                        </Box>
                                                        <Box style={{paddingLeft:0}} className={classes.profileDetailsContainer}>
                                                            <Typography variant="h6" className={classes.userName}>
                                                                {this.state.user_name}
                                                            </Typography>
                                                            <Typography variant="h6" className={classes.userPhoneNumber}>
                                                                {this.state.city} | {this.state.phoneNumber}
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                    <Box style={{width:'100%'}} className={classes.boxHpBottomBorder} />
                                                </Box>
                                                <Box>
                                                    <Box style={{marginTop:'0'}} className={classes.topHPRightContainer}>
                                                        <Box data-test-id="editHPProfile" onClick={this.hpEditProfile}>
                                                        <IconButton>
                                                            <img
                                                                alt="Edit"
                                                                className={classes.editImage}
                                                                src={Edit}
                                                            />
                                                            </IconButton>
                                                        </Box>
                                                        <Box>
                                                            <IconButton onClick={() => this.goToBlock('Dashboard')}>
                                                            <img
                                                                alt="Notification"
                                                                className={classes.notificationImage}
                                                                src={Notification}
                                                            />
                                                            </IconButton>
                                                        </Box>
                                                        <Box>
                                                            <IconButton data-test-id="open-settings" onClick={this.handleOpenSettings} style={this.state.settingPopoverEl ? webStyle.activeIcon : webStyle.settingIcon}>
                                                                <img
                                                                    alt="Setting"
                                                                    className={classes.settingImage}
                                                                    src={Setting}
                                                                />
                                                            </IconButton>
                                                            {this.renderPopover()}
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Box>
                                            <Box>
                                                <Box>
                                                    <Box className={classes.subContainer}>
                                                        <Typography className={classes.userDetailsHPLabel}>
                                                            Healthcare Personnel
                                                        </Typography>
                                                        <Typography className={classes.userDetailsColon}>
                                                            :
                                                        </Typography>
                                                        <Typography className={classes.userDetails}>
                                                            {this.state.user_type.charAt(0).toUpperCase() + this.state.user_type.slice(1)} 
                                                        </Typography>
                                                    </Box>
                                                    {this.showDepartment(this.state.user_type,this.state.connect_with_hospital) &&
                                                    (<Box className={classes.subContainer}>
                                                        <Typography className={classes.userDetailsHPLabel}>
                                                            Department
                                                        </Typography>
                                                        <Typography className={classes.userDetailsColon}>
                                                            :
                                                        </Typography>
                                                        <Typography className={classes.userDetails}>
                                                            {department[0]?.attributes?.name}
                                                        </Typography>
                                                    </Box>)}
                                                    <Box className={classes.subContainer}>
                                                        <Typography className={classes.userDetailsHPLabel}>
                                                        {this.showLabel(this.state.user_type)}
                                                        </Typography>
                                                        <Typography className={classes.userDetailsColon}>
                                                            :
                                                        </Typography>
                                                        <Typography className={classes.userDetails}>
                                                            {this.state.identification_no}
                                                        </Typography>
                                                    </Box>
                                                    <Box className={classes.subContainer}>
                                                        <Typography className={classes.userDetailsHPLabel}>
                                                            Age
                                                        </Typography>
                                                        <Typography className={classes.userDetailsColon}>
                                                            :
                                                        </Typography>
                                                        <Typography className={classes.userDetails}>
                                                            {this.state.userAge} y/o
                                                        </Typography>
                                                    </Box>
                                                    <Box className={classes.subContainer}>
                                                        <Typography className={classes.userDetailsHPLabel}>
                                                            Gender
                                                        </Typography>
                                                        <Typography className={classes.userDetailsColon}>
                                                            :
                                                        </Typography>
                                                        <Typography className={classes.userDetailsCaps}>
                                                            {this.state.gender}
                                                        </Typography>
                                                    </Box>
                                                    <Box className={classes.subContainer}>
                                                        <Typography className={classes.userDetailsHPLabel}>
                                                            Email Address
                                                        </Typography>
                                                        <Typography className={classes.userDetailsColon}>
                                                            :
                                                        </Typography>
                                                        <Typography className={classes.userDetails}>
                                                            {this.state.email}
                                                        </Typography>
                                                    </Box>
                                                    <Box className={classes.addressSubConatiner}>
                                                        <Typography className={classes.userDetailsHPLabel}>
                                                            Address
                                                        </Typography>
                                                        <Typography className={classes.userDetailsColon}>
                                                            :
                                                        </Typography>
                                                        <Typography className={classes.userDetailsCaps}>
                                                            {hpAddress}
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            </SidebarNotificationWrapper>
                        </Box>
                        
                        <Loader loading={this.state.isLoading}/>
                </ThemeProvider>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export default withStyles(styles)(CustomisableHPViewUserProfile);

const webStyle = {
    rightWrapper: {
        minHeight: '100vh',
        width: '100%',
    },
    notificationWrapper: {
        minWidth: '340px'
    },
    activeIcon: {
        backgroundColor: 'white',
        padding: '8px',
        zIndex: 200,
        borderRadius: '10px',
        width: '40px',
        height: '40px',
        top:'-10%'
    } as React.CSSProperties,
    settingIcon: {        
        borderRadius: '8px',
        height: '40px',
        width: '40px',
        top:'-10%'
    } as React.CSSProperties,
    filterModalWrapper: {
        position: "absolute",
        zIndex: 200,
        backgroundColor: 'white',
        right: 0,
        overflow: 'hidden',
        borderRadius: '10px',
    } as React.CSSProperties,
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        position: 'fixed',
        bottom: 0,
        top: 0,
        width: '100%',
        right: 0,
        zIndex: 100
    } as React.CSSProperties,
    paper: {
        borderRadius: '25px'
    },
    backDrop: {
        backgroundColor: 'rgba(0, 0, 0, 0.8)'
    },
    divider: {
        backgroundColor: "#F0F0F0"
    },
    settingsTitle: {
        color: "#292929",
        fontFamily: 'SF Pro Text Bold',
        fontSize: '24px',
        marginBottom: '14px',
        textAlign: 'center',
    } as React.CSSProperties,
    settingsItem: {
        fontSize: '20px',
        color: '#292929',
        textAlign: 'center',
        fontFamily: "SF Pro Text Regular",
        lineHeight: '64px',
        cursor: 'pointer'
    } as React.CSSProperties,
    settingsOuterWrapper: {
        width: '390px',
        background: 'white'
    },

    settingsInnerWrapper: {
        border: '1px solid #EDEDED',
        padding: '32px 24px',
        borderRadius: '25px',
        boxShadow: "0px 2px 24px 0px #2C2C2C0A"
    },

    actionButtonWrapper: {
        display: 'flex',
        flexDirection: 'column',
        gridRowGap: '24px',
        marginTop: '42px'
    } as React.CSSProperties,

}
// Customizable Area End