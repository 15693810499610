export const SOSImage  = require("../assets/SOS.png");
export const Setting  = require("../assets/setting.png");
export const Notification  = require("../assets/notification.png");
export const Edit  = require("../assets/edit.png");
export const DownArrow = require("../assets/down-arrow.png");
export const UpwardArrow  = require("../assets/upward-arrow.png");
export const close  = require("../assets/close.png");
export const closeSm  = require("../assets/close_sm.png");
export const camera  = require("../assets/camera.png");
export const gallery  = require("../assets/gallery.png");
export const homecureLogo = require("../assets/homecureLogoBlue.png");
export const CalenderIcon = require("../assets/calender.png");
export const ImageIcons = require("../assets/addImage.png");
export const search_Icon = require("../assets/searchIcon.png");
export const pexelsKarolina = require("../assets/pexelsKarolina.jpg");
export const ProfileIcon = require("../assets/user_icon.png");
export const otp_success = require("../assets/otp_success.png");
export const EmailIcon = require("../assets/EmailIcon.png");
export const verifiedEmail = require("../assets/verifiedEmailIcon.png");
export const unChecked = require('../assets/check_box_unchecked.png')
export const checked = require('../assets/checkbox_checked.png')
export const ratingStar = require('../assets/green_star.svg')
export const NoData = require('../assets/closeBtn.png');
export const MiscGray = require('../assets/image_Misc_Gray.png');
export const MiscGreen = require('../assets/image_Misc_Green.png')
export const unCheckedIcon = require('../assets/Rectangle.png')
export const receiveMail = require('../assets/receive-mail.svg');
export const unCheckedPolicyBox = require('../assets/unCheckedPolicyBox.png');
export const successLogo = require('../assets/successLogo.png')
