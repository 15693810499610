import { Theme } from "@material-ui/core";
// Customizable Area Start
import { CalenderIcon, pexelsKarolina } from "../assets";
import {
  floatType,
  objectFitType,
  overflowProperty,
  positionType,
  scrollbarType,
  textAlignType,
  textTransform,
  whiteSpaceType
} from "../types";
import { flexDirection } from "../../../email-account-registration/src/types";
import { CSSProperties } from "@material-ui/core/styles/withStyles";

const customStyles =
 {
  "& .css-g1d714-ValueContainer" : 
  {
    fontSize: "16px",
    color: "#808080",
    borderRadius: "8px",
    fontFamily: "SF Pro Text Regular"
  },
  "& .css-fke1ie-indicatorContainer:focus" :
   {
    color: "#808080",
  },
  "& .css-yk16xz-control" :
   {
    fontSize: "16px",
    borderRadius: "8px",
    borderColor: "hsl(0, 2.2%, 64.3%)",
    fontFamily: "SF Pro Text Regular"
  },
  "& .css-lpahdxg-control" :
   {
    borderRadius: "8px",
    fontSize: "16px",
    ffontFamily: "SF Pro Text Regular"
  },
  "& .css-fke1ie-indicatorContainer" :
   {
    alignItem: "center",
    color: "#808080",
    display: "flex",
    alignContent: "center",
    margin: "auto"
  },
  "& .css-yk16xz-control:focus" :
   {
    borderRadius: "8px",
    fontSize: "16px",
    color: "#808080",
    fontFamily: "SF Pro Text Regular"
  },
  "& .css-1fhf3k1-control" :
   {
    background: "#ffffff !important",
  },
  "& .css-107lb6w-singleValue" : 
  {
    fontFamily: "SF Pro Text Regular",
    color: "#292929 !important",
  },
  "& .css-1uccc91-singleValue" :
   {
    fontFamily: "SF Pro Text Regular !important",
    color: "#292929 !important",
  },
  "& .css-6q0nyr-Svg" :
   {
    fill: "#000000 !important",
    fontSize: "20px !important",
    width: "30px !important",
    height: "25px !important",
    cursor: "pointer",
    caretColor:"transparent",
    fontWeight: 400
  },
  "& .css-1pahdxg-control" :
   {
    boxShadow: "none !important",
  },
  "& .css-1pahdxg-control:focus" :
   {
    boxShadow: "none !important",
    outline: "none !important",
    fontFamily: "SF Pro Text Regular",
  },
  "& .css-1pahdxg-control:active" :
   {
    fontFamily: "SF Pro Text Regular",
    boxShadow: "none !important",
    outline: "none !important",
  },
  "& .css-1laa021a11yText" : 
  {
    boxShadow: "none !important",
    outline: "none !important",
    fontFamily: "SF Pro Text Regular",
  },
  "& .css-1l6et5q-control" :
   {
    backgroundColor: "#fff"
  },
  "& .css-siwetl-control:hover" :
   {
    borderColor: "#3f51b5 !important",
    borderWidth: "1px !important"
  },
  "& .css-1e5gspv-control": {
    backgroundColor: "white !important"
  },
  "& .css-1mg7z2r-control": {
    backgroundColor: "white !important"
  },
  "& .css-jlswka-control:hover": {
    boxShadow: "0 0 0 1px #3f51b5"
  },
  "& .css-lumyd2-control": {
    backgroundColor: "white !important"
  },
  "& .css-1wa3eu0-placeholder": {
    fontFamily: "SF Pro Text Regular",
    color: "#808080",
  },
  "& .css-360mep-control": {
    paddingRight: "5px !important"
  },
  "&  .css-1rsya3y-control": {
    padding: "8px"
  },
  "& .css-1rsya3y-control": {
    minHeight: "45px !impotant",
    padding: "6px !impotant",
  },
  "& .css-13ta6qp-control": { 
    cursor: "pointer", padding: "7px !important" 
  },
  "& .css-cjtr36-indicatorContainer" :
   {
    padding: "8px 10px !important"
  },
  "& .css-1ebpccn-control" :
   {
    minHeight: "55px !important"
  },
  "& .css-1hwfws3": {
    padding: "10px 8px !important"
  },
  "& .css-g1d714-ValueContainer:hover" : {
    minHeight: "auto !important"
  },
  "& .css-95shoe-control" : {
    padding: "8px !important"
  },
  "& .css-14jk2my-container" :{
    caretColor:"transparent"
  }
}
type OverflowXProperty = "hidden";
// Customizable Area End

export const styles: (theme: Theme) => {[key: string]: CSSProperties} = (theme: Theme) => ({
  // Customizable Area Start
  root: {
    padding: "4%"
  },
  containers: {
    textAlign: "center" as textAlignType
  },
  profileHeading: {
    width: "100%",
    fontSize: "28px",
    marginBottom: "45px",
    fontFamily: "SF Pro Text Bold",
    color: "#292929",
    caretColor: "transparent"
  },
  avatar: {
    borderRadius: "50%",
    width: "155px",
    height: "155px",
    border: "1px solid #eeeaea",
    backgroundColor: "#eeeaea"
  },
  sosImage: {
    width: "100px",
    height: "100px",
    cursor: "pointer"
  },
  avatarEdit: {
    borderRadius: "50%",
    width: "155px",
    height: "155px",
    border: "1px solid #eeeaea",
    backgroundColor: "#eeeaea",
    zIndex: 1,
    cursor: "pointer"
  },
  avatarEditIcon: {
    top: 0,
    left: "188px",
    width: "20px",
    height: "20px",
    zIndex: 1,
    position: "absolute" as positionType,
    marginTop: "52px",
    background: "white",
    padding: "10px",
    borderRadius: "18px",
    cursor: "pointer"
  },
  editImage: {
    width: "30px",
    height: "30px",
    cursor: "pointer"
  },
  notificationImage: {
    width: "30px",
    height: "30px",
    cursor: "pointer"
  },
  settingImage: {
    width: "30px",
    height: "30px",
    cursor: "pointer"
  },
  userName: {
    color: "#292929",
    fontSize: "24px",
    fontFamily: "SF Pro text Bold",
    caretColor: "transparent"
  },
  userPhoneNumber: {
    fontSize: "16px",
    fontFamily: "SF Pro Text Regular",
    caretColor: "transparent",
    color: "#808080"
  },
  userDetailsLabel: {
    fontSize: "18px",
    fontFamily: "SF Pro Text Medium",
    color: "#292929",
    width: "345px"
  },
  userDetailsHPLabel: {
    fontSize: "18px",
    fontFamily: "SF Pro Text Medium",
    width: "45%",
    "@media (max-width:1300px)": {
      width: "50% !important"
    },
    caretColor: "transparent"
  },
  userDetailsColon: {
    caretColor: "transparent",
    fontSize: "18px",
    fontFamily: "SF Pro Text Medium",
    color: "#808080",
    width: "8%"
  },
  userDetails: {
    caretColor: "transparent",
    fontSize: "18px",
    fontFamily: "SF Pro Text Regular",
    color: "#717171",
    width: "70%",
    paddingRight: "217px"
  },
  
  userDetailsCaps: {
    fontSize: "18px",
    fontFamily: "SF Pro Text Medium",
    color: "#808080",
    width: "71%",
    paddingRight: "217px",
    caretColor: "transparent",
    textTransform: "capitalize" as textTransform
  },
  topLeftContainer: {
    display: "flex",
    fontFamily: "SF Pro Text Regular",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0px 0px 35px 12px"
  },
  topHPDetailsLeftContainer: {
    display: "flex",
    fontFamily: "SF Pro Text Regular",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0px 0px 35px 0px"
  },
  topPatientLeftContainer: {
    display: "flex",
    fontFamily: "SF Pro Text Regular",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0px 0px 35px 0px"
  },
  topRightContainer: {
    position:'relative',
    display: "flex",
    fontFamily: "SF Pro Text Regular",
    alignItems: "center",
    width: "100%",
    gap: "70px",
    [theme.breakpoints.down("md")]: {
      gap: "50px"
    },
    marginTop: "17px"
  },
  topHPRightContainer: {
    position:'relative',
    display: "flex",
    fontFamily: "SF Pro Text Regular",
    alignItems: "center",
    width: "100%",
    gap: "70px",
    [theme.breakpoints.down("md")]: {
      gap: "50px"
    },
    marginTop: "52px"
  },
  boxBottomBorder: {
    width: "74%",
    borderBottom: "1px solid #C2C2C2"
  },
  boxTopBorder: {
    width: "55%",
    borderTop: "1px solid #C2C2C2"
  },
  subOfflineContainer: {
    display: "flex",
    fontFamily: "SF Pro Text Regular",
    justifyContent: "space-between",
    alignItems: "start",
    padding: "5px 0px 0px",
    width: "100%"
  },
  subContainer: {
    display: "flex",
    fontFamily: "SF Pro Text Regular",
    justifyContent: "space-between",
    alignItems: "start",
    padding: "22px 0px 0px",
    width: "100%"
  },
  addressSubConatiner: {
    display: "flex",
    fontFamily: "SF Pro Text Regular",
    justifyContent: "space-between",
    alignItems: "start",
    padding: "22px 0px 52px",
    width: "100%"
  },
  healthDetailsNameSubContainer: {
    display: "flex",
    fontFamily: "SF Pro Text Regular",
    justifyContent: "space-between",
    alignItems: "start",
    padding: "52px 0px 80px",
    width: "100%"
  },
  healthDetailsButtons: {
    width: "32%",
    backgroundColor: "#014866",
    color: "#fff",
    border: "none",
    borderRadius: "10px",
    padding: "16px",
    cursor: "pointer",
    fontSize: "18px",
    margin: "10px auto",
    fontFamily: "SF Pro Text Bold",
    "&:hover": {
      backgroundColor: "#014866",
      color: "#fff"
    },
    textTransform: "capitalize" as textTransform
  },
  saveButtons: {
    width: "20%",
    backgroundColor: "#014866",
    color: "#fff",
    border: "none",
    borderRadius: "10px",
    padding: "16px",
    cursor: "pointer",
    fontWeight: 600,
    fontSize: "18px",
    fontFamily:"SF Pro Text Bold",
    margin: "55px auto 100px",
    "&:hover": {
      backgroundColor: "#014866",
      color: "#fff"
    },
    textTransform: "capitalize" as textTransform
  },
  editIconsConatiner: {
    display: "flex",
    justifyContent: "space-between",
    padding: "2% 8% 0% 45px",
    position: "relative" as positionType,
    background: "#dce7fa;",
    zIndex: 1,
    height: "50%"
  },
  iconsContainer: {
    display: "flex",
    padding: "32px 0px",
    gap: "40px"
  },
  editProfileHeadingContainer: {
    padding: "4% 45px 0%"
  },
  editProfileFormContainer: {
    padding: "94px 4% 4% 4%"
  },
  inputErrors: {
    display: "flex",
    justifyContent: "end",
    color: "#FF0000",
    fontSize: "14px",
    fontFamily: "SF Pro Text Regular",
    caretColor: "transparent"
  },
  otpErrors: {
    display: "flex",
    justifyContent: "end",
    color: "#FF0000",
    fontSize: "12px",
    fontFamily: "SF Pro Text Regular",
    caretColor: "transparent"
  },
  registerInputFields: {
    width: "100%",
    fontSize: "14px",
    marginBottom: "10px",
    borderRadius: "8px",
    "& .MuiInputBase-root": {
      color: "#292929",
      fontSize: "14px",
      fontFamily: "SF Pro Text Regular",
      background: "white !important",
      borderRadius: "10px",
      border: "none"
    },
    "& input": {
      fontSize: "14px",
      color: "#292929",
      fontFamily: "SF Pro Text Regular",
      "&::placeholder": {
        opacity: 1,
        color: "#808080 !important",
        fontSize: "14px",
        fontFamily: "SF Pro Text Regular",
      }
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px"
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderWidth: "1px",
      borderColor: "#3f51b5 !important"
    },
    fontFamily: "SF Pro Text Regular"
  },
  errorRegisterInputFields: {
    width: "100%",
    fontSize: "14px",
    marginBottom: "10px",
    borderRadius: "8px",
    "& .MuiInputBase-root": {
      color: "#292929",
      fontSize: "14px",
      fontFamily: "SF Pro Text Regular",
      background: "white !important",
      borderRadius: "10px"
    },
    "& input": {
      fontSize: "14px",
      color: "#292929",
      fontFamily: "SF Pro Text Regular",
      "&::placeholder": {
        opacity: 1,
        color: "#808080 !important",
        fontSize: "14px",
        fontFamily: "SF Pro Text Regular",
      }
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderWidth: "1px",
      borderColor: "#3f51b5 !important"
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px"
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#FF0000",
      borderWidth: "1px"
    }
  },
  updateProfileInputFields: {
    width: "100%",
    fontSize: "15px",
    marginBottom: "10px",
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px"
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderWidth: "1px",
      borderColor: "#3f51b5 !important"
    },
    fontFamily: "SF Pro Text Regular"
  },
  registerGenderInputFields: {
    width: "100%",
    fontSize: "14px",
    marginBottom: "10px",
    "& .MuiInputBase-root": {
      color: "#292929",
      fontSize: "14px",
      fontFamily: "SF Pro Text Regular",
      background: "white !important",
      borderRadius: "10px",
      border: "none"
    },
    "& input": {
      fontSize: "14px",
      color: "#292929",
      fontFamily: "SF Pro Text Regular",
      "&::placeholder": {
        opacity: 1,
        color: "#808080 !important",
        fontSize: "14px",
        fontFamily: "SF Pro Text Regular",
      }
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px"
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderWidth: "1px",
      borderColor: "#3f51b5 !important"
    },
    "& .MuiInputBase-input": {
      cursor: "pointer"
    },
    fontFamily: "SF Pro Text Regular"
  },
  errorRegisterGenderInputFields: {
    width: "100%",
    fontSize: "15px",
    marginBottom: "10px",
    borderRadius: "8px",
    "& .MuiInputBase-root": {
      color: "#292929",
      fontSize: "14px",
      fontFamily: "SF Pro Text Regular",
      background: "white !important",
      borderRadius: "10px",
      border: "none"
    },
    "& input": {
      fontSize: "15px",
      color: "#292929",
      fontFamily: "SF Pro Text Regular",
      "&::placeholder": {
        opacity: 1,
        fontSize: "15px",
        fontFamily: "SF Pro Text Regular",
        color: "#808080 !important"
      }
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px"
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderWidth: "1px",
      borderColor: "#3f51b5 !important"
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#FF0000",
      borderWidth: "1px"
    },
    "& .MuiInputBase-input": {
      cursor: "pointer"
    },
    fontFamily: "SF Pro Text Regular"
  },
  registerLabel: {
    fontSize: "16px",
    fontFamily: "SF Pro Text Regular",
    color: "#292929",
    margin: "10px 0px",
    textAlign: "left" as textAlignType,
    caretColor: "transparent"
  },
  required: {
    color: "#FF0000"
  },
  textFieldContainer: {
    width: "92%"
  },
  selectCheckbox: {
    position: "relative" as positionType,
    pointer: "cursor"
  },
  upArrowIcon: {
    width: "18px",
    height: "15px",
    objectFit: "cover" as objectFitType,
    cursor: "pointer"
  },
  downArrowIcon: {
    width: "18px",
    height: "15px",
    objectFit: "cover" as objectFitType,
    cursor: "pointer",
    marginRight: "6px"
  },
  selectFieldDropdown: {
    position: "absolute" as positionType,
    borderRadius: "8px",
    top: "100%",
    left: 0,
    width: "100%",
    backgroundColor: "#fff",
    border: "1px solid #ccc",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    zIndex: 1000
  },
  dropdownContent: {
    padding: "10px 20px"
  },
  dropdownText: {
    display: "flex",
    justifyContent: "space-between",
    padding: "10px 3px 15px 0px",
    color: "#808080",
    cursor: "pointer",
    borderBottom: "1px solid #ccc",
    transition: "background-color 0.3s ease",
    fontSize: "14px"
  },
  selectOption: {
    padding: "5px 10px 5px 0px",
    cursor: "pointer",
    borderBottom: "1px solid #ccc",
    width: "80%",
    transition: "background-color 0.3s ease",
    fontFamily: "SF Pro Text Regular",
    display: "flex"
  },
  checkbox: {
    color: "#fff !important",
    padding: "9px 20px 9px 0px"
  },
  hospitalCheckbox: {
    color: "#fff !important",
    padding: "9px 25px 9px 0px"
  },
  gallery: {
    width: "28%",
    height: "28%",
    cursor: "pointer"
  },
  camera: {
    display: "none",
    width: "28%",
    height: "28%",
    cursor: "pointer"
  },
  dialogBoxIcons: {
    display: "flex",
    marginTop: "35px",
    width: "100%",
    justifyContent: "center",
    gap: "40px"
  },
  dialogBox: {
    "& .MuiDialog-paperWidthSm": {
      maxWidth: "400px !important",
      width: "100%"
    },
    "& .MuiDialog-paperScrollPaper": {
      display: "flex",
      flexDirection: "column" as "column",
      height: "230px"
    },
    " & .MuiPaper-rounded": {
      borderRadius: "10px !important",
      padding: "40px"
    }
  },
  dialogTitleConatiner: {
    display: "flex",
    justifyContent: "space-between"
  },
  dialogTitle: {
    fontSize: "18px",
    fontWeight: 600,
    paddingLeft: "27%",
    caretColor: "transparent"
  },
  dialogBoxText: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    gap: "50px"
  },
  uploadText: {
    width: "90px",
    textAlign: "center" as textAlignType,
    caretColor: "transparent",
    cursor: "pointer"
  },
  uploadCameraText: {
    display: "none",
    width: "90px",
    textAlign: "center" as textAlignType,
    caretColor: "transparent",
    cursor: "pointer"
  },
  profileDetailsContainer: {
    width: "75%",
    [theme.breakpoints.down("xl")]: {
      paddingLeft: "30px"
    },
    [theme.breakpoints.down("md")]: {
      width: "70%",
      paddingLeft: "10px"
    },
    [theme.breakpoints.down("sm")]: {
      width: "50%",
      paddingLeft: "0px"
    }
  },
  profileContainer: {
    width: "25%",
    marginRight:'24px',
    [theme.breakpoints.down("md")]: {
      width: "40%"
    },
    [theme.breakpoints.down("sm")]: {
      width: "50%"
    }
  },
  // Health details
  patientHeathDtailsComponent: {
    [theme.breakpoints.down("xs")]: {
      margin: "20px"
    }
  },
  homeCureContainers: {
    caretColor: "transparent",
    display: "flex",
    alignItems: "center",
    margin: "20px"
  },
  healthDetailHomecureLogos: {
    width: 180,
    margin: "20px 45px",
    [theme.breakpoints.down("lg")]: {
      margin: "20px 35px"
    },
    [theme.breakpoints.down("md")]: {
      margin: "20px"
    },
    [theme.breakpoints.down("xs")]: {
      width: 180,
      margin: "10px"
    }
  },
  healthDetailConatiner: {
    display: "flex",
    justifyContent: "space-between",
    margin: "30px 15px 0px",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column"
    }
  },
  healthDetailsSubContainer: {
    display: "flex",
    marginBottom: "15px",
    [theme.breakpoints.down("sm")]: {
      width: "200px",
      justifyContent: "space-between"
    }
  },
  healthDetailsBackButtons: {
    display: "flex",
    alignItems: "center",
    width: "20px",
    height: "20px",
    backgroundColor: "rgba(255, 255, 255, 0.3)",
    boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
    padding: "10px",
    justifyContent: "center",
    margin: "0px 30px",
    borderRadius: "5px",
    [theme.breakpoints.down("lg")]: {
      margin: "0px 20px"
    },
    [theme.breakpoints.down("md")]: {
      margin: "0px 10px"
    },
    [theme.breakpoints.down("xs")]: {
      margin: "0px"
    },
    caretColor: "transparent",
    cursor: "pointer"
  },
  boxWidth: {
    width: "100%",
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px"
    },
    "& input": {
      color: '#292929',
      fontSize: "16px",
      fontFamily:"SF Pro Text Regular",
      "&::placeholder": {
        opacity: 1,
        fontSize: "16px",
        fontFamily:"SF Pro Text Regular",
        color:"#808080"
      },
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderWidth: "1px",
      borderColor: "#3f51b5 !important"
    }
  },
  patientSize: {
    fontSize: "16px",
    caretColor: "white",
    marginBottom: "10px"
  },
  diseaseContaier: {
    width: "95%",
    margin: "20px auto"
  },
  patientTypo: {
    fontFamily:"SF Pro Text Regular",
    color:"#292929",
    caretColor: "white",
    fontSize: "16px",
    marginBottom: "10px"
  },
  typeWrapper: {
    fontSize: "16px",
    marginBottom: "10px",
    marginTop: "20px",
    caretColor: "white"
  },
  patientSelectBox: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "10px",
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px"
    }
  },
  patientAllergyBox: {
    display: "flex",
    justifyContent: "space-between"
  },
  checkboxWhite: {
    color: "#fff !important",
    marginLeft: "2px !important"
  },
  healthDetailsLinks: {
    caretColor: "transparent",
    marginLeft: "20px",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0px"
    }
  },
  healthDetailsHeadings: {
    display: "flex",
    width: "204px",
    alignItem: "center",
    justifyContent: "center",
    fontSize: "28px",
    fontFamily:"SF Pro Text Bold",
    color:"#292929",
  },
  uploadForm: {
    display: "flex",
    margin: "10px 35px",
    [theme.breakpoints.down("lg")]: {
      display: "flex",
      margin: "10px 20px"
    },
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      margin: "12px 0px",
      justifyContent: "space-between"
    }
  },
  arrowIcon: {
    height: "15px",
    caretColor: "white",
    weight: "15px"
  },
  allergyLink: {
    caretColor: "transparent",
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: "25px"
  },
  surgeryContainer: {
    marginBottom: "10px"
  },
  skipBtn: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "50%"
  },
  btnContain: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "50%"
  },
  loginButtons: {
    width: "100%",
    backgroundColor: "#014866",
    color: "#fff",
    border: "none",
    borderRadius: "10px",
    padding: "16px",
    cursor: "pointer",
    fontWeight: 600,
    fontSize: "16px",
    margin: "10px auto",
    "&:hover": {
      backgroundColor: "#014866",
      color: "#fff"
    }
  },
  paper: {
    borderRadius: "12px",
    padding: "10px",
    maxWidth: "470px"
  },
  dialogText : {
    fontSize: "20px",
    fontFamily: "SF Pro Text Medium",
    lineHeight: "10px",
    color: "#292929",
    caretColor: "transparent !important"
  },
  dialogAction: {
    justifyContent: "center"
  },

  dialogButton: {
    width: "181px",
    height:"65px",
    fontFamily: "SF Pro Text Bold",
    caretColor: "transparent",
    backgroundColor: "#014866",
    color: "#fff",
    border: "none",
    borderRadius: "10px",
    padding: "10px",
    cursor: "pointer",
    fontWeight: 600,
    fontSize: "18px",
    margin: "10px auto",
    "&:hover": {
      backgroundColor: "#014866",
      color: "#fff"
    }
  },
  patientButtonContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "30px",
    [theme.breakpoints.down("sm")]: {
      display: "none"
    }
  },
  patientMobileButtonContainer: {
    display: "flex",
    width: "100%",
    margin: "-15px 20px",
    padding: "10px 0px",
    [theme.breakpoints.down("xl")]: {
      display: "none"
    },
    [theme.breakpoints.down("sm")]: {
      display: "flex"
    }
  },
  cardContainer: {
    padding: "13px",
    borderRadius: "10px"
  },
  BoxContainer: {
    height: "520px",
    overflowY: "scroll" as overflowProperty,
    scrollbarColor: "#d9d5d5 #fff",
    scrollbarWidth: "thin" as scrollbarType,
    "& ::-webkit-scrollbar-button": {
      display: "none"
    }
  },
  patientSelectBoxDiseases: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "25px",
    width: "100%",
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px"
    }
  },
  customSelectFieldDiseases : {
    ...customStyles,
    "& .css-1pahdxg-control:hover": {
      border: "2px solid #3f515b",
      fontSize: "16px",
      borderRadius: "8px",
      fontFamily: "SF Pro Text Regular",
      color:"#292929"
    }
  },
  inputError: {
    width: "100%",
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",caretColor:"transparent"
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#FF0000",
      borderWidth: "1px",
      caretColor:"transparent"
    },caretColor:"transparent"
  },
  unselectedField: {
    width: "100%",
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px"
    },
    color: "hsl(0, 2.2%, 64.3%)",
    caretColor: "transparent"
  },
  selectedField: {
    width: "100%",
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px"
    },
    caretColor: "transparent",
    color: "#014866"
  },
  otherDiseasesTextBoxError: {
    width: "100%",
    marginBottom: "10px",
    "& input": {
      color: '#292929',
      fontSize: "16px",
      fontFamily:"SF Pro Text Regular",
      "&::placeholder": {
        opacity: 1,
        fontSize: "16px",
        fontFamily:"SF Pro Text Regular",
        color:"#808080"
      },
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px"
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#FF0000",
      borderWidth: "1px"
    }
  },
  lastDiseasesTextBoxError: {
    width: "100%",
    marginBottom: "10px",
    marginTop: "16px",
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px"
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#FF0000",
      borderWidth: "1px"
    }
  },
  otherDiseasesTextBox: {
    width: "100%",
    marginBottom: "10px",
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px"
    },
    "& input": {
      color: '#292929',
      fontSize: "16px",
      fontFamily:"SF Pro Text Regular",
      "&::placeholder": {
        opacity: 1,
        fontSize: "16px",
        fontFamily:"SF Pro Text Regular",
        color:"#808080"
      },
    },
  },
  medicineLink: {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: "25px",
    caretColor: "transparent"
  },
  uploadLink : {
    caretColor: "transparent",
    cursor: "pointer",
    color: "#3780E0",
    fontSize: "16px",
    alignItem: "center",
    fontFamily: "SF Pro Text Semibold"
  },
  link: {
    caretColor: "transparent",
    cursor: "pointer",
    color: "#3780E0",
    fontSize: "14px",
    alignItem: "center",
    fontFamily: "SF Pro Text Medium"
  },
  selectPatientField: {
    width: "48%"
  },
  customErrorSelectField: {
    ...customStyles,
    "& .css-95shoe-control": {
      borderColor: "red !important",
      padding: "8px !important"
    },
    "& .css-1pahdxg-control:hover": {
      border: "2px solid red",
      fontSize: "16px",
      borderRadius: "8px",
      fontFamily: "SF Pro Text Regular",
      color:"#292929"
    },
    "& .css-1rsya3y-control": {
      borderColor: "red !important"
    },
    "& .css-yk16xz-control": {
      minHeight: "54px !important",
      fontSize: "16px",
      borderRadius: "8px",
      border: "2px solid red",
      fontFamily: "SF Pro Text Regular",
      color:"#292929"
    },
    "& .css-1wa3eu0-placeholder": {
      fontFamily: "SF Pro Text Regular",
      color:"#808080"
    }
  },
  customSelectField: {
    ...customStyles,
    "& .css-1pahdxg-control:hover": {
      border: "1px solid #3f515b",
      fontSize: "16px",
      borderRadius: "8px",
      fontFamily: "SF Pro Text Regular",
    }
  },
  uploadDocContainer: {
    fontSize: "15px",
    "& .MuiDialog-paperWidthSm": {
      maxWidth: "400px",
      width: "400px",
      padding: "30px 40px",
      margin: "auto",
      borderRadius: "20px",
      overflow: "hidden",
      caretColor: "transparent"
    },
    [theme.breakpoints.down("xs")]: {
      "& .MuiDialog-paperWidthSm": {
        padding: "10px"
      }
    }
  },
  uploadInputSuccess: {
    display: "flex",
    justifyContent: "center",
    color: "green",
    fontSize: "14px"
  },
  uploadInputError: {
    display: "flex",
    justifyContent: "center",
    color: "#FF0000",
    fontSize: "14px",
    caretColor:"transparent"
  },
  uploadImageInputErrors: {
    display: "flex",
    justifyContent: "center",
    color: "#FF0000",
    fontSize: "14px"
  },
  titleContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    caretColor: "transparent !important"
  },
  uploadTitle: {
    "& .MuiTypography-h6": {
      fontWeight: 700,
      fontSize: "21px",
      caretColor: "transparent !important"
    }
  },
  uploadBtn: {
    colors: "#014866",
    display: "flex",
    width: "90%",
    fontWeight: 600,
    backgroundColor: "#c2e0ef",
    padding: "15px",
    borderRadius: "10px",
    margin: "30px auto 0px",
    color: "#014866",
    fontSize: "14px",
    [theme.breakpoints.down("xs")]: {
      width: "100%"
    }
  },
  previewBtn: {
    display: "flex",
    margin: "30px auto 0px",
    backgroundColor: "#014866",
    color: "#fff",
    border: "none",
    borderRadius: "10px",
    width: "80%",
    padding: "14px",
    cursor: "pointer",
    fontWeight: 600,
    fontSize: "16px",
    "&:hover": {
      backgroundColor: "#014866",
      color: "#fff"
    }
  },
  uploadLabel: {
    fontSize: "16px",
    margin: "20px 0px 10px 18px"
  },
  customDatePickerContainer: {
    display: "flex",
    alignItems: "center"
  },
  dateInput: {
    "& input": {
      cursor: "pointer",
      background: `url(${CalenderIcon})left / contain no-repeat`,
      backgroundPosition: "12px",
      backgroundSize: "25px",
      width: "90%",
      height: "50px",
      border: "1px solid #a5a1a1",
      color: "#000000",
      fontSize: "1em",
      fontWeight: 500,
      display: "flex",
      margin: "auto"
    },
    width: "100%",
    height: "100%"
  },
  dateField: {
    display: "flex",
    justifyContent: "center",
    padding: "15px 60px",
    border: "1px solid #a5a1a1",
    borderRadius: "8px",
    "&:hover": {
      border: "1px solid #3f51b5"
    },
    "&:focus": {
      border: "2px solid #3f51b5"
    }
  },
  dateFieldError: {
    display: "flex",
    padding: "15px 60px",
    border: "1px solid red",
    justifyContent: "center",
    borderRadius: "8px",
    "&:focus": {
      border: "2px solid red"
    }
  },
  uploadInputErrors: {
    display: "flex",
    justifyContent: "center",
    color: "#FF0000",
    fontFamily: "SF Pro Text Regular",
    fontSize: "14px",
    margin: "0px 20px"
  },
  patientSelectBoxSurgery: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px"
    }
  },
  leftWrapper: {
    height: "100%",
    justifyContent: 'space-between',
    display: 'flex',
    minHeight: "100vh",
    overflowX: "hidden" as const,
  },
  rightWrapper: {
    minHeight: '100vh',
    width: '100%',
    height: "100%",
    overflowY: "scroll" as OverflowXProperty,
    scrollbarColor: "#d9d5d5 white",
    scrollbarWidth: "thin" as scrollbarType,
    "& ::-webkit-scrollbar-button": {
      display: "none"
    }
  },
  sidebarWrapper: {
    width: '132px',
    minHeight: 'max(100vh, 700px)',
  },
  surgeryLink: {
    caretColor: "transparent",
    justifyContent: "flex-end",
    display: "flex"
  },
  hospitalProfileDetailsContainer: {
    width: "100%",
    paddingLeft: "30px",
    paddingTop: "130px"
  },
  hospitalPhoneNumber: {
    caretColor: "transparent",
    fontSize: "16px",
    fontFamily: "SF Pro Text Medium",
    color: "#808080",
    borderBottom: "1px solid #BFBFBF",
    width: "70%",
    paddingBottom: "5px"
  },
  hospitalSubContainer: {
    display: "flex",
    fontFamily: "SF Pro Text Regular",
    alignItems: "start",
    padding: "22px 0px 0px",
    width: "80%",
    margin: "0px 0px 0px 45px",
    "@media (max-width:1400px)": {
      width: "100% !important"
    }
  },
  hospitalAddressContainer: {
    display: "flex",
    fontFamily: "SF Pro Text Regular",
    padding: "22px 0px 0px",
    width: "80%",
    margin: "0px 0px 0px 45px",
    "@media (max-width:1400px)": {
      width: "100% !important"
    },
    alignItems: "flex-start"
  },
  hospitalDetails: {
    fontFamily: "SF Pro Text Regular",
    width: "50%",
    fontSize: "18px",
    color: "#717171",
    caretColor: "transparent"
  },
  hospitalHeading: {
    fontSize: "18px",
    caretColor: "transparent",
    fontFamily: "SF Pro Text Medium",
    width: "30%"
  },
  hospitalDepartmentButtons: {
    '&.MuiButtonBase-root':{
      width: "50%",
      backgroundColor: "#014866",
      color: "#fff",
      border: "none",
      borderRadius: "10px",
      padding: "16px",
      cursor: "pointer",
      fontWeight: 600,
      fontSize: "18px",
      margin: "10px 0px",
      fontFamily:'SF Pro Text Bold',
      "&:hover": {
        backgroundColor: "#014866",
        color: "#fff"
      },
      textTransform: "capitalize" as textTransform,
      "@media (max-width:1500px)": {
        width: "60% !important"
      }
    }
  },
  hospitalInsuranceButtons: {
    '&.MuiButtonBase-root':{
      width: "50%",
      backgroundColor: "#fff",
      color: "#014866",
      borderColor: "#014866",
      border: "1px solid #014866",
      borderRadius: "10px",
      padding: "16px",
      cursor: "pointer",
      fontWeight: 600,
      fontSize: "18px",
      margin: "10px 0px",
      fontFamily:'SF Pro Text Bold',
      "&:hover": {
        backgroundColor: "#fff",
        color: "#014866"
      },
      textTransform: "capitalize" as textTransform,
      "@media (max-width:1500px)": {
        width: "60% !important"
      }
  }
  },
  hospitalContainer: {
    display: "flex",
    fontFamily: "SF Pro Text Regular",
    alignItems: "center",
    width: "100%",
    margin: "0px 0px 0px 45px"
  },
  buttonContainer: {
    marginTop: "55px"
  },
  sideBorder: {
    borderRight: "1px solid #d7d4d4",
    paddingBottom: "160px"
  },
  imageInput: {
    display: "none"
  },
  imageIcons: {
    width: "30px",
    height: "32px",
    objectFit: "cover" as objectFitType,
    display: "flex",
    justifyContent: "center",
    margin: "0px auto"
  },
  registrationTextField: {
    width: "100%",
    fontSize: "15px",
    marginBottom: "10px",
    overflow: "hidden",
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
      height: "100px",
      cursor: "pointer"
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderWidth: "1px",
      borderColor: "#3f51b5 !important"
    },
    "& input": {
      fontSize: "15px",
      color: "#292929",
      cursor: "pointer",
      fontFamily: "SF Pro Text Regular",
      "&::placeholder": {
        opacity: 1,
        color: "#808080 !important",
        fontSize: "15px",
        fontFamily: "SF Pro Text Regular",
      }
    },
  },
  registrationTextFieldError: {
    width: "100%",
    fontSize: "15px",
    marginBottom: "10px",
    overflow: "hidden",
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
      height: "100px",
      cursor: "pointer"
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#FF0000",
      borderWidth: "1px"
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderWidth: "1px",
      borderColor: "#3f51b5 !important"
    },
    "& input": {
      fontSize: "15px",
      color: "#292929",
      cursor: "pointer",
      fontFamily: "SF Pro Text Regular",
      "&::placeholder": {
        opacity: 1,
        color: "#808080 !important",
        fontSize: "15px",
        fontFamily: "SF Pro Text Regular",
      }
    },
  },
  uploadImage: {
    height: "150px",
    borderRadius: "8px",
    border: "1px solid #c7b9b9",
    width: "100%",
    cursor: "pointer"
  },
  //need to update
  selectButtons: {
    width: "60%",
    backgroundColor: "#014866",
    color: "#fff",
    border: "none",
    borderRadius: "10px",
    padding: "16px",
    cursor: "pointer",
    fontWeight: 600,
    fontSize: "18px",
    margin: "20px auto 10px",
    fontFamily:"SF Pro Text Bold",
    "&:hover": {
      backgroundColor: "#014866",
      color: "#fff"
    }
  },
  selectPopoverContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    margin: "auto"
  },
  tableContainer: {
    width: "100%",
    margin: "5% auto"
  },
  tablePopoverContainer: {
    "& .MuiTable-root": {
      width: "95% !important"
    },
    maxHeight: "450px",
    overflowY: "scroll" as OverflowXProperty,
    scrollbarColor: "#d9d5d5 #fff",
    scrollbarWidth: "thin" as scrollbarType,
    "@media (max-height: 700px)": {
      maxHeight: "330px"
    },
    "@media (max-height: 660px)": {
      maxHeight: "300px"
    },
    "@media (max-height: 659px)": {
      maxHeight: "250px"
    }
  },
  insuranceSearchField: {
    width: "100%",
    margin: "15px auto",
    "& input": {
      color:"#292929",
      fontFamily:"SF Pro Text Regular",
      fontSize: "16px",
      "&::placeholder": {
        fontFamily:"SF Pro Text Regular",
        fontSize: "16px",
        opacity: 1,
        color:"#808080"
      },
    },
    [theme.breakpoints.down("sm")]: {
      width: "90%"
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px"
    }
  },
  selectInsurance: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "10px"
  },
  listContainer: {
    display: "block",
    margin: "30px 78px 15px ",
    border: "1px solid #eeeaea",
    borderRadius: "20px",
    padding: "15px"
  },
  searchIcon: {
    width: "25px",
    height: "25px",
    objectFit: "cover" as objectFitType,
    display: "flex",
    justifyContent: "center",
    margin: "0px 5px 0px 10px"
  },
  profileError: {
    display: "flex",
    justifyContent: "center",
    color: "#FF0000",
    fontSize: "14px",
    fontFamily: "SF Pro Text Regular"
  },
  patientSelectOption: {
    padding: "5px 10px 5px 0px",
    cursor: "pointer",
    fontSize: "16px",
    color: "#292929",
    fontFamily: "SF Pro Text Regular",
    borderBottom: "1px solid #E7E7E7",
    width: "80%",
    transition: "background-color 0.3s ease",
    display: "flex",
    alignItems: "center",
    caretColor: "transparent"
  },
  captureBtnContainer: {
    display: "flex",
    width: "100%",
    float: "right" as floatType,
    margin: "10px",
    cursor: "pointer"
  },
  capturedBtnContainer: {
    display: "flex",
    justifyContent: "space-between",
    margin: "10px"
  },
  dialogBoxWeb: {
    display: "flex",
    justifyContent: "center",
    margin: "auto",
    height: "400px"
  },
  webDialogBox: {
    "& .MuiDialog-paperWidthSm": {
      maxWidth: "400px !important",
      width: "100%"
    },
    "& .MuiDialog-paperScrollPaper": {
      display: "flex",
      flexDirection: "column" as flexDirection
    },
    " & .MuiPaper-rounded": {
      borderRadius: "10px !important",
      padding: "40px"
    }
  },
  button: {
    width: "90px",
    textAlign: "center" as textAlignType,
    background: "#014866",
    padding: "12px",
    borderRadius: "8px",
    color: "white",
    fontSize: "15px",
    fontFamily: "SF Pro Text Regular",
    cursor: "pointer",
    fontWeight: 600
  },

  //Dialog
  customContainer: {
    display: "block",
    maxWidth: "550px",
    width: "500px",
    height: "auto",
    textAlign: "left" as textAlignType,
    borderRadius: "5px"
  },
  heading: {
    width: "100%",
    color: "black",
    fontFamily: "SF Pro Text Regular",
    fontSize: "28px",
    fontWeight: 700,
    height: "8px",
    marginTop: "15px",
    caretColor: "white"
  },
  paragraph: {
    width: "100%",
    color: "black",
    fontFamily: "SF Pro Text Regular",
    fontSize: "16px",
    height: "8px",
    margin: "30px 0px",
    caretColor: "white"
  },
  popupButton: {
    backgroundColor: "rgb(33, 150, 243)",
    fontFamily: "SF Pro Text Regular",
    border: "none",
    color: "white",
    padding: "5px",
    marginBottom: "15px",
    borderRadius: "3px",
    fontWeight: 600,
    cursor: "pointer",
    caretColor: "transparent",
    "&.MuiButton-root:hover": {
      backgroundColor: "rgb(33, 150, 243)",
      color: "white"
    }
  },

  //Doctor
  languageBox: {
    display: "flex",
    width: "95%",
    alignItems: "center",
    flexWrap: "wrap" as "wrap",
    padding: "10px",
    cursor: "pointer",
    fontFamily: "SF Pro Text Regular",
    border: "1px solid #b9b6b6",
    borderRadius: "8px",
    position: "relative" as positionType,
    "&:focus": {
      border: "2px solid #3f51b5"
    },
    "&:hover": {
      border: "1px solid #3f51b5"
    }
  },
  languageBoxError: {
    display: "flex",
    marginBottom: "12px",
    width: "95%",
    flexWrap: "wrap" as "wrap",
    border: "1px solid red",
    borderRadius: "8px",
    padding: "10px",
    cursor: "pointer",
    alignItems: "center",
    position: "relative" as positionType,
    fontFamily: "SF Pro Text Regular",
    "&:focus": {
      border: "1px solid #3f51b5"
    },
    "&:hover": {
      border: "1px solid #3f51b5"
    }
  },
  hpErrorRegisterInputFields: {
    width: "100%",
    fontSize: "15px",
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#FF0000",
      borderWidth: "1px"
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
      cursor: "pointer"
    },
    fontFamily: "SF Pro Text Regular",
    "& .MuiInputBase-input": {
      cursor: "pointer"
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderWidth: "1px",
      borderColor: "#3f51b5 !important"
    },
    marginBottom: "10px",
    borderRadius: "8px",
    "& input": {
      fontSize: "16px",
      color:"#292929",
      fontFamily:"SF Pro Text Regular",
      "&::placeholder": {
        opacity: 1,
        color:"#808080 !important",
        fontSize: "16px",
        fontFamily:"SF Pro Text Regular",
  
      }
  }
},
  hpRegisterInputFields: {
    width: "100%",
    fontSize: "14px",
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderWidth: "1px",
      borderColor: "#3f51b5 !important"
    },
    fontFamily: "SF Pro Text Regular",
    "& .MuiInputBase-input": {
      cursor: "pointer"
    },
    marginBottom: "10px",
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
      cursor: "pointer"
    },
    "& input": {
      fontSize: "14px",
      color:"#292929",
      fontFamily:"SF Pro Text Regular",
      "&::placeholder": {
        opacity: 1,
        color:"#808080 !important",
        fontSize: "14px",
        fontFamily:"SF Pro Text Regular",
  
      }
  }
},
  hpUploadRegisterInputFields: {
    width: "100%",
    fontSize: "14px",
    marginBottom: "10px",
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderWidth: "1px",
      borderColor: "#3f51b5 !important"
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
      cursor: "pointer"
    },
    fontFamily: "SF Pro Text Regular",
    "& input": {
      fontSize: "14px",
      color: "#292929",
      cursor: "pointer",
      fontFamily: "SF Pro Text Regular",
      "&::placeholder": {
        opacity: 1,
        color: "#808080 !important",
        fontSize: "14px",
        fontFamily: "SF Pro Text Regular",
      }
    },
  },
  hpUploadErrorRegisterInputFields: {
    width: "100%",
    fontSize: "15px",
    marginBottom: "10px",
    borderRadius: "8px",
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
      cursor: "pointer"
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#FF0000",
      borderWidth: "1px"
    },
    fontFamily: "SF Pro Text Regular",
    "& input": {
      cursor: "pointer",
      fontSize: "15px",
      color: "#292929",
      fontFamily: "SF Pro Text Regular",
      "&::placeholder": {
        opacity: 1,
        color: "#808080 !important",
        fontSize: "15px",
        fontFamily: "SF Pro Text Regular",
      }
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderWidth: "1px",
      borderColor: "#3f51b5 !important"
    }
  },
  hpSelectCheckbox: {
    pointer: "cursor",
    position: "relative" as positionType,
    caretColor: "transparent"
  },
  registerDegreeInputFields: {
    width: "48%",
    fontSize: "15px",
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px"
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderWidth: "1px",
      borderColor: "#3f51b5 !important"
    },
    marginBottom: "65px",
    fontFamily: "SF Pro Text Regular"
  },
  hpConnectHospitalFields: {
    width: "100%",
    fontSize: "16px",
    marginBottom: "10px",
    borderColor: "#02A405 !important",
    backgroundColor: "#E3FFE4",
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
      cursor: "pointer"
    },
    "& input": {
      cursor: "pointer",
      fontSize: "16px",
      color: "#292929",
      fontFamily: "SF Pro Text Regular",
      "&::placeholder": {
        opacity: 1,
        color: "#808080 !important",
        fontSize: "16px",
        fontFamily: "SF Pro Text Regular",
      }
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderWidth: "1px",
      borderColor: "#02A405 !important"
    },
    borderRadius: "8px"
  },
  hpConnectHospitalFieldsGray: {
    width: "100%",
    fontSize: "15px",
    marginBottom: "10px",
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderWidth: "1px",
      borderColor: "#3f51b5 !important"
    },
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
      cursor: "pointer"
    },
    "& input": {
      fontSize: "16px",
      cursor: "pointer",
      color:"#292929",
      fontFamily:"SF Pro Text Regular",
      "&::placeholder": {
        color:"#808080 !important",
        opacity: 1,
        fontSize: "16px",
        fontFamily:"SF Pro Text Regular",
      }
    }
  },
  selectLanguagePlaceholder: {
    alignItems: "center",
    color: "#a2a2a2",
    padding: "8px"
  },
  gridContainer: {
    height: "100vh",
    overflow: "auto",
    "& .MuiGrid-spacing-xs-4 > .MuiGrid-item": {
      [theme.breakpoints.down("xs")]: {
        padding: "0px"
      }
    },
    [theme.breakpoints.down("sm")]: {
      height: "unset !important",
      overflow: "unset !important"
    }
  },
  fixedGrid: {
    position: "sticky" as positionType,
    top: 0,
    maxHeight: "100%",
    overflow: "hidden" as overflowProperty,
    [theme.breakpoints.down("sm")]: {
      maxHeight: "30%",
      overflow: "unset !important"
    }
  },
  container: {
    backgroundImage: `url(${pexelsKarolina})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    padding: "10px",
    [theme.breakpoints.down("sm")]: {
      padding: "30px 10px"
    }
  },
  homecureLogos: {
    caretColor: "transparent",
    margin: 20,
    width: 180,
    [theme.breakpoints.down("sm")]: {
      margin: 10,
      width: 180
    }
  },
  setLocationHeadings: {
    display: "flex",
    width: "200px",
    fontSize: "20px",
    caretColor: "transparent",
    margin: "30px 0px",
    alignItem: "center",
    fontWeight: 600,
    marginTop: "10px",
    justifyContent: "center"
  },
  patientRegistrationContainer: {
    margin: "0px 50px"
  },
  patientLabelContainer: {
    display: "flex",
    alignItems: "center",
    marginTop: "20px",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      marginTop: "80px"
    }
  },
  registrationHeading: {
    width: "50%",
    fontSize: "28px",
    caretColor: "transparent",
    fontFamily:"SF Pro Text Bold",
    color:"#292929"
  },
  setLocationContainer: {
    textDecoration: "none",
    width: "50%",
    textAlign: "right" as textAlignType
  },
  setLocation: {
    caretColor: "transparent",
    color: "#3780E0",
    cursor: "pointer",
    fontSize: "16px",
    fontFamily:"SF Pro Text Medium",
  },
  patientHeading: {
    color: "gray",
    fontSize: "16px",
    margin: "10px 0px 35px"
  },
  degreeContainerSpacing: {
    padding: "0px 16px !important"
  },
  connectIcon: {
    display: "flex",
    justifyContent: "center",
    height: "32px",
    margin: "0px auto",
    cursor: "pointer",
    objectFit: "cover" as objectFitType
  },
  locationGridContainer: {
    padding: "0px 16px 16px !important"
  },
  locationLimit: {
    color: "gray",
    marginLeft: "15px"
  },
  sliderBox: {
    width: "70%"
  },
  locationLimitBox: {
    width: "95%",
    padding: "6px 20px",
    alignItems: "center",
    border: "1px solid #b9b6b6",
    borderRadius: "8px",
    cursor: "pointer",
    fontFamily: "SF Pro Text Regular"
  },
  locationSpan: {
    justifyContent: "space-between",
    display: "flex",
    width: "100%",
    color: "#808080"
  },
  hospitalContainerSpacing: {
    padding: "0px 16px !important"
  },
  hpDownArrowIcon: {
    cursor: "pointer",
    width: "18px",
    height: "15px",
    marginRight: "6px",
    objectFit: "cover" as objectFitType
  },
  hpSelectFieldDropdown: {
    width: "100%",
    top: "100%",
    position: "absolute" as positionType,
    borderRadius: "8px",
    left: 0,
    backgroundColor: "#fff",
    zIndex: 1000,
    marginTop: "10px",
    border: "1px solid #ccc",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)"
  },
  hpDropdownContent: {
    padding: "10px 20px"
  },
  hpUpArrowIcon: {
    width: "18px",
    height: "15px",
    cursor: "pointer",
    objectFit: "cover" as objectFitType
  },
  hpSelectFieldSize: {
    scrollbarColor: "#d9d5d5 #fff",
    scrollbarWidth: "thin" as scrollbarType,
    overflowY: "scroll" as overflowProperty,
    "& ::-webkit-scrollbar-button": {
      display: "none"
    },
    maxHeight: "285px",
    minHeight: "auto"
  },
  hpSelectOption: {
    padding: "5px 10px 5px 0px",
    cursor: "pointer",
    borderBottom: "1px solid #ccc",
    width: "80%",
    transition: "background-color 0.3s ease",
    fontFamily: "Sf Pro Text Medium",
    fontSize:"16px",
    display: "flex",
    alignItems: "center",
    color:"#292929"
  },
  mobileHospitalContainerSpacing: {
    padding: "0px 16px !important",
    "&.PatientRegisterDetails-gridContainer-47 .MuiGrid-spacing-xs-4 > .MuiGrid-item": {
      padding: "0px"
    }
  },
  langIcon: {
    "& .MuiChip-deleteIcon": {
      color: "#000000 !important"
    },
    "&.MuiChip-label": {
      paddingLeft: "8px !important",
      paddingRight: "8px !important"
    },
    width: "121px",
    margin: "1px 4px",
    alignItems: "center",
    padding: "7px 0px",
    fontSize: "14px",
    backgroundColor: "#e2ecf0",
    color: "#014866",
    borderRadius: "6px",
    fontFamily:"SF Pro Text Regular",
  },
  hpLangDownArrowIcon: {
    width: "18px",
    height: "15px",
    marginRight: "12px",
    objectFit: "cover" as objectFitType,
    cursor: "pointer",
    right: "5px",
    position: "absolute" as positionType,
    top: "18px"
  },
  languageText: {
    fontSize: "14px",
    fontFamily: "SF Pro Text Regular",
    color: "#808080",
    margin: "5px 0px 0px !important",
    textAlign: "text" as textAlignType
  },
  hpLangDropdownContent: {
    padding: "10px 20px 20px 20px"
  },
  langdropdownText: {
    cursor: "pointer",
    float: "right" as floatType
  },
  degColTextField: {
    width: "48%"
  },
  degClgBox: {
    display: "flex",
    justifyContent: "space-between"
  },
  hospitalBottomContainerSpacing: {
    padding: "0px 16px 10px !important"
  },
  registerDesktopButtons: {
    width: "50%",
    backgroundColor: "#014866",
    color: "#fff",
    border: "none",
    margin: "25px auto 10px",
    borderRadius: "10px",
    padding: "16px",
    cursor: "pointer",
    fontWeight: 600,
    fontSize: "18px",
    fontFamily:"SF Pro Text Bold",
    "&:hover": {
      backgroundColor: "#014866",
      color: "#fff"
    },
    [theme.breakpoints.down("xs")]: {
      display: "none"
    }
  },
  hpNewPaperPropsClass: {
    "& > div:nth-child(1)": {
      backgroundColor: "#050505a3 !important"
    },
    "& .MuiPopover-paper": {
      borderRadius: "30px",
      overflow: "unset"
    }
  },
  hpPopoverContainer: {
    padding: "35px 100px",
    "@media (max-width: 1024px)": {
      padding: "35px 80px"
    }
  },
  hpPopoverText: {
    fontSize: "20px",
    caretColor: "transparent",
    color: "#292929",
    margin: "auto",
    fontFamily: "SF Pro Text Medium",
    textAlign: "center" as textAlignType,
    lineHeight: "32px"
  },
  hpClosePopup: {
    display: "flex",
    borderRadius: "8px",
    justifyContent: "center",
    width: "181px",
    he4ight:"56px",
    backgroundColor: "#014866",
    color: "#fff",
    border: "none",
    padding: "16px",
    cursor: "pointer",
    fontFamily:"SF Pro Text Bold",
    fontSize: "18px",
    margin: "10px auto",
    textTransform: "capitalize" as textTransform,
    "&:hover": {
      backgroundColor: "#014866",
      color: "#fff"
    }
  },
  selecthpContainer: {
    display: "flex",
    margin: "20px auto 0px",
    justifyContent: "space-between",
    width: "360px"
  },
  hpUploadIcon: {
    width: "30px",
    height: "32px",
    justifyContent: "center",
    margin: "0px auto",
    objectFit: "cover" as objectFitType,
    display: "flex"
  },
  addDegreeField: {
    display: "flex",
    marginBottom: "0px",
    justifyContent: "flex-end"
  },
  hpPersonalDetials: {
    display: "flex",
    gap: "15px",
    justifyContent: "space-between"
  },
  mobileBottomHospitalContainerSpacing: {
    padding: "0px 16px 16px !important",
    "&.PatientRegisterDetails-gridContainer-47 .MuiGrid-spacing-xs-4 > .MuiGrid-item": {
      padding: "0px"
    }
  },
  hplangSelectFieldDropdown: {
    position: "absolute" as positionType,
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
    borderRadius: "8px",
    top: "100%",
    left: 0,
    width: "100%",
    backgroundColor: "#fff",
    border: "1px solid #ccc",
    zIndex: 1000,
    marginTop: "10px"
  },
  editIconsConatiners: {
    display: "flex",
    caretColor: "transparent",
    justifyContent: "space-between",
    padding: "2% 0% 5% 0%",
    position: "relative" as positionType,
    zIndex: 1
  },
  avatarEditIcons: {
    top: 0,
    left: "135px",
    width: "20px",
    height: "20px",
    zIndex: 1,
    borderRadius: "18px",
    cursor: "pointer",
    position: "absolute" as positionType,
    marginTop: "52px",
    background: "white",
    padding: "10px"
  },
  ayahDocIcon: {
    width: "30px",
    height: "32px",
    objectFit: "cover" as objectFitType,
    display: "flex",
    justifyContent: "center",
    margin: "0px auto"
  },
  registerDegreesInputFields: {
    width: "100%",
    caretColor: "transparent",
    fontSize: "15px",
    marginBottom: "10px",
    "& .MuiOutlinedInput-root": {
      cursor: "pointer",
      caretColor: "transparent",
      borderRadius: "8px"
    },
    fontFamily: "SF Pro Text Regular",
    "& .MuiInputBase-input": {
      cursor: "pointer",
      caretColor: "transparent"
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderWidth: "1px",
      borderColor: "#3f51b5 !important"
    },
    "& input": {
      fontSize: "16px",
      color:"#292929",
      fontFamily:"SF Pro Text Regular",
      "&::placeholder": {
        opacity: 1,
        color:"#808080 !important",
        fontSize: "16px",
        fontFamily:"SF Pro Text Regular",
  
      }
  }
},
  errorRegisterDegreeInputFields: {
    width: "100%",
    fontSize: "15px",
    marginBottom: "10px",
    borderRadius: "8px",
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px",
      cursor: "pointer"
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#FF0000",
      borderWidth: "1px"
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderWidth: "1px",
      borderColor: "#3f51b5 !important"
    },
    fontFamily: "SF Pro Text Regular",
    "& .MuiInputBase-input": {
      cursor: "pointer"
    },
    "& input": {
      fontSize: "16px",
      color:"#292929",
      fontFamily:"SF Pro Text Regular",
      "&::placeholder": {
        opacity: 1,
        color:"#808080 !important",
        fontFamily:"SF Pro Text Regular",
        fontSize: "16px"
  
      }
    }
  },
  popupHpButtons: {
    backgroundColor: "rgb(33, 150, 243)",
    border: "none",
    color: "white",
    padding: "5px",
    marginBottom: "15px",
    borderRadius: "3px",
    "&.MuiButton-root:hover": {
      backgroundColor: "rgb(33, 150, 243)",
      color: "white"
    }
  },
  hospitalEditBackButtons: {
    display: "flex",
    alignItems: "center",
    width: "20px",
    height: "20px",
    backgroundColor: "rgba(255, 255, 255, 0.3)",
    boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
    padding: "12px 9px 12px 15px",
    justifyContent: "center",
    margin: "4% 30px 0px 58px",
    borderRadius: "5px"
  },
  managementHPBackButtons: {
    display: "flex",
    alignItems: "center",
    width: "20px",
    height: "20px",
    backgroundColor: "rgba(255, 255, 255, 0.3)",
    boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
    padding: "12px 9px 12px 15px",
    justifyContent: "center",
    marginRight: "25px",
    borderRadius: "5px"
  },
  editHospitalProfile: {
    marginTop: "4%"
  },
  hospitalProfileViewContainer: {
    position: "relative" as positionType,
    bottom: "-65px"
  },
  hospitalViewContainer: {
    position: "relative" as positionType
  },
  profileHospitalHeading: {
    width: "100%",
    fontWeight: 600,
    fontSize: "28px",
    fontFamily: "SF Pro Text Bold",
    caretColor: "transparent"
  },
  boxHpBottomBorder: {
    width: "60%",
    borderBottom: "2px solid #ece8e8"
  },
  ayahAddressDetailsContainer: {
    display: "flex",
    gap: "15px",
    justifyContent: "space-between"
  },
  languageAyahBoxError: {
    alignItems: "center",
    display: "flex",
    border: "1px solid red",
    borderRadius: "8px",
    padding: "10px",
    cursor: "pointer",
    width: "95%",
    "&:focus": {
      border: "1px solid #3f51b5"
    },
    "&:hover": {
      border: "1px solid #3f51b5"
    },
    fontFamily: "SF Pro Text Regular"
  },
  patientLanguageBox: {
    marginRight: "15px",
    width: "285px"
  },
  hpLangSelectFieldSize: {
    minHeight: "auto",
    overflowY: "scroll" as overflowProperty,
    maxHeight: "285px",
    marginTop: "16px",
    scrollbarColor: "#d9d5d5 #fff",
    scrollbarWidth: "thin" as scrollbarType,
    "& ::-webkit-scrollbar-button": {
      display: "none"
    }
  },
  degreeConcate: {
    width: "46%",
    fontSize: "14px",
    caretColor: "transparent",
    cursor: "pointer",
    padding: "18px 0px 18px 12px",
    fontFamily: "SF Pro Text Medium",
    marginBottom: "50px",
    display: "block",
    overflow: "hidden" as overflowProperty,
    whiteSpace: "nowrap" as whiteSpaceType,
    textOverflow: "ellipsis" as "ellipisis",
    border: "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: "8px",
    "&:hover": {
      border: "1px solid #3f51b5"
    },
    color:"#292929"

  },

  degreeConcateLast: {
    width: "46%",
    overflow: "hidden" as overflowProperty,
    whiteSpace: "nowrap" as whiteSpaceType,
    textOverflow: "ellipsis" as "ellipsisi",
    fontSize: "14px",
    fontFamily: "SF Pro Text Medium",
    marginBottom: "65px",
    display: "block",
    border: "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: "8px",
    color:"#292929",
    padding: "18px 0px 18px 12px",
    "&:hover": {
      border: "1px solid #3f51b5"
    },
    caretColor: "transparent",
    cursor: "pointer"
  },
  inputFields: {
    width: "40px",
    height: "42px !important",
    margin: "5px auto",
    fontSize: "16px",
    "& .MuiInputBase-root": {
      border: "none",
      borderRadius: "5px",
      background: "white !important"
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#3f51b5 !important"
    },
    "& .MuiOutlinedInput-inputMarginDense": {
      padding: "13px 0px !important"
    },
    [theme.breakpoints.down("xs")]: {
      "& .MuiOutlinedInput-inputMarginDense": {
        padding: "12px 0px !important"
      }
    }
  },
  inputErrorFields: {
    width: "40px",
    height: "50px",
    margin: "5px auto",
    fontSize: "16px",
    "& .MuiInputBase-root": {
      border: "1px solid #f44336 !important",
      borderRadius: "5px",
      background: "white !important"
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#f44336"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent !important"
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent !important"
    },
    "& .MuiOutlinedInput-root.Mui-focuse .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent !important"
    },
    "& .MuiOutlinedInput-inputMarginDense": {
      padding: "13px 0px !important"
    },
    [theme.breakpoints.down("xs")]: {
      "& .MuiOutlinedInput-inputMarginDense": {
        padding: "12px 0px !important"
      }
    }
  },
  errorMessages: {
    display: "flex",
    justifyContent: "end",
    width: "85%",
    margin: "0px auto 10px"
  },
  verifyOTPNew: {
    width: "100%",
    backgroundColor: "#014866",
    color: "#F2F6F7",
    fontFamily: "SF Pro Text Bold",
    border: "none",
    borderRadius: "10px",
    padding: "16px",
    cursor: "pointer",
    fontWeight: 600,
    fontSize: "18px",
    margin: "10px auto",
    caretColor: "transparent",
    "&:hover": {
      backgroundColor: "#014866",
      color: "#F2F6F7"
    }
  },
  cardSubtitle: {
    caretColor: "transparent",
    fontSize: "16px",
    fontFamily: "SF Pro Text Medium",
    color: "#292929",
    padding: 5
  },
  cardInputLabel: {
    caretColor: "transparent",
    fontSize: "18px",
    fontFamily: "SF Pro Text Regular",
    color: "#292929",
    margin: "0px auto",
    width: "270px"
  },
  otpUpdateContainer: {
    display: "flex",
    justifyContent: "space-between",
    width: "295px",
    margin: "10px auto 0px",
    [theme.breakpoints.down("xs")]: {
      width: "270px"
    }
  },
  otpNewTransparentCard: {
    backgroundColor: "rgba(255, 255, 255, 0.3)",
    boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
    width: "350px",
    borderRadius: "25px",
    padding: "15px 35px",
    [theme.breakpoints.down("sm")]: {
      width: "350px !important",
      padding: "30px"
    },
    [theme.breakpoints.down("xs")]: {
      width: "300px !important",
      margin: "80px auto",
      padding: "15px"
    }
  },
  otpCardContentNew: {
    display: "flex"
  },
  otpText: {
    fontFamily: "SF Pro Text Bold !important",
    fontSize: "28px",
    color: "#292929",
    caretColor: "transparent"
  },
  otpWelcomeTitle: {
    fontFamily: "SF Pro Text Medium",
    fontSize: "28px",
    color: "#292929",
    caretColor: "transparent",
    paddingLeft: "47px",
    [theme.breakpoints.down("xs")]: {
      fontSize: "20px"
    }
  },
  otpDialogBoxNew: {
    "& .MuiDialog-paperWidthSm": {
      borderRadius: "25px !important"
    }
  },
  OtpSuccessMessage: {
    fontSize: "20px",
    color: "#292929",
    margin: "30px auto",
    fontWeight: 700,
    caretColor: "transparent",
    fontFamily: "SF Pro Text bold"
  },
  OtpSuccessLogo: {
    width: "85px",
    margin: "20px auto",
    caretColor: "transparent"
  },
  emailOtpSuccessLogo: {
    width: "85px",
    margin: "20px auto",
    caretColor: "transparent"
  },
  successOtpBtn: {
    width: "100%",
    caretColor: "transparent",
    backgroundColor: "#014866",
    color: "#fff",
    border: "none",
    borderRadius: "10px",
    padding: "16px",
    cursor: "pointer",
    fontWeight: 600,
    fontSize: "18px",
    margin: "10px auto",
    fontFamily: "SF Pro Text Bold",
    "&:hover": {
      backgroundColor: "#014866",
      color: "#fff"
    }
  },
  transparentOtpCards: {
    backgroundColor: "rgba(255, 255, 255, 0.3)",
    boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
    width: "350px",
    borderRadius: "25px",
    padding: "25px 35px",
    [theme.breakpoints.down("sm")]: {
      width: "350px !important",
      margin: "100px auto",
      padding: "30px"
    },
    [theme.breakpoints.down("xs")]: {
      width: "300px !important",
      margin: "80px auto",
      padding: "15px"
    }
  },
  cardOtpContents: {
    display: "flex",
    padding: theme.spacing(2)
  },
  emailConfirmOtpLogo: {
    width: "100px",
    margin: "auto"
  },
  confirmEmailOtpHeading: {
    fontSize: "18px",
    fontFamily: "SF Pro Text Bold",
    color: "#292929",
    margin: "20px auto",
    caretColor: "transparent"
  },
  confirmOtpEmailText: {
    caretColor: "transparent",
    fontSize: "18px",
    fontFamily: "SF Pro Text Medium",
    color: "#383838",
    margin: "0px auto"
  },
  confirmEmailOtpSubText: {
    caretColor: "transparent",
    fontSize: "18px",
    fontFamily: "SF Pro Text Medium",
    color: "#383838",
    margin: "0px auto "
  },
  hospitalSuccessMessageContainer: {
    margin: "30px auto"
  },
  successOtpBtns: {
    width: "100%",
    fontFamily: "SF Pro Text Bold",
    caretColor: "transparent",
    backgroundColor: "#014866",
    color: "#fff",
    border: "none",
    borderRadius: "10px",
    padding: "16px",
    cursor: "pointer",
    fontWeight: 600,
    fontSize: "18px",
    margin: "0px auto",
    "&:hover": {
      backgroundColor: "#014866",
      color: "#fff"
    }
  },
  emailLogos: {
    width: "100px",
    margin: "auto"
  },
  emailVerified: {
    width: "150px",
    margin: "auto",
    caretColor: "transparent"
  },
  confirmEmail: {
    fontSize: "16px",
    color: "black",
    margin: "10px auto",
    width: "270px",
    fontWeight: 600
  },
  emailMessage: {
    fontSize: "18px",
    fontFamily: "SF Pro Text Bold",
    color: "#292929",
    margin: "30px auto 0px",
    caretColor: "transparent"
  },
  successEmailMessage: {
    fontSize: "18px",
    fontFamily: "SF Pro Text Bold",
    color: "#292929",
    margin: "0px auto 30px",
    fontWeight: 700,
    caretColor: "transparent"
  },
  successEmailMessages: {
    fontSize: "18px",
    color: "#292929",
    margin: "0px auto 30px",
    fontWeight: 700,
    caretColor: "transparent",
    fontFamily: "SF Pro Text Bold"
  },
  inputFieldOtpVerify: {
    width: "40px",
    height: "42px !important",
    margin: "5px auto",
    fontSize: "16px",
    "& .MuiInputBase-root": {
      border: "none",
      borderRadius: "5px",
      background: "white !important"
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#3f51b5 !important"
    },
    "& .MuiOutlinedInput-inputMarginDense": {
      padding: "13px 0px !important"
    },
    [theme.breakpoints.down("xs")]: {
      "& .MuiOutlinedInput-inputMarginDense": {
        padding: "12px 0px !important"
      }
    }
  },
  inputErrorFieldVerifyOtp: {
    width: "40px",
    height: "50px",
    margin: "5px auto",
    fontSize: "16px",
    "& .MuiInputBase-root": {
      border: "1px solid #f44336 !important",
      borderRadius: "5px",
      background: "white !important"
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#f44336"
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent !important"
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent !important"
    },
    "& .MuiOutlinedInput-root.Mui-focuse .MuiOutlinedInput-notchedOutline": {
      borderColor: "transparent !important"
    },
    "& .MuiOutlinedInput-inputMarginDense": {
      padding: "13px 0px !important"
    },
    [theme.breakpoints.down("xs")]: {
      "& .MuiOutlinedInput-inputMarginDense": {
        padding: "12px 0px !important"
      }
    }
  },
  ayahInputErrors: {
    display: "flex",
    justifyContent: "end",
    color: "#FF0000",
    fontSize: "14px",
    fontFamily: "SF Pro Text Regular",
    marginTop: "12px"
  },
  patientOtherSelectBoxDiseases: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "26px",
    "& .MuiOutlinedInput-root": { borderRadius: "8px" }
  },
  lastOtherSelectBoxDiseases: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "10px",
    "& .MuiOutlinedInput-root": { borderRadius: "8px" }
  },
  othersLink: {
    display: "flex",
    justifyContent: "flex-end",
    caretColor: "transparent"
  },
  surgeryinputErrors: {
    display: "flex",
    justifyContent: "end",
    color: "#FF0000",
    fontFamily: "SF Pro Text Regular",
    caretColor: "transparent",
    fontSize: "14px",
    marginTop: "10px"
  },
  patientOtherSelectBoxDiseasesError: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    marginBottom: "10px",
    "& .MuiOutlinedInput-root": { borderRadius: "8px" }
  },
  inputOthersErrors: {
    display: "flex",
    justifyContent: "end",
    color: "#FF0000",
    fontSize: "14px",
    fontFamily: "SF Pro Text Regular",
    caretColor: "transparent",
    marginBottom: "26px"
  },
  inputMedecineErrors: {
    display: "flex",
    justifyContent: "end",
    color: "#FF0000",
    fontSize: "14px",
    fontFamily: "SF Pro Text Regular",
    caretColor: "transparent",
    marginBottom: "10px"
  },
  inputAllergyErrors: {
    display: "flex",
    justifyContent: "end",
    color: "#FF0000",
    fontSize: "14px",
    fontFamily: "SF Pro Text Regular",
    caretColor: "transparent",
    marginBottom: "26px"
  },
  profileCaredWrapper: {
    display: "flex",
    justifyContent: "space-between"
  },
  confimPopup: {
    display: "flex",
    justifyContent: "end"
  },
  boxWidthError: {
    width: "100%",
    "& .MuiOutlinedInput-root": {
      borderRadius: "8px"
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
      borderWidth: "1px",
      borderColor: "#3f51b5 !important"
    },

    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#FF0000",
      borderWidth: "1px"
    },
    "& input": {
      color: '#292929',
      fontSize: "16px",
      fontFamily:"SF Pro Text Regular",
      "&::placeholder": {
        opacity: 1,
        fontSize: "16px",
        fontFamily:"SF Pro Text Regular",
        color:"#808080"
      },
    },
  },
  selectedLocation: {
    color: "#02A405"
  },
  skipBtns :{
    color: "#3780E0",
    cursor: "pointer",
    alignItem: "center", 
    fontSize: "18px",
    caretColor: "transparent",
    fontFamily: "SF Pro Text Medium",
  },
  healthDetalSaveBtn : {
    width: "253px",
    height:"56px",
    borderRadius: "10px",
    padding: "16px",
    backgroundColor: "#014866",
    color: "#fff",
    border: "none",
    cursor: "pointer",
    fontFamily:"SF Pro Text Bold",
    fontSize: "18px",
    margin: "10px auto",
    "&:hover": {
      backgroundColor: "#014866",
      color: "#F2F6F7"
    }
  },
  priceSubConatiner: {
    display: "flex",
    fontFamily: "SF Pro Text Regular",
    justifyContent: "space-between",
    alignItems: "start",
    padding: "22px 0px 0px",
    width: "100%"
  },
  noReviewsFound: {
    fontSize: "20px",
    fontFamily: "SF Pro Text Medium",
    fontWeight: 500,
    color: "#808080",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    margin: "60px auto 30px",
 },
  reviewContainer: {
    width: "50%",
  },
  reviewDivider: {
      width: '100%',
      height: '1px',
      opacity: 0.38,
      borderBottom: '1px solid #C2C2C2',
      margin: '20px 0px',
  },
  reviewHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop:"40px"
  },
  reviewTitle: {
      fontFamily: 'SF Pro Text Medium',
      fontSize: '20px',
      fontWeight: 500,
      lineHeight: '23.87px',
      color: '#292929',
  },
  seeAllReview: {
      color: '#3780E0',
      fontFamily: 'SF Pro Text Medium',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '16.71px',
      cursor: "pointer"
  },
  reviewerFlexBox: {
      display: 'flex',
      width: '100%',
      alignItems: 'center',
      gap: "20px",
      margin: "30px 0px"
  },
  reviewerCover: {
      width: "65px",
      height: "65px",
      borderRadius: '50%',
  },
  reviewerName: {
      fontFamily: 'SF Pro Text Bold',
      fontSize: '18px',
      fontWeight: 600,
      lineHeight: '21.48px',
      letterSpacing: '-0.4341176450252533px',
      color: '#292929',
      marginBottom: '5px'
  },
  reviewerDescription: {
      fontFamily: 'SF Pro Text Regular',
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '24px',
      letterSpacing: '-0.38588234782218933px',
      color: '#808080',
      width: '277px'
  },
  reviewerContent: {
      display: 'flex',
      justifyContent: "space-between",
      alignItems: "flex-start",
      width: "100%",
      padding: 0,
  },
  reviewerRating: {
      fontFamily: 'SF Pro Text Medium',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '16.71px',
      letterSpacing: '-0.02800000086426735px',
      color: '#014866',
      marginLeft: '5px',
  },
  reviewerRatingOutOf: {
      fontFamily: 'SF Pro Text Medium',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '16.71px',
      letterSpacing: '-0.02800000086426735px',
      color: '#3780E0',
  },
  reviewerOverallRating: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: "flex-end",
      color: '#4CAF50',
      fontWeight: 600,
  },
  nurseProfileDivider: {
    width: '370px',
    height: '1px',
    opacity: 0.38,
    borderBottom: '1px solid #C2C2C2',
    margin: '20px 0px',
  },
  availableTimeSlotBtn:{
      fontFamily:"SF Pro Text Medium",
      fontSize:"16px",
      border:"1px solid #014866",
      width:"190px",
      height:"42px",
      backgroundColor:"transparent",
      color:"#014866",
      borderRadius:"8px",
      margin: "10px auto",
      textTransform: "capitalize" as "capitalize",
    "&:hover": {
      backgroundColor: "transparent",
      color: "#014866"
    }
  },
  cancelHpBtn:{
      fontFamily:"SF Pro Text Medium",
      fontSize:"16px",
      width:"164px",
      height:"42px",
      backgroundColor:"#014866",
      color:"#fff",
      borderRadius:"8px",
      margin: "10px auto",
      textTransform: "capitalize" as "capitalize",
    "&:hover": {
      backgroundColor: "#014866",
      color: "#fff"
    }
  },
  btnContainer: {
    display:"flex",
    gap:"15px",
    marginTop:"40px",
    width:"400px"
  },
  datepickerClassNew: {
    "& .react-datetime-picker__wrapper": {
      border: "none"
    },
    "& .react-datepickerClass-wrapper": {
      display: "flex !important",
    },
    "& .react-datepickerClass__month": {
      margin: "0px !important"
    },
    "& .react-datepickerClass-popper": {
      transform: "translate(90px, 209px) !important"
    },
    "& .react-datepickerClass__day": {
      width: "1.3rem !important",
      height: "1rem !important"
    },
    "& .react-datepickerClass__day-name": {
      width: "1.3rem !important",
      height: "1rem !important"
    },
    "& .react-datepickerClass__month-container": {
      margin: "0px 0px 5px !important"
    },
    "& .react-datepickerClass__day-names": {
      marginBottom: "-1px !important",
      caretColor: "transparent"
    },
    "& .react-datepickerClass__navigation": {
      height: "22px !important",
      width: "22px !important",
      margin: "5px -2px !important",
      caretColor: "transparent"
    },
    "& .react-datepickerClass__day--keyboard-selected": {
      color: "#000 !important",
      backgroundColor: "white !important",
      caretColor: "transparent"
    },
    "& .react-datepickerClass__day--today": {
      color: "#1d5d90 !important",
      backgroundColor: "white !important",
      fontWeight: "bold !important",
      caretColor: "transparent"
    },
    "& .react-datepickerClass__day:hover": {
      color: "#ccc !important",
      backgroundColor: "white !important",
      caretColor: "transparent"
    },
  },
  registerNewHpDateLabelNew: {
    fontSize: "18px !important",
    fontFamily: "SF Pro Text Regular !important",
    margin: "4px 0px 0px !important",
    textAlign: "left !important" as textAlignType,
    caretColor: "transparent !important",
    color:"#292929"
  },
  boxNewContainer: {
    marginLeft:"58px"
  },
  dateInputNew: {
    "& input": {
      cursor: "pointer",
      background: `url(${CalenderIcon})left / contain no-repeat`,
      backgroundPosition: "12px",
      backgroundSize: "25px",
      width: "356px",
      height: "50px",
      border: "1px solid #a5a1a1",
      color: "#000000",
      fontSize: "1em",
      fontWeight: 500,
      display: "flex",
      margin: "auto",
      caretColor:"transparent"
    },
    width: "100%",
    height: "100%"
  },
  appointementProfileHeading: {
    width: "100%",
    fontSize: "28px",
    marginBottom: "15px",
    fontFamily: "SF Pro Text Bold",
    color: "#292929",
    caretColor: "transparent"
  },
  removeProfileBtn: {
    width: "100px",
    height: "42px",
    color: "#014866",
    background: "transparent",
    border: "1px solid #014866",
    borderRadius:"8px",
    textTransform: "capitalize" as textTransform,
    caretColor:"transparent",
    "&:hover": {
      color: "#014866",
      background: "transparent",
    }
  },
  cancelRemoveProfileBtn: {
      width: "100px",
      height: "42px",
      backgroundColor: "#014866",
      color: "#fff",
      border: "none",
      textTransform: "capitalize" as textTransform,
      borderRadius:"8px",
      caretColor:"transparent",
      "&:hover": {
        backgroundColor: "#014866",
        color: "#fff"
      }
  },
  removeProfileDialog:{
    "& .MuiDialog-root":{
      width: "420px",
      height: "263px",
      margin: "auto",
      borderRadius:"8px",
      caretColor:"transparent"
    },
    "& .MuiDialog-paperWidthSm":{
      borderRadius:"10px"
    }
  },
  txtContainer: {
    margin: "50px 25px 25px", 
    width: "370px",
    height: "188px",
    caretColor:"transparent"
  },
  removeProfileText:{
    fontFamily:"SF Pro Text Bold",
    fontSize:"20",
    color:"#292929",
    textAlign:"center"
  },
  removeBtnContainer:{
    display: "flex",
    justifyContent: "center", 
    gap: "36px", 
    marginTop: "40px"
  },
  scheduler:{
    marginTop: "30px",
    marginBottom: "50px",
    fontFamily: "SF Pro Text Medium",
    fontSize: "16px",
    color: "#292929"
  },
  header: {
    fontSize: "16px",
    marginBottom: "20px",
    caretColor:"transparent"
  },
  timeSlots: {
    display: "flex",
    flexDirection: "column"
  },
  timeSlot: {
    border: "1px solid #ccc",
    padding: "10px",
    marginBottom: "5px",
    backgroundColor: "#f8f8f8",
    marginTop: "5px",
    caretColor:"transparent"
  },
  timeSlotBooked: {
    backgroundColor: "#e3f2fd"
  },
  bookingTrue: {
    borderLeft: "2px solid #014866",
    backgroundColor: "#D9E4E8",
    border: "none",
    padding: "10px",
    borderBottom:"none",
    borderTopRightRadius:"8px",
    borderBottomRightRadius:"8px",
    alignContent: "center",
    paddingLeft: "35px",
    fontSize: "18px",
    color: "#292929",
    fontFamily: "SF Pro Text Medium",
    caretColor:"transparent"
  },
  bookingFalse: {
    borderLeft: "2px solid #979797 ",
    borderBottom:"none",
    backgroundColor: "#dfdddd",
    opacity:20,
    border: "none",
    padding: "10px",
    borderTopRightRadius:"8px",
    borderBottomRightRadius:"8px",
    alignContent: "center",
    paddingLeft: "35px",
    fontSize: "18px",
    color: "#292929",
    fontFamily: "SF Pro Text Regular",
    caretColor:"transparent"
  },
  slotFree: {
    borderLeft: "2px dashed #D8D8D8 ",
    backgroundColor: "transparent",
    border: "2px dashed #D8D8D8",
    padding: "10px",
    borderRight:"none",
    caretColor:"transparent"
  },
  time: {
    fontSize:"16px",
    fontFamily:"SF Pro Text Medium",
    color:"#292929",
    caretColor:"transparent"
  },
  acceptedTime: {
    fontSize:"12px",
    fontFamily:"SF Pro Text Regular",
    color:"#014866",
    caretColor:"transparent"
  },
  registerAppointmentHeading:{
    fontSize:"28px",
    fontFamily:"SF Pro Text Bold",
    color:"#000000",
    caretColor:"transparent"
  }
  // Customizable Area End
});