import * as React from "react";
// Customizable Area Start
import { withStyles, Box, Typography, Grid, Button, createTheme, ThemeProvider, TextField, InputAdornment, TableContainer, Table, TableBody, TableRow, TableCell, Checkbox, SvgIcon, FormHelperText} from "@material-ui/core";
import CustomisableUserProfilesController, {
    Props
} from "./CustomisableUserProfilesController";
import { styles } from "./styles/CustomisableUserProfiles";
import { search_Icon } from "./assets"
import HospitalProfileWrapper from "./HospitalProfileWrapper.web";
import Loader from "../../../components/src/Loader.web";
import SidebarNotificationWrapper from "../../notifications/src/Notifications.web";

const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff"
        }
    }
});

const CustomCheckboxIconCheckeds = () => (
  <SvgIcon viewBox="0 0 18 18"  width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="15" height="15" rx="1.5" stroke="#014866" />
    <path d="M3.75 7.99992L6.58 10.8299L12.25 5.16992" stroke="#014866" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
   </SvgIcon>
);

const CustomCheckboxIconUnCheckeds = () => (
    <SvgIcon viewBox="0 0 18 18"  width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.5" y="0.5" width="15" height="15" rx="1.5" stroke="#014866" />
    </SvgIcon>
);
// Customizable Area End

export class CustomisableViewHospitalProfile extends CustomisableUserProfilesController {
    constructor(props: Props) {
        super(props);
    }

    // Customizable Area Start

    getRowStyles = (index: number, idSelected: boolean) => {
        return {
          padding: "5px 0px",
          borderBottom: "1px solid #ddd",
          fontSize: "15px",
          fontWeight: idSelected ? 600 : 500,
          width:"14px",
        };
      };
    
      getSelectedField = (index: number, idSelected: boolean) => {
        return {
          padding: "5px 0px",
          borderBottom: "1px solid #ddd",
          fontSize: "16px",
          fontWeight: idSelected ? 600 : 500,
          fontFamily: idSelected ? "SF Pro Text Medium" : "SF Pro Text Regular",
        };
      };
    
      showSuccessMessage = (message: string | undefined, success: any) => {
        return (message ? <FormHelperText className={success}>{message}</FormHelperText> : null)
      };
    // Customizable Area End

    render() {
        // Customizable Area Start
        const { classes } = this.props;
        const { district, postalCode, address, city } = this.state;
        const fullAddress = [address, city, district, postalCode];
        const hospitalAddress = fullAddress.filter(com => com).join(', ');
        // Customizable Area End
        return (
            // Customizable Area Start
            <>
                <ThemeProvider theme={theme}>
                    <Box style={webStyle.container} className={classes.leftWrapper}>
                        <SidebarNotificationWrapper hideNotification {...this.props}>
                            <Box style={webStyle.rightWrapper} className={classes.rightWrapper}>
                                <HospitalProfileWrapper data-test-id='profile-wrapper' {...this.props} profilePageTitle="My Profile" >
                                <Box>
                                    <Grid container>
                                        <Grid container className={classes.hospitalViewContainer}>
                                        <Grid item xl={6} md={6} sm={6} xs={12}>
                                                <Box className={classes.sideBorder}>
                                                    <Box className={classes.topLeftContainer}>
                                                        <Box className={classes.hospitalProfileDetailsContainer}>
                                                            <Typography variant="h6" className={classes.userName}>
                                                                {this.state.user_name}
                                                            </Typography>
                                                            <Typography variant="h6" className={classes.hospitalPhoneNumber}>
                                                            {this.state.city} | {this.state.phoneNumber}
                                                            </Typography>
                                                        </Box>
                                                    </Box>
                                                    <Box className={classes.hospitalSubContainer}>
                                                        <Typography className={classes.hospitalHeading}>
                                                            Registration No
                                                        </Typography>
                                                        <Typography className={classes.hospitalDetails}>
                                                            {this.state.registrationNo}
                                                        </Typography>
                                                    </Box>
                                                    <Box className={classes.hospitalSubContainer}>
                                                        <Typography className={classes.hospitalHeading}>
                                                            Email Address
                                                        </Typography>
                                                        <Typography className={classes.hospitalDetails}>
                                                            {this.state.email}
                                                        </Typography>
                                                    </Box>
                                                    <Box className={classes.hospitalAddressContainer}>
                                                        <Typography className={classes.hospitalHeading}>
                                                            Address
                                                        </Typography>
                                                        <Typography className={classes.hospitalDetails}>
                                                        {`${hospitalAddress}`}
                                                        </Typography>
                                                    </Box>
                                                    <Box className={classes.buttonContainer}>
                                                        <Box className={classes.hospitalContainer}>
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                data-test-id="availableDepart"
                                                                className={this.state.isDepartment? classes.hospitalDepartmentButtons:classes.hospitalInsuranceButtons}
                                                                onClick={this.openAvailableDepartmentView}
                                                            >
                                                                Available Departments
                                                            </Button>
                                                        </Box>
                                                        <Box className={classes.hospitalContainer}>
                                                            <Button
                                                                variant="contained"
                                                                data-test-id="availableInsurance"
                                                                color="primary"
                                                                className={!this.state.isDepartment? classes.hospitalDepartmentButtons:classes.hospitalInsuranceButtons}
                                                                onClick={this.openInsuranceView}
                                                            >
                                                                Available Insurance Scheme
                                                            </Button>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                        </Grid>
                                        <Grid item xl={6} md={6} sm={6} xs={12}>
                                                {this.state.isInsurance && (<Box className={classes.listContainer}>
                                                    {this.showSuccessMessage(this.state.successMessage, classes.uploadInputSuccess)}
                                                    {this.showSuccessMessage(this.state.errorMessage, classes.uploadInputError)}
                                                    <Box className={classes.selectInsurance}>
                                                        <TextField
                                                            data-test-id="searchInsurance"
                                                            variant="outlined"
                                                            placeholder="Search insurance here.."
                                                            className={classes.insuranceSearchField}
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <InputAdornment position="start">
                                                                        <img
                                                                            src={search_Icon}
                                                                            className={classes.searchIcon}
                                                                        />
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                            value={this.state.search}
                                                            onChange={(event) => this.handleSearch(event.target.value.trimStart())}
                                                        />
                                                    </Box>
                                                    <Box className={classes.tablePopoverContainer}>
                                                        <TableContainer className={classes.tablecontainer}>
                                                            <Table>
                                                                <TableBody>
                                                                    {this.state.insurancesList?.filter((insurance: any) => insurance.attributes.name.toLowerCase().includes(this.state.search.toLowerCase())).map((insurance: { id: string, attributes: { name: string }; }, index: number) => (
                                                                        <TableRow key={index}>
                                                                            <TableCell style={this.getRowStyles(index, false)}>
                                                                                <Checkbox
                                                                                    data-test-id={`selectInsurance${index}`}
                                                                                    className={classes.hospitalCheckbox}
                                                                                    checked={this.state.insurances?.includes(parseInt(insurance.id))} 
                                                                                    onChange={() => this.handleInsuranceCheckboxChange(parseInt(insurance.id))}
                                                                                    icon={<CustomCheckboxIconUnCheckeds />}
                                                                                    checkedIcon={<CustomCheckboxIconCheckeds />}
                                                                                />
                                                                            </TableCell>
                                                                            <TableCell style={this.getSelectedField(index, this.state.insurances.includes(parseInt(insurance.id)))}>{insurance?.attributes?.name}</TableCell>
                                                                        </TableRow>
                                                                    ))}

                                                                </TableBody>
                                                            </Table>
                                                        </TableContainer>
                                                    </Box>
                                                    <Box className={classes.selectPopoverContainer}>
                                                        <Button
                                                            data-test-id="saveInsuranceDetails"
                                                            variant="contained"
                                                            color="primary"
                                                            className={classes.selectButtons}
                                                            style={{ textTransform: "capitalize" }}
                                                            onClick={()=>this.isMatchFound(this.state.insurancesList)}
                                                        >
                                                            Save
                                                        </Button>
                                                    </Box>
                                                </Box>)}
                                                {this.state.isDepartment&& (<Box className={classes.listContainer}>
                                                    {this.showSuccessMessage(this.state.successMessage, classes.uploadInputSuccess)}
                                                    {this.showSuccessMessage(this.state.errorMessage, classes.uploadInputError)}
                                                    <Box className={classes.selectInsurance}>
                                                        <TextField
                                                            data-test-id="searchDepartments"
                                                            variant="outlined"
                                                            placeholder="Search departments here.."
                                                            className={classes.insuranceSearchField}
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <InputAdornment position="start">
                                                                        <img
                                                                            src={search_Icon}
                                                                            className={classes.searchIcon}
                                                                        />
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                            value={this.state.search}
                                                            onChange={(event) => this.handleSearch(event.target.value.trimStart())}
                                                        />
                                                    </Box>
                                                    <Box className={classes.tablePopoverContainer}>
                                                        <TableContainer className={classes.tablecontainer}>
                                                            <Table>
                                                                <TableBody>
                                                                    {this.state.departmentsList?.filter((department: any) => department.attributes.name.toLowerCase().includes(this.state.search.toLowerCase())).map((department: { id: string, attributes: { name: string }; }, index: number) => (
                                                                        <TableRow key={index}>
                                                                            <TableCell style={this.getRowStyles(index, false)}>
                                                                                <Checkbox
                                                                                    data-test-id={`selectDepartment${index}`}
                                                                                    className={classes.hospitalCheckbox}
                                                                                    checked={this.state.departments?.includes(parseInt(department.id))} 
                                                                                    onChange={() => this.handleDepartmentCheckboxChange(parseInt(department.id))}
                                                                                    icon={<CustomCheckboxIconUnCheckeds />}
                                                                                    checkedIcon={<CustomCheckboxIconCheckeds />}
                                                                                />
                                                                            </TableCell>
                                                                            <TableCell style={this.getSelectedField(index, this.state.departments.includes(parseInt(department.id)))}>{department?.attributes?.name}</TableCell>
                                                                        </TableRow>
                                                                    ))}

                                                                </TableBody>
                                                            </Table>
                                                        </TableContainer>
                                                    </Box>
                                                    <Box className={classes.selectPopoverContainer}>
                                                        <Button
                                                            data-test-id="saveDepartements"
                                                            variant="contained"
                                                            color="primary"
                                                            className={classes.selectButtons}
                                                            style={{ textTransform: "capitalize" }}
                                                            onClick={()=>this.isMatchFound(this.state.departmentsList)}
                                                        >
                                                            Save
                                                        </Button>
                                                    </Box>
                                                </Box>
                                                )}
                                        </Grid>
                                        </Grid>
                                    </Grid>
                                </Box >
                                <Loader loading={this.state.isLoading}/>
                                </HospitalProfileWrapper>
                            </Box>
                        </SidebarNotificationWrapper>
                    </Box>
                </ThemeProvider>
            </>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
export default withStyles(styles)(CustomisableViewHospitalProfile);

const webStyle = {
    sidebarWrapper: {
        width: '132px',
        minHeight: 'max(100vh, 700px)',
    },
    container: {
        height: "100%",
        display: 'flex',
        justifyContent: 'space-between',
        minHeight: "100vh",
        overflowX: "hidden" as const,
    },
    rightWrapper: {
        minHeight: '100vh',
        width: '100%',
    },
    contentWrapper: {
        padding: '94px 60px 72px 60px',
        maxWidth: '55%',
    },
}
// Customizable Area End