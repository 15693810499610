import { Box, Divider, IconButton, Typography } from "@material-ui/core";
import CustomisableUserProfilesController, { configJSON } from "./CustomisableUserProfilesController";
import { Edit, Notification, Setting } from "./assets";
import React from 'react'
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import CustomStyledButton from "../../../components/src/CustomStyledButton.web";
import Loader from "../../../components/src/Loader.web";
import { getStorageData } from "../../../framework/src/Utilities";

export default class HospitalProfileWrapper extends CustomisableUserProfilesController {

    async componentDidMount() {
        const userType = await getStorageData('userType');
        this.setState({ userType })
    }

    renderPopover = () => {
        const boundingRect = this.state.settingPopoverEl?.getBoundingClientRect();
        return this.state.settingPopoverEl && <>
            <div style={webStyle.overlay} data-test-id='overlay' onClick={this.closeSettingsPopover}></div>
            <Box data-test-id='popover' style={{ ...webStyle.filterModalWrapper, top: `${boundingRect && boundingRect.bottom + window.scrollY - 150}px`, right: `${window.innerWidth - Number(boundingRect && boundingRect.left) - 207}px` }}>
                <Box style={webStyle.settingsOuterWrapper} >
                    <Box style={webStyle.settingsInnerWrapper}>
                        <Typography style={webStyle.settingsTitle}>{configJSON.settingsItems.Settings} </Typography>
                        {this.state.userType === 'hospital' && <>
                            <Typography style={webStyle.settingsItem} data-test-id="goto-manage" onClick={this.navigateFromSettings(configJSON.settingsPageName.manage)} >{configJSON.settingsItems.Manage}</Typography>
                            <Divider style={webStyle.divider} />
                        </>}
                        <Typography style={webStyle.settingsItem} data-test-id='goto-about' onClick={this.navigateFromSettings(configJSON.settingsPageName.aboutUs)} >{configJSON.settingsItems.AboutUs}</Typography>
                        <Divider style={webStyle.divider} />
                        <Typography style={webStyle.settingsItem} data-test-id='termsandcondition' onClick={this.navigateFromSettings(configJSON.settingsPageName.termsCondition)}>{configJSON.settingsItems.TermsConditions}</Typography>
                        <Divider style={webStyle.divider} />
                        <Typography style={webStyle.settingsItem} data-test-id='privacy-policy' onClick={this.navigateFromSettings(configJSON.settingsPageName.privacyPolicy)}>{configJSON.settingsItems.PrivacyPolicy}</Typography>
                        <Divider style={webStyle.divider} />
                        <Typography style={webStyle.settingsItem} data-test-id='help' onClick={this.navigateFromSettings(configJSON.settingsPageName.help)} >{configJSON.settingsItems.Help}</Typography>
                        <Box style={webStyle.actionButtonWrapper} >
                            <CustomStyledButton
                                isSecondary
                                data-test-id="deactivate"
                                onClick={this.navigateFromSettings('deactivate')}
                                label={configJSON.ButtonLabel.DeactivateAccount}
                            />
                            <CustomStyledButton
                                data-test-id="logout"
                                onClick={this.handleLogout}
                                label={configJSON.ButtonLabel.Logout}
                            />
                        </Box>
                    </Box>
                </Box>
            </Box>
        </>
    }
    render() {
        return <>
            <Box>
                <Box sx={webStyle.pageTitleWrapper}>
                    {this.props.profileBackBtn && <IconButton data-test-id="backBtn" onClick={() => this.goToBlock(this.getBackPagesPath(this.state.userType))}>
                        <Box style={webStyle.hospitalEditBackButtons}>
                            <ArrowBackIosIcon style={webStyle.arrowIcon} />
                        </Box>
                    </IconButton>}
                    <Typography style={webStyle.profileHospitalHeading} variant="h6">
                        {this.props.profilePageTitle}
                    </Typography>
                </Box>
                <Box style={webStyle.profileBarWrapper}>
                    <Box style={webStyle.hospitalProfileViewContainer}>
                        <Box style={webStyle.profileSectionWrapper} >
                            <Box style={webStyle.editIconsConatiner}>
                                <Box>
                                    <img style={webStyle.avatarEdit} src={this.props.profilePicture ?? this.state.profilePicture} />
                                    {this.props.openProfileSelectModal && <img
                                        data-test-id="editProfile"
                                        style={webStyle.avatarEditIcon}
                                        src={Edit}
                                        onClick={this.props.openProfileSelectModal}
                                    />}
                                </Box>
                                {!this.props.hideProfileActionBtns && <Box style={webStyle.iconsContainer}>
                                    <Box data-test-id="editProfile-page" onClick={() => this.openHospitalEditProfiles(this.state.userType)}>
                                        <img style={webStyle.iconStyle} src={Edit} />
                                    </Box>
                                    <Box>
                                        <IconButton onClick={() => this.goToBlock('Dashboard')}>
                                            <img style={webStyle.iconStyle} src={Notification} />
                                        </IconButton>
                                    </Box>
                                    <Box >
                                        <IconButton data-test-id="open-settings" onClick={this.handleOpenSettings} style={this.state.settingPopoverEl ? webStyle.activeIcon : webStyle.settingIcon}>
                                            <img style={webStyle.iconStyle} src={Setting} />
                                        </IconButton>
                                    </Box>
                                    {this.renderPopover()}
                                </Box>}
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box>
                    {this.props.children}
                </Box>
                <Loader loading={this.state.isLoading} />
            </Box>
        </>
    }
}

const webStyle = {
    filterModalWrapper: {
        position: "absolute",
        zIndex: 200,
        right: 0,
        backgroundColor: 'white',
        borderRadius: '10px',
        overflow: 'hidden',
    } as React.CSSProperties,
    overlay: {
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        position: 'fixed',
        top: 0,
        bottom: 0,
        right: 0,
        width: '100%',
        zIndex: 100
    } as React.CSSProperties,
    paper: {
        borderRadius: '25px'
    },
    backDrop: {
        backgroundColor: 'rgba(0, 0, 0, 0.8)'
    },
    divider: {
        backgroundColor: "#F0F0F0"
    },
    settingsTitle: {
        color: "#292929",
        fontSize: '24px',
        fontFamily: 'SF Pro Text Bold',
        textAlign: 'center',
        marginBottom: '14px'
    } as React.CSSProperties,
    settingsItem: {
        color: '#292929',
        fontSize: '20px',
        fontFamily: "SF Pro Text Regular",
        textAlign: 'center',
        lineHeight: '64px',
        cursor: 'pointer'
    } as React.CSSProperties,
    settingsOuterWrapper: {
        width: '390px',
        background: 'white'
    },

    settingsInnerWrapper: {
        border: '1px solid #EDEDED',
        padding: '32px 24px',
        borderRadius: '25px',
        boxShadow: "0px 2px 24px 0px #2C2C2C0A"
    },

    actionButtonWrapper: {
        display: 'flex',
        flexDirection: 'column',
        gridRowGap: '24px',
        marginTop: '42px'
    } as React.CSSProperties,

    secondaryBtn: {
        margin: '0 auto 0',
        width: '100%',
        maxWidth: '300px',
        border: "1px solid #014866",
        height: '56px',
        color: '#014866',
        fontSize: '18px',
        fontFamily: 'SF Pro Text Bold',
        borderRadius: '8px',
        backgroundColor: 'white',
        textTransform: 'capitalize'
    } as React.CSSProperties,

    primaryBtn: {
        margin: '0 auto 0',
        width: '100%',
        maxWidth: '300px',
        height: '56px',
        fontSize: '18px',
        fontFamily: 'SF Pro Text Bold',
        borderRadius: '8px',
        backgroundColor: '#014866',
        color: 'white',
        textTransform: 'capitalize'
    } as React.CSSProperties,

    profileSectionWrapper: {
        display: "flex",
        justifyContent: "space-between",
        position: "relative" as const,
        background: "#dce7fa;",
        zIndex: 1,
        height: "130px"
    },
    iconsContainer: {
        display: "flex",
        alignItems: 'center',
        padding: "32px 0px",
        gap: "40px",
        position: 'relative'
    } as React.CSSProperties,
    iconStyle: {
        width: "30px",
        height: "30px",
        cursor: "pointer"
    },
    avatarEdit: {
        borderRadius: "50%",
        width: "170px",
        height: "170px",
        border: "1px solid #eeeaea",
        backgroundColor: "#eeeaea",
        zIndex: 1,
        cursor: "pointer"
    },
    editIconsConatiner: {
        display: "flex",
        justifyContent: "space-between",
        padding: "30px 140px 0 60px",
        position: "relative" as const,
        background: "#dce7fa",
        zIndex: 1,
        height: "100%",
        boxSizing: 'border-box',
        width: "100%"
    } as React.CSSProperties,
    hospitalProfileViewContainer: {
        position: "relative" as const,

    },
    profileHospitalHeading: {
        width: "100%",
        fontWeight: 600,
        fontSize: "28px",
        fontFamily: "SF Pro Text Bold",
        caretColor: "transparent"
    },
    hospitalEditBackButtons: {
        display: "flex",
        alignItems: "center",
        width: "50px",
        height: "50px",
        backgroundColor: "rgba(255, 255, 255, 0.3)",
        border: "1px solid #E9E9E9",
        padding: "10px",
        justifyContent: "center",
        borderRadius: "8px",
        boxSizing: 'border-box'
    } as React.CSSProperties,
    arrowIcon: {
        height: "16px",
        caretColor: "white",
        weight: "8px"
    },
    avatarEditIcon: {
        top: 0,
        left: "188px",
        width: "20px",
        height: "20px",
        zIndex: 1,
        position: "absolute" as const,
        marginTop: "52px",
        background: "white",
        padding: "10px",
        borderRadius: "18px",
        cursor: "pointer"
    },
    pageTitleWrapper: {
        display: 'flex',
        alignItems: 'center',
        height: '140px',
        paddingLeft: '60px',
        gridColumnGap: '30px'
    },
    profileBarWrapper: {
        width: "100%"
    },
    activeIcon: {
        backgroundColor: 'white',
        padding: '8px',
        borderRadius: '10px',
        position: 'absolute',
        zIndex: 200,
        width: '40px',
        height: '40px',
        top: '30%'
    } as React.CSSProperties,
    settingIcon: {
        position: 'absolute',
        zIndex: 200,
        borderRadius: '8px',
        width: '40px',
        height: '40px',
        top: '30%'
    } as React.CSSProperties,
}
