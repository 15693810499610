import React from "react";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { Message } from "../../../framework/src/Message";
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import moment from "moment";
export const configJSON = require("./config");

export enum FilterMode {
    INCOMING = 'incoming',
    UPCOMING = 'upcoming',
    PAST = 'past',
    NONE = 'none',
}

export enum ManageView {
    BED = 'bed',
    HEALTHCARE_PERSON = 'healthcare-person',
}

export enum ModalType {
    PATIENT_DETAILS = 'patient-details',
    MEDIC_BOOKING_DETAILS = 'medic-booking-details',
    HEALTH_DETAILS = 'health-details',
    INSURANCE_MODAL = 'insurance-modal',
}

export interface Props {
    navigation: any;
    id: string;
    classes: any;
}


interface BedBookingsResponse {
    bed_bookings: {
        data: BedBooking[];
    };
    meta: MetaData;
}

interface BedBooking {
    id: string;
    type: string;
    attributes: BedBookingAttributes;
}

interface BedBookingAttributes {
    status: string;
    is_paid: boolean;
    patient_name: string;
    patient_profile_image: string | null;
    bed_type: string;
    bed_category: string;
}

interface MetaData {
    message: string;
    total_count: number;
    page: number;
    per_page: number;
}

interface PatientHealthDetails {
    booking: {
        data: PatientDetails;
    };
    meta: {
        message: string;
    };
}

interface PatientDetails {
    id: string;
    type: string;
    attributes: BookingAttributes;
}

interface BookingAttributes {
    date: string;
    bed_id: number;
    status: string;
    is_paid: boolean;
    patient_name: string;
    patient_age: number;
    patient_gender: string;
    patient_profile_image: string;
    bed_type: string;
    bed_name: string;
    hospital_departments: HospitalDepartment[];
}

interface HospitalDepartment {
    id: number;
    name: string;
    created_at: string;
    updated_at: string;
}


interface HealthDetailResponse {
    data: {
        id: string;
        type: string;
        attributes: PatientHealthAttributes;
    };
}

interface PatientHealthAttributes {
    id: number;
    patient_info: PatientInfo;
    diseases: Disease[];
    medicines: Medicine[];
    surgeries: Surgery[];
    allergies: Allergy[];
    prescriptions: Prescription[];
    reports: Report[];
}

interface PatientInfo {
    height: number | null;
    weight: number | null;
    previous_hospital: boolean;
    smoke: boolean;
    smoke_months: number | null;
    smoke_years: number | null;
    drink: boolean;
    drink_months: number | null;
    drink_years: number | null;
}

interface Disease {
    id: number;
    disease_name: string;
    disease_months: string | null;
    disease_years: number | null;
    custom_disease: string | null;
}

type Medicine = { id: number, name: string, days: number };
type Surgery = { id: number, name: string, surgery_months: string };
type Allergy = { id: number, name: string };
type Prescription = { id: number, url: string };
type Report = { id: number, url: string };


interface InsuranceList {
    id: number;
    name: string;
    created_at: string;
    updated_at: string;
}

interface MedicBookingResponse {
    medic_bookings: {
        data: BookingData[];
    };
    meta: {
        message: string;
        total_count: number;
        page: number;
        per_page: number;
    };
}

interface BookingData {
    id: string;
    type: string;
    attributes: {
        status: string;
        is_paid: boolean;
        start_time: string;
        end_time: string;
        slot_time: string;
        date: string;
        day: string;
        patient_name: string;
        patient_profile_image: string;
        doctor_name: string;
    };
}

interface MedicBookingDetailsResponse {
    booking: {
        data: {
            id: string;
            type: string;
            attributes: {
                date: string;
                start_time: string;
                end_time: string;
                slot_time: string;
                status: string;
                problem: string;
                patient_name: string;
                patient_age: number;
                patient_gender: string;
                patient_image: string;
                healthcare_personnel_name: string;
                healthcare_personnel_number: number;
                healthcare_personnel_full_number: string;
                healthcare_personnel_department: string;
                healthcare_personnel_specialization: string;
            };
        };
    };
    meta: {
        message: string;
    };
}



interface S {
    manageView: ManageView;
    modalType: ModalType
    modalOpen: boolean;
    selectedDate: Date;
    filterPopoverEl: HTMLButtonElement | null;
    filterMode: FilterMode;
    selectedFilter: FilterMode;
    date: Date;
    isOpen: boolean;
    showPopup: boolean;
    filterDate: string;
    currentPage: number;
    bedBookingList: BedBooking[];
    totalBedCount: number;
    prevScrollTop: number;
    patientDetails: PatientDetails | null;
    healthDetails: HealthDetailResponse | null;
    insuranceList: InsuranceList[];
    healthcarePersonBookingList: BookingData[];
    totalBookedHealthcarePerson: number;
    isListLoading: boolean;
    isDataEmpty: boolean;
    disableBtnId: string | undefined;
    medicBookingDetails: MedicBookingDetailsResponse | null,
    isLoading: boolean
}

interface SS {
    id: number;
}

export default class OrderManagementController extends BlockComponent<
    Props,
    S,
    SS
> {
    scrollContainerRef: React.RefObject<HTMLDivElement> = React.createRef();
    getBedListApiCallId: string = "";
    getHealthcarePersonListApiCallId: string = "";
    getPatientHealthDetailsApiCallId: string = "";
    getHealthDetailsApiCallId: string = "";
    getInsuranceListApiCallId: string = "";
    getMedicBookingDetailsApiCallId: string = "";
    getBedActionBookingApiCallId: string = "";
    getMedicActionBookingApiCallId: string = "";
    getMedicBookingHealthDetailsApiCallId: string = "";

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
        ];

        this.state = {
            manageView: ManageView.BED,
            modalType: ModalType.PATIENT_DETAILS,
            modalOpen: false,
            selectedDate: new Date(),
            filterPopoverEl: null,
            filterMode: FilterMode.INCOMING,
            selectedFilter: FilterMode.INCOMING,
            date: new Date(),
            isOpen: false,
            showPopup: false,
            filterDate: moment(new Date()).format('DD-MM-YYYY'),
            currentPage: 1,
            bedBookingList: [],
            prevScrollTop: 0,
            totalBedCount: 0,
            patientDetails: null,
            healthDetails: null,
            insuranceList: [],
            healthcarePersonBookingList: [],
            totalBookedHealthcarePerson: 0,
            isListLoading: false,
            isDataEmpty: false,
            disableBtnId: '',
            medicBookingDetails: null,
            isLoading: false,
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        const currentView = await getStorageData('booking-list-view');
        if (currentView) {
            this.setState({ manageView: currentView }, () => {
                this.loadDataBasedOnView()
            })
        } else {
            await setStorageData('booking-list-view', ManageView.BED)
            this.getBedList()
        }
    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            this.handleResponse(message);
        }
    }

    handleResponse = (message: Message) => {
        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );
        const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        this.hideLoader()
        switch (apiRequestCallId) {
            case this.getBedListApiCallId:
                const bedListResp = responseJson as BedBookingsResponse;
                this.setState((prevState) => {
                    return {
                        isListLoading: false,
                        isDataEmpty: bedListResp.bed_bookings.data.length === 0,
                        bedBookingList: [...prevState.bedBookingList, ...bedListResp.bed_bookings.data],
                        totalBedCount: bedListResp.meta.total_count
                    }
                })
                break;
            case this.getHealthcarePersonListApiCallId:
                const healthCarePersonList = responseJson as MedicBookingResponse;
                this.setState((prevState) => {
                    return {
                        isListLoading: false,
                        isDataEmpty: healthCarePersonList.medic_bookings.data.length === 0,
                        healthcarePersonBookingList: [...prevState.healthcarePersonBookingList, ...healthCarePersonList.medic_bookings.data],
                        totalBookedHealthcarePerson: healthCarePersonList.meta.total_count
                    }
                })
                break;
            case this.getPatientHealthDetailsApiCallId:
                const patientDetails = responseJson as PatientHealthDetails;
                this.setState({ patientDetails: patientDetails.booking.data }, () => {
                    this.handleOpenPatientDetailsModal()
                })
                break;
            case this.getHealthDetailsApiCallId:
                const healthDetails = responseJson as HealthDetailResponse;
                this.setState({ healthDetails: healthDetails }, () => {
                    this.handleOpenHealthDetailsModalFromLink();
                })
                break;
            case this.getInsuranceListApiCallId:
                const insuranceList = responseJson as InsuranceList[];
                this.setState({ insuranceList: insuranceList }, () => {
                    this.handleOpenInsuranceModal();
                })
                break;
            case this.getBedActionBookingApiCallId:
                this.setState((prevState) => {
                    const updatedBedList = prevState.bedBookingList.filter((bed) => bed.id !== prevState.disableBtnId)
                    return { bedBookingList: updatedBedList, totalBedCount: prevState.totalBedCount - 1, disableBtnId: '' }
                })
                break;
            case this.getMedicActionBookingApiCallId:
                this.setState((prevState) => {
                    const updatedBedList = prevState.healthcarePersonBookingList.filter((medicBooking) => medicBooking.id !== prevState.disableBtnId)
                    return { healthcarePersonBookingList: updatedBedList, totalBookedHealthcarePerson: prevState.totalBookedHealthcarePerson - 1, disableBtnId: '' }
                })
                break;
            case this.getMedicBookingDetailsApiCallId:
                const medicBookinDetails = responseJson as MedicBookingDetailsResponse
                this.setState({ medicBookingDetails: medicBookinDetails, modalOpen: true, modalType: ModalType.MEDIC_BOOKING_DETAILS })
                break;
            case this.getMedicBookingHealthDetailsApiCallId:
                const medicBookingHealthDetails = responseJson as HealthDetailResponse;
                this.setState({ healthDetails: medicBookingHealthDetails }, () => {
                    this.handleOpenHealthDetailsModalFromLink();
                });
                break;
        }
    }

    onScroll = () => {
        const element = this.scrollContainerRef.current;
        if (element && !this.state.isListLoading) {
            const { scrollTop, scrollHeight, clientHeight } = element;
            const isContentRemaining = this.state.manageView === ManageView.BED ? this.state.bedBookingList?.length < this.state.totalBedCount : this.state.healthcarePersonBookingList?.length < this.state.totalBookedHealthcarePerson
            if (scrollTop > this.state.prevScrollTop && scrollTop + clientHeight >= scrollHeight - 50 && isContentRemaining) {
                this.setState(prevState => ({
                    currentPage: Math.max(1, prevState.currentPage + 1)
                }));
            }
            this.setState({ prevScrollTop: scrollTop });
        }
    };

    async componentDidUpdate(prevProps: Props, prevState: any) {
        const { currentPage } = this.state;
        if (prevState.currentPage !== currentPage) {
            try {
                if (!this.state.isDataEmpty && !this.state.isListLoading) { this.loadDataBasedOnView() }
            } catch (error) {
                this.showAlert("Error fetching hospital data:", "Something went wrong");
            }
        }
    }

    formatCustomDate = (date: any) => {
        if (!date || !(date instanceof Date)) {
            return '';
        } else {
            const formattedDate = new Date(date).toLocaleDateString('en-US', {
                weekday: 'long',
                day: 'numeric',
                month: 'long',
                year: 'numeric',
            });
            const day = date.getDate();
            return formattedDate.replace(`${day}`, `${day}`);;
        }
    }

    handleDateChange = (date: Date) => {
        this.setState({ selectedDate: date, filterDate: moment(date).format('DD-MM-YYYY'), totalBedCount: 0, totalBookedHealthcarePerson: 0, bedBookingList: [], healthcarePersonBookingList: [], currentPage: 1, prevScrollTop: 0 }, () => {
            this.loadDataBasedOnView()
        })
    }

    handleOpenFilter = (event: React.MouseEvent<HTMLButtonElement>) => {
        this.setState({ filterPopoverEl: event.currentTarget })
    }

    handleCloseFilter = () => {
        this.setState({ filterPopoverEl: null })
    }

    handleChangeFilter = (filterType: string) => {
        this.setState({ filterMode: filterType as FilterMode })
    }

    loadDataBasedOnView = () => {
        this.state.manageView === ManageView.BED ? this.getBedList() : this.getHealthcarePersonList()
    }

    handleSelectFilter = () => {
        this.setState({ selectedFilter: this.state.filterMode, filterPopoverEl: null, bedBookingList: [], totalBedCount: 0, totalBookedHealthcarePerson: 0, healthcarePersonBookingList: [] }, () => {
            this.loadDataBasedOnView()
        })
    }

    handleOpenBedView = () => {
        this.setState({ manageView: ManageView.BED, currentPage: 1, totalBookedHealthcarePerson: 0, healthcarePersonBookingList: [], prevScrollTop: 0 }, async () => {
            await setStorageData('booking-list-view', this.state.manageView);
            this.getBedList()
        })
    }

    handleOpenHealthCarePersonView = () => {
        this.setState({ manageView: ManageView.HEALTHCARE_PERSON, prevScrollTop: 0, currentPage: 1, totalBedCount: 0, bedBookingList: [] }, async () => {
            await setStorageData('booking-list-view', this.state.manageView)
            this.getHealthcarePersonList()
        })
    }

    handleCloseHealthDetailsModal = () => {
        this.setState({ modalType: this.state.manageView === ManageView.BED ? ModalType.PATIENT_DETAILS : ModalType.MEDIC_BOOKING_DETAILS })
    }

    handleClosePatientDetailsModal = () => {
        this.setState({ modalOpen: false })
    }

    handleCloseInsuranceModal = () => {
        this.setState({ modalType: ModalType.PATIENT_DETAILS })
    }

    handleOpenPatientDetailsModal = () => {
        this.setState({ modalOpen: true, modalType: ModalType.PATIENT_DETAILS })
    }

    handleOpenHealthDetailsModalFromLink = () => {
        this.setState({ modalOpen: true, modalType: ModalType.HEALTH_DETAILS })
    }

    handleOpenInsuranceModal = () => {
        this.setState({ modalOpen: true, modalType: ModalType.INSURANCE_MODAL })
    }

    handleBedBookingAction = async (bookingId: string | undefined, isAccept: boolean) => {
        this.setState({ disableBtnId: bookingId })
        const token = await getStorageData("authToken");
        const header = {
            "Content-Type": configJSON.apiContentType,
            token: token
        };


        const payload = {
            "data": {
                "type": "hospital_booking",
                "attributes": {
                    "status": isAccept ? "accepted" : "rejected"
                }
            }
        }

        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getBedActionBookingApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.bookingActionApiEndPoint}/${bookingId}`);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(payload));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.PATCH);
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleMedicBookingAction = async (bookingId: string | undefined, isAccept: boolean) => {
        this.setState({ disableBtnId: bookingId })
        const token = await getStorageData("authToken");
        const header = {
            "Content-Type": configJSON.apiContentType,
            token: token
        };


        const payload = {
            "data": {
                "type": "hospital_booking",
                "attributes": {
                    "status": isAccept ? "accepted" : "rejected"
                }
            }
        }

        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getMedicActionBookingApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.medicActionApiEndPoint}/${bookingId}`);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), JSON.stringify(payload));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.PATCH);
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleCustomDateChange = (customDate: any) => {
        this.setState({ date: customDate });
    }

    toggleDatePicker = () => {
        this.setState({ isOpen: !this.state.isOpen })
    }

    openProfile = () => {
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), "UserProfile");
        message.addData(
            getName(MessageEnum.NavigationPropsMessage),
            this.props
        );
        this.send(message);
    }

    backToOrderManagementScreen = () => {
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), "OrderManagementView");
        message.addData(
            getName(MessageEnum.NavigationPropsMessage),
            this.props
        );
        this.send(message);
    }

    togglePopup = () => {
        this.setState({ showPopup: !this.state.showPopup })
    }

    openLink = (url: string) => {
        window.open(url, "blank")
    }


    getMonth = (month: string | null): string => {
        const monthMap: Record<string, string> = {
            January: '01',
            February: '02',
            March: '03',
            April: '04',
            May: '05',
            June: '06',
            July: '07',
            August: '08',
            September: '09',
            October: '10',
            November: '11',
            December: '12',
        };

        return monthMap[month || ""] || '01';
    };

    showLoader = () => {
        this.setState({ isLoading: true })
    }

    hideLoader = () => {
        this.setState({ isLoading: false })
    }

    getBedList = async () => {
        this.setState({ isListLoading: true })
        const token = await getStorageData("authToken");
        const header = {
            "Content-Type": configJSON.apiContentType,
            token: token
        };

        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getBedListApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.BedListEndPoint}?filter=${this.state.selectedFilter}&date=${this.state.filterDate}&page=${this.state.currentPage}`);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.listOfOrdersMethod);
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getHealthcarePersonList = async () => {
        this.setState({ isListLoading: true })
        const token = await getStorageData("authToken");
        const header = {
            "Content-Type": configJSON.apiContentType,
            token: token
        };

        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getHealthcarePersonListApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.HealthCarePersonListEndPoint}?filter=${this.state.selectedFilter}&date=${this.state.filterDate}&page=${this.state.currentPage}`);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.listOfOrdersMethod);
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getPatientHealthDetails = async (bookingId: string | undefined) => {
        this.showLoader();
        const token = await getStorageData("authToken");
        const header = {
            "Content-Type": configJSON.apiContentType,
            token: token
        };

        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getPatientHealthDetailsApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.ViewPatientDetailsEndPoint}${bookingId}`);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.listOfOrdersMethod);
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }


    getHealthDetails = async (bookingId: string | undefined) => {
        this.showLoader();
        const token = await getStorageData("authToken");
        const header = {
            "Content-Type": configJSON.apiContentType,
            token: token
        };

        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getHealthDetailsApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.HealthDetailsEndPoint}${bookingId}`);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.listOfOrdersMethod);
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getInsuranceList = async (bookingId: string | undefined) => {
        this.showLoader();
        const token = await getStorageData("authToken");
        const header = {
            "Content-Type": configJSON.apiContentType,
            token: token
        };

        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getInsuranceListApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.InsuranceListEndPoint}${bookingId}`);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.listOfOrdersMethod);
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getMedicBookingDetails = async (bookingId: string | undefined) => {
        this.showLoader();
        const token = await getStorageData("authToken");
        const header = {
            "Content-Type": configJSON.apiContentType,
            token: token
        };

        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getMedicBookingDetailsApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.MedicBookingDetailsEndPoint}${bookingId}`);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.listOfOrdersMethod);
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getMedicBookingHealthDetails = async (bookingId: string | undefined) => {
        this.showLoader();
        const token = await getStorageData("authToken");
        const header = {
            "Content-Type": configJSON.apiContentType,
            token: token
        };

        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.getMedicBookingHealthDetailsApiCallId = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), `${configJSON.MedicBookingHealthDetailsEndPoint}${bookingId}`);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.listOfOrdersMethod);
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
}