import { Avatar, Box, Divider, IconButton, InputAdornment, styled, TextField, Typography } from "@material-ui/core";
import DashboardController, { configJSON } from "./DashboardController";
import { searchIcon } from "./assets";
import Skeleton from "@material-ui/lab/Skeleton";
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import React from 'react'

class AdvanceSearch extends DashboardController {
    renderDoctorList = () => {
        return this.state.doctorList.length > 0 ?
            this.state.doctorList.map((docData, indx) => <>
                <Box sx={webStyle.doctorCard} key={docData.id}>
                    <Box sx={webStyle.avatarNameWrapper}>
                        <Avatar style={webStyle.avatar} src={`${docData.attributes?.profile_image_url}`} alt="profile_image" />
                        <Box sx={webStyle.ratingWrapper}>
                            <Typography style={webStyle.doctorName}>{docData.attributes?.name}</Typography>
                            <Typography style={webStyle.availableSlots}>{configJSON.AvailableTimeSlots}</Typography>
                        </Box>
                    </Box>
                    <Box>
                        <Typography style={webStyle.rating}>4.2 / 5</Typography>
                        <Typography style={webStyle.speciality}>{docData.attributes?.profile_type_specific_data?.specialization?.name}</Typography>
                    </Box>
                </Box>
                {indx !== this.state.doctorList.length - 1 && <Divider />}
            </>) : this.renderNoSearchResult()
    }

    renderHealthCarePersonList = () => {
        return this.state.healthCarePersonList.length > 0 ?
            this.state.healthCarePersonList.map((docData, indx) => <>
                <Box sx={webStyle.doctorCard} key={docData?.id}>
                    <Box sx={webStyle.avatarNameWrapper}>
                        <Avatar style={webStyle.avatar} src={`${docData.attributes?.profile_image}`} alt="profile_image" />
                        <Box sx={webStyle.ratingWrapper}>
                            <Typography style={webStyle.doctorName}>{docData.attributes?.name}</Typography>
                            <Typography style={webStyle.speciality}>{docData.attributes?.profile_type}</Typography>
                        </Box>
                    </Box>
                </Box>
                {indx !== this.state.healthCarePersonList.length - 1 && <Divider />}
            </>) : this.renderNoSearchResult()
    }

    renderSkeletonLoader = () => {
        return <Box sx={webStyle.avatarNameWrapper}>
            <Skeleton variant="circle" style={webStyle.avatar} />
            <Box sx={webStyle.ratingWrapper}>
                <Skeleton variant="text" width={150} />
                <Skeleton variant="text" width={100} />
            </Box>
        </Box>
    }

    renderNoSearchResult = () => {
        return this.state.isAdvanceSearchLoading ? this.renderSkeletonLoader() : <Typography style={{ fontFamily: "SF Pro text Regular" }} >No healthcare person found</Typography>
    }
    render() {
        return <Box sx={webStyle.searchAndResultWrapper}>
            <StyledSearch
                fullWidth
                onBlur={this.removeListOnBlur}
                ref={this.searchInputRef}
                onChange={this.onSearch}
                onKeyDown={this.handleKeyDown}
                data-test-id="search-doctor"
                value={this.state.searchValue}
                variant="outlined"
                placeholder={configJSON.PlaceholderSearchDoctors}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <img
                                src={searchIcon}
                                alt="search"
                                style={webStyle.searchIcons}
                            />
                        </InputAdornment>
                    ),
                    endAdornment: (
                        <InputAdornment position="end">
                            {this.state.searchValue && <IconButton onClick={this.clearSearch}>
                                <CloseRoundedIcon />
                            </IconButton>}
                        </InputAdornment>
                    ),
                    style: {
                        borderRadius: "8px",
                    }
                }}
            />
            {this.state.isSearchActive && <div ref={this.scrollContainerRef} onScroll={this.onScroll} data-test-id="search-result" style={{ ...webStyle.searchResultWrapper, width: this.searchInputRef.current?.offsetWidth }}>
                {this.state.userType === configJSON.userType.Patient ? this.renderHealthCarePersonList() : this.renderDoctorList()}
            </div>
            }
        </Box>
    }
}

export default AdvanceSearch;

const StyledSearch = styled(TextField)({
    fontFamily: "SF Pro text Regular",
    "& .MuiInputBase-root.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #CBD5E1",
        borderRadius: "8px",
    },
    "& .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #014866",
        borderRadius: "8px",
    },
    "& .MuiInputBase-root.MuiOutlinedInput-root": {
        borderRadius: "8px",
        paddingLeft: "16px",
    },
    "& .MuiFormHelperText-root": {
        marginLeft: 0,
        fontFamily: "SF Pro text Regular",
    },
    '& .MuiOutlinedInput-adornedStart': {
        paddingLeft: '0'
    },
    '& .MuiOutlinedInput-inputAdornedStart': {
        paddingLeft: '10px'
    }
})

const webStyle = {
    searchResultWrapper: {
        position: 'absolute',
        zIndex: 1000,
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
        marginTop: '5px',
        backdropFilter: 'blur(32px)',
        boxShadow: "0px 2px 24px 0px #98989838",
        padding: "20px 25px",
        boxSizing: 'border-box',
        border: "1px solid #C2C2C24D",
        borderRadius: '8px',
        maxHeight: "376px",
        overflowY: "auto",
        scrollbarColor:"#d9d5d5 #fff",
        scrollbarWidth: "thin" as const,
    } as React.CSSProperties,
    doctorCard: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    avatarNameWrapper: {
        display: 'flex',
        alignItems: 'center',
        gridGap: '20px'
    },
    avatar: {
        width: '85px',
        height: '85px',
        textTransform: 'capitalize'
    } as React.CSSProperties,
    ratingWrapper: {
        display: 'flex',
        flexDirection: 'column'
    },
    availableSlots: {
        color: '#3780E0',
        fontSize: '14px',
        fontFamily: 'SF Pro Text Regular'
    },
    doctorName: {
        fontSize: '18px',
        fontFamily: 'SF Pro Text Bold',
        marginBottom: "8px"
    },
    speciality: {
        color: '#AC8E68',
        fontSize: '14px',
        fontFamily: 'SF Pro Text Regular',
        textTransform: 'capitalize'
    } as React.CSSProperties,
    searchAndResultWrapper: {
        width: '100%'
    },
    searchIcons: {
        width: "24px",
        objectFit: "cover" as const,
        height: "24px",
        display: "flex",
        justifyContent: "center",
        margin: "0px auto"
    },
    rating: {
        color: '#014866',
        fontSize: '14px',
        fontFamily: 'SF Pro Text Medium',
        textAlign: 'end',
        marginBottom: "8px"
    } as React.CSSProperties,
}