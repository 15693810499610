import React from "react";
// Customizable Area Start
import {
  Box,
  Button,
  withStyles,
} from "@material-ui/core";
import FilterController, { Props } from "./FilterController";
// Customizable Area End

export class OrderManagementFilter extends FilterController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <>
        <Box className={this.props.classes.dialogHpOverlay}/>
        <Box className={this.props.classes.dialogHpContainer}>
          <Box className={this.props.classes.popupFilter}>
            <Box style={{ display: "block", width: "100%" }}>
              <Box style={{ background:this.state.selectedFilters === "past" ? "#C3D9F6" : "", width: "343px", height: "71px", textAlign: "center", alignContent: "center", fontFamily: this.state.selectedFilters === "past" ? "SF Pro Text Semibold" : "SF Pro Text Regular", fontSize: "18px", color: "#292929", caretColor:"transparent",cursor:"pointer",borderTopLeftRadius:"8px",borderTopRightRadius:"8px"}} onClick={() => this.updateSelectedFilter("past")}>
                Past
              </Box>
              <Box style={{background:this.state.selectedFilters === "current" ? "#C3D9F6" : "", width: "343px", height: "71px", textAlign: "center", alignContent: "center", fontFamily: this.state.selectedFilters === "current" ? "SF Pro Text Semibold" : "SF Pro Text Regular", fontSize: "18px", color: "#292929", caretColor:"transparent", cursor:"pointer" }} onClick={() => this.updateSelectedFilter("current")}>
                Current
              </Box>
              <Box style={{background:this.state.selectedFilters === "upcoming" ? "#C3D9F6" : "", width: "343px", height: "71px", textAlign: "center", alignContent: "center", fontFamily: this.state.selectedFilters === "upcoming" ? "SF Pro Text Semibold" : "SF Pro Text Regular", fontSize: "18px", color: "#292929", caretColor:"transparent", cursor:"pointer" }} onClick={() => this.updateSelectedFilter("upcoming")}>
                Upcoming
              </Box>
              <Button
                  variant="contained"
                  color="primary"
                  className={this.props.classes.button} 
                  onClick={this.setFilter}>
                    Select
                </Button>
            </Box>
          </Box>
        </Box>
       </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyles = {
  dialogHpOverlay: {
    position: "fixed" as any,
    top: 0,
    right: 0,
    width: "91.7%",
    height: "100%",
    backgroundColor: "#000000b0",
    zIndex: 999
  },
  dialogHpContainer: {
    position: "fixed" as any,
    top: "10% !important",
    width: "60%",
    zIndex: 1000,
    padding: "0px 20px",
    borderRadius: "8px"
  },
  popupFilter: {
    background: "white",
    position: "absolute" as "absolute",
    top: "calc(100% + 70px)",
    borderRadius: "8px",
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 10px",
    width: "343px",
    transform: "translateX(-105%)",
    height: "309px"
  },
  button: {
    backgroundColor: "#014866",
    color: "#FFFFFF",
    border: "medium none",
    borderRadius: "10px",
    cursor: "pointer",
    fontFamily: "SF Pro Text Bold",
    fontSize: "18px",
    margin: "20px 15px",
    textTransform: "capitalize" as "capitalize",
    width: "312",
    height: "56px",
    caretColor: "transparent",
    "&:hover": {
      backgroundColor: "#014866",
      color: "#FFFFFF"
    }
  },
};
export default withStyles(webStyles)(OrderManagementFilter);
// Customizable Area End
