import { Theme } from "@material-ui/core";
// Customizable Area Start
import { pexelsKarolina, CalenderIcon } from "../assets";
import { FlexType, ObjectFitType, OverflowProperty, PositionType, ScrollbarType, TextTransform, WhiteSpaceType } from "../types";
import { floatType, objectFitType, textAlignType } from "../../../dashboard/src/types";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
const customStyle = {
  "& .css-g1d714-ValueContainer":
   {
    fontSize: "16px",
    borderRadius: "8px",
    color: "#808080",
    fontFamily: "SF Pro Text Regular"
  },
  "& .css-fke1ie-indicatorContainer:focus": 
  {
    color: "#808080",
  },
  "& .css-yk16xz-control": 
  {
    fontSize: "16px",
    borderRadius: "8px",
    borderColor: "hsl(0, 2.2%, 64.3%)",
    fontFamily: "SF Pro Text Regular"
  },
  "& .css-lpahdxg-control": 
  {
    fontSize: "16px",
    borderRadius: "8px",
    ffontFamily: "SF Pro Text Regular"
  },
  "& .css-fke1ie-indicatorContainer":
   {
    color: "#808080",
    display: "flex",
    alignItem: "center",
    alignContent: "center",
    margin: "auto"
  },
  "& .css-yk16xz-control:focus": 
  {
    fontSize: "16px",
    borderRadius: "8px",
    color: "#808080",
    fontFamily: "SF Pro Text Regular"
  },
  "& .css-1fhf3k1-control":
   {
    background: "#ffffff !important",
  },
  "& .css-107lb6w-singleValue":
   {
    color: "#292929 !important",
    fontFamily: "SF Pro Text Regular"
  },
  "& .css-1uccc91-singleValue":
   {
    color: "#292929 !important",
    fontFamily: "SF Pro Text Regular !important",
  },
  "& .css-6q0nyr-Svg" :
{
    fill: "#000000 !important",
    fontSize: "20px !important",
    width: "30px !important",
    height: "25px !important",
    cursor: "pointer",
    caretColor:"transparent",
    fontWeight: 400
  },
  "& .css-1pahdxg-control" :
{
    boxShadow: "none !important",
  },
  "& .css-1pahdxg-control:focus":
   {
    boxShadow: "none !important",
    outline: "none !important",
    fontFamily: "SF Pro Text Regular",
  },
  "& .css-1pahdxg-control:active":
   {
    boxShadow: "none !important",
    outline: "none !important",
    fontFamily: "SF Pro Text Regular",
  },
  "& .css-1laa021a11yText":
   {
    outline: "none !important",
    boxShadow: "none !important",
    fontFamily: "SF Pro Text Regular",
  },
  "& .css-1l6et5q-control": 
  {
    backgroundColor: "#fff"
  },
  "& .css-siwetl-control:hover":
   {
    borderColor: "#3f51b5 !important",
    borderWidth: "1px !important"
  },
  "& .css-1e5gspv-control": 
  {
    backgroundColor: "white !important"
  },
  "& .css-1mg7z2r-control":
   {
    backgroundColor: "white !important"
  },
  "& .css-jlswka-control:hover": 
  {
    boxShadow: "0 0 0 1px #3f51b5"
  },
  "& .css-lumyd2-control":
   {
    backgroundColor: "white !important"
  },
  "& .css-1wa3eu0-placeholder":
   {
    color: "#808080",
    fontFamily: "SF Pro Text Regular"
  },
  "& .css-360mep-control": 
  {
    paddingRight: "5px !important"
  },
  "&  .css-1rsya3y-control": 
  {
    padding: "8px"
  },
  "& .css-1rsya3y-control": 
  {
    minHeight: "45px !impotant",
    padding: "6px !impotant",
  },
  "& .css-13ta6qp-control": 
  { cursor: "pointer", padding: "7px !important" },
  "& .css-cjtr36-indicatorContainer": 
  {
    padding: "8px 10px !important"
  },
  "& .css-1ebpccn-control":
   {
    minHeight: "55px !important"
  },
  "& .css-1hwfws3":
   {
    padding: "10px 8px !important"
  },
  "& .css-g1d714-ValueContainer:hover":
   {
    minHeight: "auto !important"
  },
  "& .css-95shoe-control":
   {
    padding: "8px !important"
  },
  "& .css-14jk2my-container":
  {
    caretColor:"transparent"
  }
}

// Customizable Area End

export const styles: (theme: Theme) => {[key: string]: CSSProperties} = (theme: Theme) => ({
  // Customizable Area Start
  homeCureContainers : 
  {
    display: "flex",
    alignItems: "center",
    caretColor: "transparent",
    margin: "20px"
  },
  homecureLogos:
  {
    width: 180,
    margin: 20,
    caretColor: "transparent",
    [theme.breakpoints.down("sm")]:
    {
      width: 180,
      margin: 10
    }
  },
  healthDetailHomecureLogos:
  {
    width: 180,
    margin: "20px 45px",
    [theme.breakpoints.down("lg")]:
    {
      margin: "20px 35px",
    },
    [theme.breakpoints.down("md")]:
    {
      margin: "20px",
    },
    [theme.breakpoints.down("xs")]:
    {
      width: 180,
      margin: "10px",
    }
  },
  backButtons:
  {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "20px",
    height: "20px",
    backgroundColor: "rgba(255, 255, 255, 0.3)",
    boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
    padding: "10px",
    margin: "35px",
    borderRadius: "5px"
  },
  imageStyles:
  {
    height: 80,
    width: 80,
    marginRight: "15px"
  },
  homeCureHeadings:
  {
    fontSize: 20,
    color: "#fff",
    fontWeight: 600
  },
  transparentCards:
  {
    backgroundColor: "rgba(255, 255, 255, 0.3)",
    boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
    width: "350px",
    margin: "50px 120px",
    borderRadius: "25px",
    padding: "25px 35px",
    [theme.breakpoints.down("sm")]:
    {
      width: "350px !important",
      margin: "100px auto",
      padding: "30px"
    },
    [theme.breakpoints.down("xs")]:
    {
      width: "300px !important",
      margin: "80px auto",
      padding: "15px"
    }
  },
  container:
  {
    backgroundImage: `url(${pexelsKarolina})`,
    backgroundPosition: "center",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    padding: "10px",
    [theme.breakpoints.down("sm")]:
    {
      padding: "30px 10px"
    }
  },
  patientHeading:
  {
    fontSize: "16px",
    margin: "10px 0px 35px",
    caretColor: "transparent",
    fontFamily: "SF Pro Text Regular",
    color: "#808080",
  },
  cardContents:
  {
    display: "flex",
    padding: theme.spacing(2)
  },
  boldText:
  {
    fontWeight: 600
  },
  titleWelcomes:
  {
    fontSize: "28px",
    marginVertical: 8,
    fontFamily: "SF Pro Text Bold",
    color: "#292929",
    [theme.breakpoints.down("xs")]:
    {
      fontSize: "20px"
    },
    caretColor: "transparent",
  },
  subtitles:
  {
    fontFamily: "SF Pro Text Regular",
    fontSize: "14px",
    color: "#292929",
    padding: 5,
    caretColor: "transparent"
  },
  inputLabels:
  {
    caretColor: "transparent",
    fontFamily: "SF Pro Text Regular",
    marginTop: "30px",
    marginBottom: "10px",
    fontSize: "16px",
    color: "#292929"
  },
  inputFields:
  {
    width: "100%",
    fontSize: "14px",
    fontFamily: "SF Pro Text Regular",
    marginBottom: "15px",
    "& .MuiInputBase-root": {
      color: "#292929",
      fontSize: "14px",
      fontFamily: "SF Pro Text Regular",
      background: "white !important",
      borderRadius: "10px",
      border: "none"
    },
    "& input":
    {
      color: "#292929",
      fontSize: "14px",
      fontFamily: "SF Pro Text Regular",
      "&::placeholder":
      {
        opacity: 1
      }
    },
  },
  inputErrorFields:
  {
    width: "100%",
    fontSize: "14px",
    marginBottom: "10px",
    fontFamily: "SF Pro Text Regular",
    "& .MuiInputBase-root": {
      color: "#292929",
      fontSize: "14px",
      fontFamily: "SF Pro Text Regular",
      background: "white !important",
      borderRadius: "10px",
      border: "1px solid #f44336 !important",
    },
    "& input":
    {
      color: "#292929",
      fontSize: "14px",
      fontFamily: "SF Pro Text Regular",
      "&::placeholder":
      {
        opacity: 1
      }
    },
  },
  loginButtons:
  {
    width: "100%",
    backgroundColor: "#014866",
    color: "#F2F6F7",
    border: "none",
    borderRadius: "10px",
    padding: "16px",
    cursor: "pointer",
    fontFamily: "SF Pro Text Bold",
    fontSize: "18px",
    margin: "10px auto",
    "&:hover":
    {
      backgroundColor: "#014866",
      color: "#F2F6F7"
    }
  },
  inputErrors:
  {
    display: "flex",
    justifyContent: "end",
    color: "#FF0000",
    caretColor: "transparent",
    fontFamily: "SF Pro Text Regular",
    fontSize: "12px"
  },
  notchedOutlines:
  {
    borderWidth: "1px",
    borderColor: "white !important",
    borderRadius: "10px",
    fontSize: "14px",
    "& .MuiInputBase-root": {
      color: "#292929",
      fontSize: "14px",
      fontFamily: "SF Pro Text Regular",
      background: "white !important",
      borderRadius: "10px",
      border: "1px solid #f44336 !important",
    },
    "& input":
    {
      fontSize: "16px",
      color: "#292929",
      fontFamily: "SF Pro Text Regular",
      "&::placeholder":
      {
        opacity: 1,
        color: "#808080 !important",

      }
    },
    "& .MuiInputBase-input":
    {
      color: "#292929",
      fontSize: "14px",
    },
    "& .MuiFormLabel-root":
    {
      color: "#292929",
    },
    "& .MuiInputLabel-formControl":
    {
      color: "#292929",
    },
  },
  errorOutlines:
  {
    borderWidth: "1px",
    borderColor: "#FF0000 !important",
    borderRadius: "10px",
    fontSize: "14px",
    "& .MuiInputBase-input":
    {
      fontSize: "14px",
      color: "#292929",
    },
    "& .MuiFormLabel-root":
    {
      color: "#292929",
    },
    "& .MuiInputLabel-formControl":
    {
      color: "#292929",
    },
    "& .MuiInputBase-root": {
      color: "#292929",
      fontSize: "14px",
      fontFamily: "SF Pro Text Regular",
      background: "white !important",
      borderRadius: "10px",
      border: "1px solid #f44336 !important",
    },
    "& input":
    {
      color: "#292929",
      fontSize: "14px",
      fontFamily: "SF Pro Text Regular",
      "&::placeholder":
      {
        opacity: 1
      }
    }
  },
  verifyOTPs:
  {
    width: "100%",
    backgroundColor: "#014866",
    color: "#fff",
    border: "none",
    borderRadius: "10px",
    padding: "16px",
    cursor: "pointer",
    fontWeight: 600,
    fontSize: "16px",
    margin: "10px auto",
    "&:hover":
    {
      backgroundColor: "#014866",
      color: "#fff"
    }
  },
  emailLogos:
  {
    width: "100px",
    margin: "auto"
  },
  emailVerifiedLogo:
  {
    width: "150px",
    margin: "auto"
  },
  emailConfirmLogo:
  {
    width: "100px",
    margin: "auto"
  },
  confirmEmailAddress:
  {
    fontSize: "16px",
    color: "#292929",
    margin: "10px auto",
    width: "270px",
    fontWeight: 600,
  },
  successMessages:
  {
    fontSize: "15px",
    color: "#292929",
    margin: "30px auto",
    fontWeight: 700,
    caretColor: "transparent"
  },
  hospitalSuccessMessages:
  {
    fontSize: "15px",
    color: "#292929",
    caretColor: "transparent",
    fontWeight: 700,
  },
  hospitalSuccessMessageContainer:
  {
    margin: "30px auto",
  },
  successLogos:
  {
    width: "85px",
    margin: "20px auto"
  },
  successBtns:
  {
    width: "100%",
    backgroundColor: "#014866",
    color: "#fff",
    border: "none",
    borderRadius: "10px",
    padding: "16px",
    cursor: "pointer",
    fontFamily: "SF Pro Text Bold",
    fontSize: "18px",
    margin: "0px auto",
    "&:hover":
    {
      backgroundColor: "#014866",
      color: "#fff"
    }
  },
  emailConfirmationText:
  {
    margin: "10px auto",
    fontSize: "16px",
    color: "#292929"
  },
  homeCureLogoContainer:
  {
    display: "flex",
    alignItems: "center"
  },
  homeCureLogos:
  {
    width: 80,
    margin: 20,
    [theme.breakpoints.down("xs")]:
    {
      width: 180,
      margin: 10
    }
  },
  logoTitles:
  {
    fontSize: "20px",
    color: "#fff",
    fontWeight: 600
  },
  registerInputFields:
  {
    width: "100%",
    marginBottom: "10px",
    fontFamily: "SF Pro Text Regular",
    "& .MuiInputBase-root": {
      color: "#292929",
      fontSize: "14px",
      fontFamily: "SF Pro Text Regular",
      background: "white !important",
      borderRadius: "10px",
    },
    "& input":
    {
      fontSize: "14px",
      color: "#292929",
      fontFamily: "SF Pro Text Regular",
      "&::placeholder":
      {
        opacity: 1,
        color: "#808080 !important",
      }
    },
    "& .MuiOutlinedInput-root":
    {
      borderRadius: "8px",
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
    {
      borderWidth: "1px",
      borderColor: "#3f51b5 !important",
    },
  },
  errorRegisterInputFields:
  {
    width: "100%",
    marginBottom: "10px",
    borderRadius: "8px",
    "& .MuiInputBase-root": {
      color: "#292929",
      fontSize: "14px",
      fontFamily: "SF Pro Text Regular",
      background: "white !important",
      borderRadius: "10px",
    },
    "& input":
    {
      fontSize: "14px",
      color: "#292929",
      fontFamily: "SF Pro Text Regular",
      "&::placeholder":
      {
        opacity: 1,
        color: "#808080 !important",

      }
    },
    "& .MuiOutlinedInput-root":
    {
      borderRadius: "8px"
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
    {
      borderWidth: "1px",
      borderColor: "#3f51b5 !important",
    },
    "& .MuiOutlinedInput-notchedOutline":
    {
      borderColor: "#FF0000",
      borderWidth: "1px",
    },
    fontFamily: "SF Pro Text Regular",
  },
  registerDesktopButtons:
  {
    width: "50%",
    backgroundColor: "#014866",
    color: "#fff",
    border: "none",
    borderRadius: "10px",
    padding: "16px",
    cursor: "pointer",
    fontWeight: 600,
    fontSize: "18px",
    margin: "25px auto 10px",
    fontFamily: "SF Pro Text Bold",
    "&:hover":
    {
      backgroundColor: "#014866",
      color: "#fff"
    },
    [theme.breakpoints.down("xs")]:
    {
      display: "none"
    }
  },
  registerHospitalDesktopButtons:
  {
    width: "50%",
    backgroundColor: "#014866",
    color: "#fff",
    border: "none",
    borderRadius: "10px",
    padding: "16px",
    cursor: "pointer",
    fontWeight: 600,
    fontSize: "18px",
    fontFamily: "SF Pro Text Bold",
    margin: "100px auto 10px",
    "&:hover":
    {
      backgroundColor: "#014866",
      color: "#fff"
    },
    [theme.breakpoints.down("xs")]:
    {
      display: "none"
    }
  },
  registerMobileButtons:
  {
    width: "100%",
    backgroundColor: "#014866",
    color: "#fff",
    border: "none",
    borderRadius: "10px",
    padding: "16px",
    cursor: "pointer",
    fontWeight: 600,
    fontSize: "16px",
    margin: "25px auto 10px",
    "&:hover":
    {
      backgroundColor: "#014866",
      color: "#fff"
    },
    [theme.breakpoints.down("xl")]:
    {
      display: "none"
    },
    [theme.breakpoints.down("xs")]:
    {
      display: "block"
    }
  },
  healthDetailsBackButtons:
  {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "20px",
    height: "20px",
    backgroundColor: "rgba(255, 255, 255, 0.3)",
    boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
    padding: "10px",
    margin: "0px 30px",
    borderRadius: "5px",
    [theme.breakpoints.down("lg")]:
    {
      margin: "0px 20px",
    },
    [theme.breakpoints.down("md")]:
    {
      margin: "0px 10px",
    },
    [theme.breakpoints.down("xs")]:
    {
      margin: "0px"
    }
  },
  healthDetailsHeadings:
  {
    caretColor: "transparent",
    display: "flex",
    width: "204px",
    alignItem: "center",
    justifyContent: "center",
    fontSize: "28px",
    fontFamily:"SF Pro Text Bold",
    color:"#292929",
  },
  uploadLink:
   {
    caretColor: "transparent",
    alignItem: "center",
    cursor: "pointer",
    color: "#3780E0",
    fontSize: "16px",
    fontFamily: "SF Pro Text Semibold"
  },
  link:
  {
    cursor: "pointer",
    color: "#3780E0",
    fontSize: "14px",
    alignItem: "center",
    caretColor: "transparent",
    fontFamily: "SF Pro Text Medium",
  },
  hideContainers:
  {
    [theme.breakpoints.down("sm")]:
    {
      display: "none"
    }
  },
  AddressInputFields:
  {
    width: "50%",
    caretColor: "transparent",
    borderRadius: "8px",
    "& .MuiInputBase-root": {
      color: "#292929",
      fontSize: "14px",
      fontFamily: "SF Pro Text Regular",
      background: "white !important",
      borderRadius: "10px",
    },
    "& input":
    {
      fontSize: "14px",
      color: "#292929",
      fontFamily: "SF Pro Text Regular",
      "&::placeholder":
      {
        opacity: 1,
        color: "#808080 !important",

      }
    },
    "& .MuiOutlinedInput-root":
    {
      borderRadius: "8px"
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
    {
      borderWidth: "1px",
      borderColor: "#3f51b5 !important",
    },
    "& .MuiOutlinedInput-notchedOutline":
    {
      borderColor: "#FF0000",
      borderWidth: "1px",
    },
    fontFamily: "SF Pro Text Regular",
    marginBottom: "30px",
    [theme.breakpoints.down("sm")]:
    {
      width: "100%"
    },
    [theme.breakpoints.down("xs")]:
    {
      width: "100%"
    }
  },
  AddressInputLabels:
  {
    marginTop: "25px",
    caretColor: "transparent",
    marginBottom: "10px",
    fontSize: "18px",
    fontFamily: "SF Pro Text Medium",
    color: "#292929"
  },
  setLocationHeadings:
  {
    caretColor: "transparent",
    display: "flex",
    width: "200px",
    alignItem: "center",
    justifyContent: "center",
    fontSize: "28px",
    margin: "0px 0px 30px",
    color: "#292929",
    fontFamily: "SF Pro Text Bold",
  },
  patientRegistrationContainer:
  {
    margin: "0px 50px"
  },
  patientLabelContainer:
  {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "20px",
    [theme.breakpoints.down("sm")]:
    {
      marginTop: "80px"
    }
  },
  registrationHeading:
  {
    width: "50%",
    caretColor: "transparent",
    fontFamily: "SF Pro Text Bold",
    fontSize: "28px",
    color: "#292929"
  },
  setLocation:
  {
    caretColor: "transparent",
    fontSize: "16px",
    fontFamily: "SF Pro Text Medium",
    color: "#3780E0",
    cursor: "pointer"
  },
  registerLabel:
  {
    fontSize: "16px",
    fontFamily: "SF Pro Text Regular",
    caretColor: "transparent",
    color: "#292929",
    margin: "10px 0px",
  },
  required:
  {
    color: "#FF0000"
  },
  gridContainer:
  {
    height: "100vh",
    overflow: "auto",
    "& .MuiGrid-spacing-xs-4 > .MuiGrid-item":
    {
      [theme.breakpoints.down("xs")]:
      {
        padding: "0px",
      }
    },
    [theme.breakpoints.down("sm")]:
    {
      height: "unset !important",
      overflow: "unset !important"
    }
  },
  dialogButton:
  {
    width: "181px",
    height:"65px",
    backgroundColor: "#014866",
    color: "#fff",
    border: "none",
    borderRadius: "10px",
    padding: "10px",
    cursor: "pointer",
    fontFamily: "SF Pro Text Bold",
    fontSize: "18px",
    margin: "10px auto",
    "&:hover":
    {
      backgroundColor: "#014866",
      color: "#fff"
    },
    caretColor: "transparent"
  },
  patientHeathDtailsComponent:
  {
    [theme.breakpoints.down("xs")]:
    {
      margin: "20px"
    }
  },
  healthDetailConatiner:
  {
    display: "flex",
    justifyContent: "space-between",
    margin: "30px 15px 0px",
    [theme.breakpoints.down("xs")]:
    {
      flexDirection: "column"
    }
  },
  healthDetailsSubContainer:
  {
    display: "flex",
    marginBottom: "15px",
    [theme.breakpoints.down("sm")]:
    {
      width: "200px",
      justifyContent: "space-between"
    }
  },
  arrowIcon:
  {
    height: "15px",
    weight: "15px",
    caretColor: "white"
  },
  uplodForm:
  {
    display: "flex",
    margin: "10px 35px",
  },
  healthDetailsLinks:
  {
    caretColor: "transparent",
    marginLeft: "20px",
    [theme.breakpoints.down("sm")]:
    {
      marginLeft: "0px",
    }
  },
  patientTypo:
  {
    fontSize: "16px",
    marginBottom: "10px",
    fontFamily:"SF Pro Text Regular",
    color:"#292929",
    caretColor: "white",
  },
  typeWrapper:
  {
    fontSize: "16px",
    marginBottom: "10px",
    caretColor: "white",
    marginTop: "20px",
  },
  patientSelectBox:
  {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "10px",
    "& .MuiOutlinedInput-root":
    {
      borderRadius: "8px"
    }
  },
  patientAllergyBox:
  {
    display: "flex",
    justifyContent: "space-between",
  },
  selectPatientField:
  {
    width: "48%"
  },
  boxWidth:
  {
    width: "100%",
    "& .MuiOutlinedInput-root":
    {
      borderRadius: "8px"
    },
    "& input": {
      color: '#292929',
      fontSize: "16px",
      cursor: "pointer",
      fontFamily:"SF Pro Text Regular",
      "&::placeholder": {
        opacity: 1,
        fontSize: "16px",
        fontFamily:"SF Pro Text Regular",
        color:"#808080"
      },
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
    {
      borderWidth: "1px",
      borderColor: "#3f51b5 !important",
    },
  },
  otherDiseasesTextBox:
  {
    width: "100%",
    marginBottom: "10px",
    "& .MuiOutlinedInput-root":
    {
      borderRadius: "8px"
    },
    "& input": {
      color: '#292929',
      fontSize: "16px",
      cursor: "pointer",
      fontFamily:"SF Pro Text Regular",
      "&::placeholder": {
        opacity: 1,
        fontSize: "16px",
        fontFamily:"SF Pro Text Regular",
        color:"#808080"
      },
    },
  },
  inputError:
  {
    width: "100%",
    caretColor:"transparent",
    "& .MuiOutlinedInput-root":
    {
      caretColor:"transparent",borderRadius: "8px"
    },
    "& .MuiOutlinedInput-notchedOutline":
    {
      borderColor: "#FF0000",
      caretColor:"transparent",
      borderWidth: "1px",
    }
  },
  unselectedField:
  {
    width: "100%",
    "& .MuiOutlinedInput-root":
    {
      borderRadius: "8px"
    },
    color: "hsl(0, 2.2%, 64.3%)",
    caretColor: "transparent"
  },
  selectedField:
  {
    width: "100%",
    caretColor: "transparent",
    "& .MuiOutlinedInput-root":
    {
      borderRadius: "8px"
    },
    color: "#014866"
  },
  diseaseContaier:
  {
    width: "95%",
    margin: "20px auto"
  },
  cardContainer:
  {
    padding: "13px",
    borderRadius: "10px",
  },
  BoxContainer:
  {
    height: "520px",
    overflowY: "scroll" as OverflowProperty,
    scrollbarWidth: "thin" as ScrollbarType,
    scrollbarColor: "#d9d5d5 #fff",
    '& ::-webkit-scrollbar-button':
    {
      display: "none"
    },
  },
  patientButtonContainer:
  {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "30px",
    [theme.breakpoints.down("sm")]:
    {
      display: "none"
    }
  },
  patientMobileButtonContainer:
  {
    display: "flex",
    width: "100%",
    margin: "-15px 20px",
    padding: "10px 0px",
    [theme.breakpoints.down("xl")]:
    {
      display: "none"
    },
    [theme.breakpoints.down("sm")]:
    {
      display: "flex"
    }
  },
  btnContain:
  {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "50%"
  },
  skipBtn:
  {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "50%"
  },
  medicineLink:
  {
    display: "flex",
    caretColor: "transparent",
    justifyContent: "flex-end",
    marginBottom: "25px"
  },
  patientSize:
  {
    fontSize: "16px",
    marginBottom: "10px",
    caretColor: "white",
  },
  allergyLink:
  {
    display: "flex",
    caretColor: "transparent",
    justifyContent: "flex-end",
    marginBottom: "25px"
  },
  dialogAction:
  {
    justifyContent: "center"
  },
  dialogConatiner:
  {
    width: "450px",
    margin: "auto",
    borderRadius: "20px",
    [theme.breakpoints.down("xs")]:
    {
      width: "320"
    }
  },
  uploadForm:
  {
    display: "flex",
    margin: "10px 35px",
    [theme.breakpoints.down("lg")]:
    {
      display: "flex",
      margin: "10px 20px",
    },
    [theme.breakpoints.down("sm")]:
    {
      display: "flex",
      margin: "12px 0px",
      justifyContent: "space-between"
    }
  },
  uploadDocContainer:
  {
    fontSize: "15px",
    "& .MuiDialog-paperWidthSm":
    {
      maxWidth: "400px",
      width: "400px",
      padding: "30px 40px",
      margin: "auto",
      borderRadius: "20px",
      overflow: "hidden",
      caretColor: "transparent"
    },
    [theme.breakpoints.down("xs")]:
    {
      "& .MuiDialog-paperWidthSm":
      {

        padding: "10px"
      }
    }
  },
  titleContainer:
  {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    caretColor: "transparent !important"
  },
  uploadTitle:
  {
    "& .MuiTypography-h6":
    {
      fontSize: "21px",
      fontWeight: 700,
      caretColor: "transparent !important"
    }
  },
  uploadBtn:
  {
    colors: "#014866",
    display: "flex",
    width: "90%",
    backgroundColor: "#c2e0ef",
    padding: "15px",
    borderRadius: "10px",
    margin: "30px auto 0px",
    color: "#014866",
    fontSize: "14px",
    fontWeight: 600,
    [theme.breakpoints.down("xs")]:
    {
      width: "100%"
    }
  },
  previewBtn:
  {
    display: "flex",
    margin: "30px auto 0px",
    width: "80%",
    backgroundColor: "#014866",
    color: "#fff",
    border: "none",
    borderRadius: "10px",
    padding: "14px",
    cursor: "pointer",
    fontWeight: 600,
    fontSize: "16px",
    "&:hover":
    {
      backgroundColor: "#014866",
      color: "#fff"
    },
  },
  mobileGrid:
  {
    "&.PatientRegisterDetails-gridContainer-47 .MuiGrid-spacing-xs-4 > .MuiGrid-item":
    {
      padding: "0px"
    }
  },
  confirmEmailHeading:
  {
    fontSize: "18px",
    color: "#292929",
    fontFamily: "SF Pro Text Bold",
    margin: "20px auto",
    caretColor: "transparent"
  },
  confirmEmailText:
  {
    caretColor: "transparent",
    fontSize: "18px",
    fontFamily: "SF Pro Text Medium",
    color: "#383838",
    margin: "0px auto"
  },
  confirmEmailSubText:
  {
    caretColor: "transparent",
    fontSize: "18px",
    fontFamily: "SF Pro Text Medium",
    color: "#383838",
    margin: "0px auto "
  },
  paper:
  {
    borderRadius: "12px",
    maxWidth: "470px",
    padding: "10px",
  },
  dialogText:
  {
    fontSize: "20px",
    color: "#292929",
    fontFamily: "SF Pro Text Medium",
    lineHeight: "10px",
    caretColor: "transparent !important"
  },
  surgeryContainer:
  {
    marginBottom: "10px"
  },
  customSelectField:
  {
    ...customStyle,
    "& .css-1pahdxg-control:hover":
    {
      border: "1px solid #3f515b",
      fontSize: "16px",
      borderRadius: "8px",
      fontFamily: "SF Pro Text Regular"
    },
  },
  customErrorSelectField:
  {
    ...customStyle,
    "& .css-1pahdxg-control:hover":
    {
      border: "2px solid #FF0000",
      fontSize: "16px",
      borderRadius: "8px",
      fontFamily: "SF Pro Text Regular"
    },
    "& .css-95shoe-control":
    {
      borderColor: "#FF0000 !important",
      padding: "8px !important"
    },
    "& .css-1rsya3y-control":
    {
      borderColor: "#FF0000 !important",
    },
    "& .css-yk16xz-control":
    {
      minHeight: "54px !important",
      fontSize: "14px",
      borderRadius: "8px",
      border: "2px solid #FF0000",
      fontFamily: "SF Pro Text Regular"
    },
    "& .css-1wa3eu0-placeholder":
    {
      color: "#808080",
      fontFamily: "SF Pro Text Regular"
    },
  },
  patientSelectBoxDiseases:
  {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "25px",
    width: "100%",
    "& .MuiOutlinedInput-root":
    {
      borderRadius: "8px"
    }
  },
  customSelectFieldDiseases:
  {
    ...customStyle,
    "& .css-1pahdxg-control:hover":
    {
      border: "2px solid #3f515b",
      fontSize: "16px",
      borderRadius: "8px",
      fontFamily: "SF Pro Text Regular",
      color:"#292929"
    },
  },
  fixedGrid:
  {
    top: 0,
    maxHeight: "100%",
    overflow: "hidden" as OverflowProperty,
    position: "sticky" as PositionType,
    [theme.breakpoints.down("sm")]:
    {
      maxHeight: "30%",
      overflow: "unset !important"
    }
  },
  selectBoxMargin:
  {
    marginBottom: "20px"
  },
  hospitalRegistrationContainer:
  {
    margin: "0px 50px"
  },
  hospitalLabelContainer:
  {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "20px",
    [theme.breakpoints.down("sm")]:
    {
      marginTop: "80px"
    }
  },
  hospitalHeading:
  {
    fontSize: "16px",
    margin: "10px 0px",
    color: "gray",
    [theme.breakpoints.down("sm")]:
    {
      marginBottom: "15px",
      marginTop: "10px"
    }
  },
  bedsHeading:
  {
    display: "flex",
    caretColor: "transparent",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: "18px",
    fontWeight: 600,
    marginLeft: "35%",
    marginTop: "25px",
    fontFamily: "SF Pro Text Bold",
    color: '#292929',
    [theme.breakpoints.down("sm")]:
    {
      marginLeft: "32%"
    }
  },
  insuranceHeading:
  {
    display: "flex",
    justifyContent: "space-between",
    caretColor: "transparent",
    alignItems: "center",
    fontSize: "18px",
    fontWeight: 600,
    marginLeft: "26%",
    marginTop: "25px",
    fontFamily: 'SF Pro Text Bold',
    color: "#292929",
    [theme.breakpoints.down("sm")]:
    {
      marginLeft: "32%"
    },
    [theme.breakpoints.down("xs")]:
    {
      marginLeft: "25%"
    }
  },
  departmentsHeading:
  {
    display: "flex",
    caretColor: "transparent",
    justifyContent: "space-between",
    alignItems: "center",
    fontSize: "18px",
    fontWeight: 600,
    marginLeft: "26%",
    marginTop: "25px",
    fontFamily: 'SF Pro Text Bold',
    color: "@292929",
    [theme.breakpoints.down("sm")]:
    {
      marginLeft: "32%"
    },
    [theme.breakpoints.down("xs")]:
    {
      marginLeft: "25%"
    }
  },
  closeBtn:
  {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginRight: "4px",
    marginTop: "20px",
    fontSize: "22px",
    cursor: "pointer",
    caretColor: "transparent"
  },
  closePopoverBtn:
  {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginRight: "0px",
    marginTop: "20px",
    caretColor: "transparent",
    fontSize: "22px",
    cursor: "pointer"
  },
  overlay:
  {
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: 9999,
  },
  popoverContainer:
  {
    padding: "20px 18px 20px 20px",
    width: "360px",
    [theme.breakpoints.down("sm")]:
    {
      padding: "5px",
    },
  },
  registrationTextField:
  {
    width: "100%",
    fontSize: "15px",
    marginBottom: "10px",
    overflow: "hidden",
    "& .MuiOutlinedInput-root":
    {
      cursor: "pointer",
      borderRadius: "8px",
      height: "100px",
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
    {
      borderWidth: "1px",
      borderColor: "#3f51b5 !important",
    },
    "& .MuiInputBase-input":
    {
      cursor: "pointer",
    },
    "& input":
    {
      fontSize: "16px",
      color: "#292929",
      fontFamily: "SF Pro Text Regular",
      "&::placeholder":
      {
        opacity: 1,
        color: "#808080 !important",

      }
    },
    registrationTextFieldError:
    {
      width: "100%",
      fontSize: "15px",
      marginBottom: "10px",
      overflow: "hidden",
      "& .MuiOutlinedInput-root":
      {
        borderRadius: "8px",
        height: "100px",
        cursor: "pointer",
      },
      "& .MuiOutlinedInput-notchedOutline":
      {
        borderColor: "#FF0000",
        borderWidth: "1px",
      },
      "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
      {
        borderWidth: "1px",
        borderColor: "#3f51b5 !important",
      },
      "& .MuiInputBase-input":
      {
        cursor: "pointer",
      },
      "& input":
      {
        fontSize: "16px",
        color: "#292929",
        fontFamily: "SF Pro Text Regular",
        "&::placeholder":
        {
          opacity: 1,
          color: "#808080 !important",

        }
      }
    }
  },
  selectButtons:
  {
    width: "60%",
    backgroundColor: "#014866",
    color: "#fff",
    border: "none",
    borderRadius: "10px",
    padding: "16px",
    cursor: "pointer",
    fontWeight: 600,
    fontSize: "18px",
    margin: "10px auto",
    fontFamily: 'SF Pro Text Bold',
    "&:hover":
    {
      backgroundColor: "#014866",
      color: "#fff",
    },
  },
  insuranceSearchField:
  {
    width: "100%",
    margin: "15px auto",
    "& input":
    {
      color: "#292929",
      fontFamily: "SF Pro Text Regular",
      fontSize: "16px",
      "&::placeholder":
      {
        fontFamily: "SF Pro Text Regular",
        fontSize: "16px",
        opacity: 1,
        color: "#808080"
      },
    },
    [theme.breakpoints.down("sm")]:
    {
      width: "90%"
    },
    "& .MuiOutlinedInput-root":
    {
      borderRadius: "8px",
    }
  },
  tableContainer:
  {
    width: "100%",
    margin: "5% auto"
  },
  selectPopoverContainer:
  {
    display: "flex",
    justifyContent: "space-between",
    width: "360px"
  },
  selecthospitalContainer:
  {
    display: "flex",
    justifyContent: "space-between",
    width: "360px"
  },
  selectInsurance:
  {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "10px"
  },
  uploadInputErrors:
  {
    display: "flex",
    justifyContent: "center",
    color: "#FF0000",
    fontFamily: "SF Pro Text Regular",
    fontSize: "14px",
    margin: "0px 20px"
  },
  dateFieldError:
  {
    display: "flex",
    padding: "15px 60px",
    border: "1px solid #FF0000",
    justifyContent: "center",
    borderRadius: "8px",
    "&:focus":
    {
      border: "2px solid #FF0000",
    }
  },
  customDatePickerContainer:
  {
    display: "flex",
    alignItems: "center"
  },
  icon:
  {
    marginRight: " 10px"
  },
  dateInput:
  {
    width: "100%",
    height: "100%",
    "& input":
    {
      background: `url(${CalenderIcon})left / contain no-repeat`,
      backgroundPosition: "12px",
      cursor: "pointer",
      backgroundSize: "25px",
      width: "90%",
      height: "50px",
      border: "1px solid #a5a1a1",
      color: "#000000",
      fontSize: "1em",
      fontWeight: 500,
      display: "flex",
      margin: "auto"
    }
  },
  dateField:
  {
    display: "flex",
    padding: "15px 60px",
    border: "1px solid #a5a1a1",
    justifyContent: "center",
    borderRadius: "8px",
    "&:hover":
    {
      border: "1px solid #3f51b5",
    },
    "&:focus":
    {
      border: "2px solid #3f51b5",
    },
  },
  uploadLabel:
  {
    fontSize: "16px",
    margin: "20px 0px 10px 18px",
    caretColor: "transparent",
  },
  tablePopoverContainer:
  {
    maxHeight: '400px',
    marginLeft: "4px",
    overflowY: "scroll" as OverflowProperty,
    scrollbarWidth: "thin" as ScrollbarType,
    scrollbarColor:"#d9d5d5 #fff !important",
    '@media (max-height: 700px)':
    {
      maxHeight: '330px',
    },
    '@media (max-height: 660px)':
    {
      maxHeight: '300px',
    },
    '@media (max-height: 659px)':
    {
      maxHeight: '250px',
    },
  },
  uploadImage:
  {
    height: "150px",
    borderRadius: "8px",
    border: "1px solid #c7b9b9",
    width: "100%",
    cursor: "pointer"
  },
  popoverModal:
  {
    position: 'static !important' as PositionType,
    '& > div:nth-child(1)':
    {
      position: 'absolute !important' as PositionType,
      backgroundColor: '#797575 !important',
      width: "100% !important"
    },
  },
  PaperPropsClass:
  {
    '& > div:nth-child(1)':
    {
      backgroundColor: '#050505a3 !important',
      left: "33.3%!important"
    },
    "& .MuiPopover-paper":
    {
      borderRadius: '30px',
      overflow: "unset" as OverflowProperty,
      '@media (max-width: 2100px)':
      {
        left: '58% !important',
      },
      '@media (max-width: 1920px)':
      {
        left: '56% !important',
      },
      '@media (max-width: 1500px)':
      {
        left: '55% !important',
      },
      '@media (max-width: 1366px)':
      {
        left: '52% !important',
      },
      '@media (max-width: 1024px)':
      {
        left: '48% !important',
      },
      '@media (max-height: 1500px)':
      {
        top: '32% !important',
      },
      '@media (max-height: 1200px)':
      {
        top: '30% !important',
      },
      '@media (max-height: 1000px)':
      {
        top: '20% !important',
      },
      '@media (max-height: 900px)':
      {
        top: '15% !important',
      },
      '@media (max-height: 768px)':
      {
        top: '10% !important',
      },
    }
  },
  datepickerClass:
  {
    "& .react-datepicker__month":
    {
      margin: "0px !important"
    },
    "& .react-datepicker-popper":
    {
      transform: "translate(90px, 209px) !important"
    },
    "& .react-datepicker__day":
    {
      width: "1.3rem !important",
      height: "1rem !important"
    },
    "& .react-datepicker__day-name":
    {
      width: "1.3rem !important",
      height: "1rem !important"
    },
    "& .react-datepicker__month-container":
    {
      margin: "0px 0px 5px !important"
    },
    "& .react-datepicker__day-names":
    {
      marginBottom: "-1px !important"
    },
    "& .react-datepicker__navigation":
    {
      height: "22px !important",
      width: "22px !important",
      margin: "5px -2px !important"
    },
    "& .react-datepicker__day--keyboard-selected":
    {
      color: "#000 !important",
      backgroundColor: "white !important",
    },
    "& .react-datepicker__day--today":
    {
      color: "#1d5d90 !important",
      backgroundColor: "white !important",
      fontWeight: "bold !important"
    },
    "& .react-datepicker__day:hover":
    {
      color: "#ccc !important",
      backgroundColor: "white !important",
    }
  },
  uploadInputSuccess:
  {
    display: "flex",
    justifyContent: "center",
    color: "green",
    fontSize: "14px",
  },
  uploadImageInputErrors:
  {
    display: "flex",
    justifyContent: "center",
    color: "#FF0000",
    fontSize: "14px",
  },
  otherDiseasesTextBoxError:
  {
    width: "100%",
    marginBottom: "10px",
    "& .MuiOutlinedInput-root":
    {
      borderRadius: "8px"
    },
    "& .MuiOutlinedInput-notchedOutline":
    {
      borderColor: "#FF0000",
      borderWidth: "1px",
    },
    "& input": {
      color: '#292929',
      fontSize: "16px",
      cursor: "pointer",
      fontFamily:"SF Pro Text Regular",
      "&::placeholder": {
        opacity: 1,
        fontSize: "16px",
        fontFamily:"SF Pro Text Regular",
        color:"#808080"
      },
    },
  },
  hpConnectHospitalFields:
  {
    width: "100%",
    fontSize: "15px",
    marginBottom: "10px",
    borderColor: "#02A405 !important",
    backgroundColor: "#E3FFE4",
    borderRadius: "8px",
    caretColor: "white !important",
    "& .MuiOutlinedInput-root":
    {
      borderRadius: "8px",
      cursor: "pointer"
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
    {
      borderWidth: "1px",
      borderColor: "#02A405 !important",
    },
    "& input":{
      fontSize: "16px",
      color: '#292929',
      cursor: "pointer",
      fontFamily:"SF Pro Text Regular",
      "&::placeholder":{
        fontFamily:"SF Pro Text Regular",
        color:"#808080",
        opacity: 1,
        fontSize: "16px",
      },
    },
  },
  hpConnectHospitalFieldsGray:
  {
    width: "100%",
    fontSize: "15px",
    marginBottom: "10px",
    caretColor: "white !important",
    "& .MuiOutlinedInput-root":
    {
      cursor: "pointer",
      borderRadius: "8px",
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
    {
      borderWidth: "1px",
      borderColor: "#3f51b5 !important",
    },
    "& input":{
      fontSize: "16px",
      color: '#292929',
      cursor: "pointer",
      fontFamily:"SF Pro Text Regular",
      "&::placeholder":{
        fontFamily:"SF Pro Text Regular",
        color:"#808080",
        opacity: 1,
        fontSize: "16px",
      },
    },
  },
  locationLimit:
  {
    color: "gray",
    marginLeft: "15px"
  },
  sliderBox:
  {
    width: "70%"
  },
  hpPaperPropsClass:
  {
    '& > div:nth-child(1)':
    {
      backgroundColor: '#050505a3 !important',
    },
    "& .MuiPopover-paper":
    {
      borderRadius: '30px',
      overflow: "unset",
      '@media (max-width: 2100px)':
      {
        left: '32% !important',
      },
      '@media (max-width: 1920px)':
      {
        left: '23% !important',
      },
      '@media (max-width: 1800px)':
      {
        left: '22% !important',
      },
      '@media (max-width: 1500px)':
      {
        left: '20% !important',
      },
      '@media (max-width: 1366px)':
      {
        left: '19% !important',
      },
      '@media (max-width: 1024px)':
      {
        left: '16% !important',
      },
    }
  },
  hpPopoverContainer:
  {
    padding: "35px 100px",
    '@media (max-width: 1024px)':
    {
      padding: "35px 80px",
    },
  },
  hpPopoverText:
  {
    fontSize: "20px",
    color: "#292929",
    margin: "auto",
    textAlign: "center" as textAlignType,
    lineHeight: "32px",
    caretColor: "transparent",
    fontFamily: "SF Pro Text Medium",
  },
  hpClosePopup:
  {
    display: "flex",
    justifyContent: "center",
    width: "181px",
    heught:"56px",
    backgroundColor: "#014866",
    color: "#fff",
    border: "none",
    borderRadius: "10px",
    padding: "16px",
    cursor: "pointer",
    fontFamily:"SF Pro Text Bold",
    fontSize: "18px",
    margin: "10px auto",
    textTransform: "capitalize" as TextTransform,
    "&:hover":
    {
      backgroundColor: "#014866",
      color: "#fff",
    },
  },
  selecthpContainer:
  {
    display: "flex",
    justifyContent: "space-between",
    width: "360px",
    margin: "20px auto 0px"
  },
  clgRegisterInputFields:
  {
    width: "100%",
    fontSize: "15px",
    marginBottom: "10px",
    marginTop: "44px",
    "& .MuiOutlinedInput-root":
    {
      borderRadius: "8px"
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
    {
      borderWidth: "1px",
      borderColor: "#3f51b5 !important",
    },
    fontFamily: "SF Pro Text Regular",
  },
  clgErrorRegisterInputFields:
  {
    width: "100%",
    fontSize: "15px",
    marginBottom: "10px",
    borderRadius: "8px",
    marginTop: "44px",
    "& .MuiOutlinedInput-root":
    {
      borderRadius: "8px"
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
    {
      borderWidth: "1px",
      borderColor: "#3f51b5 !important",
    },
    "& .MuiOutlinedInput-notchedOutline":
    {
      borderColor: "#FF0000",
      borderWidth: "1px",
    },
    fontFamily: "SF Pro Text Regular",
  },
  connectIcon:
  {
    height: "32px",
    objectFit: "cover" as ObjectFitType,
    display: "flex",
    justifyContent: "center",
    margin: "0px auto",
    cursor: "pointer"
  },
  hpSelectCheckbox:
  {
    position: 'relative' as PositionType,
    pointer: "cursor",
    caretColor: "transparent"
  },
  hpUpArrowIcon:
  {
    width: "18px",
    height: "15px",
    objectFit: "cover" as objectFitType,
    cursor: "pointer"
  },
  hpDownArrowIcon:
  {
    width: "18px",
    height: "15px",
    objectFit: "cover" as objectFitType,
    cursor: "pointer",
    marginRight: "6px",
  },
  hpLangDownArrowIcon:
  {
    width: "18px",
    height: "15px",
    objectFit: "cover" as objectFitType,
    cursor: "pointer",
    marginRight: "12px",
    right: "5px",
    position: "absolute" as PositionType,
    top: "18px",
  },
  hpSelectFieldDropdown:
  {
    position: 'absolute' as PositionType,
    borderRadius: "8px",
    top: '100%',
    left: 0,
    width: '100%',
    backgroundColor: '#fff',
    border: '1px solid #ccc',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    zIndex: 1000,
    marginTop: "10px"
  },
  hpDropdownContent:
  {
    padding: "10px 20px"
  },
  dropdownText:
  {
    display: "flex",
    justifyContent: "space-between",
    padding: '10px 3px 15px 0px',
    color: "#a4a1a1",
    cursor: 'pointer',
    borderBottom: '1px solid #ccc',
    transition: 'background-color 0.3s ease',
    fontSize: "17px",
    fontFamily: "SF Pro Text Regular",
    alignItems: "center"
  },
  hpSelectOption:
  {
    padding: '5px 10px 5px 0px',
    cursor: 'pointer',
    borderBottom: '1px solid #ccc',
    width: "80%",
    transition: 'background-color 0.3s ease',
    fontFamily: "Sf Pro Text Regular",
    fontSize: "16px",
    display: "flex",
    alignItems: "center",
    color: "#292929"
  },
  hpSelectKindOption:
  {
    margin: '12px 10px 10px 0px',
    cursor: 'pointer',
    width: "80%",
    transition: 'background-color 0.3s ease',
    fontFamily: "SF Pro Text Regular",
    fontSize: "16px",
    display: "flex",
    alignItems: "center",
    caretColor: "transparent",
  },
  checkbox:
  {
    color: "#fff !important",
    padding: "9px 20px 9px 0px"
  },
  checkboxWhite:
  {
    color: "#fff !important",
    marginLeft: "2px !important",
  },
  hpSelectFieldSize:
  {
    maxHeight: "285px",
    minHeight: "auto",
    overflowY: "scroll" as OverflowProperty,
    scrollbarColor: "#d9d5d5 #fff",
    scrollbarWidth: "thin" as ScrollbarType,
    '& ::-webkit-scrollbar-button':
    {
      display: "none"
    },
  },
  languageText:
  {
    fontSize: "14px",
    margin: "5px 0px",
    fontFamily: "SF Pro Text Regular",
    color: "#808080",
    marginBottom: "0px !important",
    caretColor: "transparent",
    textAlign: "text" as textAlignType,
  },
  langIcon:
  {
    width: "121px",
    "& .MuiChip-deleteIcon": {
      color: "#000000 !important"
    },
    "&.MuiChip-label": {
      paddingLeft: "8px !important",
      paddingRight: "8px !important"
    },
    margin: "1px 4px",
    alignItems: "center",
    backgroundColor: "#e2ecf0",
    color: "#014866",
    borderRadius: "6px",
    padding: "7px 0px",
    fontSize: "14px",
    fontFamily: "SF Pro Text Regular"
  },
  languageBox:
  {
    position: "relative" as PositionType,
    display: 'flex',
    flexWrap: 'wrap' as FlexType,
    border: '1px solid #b9b6b6',
    borderRadius: '8px',
    padding: "10px",
    cursor: "pointer",
    width: "95%",
    fontFamily: "SF Pro Text Regular",
    alignItems: "center",
    '&:focus':
    {
      border: '2px solid #3f51b5',
    },
    '&:hover':
    {
      border: '1px solid #3f51b5',
    },
  },
  languageBoxError:
  {
    position: "relative" as PositionType,
    display: 'flex',
    flexWrap: 'wrap' as FlexType,
    border: '1px solid #FF0000',
    borderRadius: '8px',
    padding: "10px",
    cursor: "pointer",
    width: "95%",
    alignItems: "center",
    fontFamily: "SF Pro Text Regular",
    marginBottom: "12px",
    '&:focus':
    {
      border: '1px solid #3f51b5',
    },
    '&:hover':
    {
      border: '1px solid #3f51b5',
    },
  },
  languageAyahBoxError:
  {
    display: 'flex',
    alignItems: 'center',
    border: '1px solid #FF0000',
    borderRadius: '8px',
    padding: "10px",
    cursor: "pointer",
    width: "95%",
    '&:focus':
    {
      border: '1px solid #3f51b5',
    },
    '&:hover':
    {
      border: '1px solid #3f51b5',
    },
    fontFamily: "SF Pro Text Regular",
  },
  selectLanguagePlaceholder:
  {
    color: '#808080',
    fontFamily: "SF Pro Text Regular",
    fontSize: "14px",
    padding: "8px",
    alignItems: "center"
  },
  locationLimitBox:
  {
    alignItems: 'center',
    border: '1px solid #b9b6b6',
    borderRadius: '8px',
    padding: "6px 20px",
    cursor: "pointer",
    width: "95%",
    fontFamily: "SF Pro Text Regular",
  },
  locationLimitBoxError:
  {
    alignItems: 'center',
    border: '1px solid #FF0000',
    borderRadius: '8px',
    padding: '6px 20px',
    cursor: "pointer",
    width: "95%",
    fontFamily: "SF Pro Text Regular",
    marginBotttom: "10px"
  },
  locationSpan:
  {
    display: "flex",
    justifyContent: "space-between",
    color: "#808080",
    width: "100%"
  },
  hpGenderFieldDropdown:
  {
    borderRadius: "8px",
    top: '100%',
    left: 0,
    backgroundColor: '#fff',
    border: '1px solid #ccc',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    zIndex: 1000
  },
  degreeContainerSpacing:
  {
    padding: "0px 16px !important",
  },
  hospitalContainerSpacing:
  {
    padding: "0px 16px !important",
  },
  mobileHospitalContainerSpacing:
  {
    padding: "0px 16px !important",
    "&.PatientRegisterDetails-gridContainer-47 .MuiGrid-spacing-xs-4 > .MuiGrid-item":
    {
      padding: "0px"
    }
  },
  hpPersonalDetials:
  {
    display: "flex",
    justifyContent: "space-between",
    gap: "15px"
  },
  registerDegreeInputFields:
  {
    width: "48%",
    fontSize: "15px",
    marginBottom: "65px",
    "& .MuiOutlinedInput-root":
    {
      borderRadius: "8px"
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
    {
      borderWidth: "1px",
      borderColor: "#3f51b5 !important",
    },
    fontFamily: "SF Pro Text Regular",
  },
  hospitalBottomContainerSpacing:
  {
    padding: "0px 16px 10px !important",
  },
  mobileBottomHospitalContainerSpacing:
  {
    padding: "0px 16px 16px !important",
    "&.PatientRegisterDetails-gridContainer-47 .MuiGrid-spacing-xs-4 > .MuiGrid-item":
    {
      padding: "0px"
    }
  },
  degClgBox:
  {
    display: "flex",
    justifyContent: "space-between",
  },
  degColTextField:
  {
    width: "48%"
  },
  hpUploadIcon:
  {
    width: "30px",
    height: "32px",
    objectFit: "cover" as objectFitType,
    display: "flex",
    justifyContent: "center",
    margin: "0px auto"
  },
  langdropdownText:
  {
    float: 'right' as floatType,
    cursor: 'pointer',
  },
  hpLangSelectFieldSize:
  {
    maxHeight: "285px",
    minHeight: "auto",
    overflowY: "scroll" as OverflowProperty,
    marginTop: "16px",
    scrollbarColor: "#d9d5d5 #fff",
    scrollbarWidth: "thin" as ScrollbarType,
    '& ::-webkit-scrollbar-button':
    {
      display: "none"
    },
  },
  hpLangDropdownContent:
  {
    padding: "10px 20px 10px 20px"
  },
  ayahHomecureLogos:
  {
    width: 180,
    margin: 20,
    [theme.breakpoints.down("sm")]:
    {
      width: 180,
      margin: 10
    },
    objectFit: "contain" as objectFitType
  },
  setLocationContainer:
  {
    width: "50%",
    textAlign: "right" as textAlignType,
    textDecoration: "none"
  },
  ayahDocIcon:
  {
    width: "30px",
    height: "32px",
    objectFit: "cover" as objectFitType,
    display: "flex",
    justifyContent: "center",
    margin: "0px auto"
  },
  ayahInputErrors:
  {
    display: "flex",
    justifyContent: "end",
    color: "#FF0000",
    fontSize: "14px",
    fontFamily: "SF Pro Text Regular",
    marginTop: "12px",
  },
  ayahAddressDetailsContainer:
  {
    display: "flex",
    justifyContent: "space-between",
    gap: "15px"
  },
  hpKindregisterInputFields:
  {
    width: "100%",
    fontSize: "16px",
    marginBottom: "10px",
    cursor: "pointer !important",
    "& .MuiOutlinedInput-root":
    {
      borderRadius: "8px",
      caretColor: "white !important",
      cursor: "pointer !important",
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
    {
      borderWidth: "1px",
      borderColor: "#3f51b5 !important",
    },
    "& .MuiOutlinedInput-notchedOutline":
    {
      borderWidth: "1px",
      borderColor: "rgba(0, 0, 0, 0.23)",
    },
    fontFamily: "SF Pro Text Regular",
    "& .MuiInputBase-input":
    {
      cursor: "pointer",
    },
    "& input":
    {
      fontSize: "16px",
      color: "#292929",
      fontFamily: "SF Pro Text Regular",
      "&::placeholder":
      {
        opacity: 1,
        color: "#808080 !important",

      }
    },
  },
  hpKindregisterErrorInputFields:
  {
    width: "100%",
    fontSize: "16px",
    marginBottom: "10px",
    "& .MuiOutlinedInput-root":
    {
      borderRadius: "8px"
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
    {
      borderWidth: "1px",
      borderColor: "#3f51b5 !important",
    },
    "& .MuiOutlinedInput-notchedOutline":
    {
      borderColor: "#FF0000",
      borderWidth: "1px",
    },
    fontFamily: "SF Pro Text Regular",
    "& .MuiInputBase-input":
    {
      cursor: "pointer",
    },
    "& input":
    {
      fontSize: "16px",
      color: "#292929",
      fontFamily: "SF Pro Text Regular",
      "&::placeholder":
      {
        opacity: 1,
        color: "#808080 !important",

      }
    },
  },
  hpRegisterDesktopButtons:
  {
    width: "50%",
    backgroundColor: "#014866",
    color: "#fff",
    border: "none",
    borderRadius: "10px",
    padding: "16px",
    cursor: "pointer",
    fontWeight: 600,
    fontSize: "18px",
    margin: "90px auto 10px",
    fontFamily: "SF Pro Text Bold",
    "&:hover":
    {
      backgroundColor: "#014866",
      color: "#fff"
    },
    [theme.breakpoints.down("xs")]:
    {
      display: "none"
    }
  },
  hpRegisterInputFields:
  {
    width: "100%",
    fontSize: "15px",
    marginBottom: "10px",
    "& .MuiOutlinedInput-root":
    {
      borderRadius: "8px",
      cursor: "pointer"
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
    {
      borderWidth: "1px",
      borderColor: "#3f51b5 !important",
    },
    fontFamily: "SF Pro Text Regular",
    "& .MuiInputBase-input":
    {
      cursor: "pointer",
    },
    "& input":
    {
      fontSize: "16px",
      color: "#292929",
      fontFamily: "SF Pro Text Regular",
      "&::placeholder":
      {
        opacity: 1,
        color: "#808080 !important",

      }
    }
  },
  hpErrorRegisterInputFields:
  {
    width: "100%",
    fontSize: "15px",
    marginBottom: "10px",
    borderRadius: "8px",
    "& .MuiOutlinedInput-root":
    {
      borderRadius: "8px",
      cursor: "pointer"
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
    {
      borderWidth: "1px",
      borderColor: "#3f51b5 !important",
    },
    "& .MuiOutlinedInput-notchedOutline":
    {
      borderColor: "#FF0000",
      borderWidth: "1px",
    },
    fontFamily: "SF Pro Text Regular",
    "& .MuiInputBase-input":
    {
      cursor: "pointer",
    },
    "& input":
    {
      fontSize: "16px",
      color: "#292929",
      fontFamily: "SF Pro Text Regular",
      "&::placeholder":
      {
        opacity: 1,
        color: "#808080 !important",

      }
    }
  },
  hplangSelectFieldDropdown:
  {
    position: 'absolute' as PositionType,
    borderRadius: "8px",
    top: '100%',
    left: 0,
    width: '100%',
    backgroundColor: '#fff',
    border: '1px solid #ccc',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    zIndex: 1000,
    marginTop: "10px"
  },
  locationGridContainer:
  {
    padding: "0px 16px 16px !important",
  },
  // New  popover css
  hpNewPaperPropsClass:
  {
    '& > div:nth-child(1)':
    {
      backgroundColor: '#050505a3 !important',
    },
    "& .MuiPopover-paper":
    {
      borderRadius: '30px',
      overflow: "unset",
    }
  },
  hpUploadRegisterInputFields:
  {
    width: "100%",
    fontSize: "14px",
    marginBottom: "10px",
    "& .MuiOutlinedInput-root":
    {
      borderRadius: "8px",
      cursor: "pointer",
      caretColor: "white !important"
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
    {
      borderWidth: "1px",
      borderColor: "#3f51b5 !important",
    },
    fontFamily: "SF Pro Text Regular",
    "& input": {
      color: '#292929',
      cursor:"pointer",
      fontSize: "14px",
      fontFamily:"SF Pro Text Regular",
      "&::placeholder": {
        color:"#808080",
        fontFamily:"SF Pro Text Regular",
        opacity: 1,
        fontSize: "14px",
      },
    },
  },
  hpUploadErrorRegisterInputFields:
  {
    width: "100%",
    fontSize: "15px",
    marginBottom: "10px",
    borderRadius: "8px",
    "& .MuiOutlinedInput-root":
    {
      borderRadius: "8px",
      cursor: "pointer",
      caretColor: "white !important"
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
    {
      borderWidth: "1px",
      borderColor: "#3f51b5 !important",
    },
    "& .MuiOutlinedInput-notchedOutline":
    {
      borderColor: "#FF0000",
      borderWidth: "1px",
    },
    fontFamily: "SF Pro Text Regular",
    "& input":
    {
      cursor: "pointer"
    }
  },
  patientSelectBoxSurgery:
  {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    "& .MuiOutlinedInput-root":
    {
      borderRadius: "8px"
    }
  },
  surgeryLink:
  {
    display: "flex",
    caretColor: "transparent",
    justifyContent: "flex-end",
  },
  registerDegreesInputFields:
  {
    width: "100%",
    fontSize: "15px",
    caretColor: "transparent",
    marginBottom: "10px",
    "& .MuiOutlinedInput-root":
    {
      borderRadius: "8px",
      cursor: "pointer",
      caretColor: "transparent",
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
    {
      borderWidth: "1px",
      caretColor: "transparent",
      borderColor: "#3f51b5 !important",
    },
    fontFamily: "SF Pro Text Regular",
    "& .MuiInputBase-input":
    {
      cursor: "pointer",
      caretColor: "transparent",
    },
    "& input":
    {
      fontSize: "16px",
      color: "#292929",
      fontFamily: "SF Pro Text Regular",
      "&::placeholder":
      {
        opacity: 1,
        color: "#808080 !important",
      }
    }
  },
  errorRegisterDegreeInputFields:
  {
    width: "100%",
    fontSize: "15px",
    marginBottom: "10px",
    borderRadius: "8px",
    "& .MuiOutlinedInput-root":
    {
      borderRadius: "8px",
      cursor: "pointer"
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
    {
      borderWidth: "1px",
      borderColor: "#3f51b5 !important",
    },
    "& .MuiOutlinedInput-notchedOutline":
    {
      borderColor: "#FF0000",
      borderWidth: "1px",
    },
    fontFamily: "SF Pro Text Regular",
    "& .MuiInputBase-input":
    {
      cursor: "pointer",
    },
    "& input":
    {
      fontSize: "16px",
      color: "#292929",
      fontFamily: "SF Pro Text Regular",
      "&::placeholder":
      {
        opacity: 1,
        color: "#808080 !important",
      }
    }
  },
  addDegreeField:
  {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: "0px"
  },
  langDownArrowIcon:
  {
    width: "25px",
    height: "25px",
    cursor: "pointer",
    marginRight: "12px",
  },
  registerHospitalInputFields:
  {
    width: "100%",
    fontSize: "15px",
    marginBottom: "10px",
    "& .MuiOutlinedInput-root":
    {
      borderRadius: "8px",
      cursor: "pointer",
      caretColor: "white !important"
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
    {
      borderWidth: "1px",
      borderColor: "#3f51b5 !important",
    },
    fontFamily: "SF Pro Text Regular",
    "& .MuiInputBase-input":
    {
      cursor: "pointer",
      caretColor: "white"
    },
    "& input":
    {
      fontSize: "16px",
      color: "#292929",
      fontFamily: "SF Pro Text Regular",
      "&::placeholder":
      {
        opacity: 1,
        color: "#808080 !important",
      }
    }
  },
  errorHospitalRegisterInputFields:
  {
    width: "100%",
    fontSize: "15px",
    marginBottom: "10px",
    borderRadius: "8px",
    "& .MuiOutlinedInput-root":
    {
      borderRadius: "8px",
      cursor: "pointer",
      caretColor: "white !important"
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
    {
      borderWidth: "1px",
      borderColor: "#3f51b5 !important",
    },
    "& .MuiOutlinedInput-notchedOutline":
    {
      borderColor: "#FF0000",
      borderWidth: "1px",
    },
    fontFamily: "SF Pro Text Regular",
    "& .MuiInputBase-input":
    {
      cursor: "pointer",
      caretColor: "white"
    },
    "& input":
    {
      fontSize: "16px",
      color: "#292929",
      fontFamily: "SF Pro Text Regular",
      "&::placeholder":
      {
        opacity: 1,
        color: "#808080 !important",
      }
    }
  },
  uploadIcon:
  {
    width: "30px",
    height: "32px",
    objectFit: "cover" as objectFitType,
    display: "flex",
    justifyContent: "center",
    margin: "0px auto",
    cursor: "pointer"
  },
  languagePatientBox:
  {
    position: "relative" as PositionType,
    display: 'flex',
    flexWrap: 'wrap' as FlexType,
    border: '1px solid #b9b6b6',
    borderRadius: '8px',
    padding: "10px",
    cursor: "pointer",
    width: "95%",
    fontFamily: "SF Pro Text Regular",
    alignItems: "center",
    '&:focus':
    {
      border: '2px solid #3f51b5',
    },
    '&:hover':
    {
      border: '1px solid #3f51b5',
    },
    marginBottom: "8x"
  },
  languagePatientBoxError:
  {
    position: "relative" as PositionType,
    display: 'flex',
    alignItems: 'center',
    border: '1px solid #FF0000',
    borderRadius: '8px',
    padding: "10px",
    cursor: "pointer",
    width: "95%",
    fontFamily: "SF Pro Text Regular",
    marginBottom: "8x",
    flexWrap: "wrap" as FlexType
  },
  langUpArrowIcon:
  {
    width: "25px",
    height: "25px",
    cursor: "pointer",
    marginRight: "12px",
    transform: "rotate(180deg)"
  },
  patientSelectOption:
  {
    padding: '5px 10px 10px 0px',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
    fontFamily: "SF Pro Text Regular",
    display: "flex",
    alignItems: "center",
    fontSize: "16px",
    caretColor: "transparent"
  },
  patientangSelectFieldSize:
  {
    overflowY: "scroll" as OverflowProperty,
    scrollbarWidth: "thin" as ScrollbarType,
    maxHeight: "285px",
    minHeight: "auto",
    '& ::-webkit-scrollbar':
    {
      width: "5px",
      height: "8px",
      scrollbarColor:"#d9d5d5 #fff",
    },
    '::webkit-scrollbar-thumb':
    {
      backgroundColor: "#C0C0C0"
    },
    '& ::-webkit-scrollbar-thumb:hover':
    {
      backgroundColor: "#fff"
    },
    '& ::-webkit-scrollbar-thumb:focus':
    {
      backgroundColor: "#fff"
    },
    '& ::-webkit-scrollbar-button':
    {
      display: "none"
    },
  },
  patientlangSelectFieldDropdown:
  {
    position: 'absolute' as PositionType,
    backgroundColor: '#fff',
    border: '1px solid #dfdcdc',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    zIndex: 1000,
    marginTop: "10px",
    borderRadius: "4px",
    top: '100%',
    left: 0,
    width: '100%',
  },
  //Dialog
  customContainer:
  {
    display: "block",
    width: "500px",
    maxWidth: "550px",
    height: "auto",
    textAlign: "left" as textAlignType,
    borderRadius: "5px",
  },
  heading:
  {
    width: "100%",
    fontSize: "28px",
    fontWeight: 700,
    fontFamily: "SF Pro Text Regular",
    height: "8px",
    marginTop: "15px",
    color: "#292929",
    caretColor: "white"
  },
  paragraph:
  {
    width: "100%",
    color: "#292929",
    fontSize: "16px",
    height: "8px",
    caretColor: "white",
    fontFamily: "SF Pro Text Regular",
    margin: "30px 0px"
  },
  button:
  {
    border: "none",
    color: "white",
    padding: "5px",
    marginBottom: "15px",
    borderRadius: "3px",
    caretColor: "transparent",
    backgroundColor: "rgb(33, 150, 243)",
    "&.MuiButton-root:hover":
    {
      backgroundColor: "rgb(33, 150, 243)",
      color: "white"
    },
    cursor: "pointer",
  },
  editIconsConatiners:
  {
    display: "flex",
    justifyContent: "space-between",
    padding: "2% 0% 5% 0%",
    position: "relative" as PositionType,
    zIndex: 1,
    caretColor: "transparent",
  },
  avatarEdit:
  {
    borderRadius: '50%',
    width: "155px",
    height: "155px",
    border: "1px solid #eeeaea",
    backgroundColor: "#eeeaea",
    zIndex: 1,
    cursor: "pointer"
  },
  avatarEditIcons:
  {
    top: 0,
    left: "135",
    width: "20px",
    height: "20px",
    zIndex: 1,
    position: "absolute" as PositionType,
    marginTop: "52px",
    background: "white",
    padding: "10px",
    borderRadius: "18px",
    cursor: "pointer"
  },
  popupHpButtons:
  {
    borderRadius: "3px",
    backgroundColor: "rgb(33, 150, 243)",
    border: "none",
    color: "white",
    padding: "5px",
    marginBottom: "15px",
    "&.MuiButton-root:hover":
    {
      backgroundColor: "rgb(33, 150, 243)",
      color: "white"
    }
  },
  popupButton:
  {
    backgroundColor: "rgb(33, 150, 243)",
    border: "none",
    caretColor: "transparent",
    color: "white",
    padding: "5px",
    marginBottom: "15px",
    borderRadius: "3px",
    fontWeight: 600,
    fontFamily: "SF Pro Text Regular",
    "&.MuiButton-root:hover":
    {
      backgroundColor: "rgb(33, 150, 243)",
      color: "white"
    }
  },
  patientLangUpArrowIcon:
  {
    width: "20px",
    height: "20px",
    cursor: "pointer",
    marginRight: "12px",
    transform: "rotate(180deg)",
    right: "0px",
    position: "absolute" as PositionType,
    top: "18px",
    filter: "opacity(0.2)",
    "&:hover":
    {
      filter: "opacity(0.4)"
    },
  },
  patientLangDownArrowIcon:
  {
    width: "20px",
    height: "20px",
    cursor: "pointer",
    marginRight: "12px",
    right: "0px",
    position: "absolute" as PositionType,
    top: "18px",
    filter: "opacity(0.2)",
    "&:hover":
    {
      filter: "opacity(0.4)"
    },
  },
  patientLanguageBox: {
    width: "285px",
    marginRight: "15px"
  },
  degreeConcate:
  {
    caretColor: "transparent",
    cursor: "pointer",
    width: "46%",
    overflow: "hidden",
    whiteSpace: "nowrap" as WhiteSpaceType,
    textOverflow: "ellipsis",
    fontSize: "14px",
    fontFamily: "SF Pro Text Medium",
    marginBottom: "50px",
    display: "block",
    border: "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: "8px",
    padding: "18px 0px 18px 12px",
    "&:hover":
    {
      border: "1px solid #3f51b5",
    },
    color:"#292929"
  },
  degreeConcateLast:
  {
    width: "46%",
    overflow: "hidden" as OverflowProperty,
    whiteSpace: "nowrap" as WhiteSpaceType,
    textOverflow: "ellipsis",
    fontSize: "14px",
    fontFamily: "SF Pro Text Medium",
    marginBottom: "65px",
    display: "block",
    border: "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: "8px",
    padding: "18px 0px 18px 12px",
    "&:hover":
    {
      border: "1px solid #3f51b5",
    },
    caretColor: "transparent",
    cursor: "pointer",
    color:"#292929"
  },
  emailMessages:
  {
    fontSize: "18px",
    color: "#292929",
    fontFamily: "SF Pro Text Bold",
    margin: "30px auto 0px",
    caretColor: "transparent",
  },
  successEmailMessages:
  {
    fontSize: "18px",
    color: "#292929",
    fontFamily: "SF Pro Text Bold",
    margin: "0px auto 30px",
    caretColor: "transparent",
  },
  placeholderClass: {
    '&::placeholder':
    {
      color: 'rgb(175, 206, 246) !important',
      opacity: 1,
    },
    '&::-webkit-input-placeholder':
    {
      color: 'rgb(175, 206, 246) !important',
      opacity: 1,
    },
  },
  lastOtherSelectBoxDiseases: {
    width: "100%",
    display: "flex",
    marginBottom: "10px",
    justifyContent: "space-between",
    "& .MuiOutlinedInput-root":
      { borderRadius: "8px" }
  },
  patientOtherSelectBoxDiseases:
  {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "26px",
    width: "100%",
    "& .MuiOutlinedInput-root":
    {
      borderRadius: "8px"
    }
  },
  othersLink:
  {
    display: "flex",
    justifyContent: "flex-end",
    caretColor: "transparent"
  },
  surgeryinputErrors:
  {
    display: "flex",
    justifyContent: "end",
    color: "#FF0000",
    caretColor: "transparent",
    fontFamily: "SF Pro Text Regular",
    fontSize: "14px",
    marginTop: "10px"
  },
  patientOtherSelectBoxDiseasesError:
  {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "10px",
    width: "100%",
    "& .MuiOutlinedInput-root":
    {
      borderRadius: "8px"
    }
  },
  inputOthersErrors: {
    display: "flex",
    justifyContent: "end",
    color: "#FF0000",
    fontSize: "14px",
    fontFamily: "SF Pro Text Regular",
    caretColor: "transparent",
    marginBottom: "26px"
  },
  inputAllergyErrors: {
    display: "flex",
    justifyContent: "end",
    color: "#FF0000",
    fontSize: "14px",
    fontFamily: "SF Pro Text Regular",
    caretColor: "transparent",
    marginBottom: "26px"
  },
  inputMedecineErrors: {
    display: "flex",
    justifyContent: "end",
    fontFamily: "SF Pro Text Regular",
    color: "#FF0000",
    fontSize: "14px",
    caretColor: "transparent",
    marginBottom: "10px"
  },
  lastDiseasesTextBoxError: {
    width: "100%",
    "& .MuiOutlinedInput-root":
    {
      borderRadius: "8px"
    },
    marginBottom: "10px",
    marginTop: "16px",
    "& .MuiOutlinedInput-notchedOutline":
    {
      borderColor: "#FF0000",
      borderWidth: "1px"
    }
  },
  boxWidthError: {
    "& input": {
      color: '#292929',
      fontSize: "16px",
      cursor: "pointer",
      fontFamily:"SF Pro Text Regular",
      "&::placeholder": {
        opacity: 1,
        fontSize: "16px",
        fontFamily:"SF Pro Text Regular",
        color:"#808080"
      },
    },
    "& .MuiOutlinedInput-root":
    {
      borderRadius: "8px"
    },
    width: "100%",
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
    {
      borderWidth: "1px",
      borderColor: "#3f51b5 !important",
    },
    "& .MuiOutlinedInput-notchedOutline":
    {
      borderColor: "#FF0000",
      borderWidth: "1px",
    },
  },
  hospitalBedError:
  {
    display: "flex",
    justifyContent: "center",
    color: "#f44336",
    fontFamily: "SF Pro Text Regular",
    fontSize: "14px",
    caretColor: "transparent",
  },
  selectedLocation:
  {
    color: "#02A405"
  },
  registerTypeInputFields:
  {
    width: "100%",
    marginBottom: "10px",
    fontFamily: "SF Pro Text Regular",
    "& .MuiInputBase-root": {
      color: "#292929",
      fontSize: "16px",
      fontFamily: "SF Pro Text Regular",
      background: "white !important",
      borderRadius: "10px",
    },
    "& input":
    {
      fontSize: "16px",
      color: "#292929",
      fontFamily: "SF Pro Text Regular",
      "&::placeholder":
      {
        opacity: 1,
        color: "#808080 !important",
      }
    },
    "& .MuiOutlinedInput-root":
    {
      borderRadius: "8px",
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
    {
      borderWidth: "1px",
      borderColor: "#3f51b5 !important",
    },
  },
  errorRegisterTypeInputFields:
  {
    width: "100%",
    marginBottom: "10px",
    borderRadius: "8px",
    "& .MuiInputBase-root": {
      color: "#292929",
      fontSize: "16px",
      fontFamily: "SF Pro Text Regular",
      background: "white !important",
      borderRadius: "10px",
    },
    "& input":
    {
      fontSize: "16px",
      color: "#292929",
      fontFamily: "SF Pro Text Regular",
      "&::placeholder":
      {
        opacity: 1,
        color: "#808080 !important",

      }
    },
    "& .MuiOutlinedInput-root":
    {
      borderRadius: "8px"
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
    {
      borderWidth: "1px",
      borderColor: "#3f51b5 !important",
    },
    "& .MuiOutlinedInput-notchedOutline":
    {
      borderColor: "#FF0000",
      borderWidth: "1px",
    },
    fontFamily: "SF Pro Text Regular",
  },
  gridLocationContainer:
  {
    height: "100vh",
    overflow: "auto",
    "& .MuiGrid-spacing-xs-4 > .MuiGrid-item":
    {
      [theme.breakpoints.down("xs")]:
      {
        padding: "0px",
      }
    },
    [theme.breakpoints.down("sm")]:
    {
      height: "unset !important",
      overflow: "unset !important"
    }
  },
  fixedLocationGrid:
  {
    top: 0,
    maxHeight: "100%",
    overflow: "auto" as any,
    position: "sticky" as any,
    [theme.breakpoints.down("sm")]:
    {
      maxHeight: "30%",
      overflow: "unset !important"
    }
  },
  setLocationBackButtons:
  {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "20px",
    height: "20px",
    backgroundColor: "rgba(255, 255, 255, 0.3)",
    boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2)",
    padding: "10px",
    borderRadius: "5px"
  },
  addressInputFields:
  {
    width: "45%",
    fontSize: "14px",
    marginBottom: "10px",
    caretColor: "transparent",
    cursor: "none",
    "& .MuiOutlinedInput-root":
    {
      borderRadius: "8px"
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
    {
      borderWidth: "1px",
      borderColor: "#3f51b5 !important",
    },
    fontFamily: "SF Pro Text Regular !important",
    color: "#808080",
    "& input":
    {
      color: '#808080',
      fontSize: "14px",
      cursor: "pointer",
      fontFamily: "SF Pro Text Regular !important",
      "&::placeholder":
      {
        opacity: 1,
        fontFamily: "SF Pro Text Regular !important",
        color: '#808080',
      },
    }
  },
  errorAddressInputFields:
  {
    width: "45%",
    fontSize: "15px",
    marginBottom: "10px",
    borderRadius: "8px",
    caretColor: "transparent",
    cursor: "none",
    fontFamily: "SF Pro Text Regular !important",
    "& .MuiOutlinedInput-root":
    {
      borderRadius: "8px"
    },
    "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
    {
      borderWidth: "1px",
      borderColor: "#3f51b5 !important",
    },
    "& .MuiOutlinedInput-notchedOutline":
    {
      borderColor: "#FF0000 !important",
      borderWidth: "1px",
    },
    "& input":
    {
      color: '#808080',
      fontSize: "14px",
      cursor: "pointer",
      fontFamily: "SF Pro Text Regular !important",
      "&::placeholder":
      {
        fontFamily: "SF Pro Text Regular !important",
        color: '#808080',
        opacity: 1,
      },
    },
  },
  textError:
  {
    display: "flex",
    justifyContent: "end",
    color: "#f44336",
    fontSize: "14px",
    width: "45%"
  },
  setLocationButtons:
  {
    width: "253px",
    height: "56px",
    backgroundColor: "#014866",
    color: "#ffffff",
    border: "none",
    borderRadius: "10px",
    padding: "16px",
    cursor: "pointer",
    fontFamily: "SF Pro Text Bold !important",
    caretColor: "transparent",
    fontSize: "18px",
    "&:hover":
    {
      backgroundColor: "#014866",
      color: "#fff"
    },
    [theme.breakpoints.down("sm")]:
    {
      width: "100%"
    },
    [theme.breakpoints.down("xs")]:
    {
      width: "100%"
    },
    marginTop: "20px"
  },
  skipBtns:
  {
    cursor: "pointer",
    color: "#3780E0",
    fontSize: "18px",
    alignItem: "center",
    caretColor: "transparent",
    fontFamily: "SF Pro Text Medium",
  },
  healthDetalSaveBtn: 
  {
    width: "253px",
    height:"56px",
    backgroundColor: "#014866",
    color: "#fff",
    borderRadius: "10px",
    padding: "16px",
    border: "none",
    cursor: "pointer",
    fontFamily:"SF Pro Text Bold",
    fontSize: "18px",
    margin: "10px auto",
    "&:hover": {
      backgroundColor: "#014866",
      color: "#F2F6F7"
    }
  },
   // Customizable Area End
});

