import React from "react";
// Customizable Area Start
import Reservation2Controller, { configJSON, filters, months, Props } from "./Reservations2Controller";

import {
  Box,
  createTheme,
  InputAdornment,
  TextField,
  ThemeProvider,
  Typography,
  Button,
  Chip,
  withStyles,
  CardContent,
  TableRow,
  TableCell,
  Checkbox,
  SvgIcon,
  ClickAwayListener,
  IconButton,
  Dialog,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import { searchIcons, NoData, DownArrow, UserProfile, filterIcons, NurseBanner, AyahBannner, LeftArrow, RightArrow } from "./assets";
import { PositionValue, floatType, objectFitType, textTransform, FlexDirectionProperty} from "./types";
import CloseIcon from '@material-ui/icons/Close';
import '../../../web/src/fonts/stylesheet.css';
import StarIcon from '@material-ui/icons/Star';
import Filter from "../../filteritems/src/Filter.web"
import { getStorageData } from "../../../framework/src/Utilities";
import DatePicker from "react-datepicker";
import SidebarNotificationWrapper from "../../notifications/src/Notifications.web";

const theme = createTheme({
  palette: {
    primary: {
      contrastText: "#fff",
      main: "#0000ff",
    }
  }
});

const Checked = () => (
  <SvgIcon
    viewBox="0 0 18 18"
    fill="none"
    height="16"
    width="16"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.5" y="0.5" rx="1.5" width="15" height="15" stroke="#014866" />
    <path
      d="M3.75 7.99992L6.58 10.8299L12.25 5.16992"
      stroke="#014866"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </SvgIcon>
);

const UnChecked = () => (
  <SvgIcon
    fill="none"
    width="16"
    height="16"
    viewBox="0 0 18 18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.5" y="0.5" width="15" height="15" rx="2.2" strokeWidth={'1.5'} opacity={'0.3'} stroke="#014866" />
  </SvgIcon>
);

// Customizable Area End
export class Reservations2NurseBooking extends Reservation2Controller {
  scrollContainerRef: React.RefObject<HTMLDivElement>;
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    this.scrollContainerRef = React.createRef();
    // Customizable Area End 
  }
  // Customizable Area Start

  async componentDidUpdate(prevProps: Props, prevState: any) {
    const { currentPage, selectedFilters } = this.state;
    if (prevState.currentPage !== currentPage || prevState.selectedFilters !== selectedFilters) {
        try {
          if (!this.state.isDataEmpty && !this.state.loading) { await this.getNursesList(); }
        } catch (error) {
            this.showAlert("Error fetching hospital data:", "Something went wrong");
        }
    }
  }

  onScroll = () => {
    const element = this.scrollContainerRef.current;

    if (element && !this.state.loading) {
      const { scrollTop, scrollHeight, clientHeight } = element;
      if (scrollTop > this.state.prevScrollTop && scrollTop + clientHeight >= scrollHeight - configJSON.TEN && this.state.nurseList?.length !=this.state.totalNurseCount) {
        this.setState(prevState => ({
          perPage: Math.max(10, prevState.perPage),
          currentPage: Math.max(1, prevState.currentPage + 1)
        }));
      }
      this.setState({ prevScrollTop: scrollTop });
    }
  };

  async componentDidMount() {
    let prevFilters= await getStorageData('hpFilters') as string;
    let parseFilters= JSON.parse(prevFilters);

    if (parseFilters) {
      this.setState((prevState) => ({
        ...prevState,
        selectedFilters: parseFilters,
      })); 
    } else {
      this.setState({
        selectedFilters: [{shift: [{value: "shift_1", name: "Morning"}]}]
      })
    }
    
    try {
      await Promise.all([this.getNursesList()]);
    } catch (errors) {
      this.showAlert("Error fetching data:", "Something went wrong");
    }
    let redirectedFromDashboard = await getStorageData("redirectFromDashboard");
    redirectedFromDashboard = JSON.parse(redirectedFromDashboard);
    
    const serviceType = await getStorageData("serviceType");
    const serviceName = serviceType === "Nurses" ? serviceType : `${serviceType}s`;
    
    this.setState({ 
      serviceType: serviceName,
      showDialog: redirectedFromDashboard,
      bookingErrorMessage: `All ${(serviceName || "healthcare personnals")} are filtered according to languages known by you.` 
    })
  }

  async componentWillUnmount() {
    const element = document.getElementById("test");
    if (element) {
      element.removeEventListener('scroll', this.onScroll);
    }
  }

  RenderBannerImage = (serviceType: string) => {
    return (
      <img src={serviceType === "Nurses" ? NurseBanner : AyahBannner} style={webStyles.bannerImg} alt="Banner" />
    )
  }

  RenderBannerDesc = (classes: any) => {
    const checkIfNurse = this.state.serviceType === "Nurses";
    return (
      <div className={classes.bannerTextDiv}>
        <p style={webStyles.bannerTitle}>{checkIfNurse ? "Nurses" : "Ayahs"}</p>
        <p className={classes.bannerDescription}>
          {checkIfNurse
            ? "Educated, trained and empathetic nurses for providing complete patient care."
            : "There are only two sorts of doctors those who practice with their brain."
          }
        </p>
      </div>
    )
  }

  renderShiftFilter = () =>{
    return (this.state.serviceType === "Nurses" ? filters[1] : filters[2]).map((selectedShiftFilter: { name: string, value: string, time: string }, index: number) => {
      const filterTypeObjectShift = this.state.selectedFilters.length > 0 && this.state.selectedFilters.find(
        (filter: any) => filter.shift !== undefined
      );
      const checkForShifts = filterTypeObjectShift
        ? filterTypeObjectShift.shift.some((i: any) => i.name === selectedShiftFilter.name)
        : false;

      return (
        <TableRow key={index} id="shiftTableRow" className={this.props.classes.shiftSortOptionDiv}>
          <TableCell id="shiftType" style={this.getSelectedField(index, checkForShifts, false)} className={this.props.classes.shiftSortBox}>
            <span style={{
              fontFamily: 'SF Pro Text Medium',
              fontSize: '18px',
              fontWeight: 500,
              lineHeight: '21.48px',
              color: '#292929'
            }}>
              {selectedShiftFilter.name}
            </span>
            <span style={{
              fontFamily: 'SF Pro Text Regular',
              fontSize: '16px',
              fontWeight: 400,
              lineHeight: '19.09px',
              color: '#717171'
            }}>
              {selectedShiftFilter.time}
            </span>
          </TableCell>
          <TableCell id="shiftTableCell"  style={this.getRowStyles(index, checkForShifts, false)}>
            <Checkbox data-test-id={`shift${index}`} className={this.props.classes.checkbox} checked={checkForShifts} onChange={() => this.handleFilterCheckboxChange(index, selectedShiftFilter.value, "shift", selectedShiftFilter.name)} icon={<UnChecked />} checkedIcon={<Checked />}
            />
          </TableCell>
        </TableRow>
      )
    })
  }

  renderCustomCalender = () =>{
    const { month, currentDate } = this.state;

    return (
      <Box 
        sx={{ 
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'center', 
          borderRadius: 2, 
          boxShadow: 3 
        }}
      >
        <Typography variant="h6" className={this.props.classes.calendarLabel}>Month</Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: "100%" }}>
          <IconButton style={{ paddingLeft: 0 }} className={this.props.classes.iconBtn} onClick={() => this.handleMonthChange(-1)}>
            <img width={8} src={LeftArrow} />
          </IconButton>
          <Typography variant="h6" className={this.props.classes.calendarVal}>{months[month]}</Typography>
          <IconButton style={{ paddingRight: 0 }} className={this.props.classes.iconBtn} onClick={() => this.handleMonthChange(1)}>
            <img width={8} src={RightArrow} />
          </IconButton>
        </Box>
        <Box style={{
          border: 0,
          borderBottom: '1px solid #F2F2F2',
          margin: '10px 0 15px',
          width: "100%",
          height: "1px"
        }} />
        <Typography variant="h6" className={this.props.classes.calendarLabel}>Date</Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: "100%" }}>
          <IconButton style={{ paddingLeft: 0 }} className={this.props.classes.iconBtn} onClick={() => this.handleDateChange(-1)}>
            <img width={8} src={LeftArrow} />
          </IconButton>
          <Typography variant="h6" className={this.props.classes.calendarVal}>{currentDate}</Typography>
          <IconButton style={{ paddingRight: 0 }} className={this.props.classes.iconBtn} onClick={() => this.handleDateChange(1)}>
            <img width={8} src={RightArrow} />
          </IconButton>
        </Box>
      </Box>
    );
  }

  renderPriceShift = (webStyles: any, user: any) => {
    return (
      <div style={webStyles.nursePriceShift}>
        <Typography style={webStyles.nurseFees} variant="h6">
        {this.state.sign}{this.handlePriceCalcOnListing(user)}
        </Typography>
        <Typography style={webStyles.nurseShift} variant="subtitle1" color="textSecondary">
          / {user.attributes && `${this.getShiftDetails(user.attributes.working_shifts[0], user.attributes.profile_type).label || "No Available"} Shift`}
        </Typography>
      </div>
    )
  }

  // Customizable Area End

  render() {
    // Customizable Area Start
    const { classes } = this.props;
    // Customizable Area End
    return (
      // Customizable Area Start
      <>
         <ThemeProvider theme={theme}>
          <Box style={webStyles.container}>
            <SidebarNotificationWrapper {...this.props}>
                <Box style={webStyles.availbleContainer}>
                  <Box style={webStyles.searchBarHolder}>
                    <Box style={{ width: "100%" }}>
                      <TextField
                        data-test-id="searchBarHP"
                        variant="outlined"
                        placeholder={`Search ${this.state.serviceType}...`}
                        className={classes.searchNurses}
                        onChange={(e)=>this.setState({searchNurse:e.target.value})}
                        onKeyPress={(event)=>this.searchNurseByName(event)}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <img
                                src={searchIcons}
                                alt="search"
                                style={webStyles.searchingIcon}
                              />
                            </InputAdornment>
                          ),
                          style: {
                            borderRadius: "8px",
                            color: "#999999 !important",
                            fontFamily: "SF Pro Text Regular !important",
                            fontSize: "16px !important",
                          }
                        }}
                      />
                    </Box>
                    <Box>
                      <Box
                        style={this.state.showPopup?webStyles.hpFilterIconSelected:webStyles.hpFilterIcon}
                      >
                        <img data-test-id="hpToggleButton" src={filterIcons} onClick={this.togglePopup} />
                      </Box>
                      <Box>
                        {this.state.showPopup && (
                          <Filter navigation={this.props.navigation}
                          selectedFilters={this.state.selectedFilters}
                          getHpList={this.getHpList} closePopup={this.togglePopup} id={""}/>
                        )}
                      </Box>
                    </Box>
                  </Box>
                  <Box className={classes.bannerHolder}>                  
                    {this.RenderBannerImage(this.state.serviceType)}
                    {this.RenderBannerDesc(classes)}
                  </Box>
                  <Box  style={{ width: "100%" }}>
                    <Typography variant="h6" style={webStyles.upcommingBookingText}>
                      Available {this.state.serviceType}
                    </Typography>
                    <Typography variant="h6" style={webStyles.subDescText}>
                      Please choose date and time slots to see available {this.state.serviceType?.toLowerCase()}
                    </Typography>
                    <Box style={webStyles.selectFilterHolder}>
                      <Box style={webStyles.selectParentContainer}>

                      <Box className={classes.customDatePickerName}>

                          <span className={classes.customDateInputsName}>

                            <DatePicker
                              wrapperClassName={classes.datepickerClassesFullWidth}
                              popperPlacement="top-start"
                              calendarClassName={classes.datespickerClasses}
                              popperModifiers={[
                                {
                                  name: "preventOverflow",
                                  options: {
                                    rootBoundary: "viewport",
                                    altAxis: true,
                                    tether: false,
                                  },
                                },
                                {
                                  name: "offset",
                                  options: {
                                    offset: [0, 0],
                                  },
                                },
                              ]}
                              data-test-id="bookingdate"
                              placeholderText="Select Date"
                              minDate={new Date()}
                              value={this.state.date}
                              dateFormat="Select Date"
                              onChange={this.formatNewCustomDate}
                              className={classes.dateField}
                            />
                          </span>
                        </Box>
                      </Box>
                      <Box style={webStyles.selectParentContainer}>
                        <Typography
                          onClick={this.handleShiftSelect}
                          style={webStyles.selectMenu}>Select Shift
                          <img
                            src={DownArrow}
                            alt="DownArrow"
                            style={webStyles.hpDownArrowIcon}
                          /></Typography>
                        {
                          this.state.shiftsAnchorEl && (
                            <ClickAwayListener
                              data-test-id="handleShiftSelect"
                              onClickAway={() => this.handleShiftSelect()}
                            >
                              <Box
                                style={{ ...(this.state.shiftsAnchorEl ? webStyles.shiftsDropdownVisible : webStyles.dropdown) }}
                              >
                                {this.renderShiftFilter()}
                              </Box>
                            </ClickAwayListener>
                          )
                        }
                      </Box>
                    </Box>
                    <Box>
                      {this.extractNamedObjects(this.state?.selectedFilters)?.map((elem: any, index: number) => {
                        return (
                        <Chip
                          key={index}
                          data-test-id={`deleteFilter${index}`}
                          label={elem.name}
                          onDelete={() => this.handleRemoveChip(elem)}
                          deleteIcon={<CloseIcon style={{ color: "white", width: "20px" }} fontSize="small" />}
                          style={webStyles.chip}
                        />
                      )})}
                    </Box>
                    <div ref={this.scrollContainerRef} className={classes.scrollContainer} style={{ height: "300px", overflow: this.state.nurseList?.length > 0 ? "auto" : "hidden", marginTop: "45px" }} data-test-id="scroll" onScroll={this.onScroll}>
                      {this.state.nurseList?.length > 0 ? (
                        <div id="nurseListBox" style={webStyles.availableNurseList}>
                          {this.state.nurseList?.map((user: any, index: number) => (
                            <Box key={index} className={classes.nurseRoot}>
                              <div data-test-id="navigateToNurseProfileDiv" onClick={() => this.navigateToNurseProfile(user.id)} className={classes.flexBox}>
                                <img style={webStyles.nurseCover} src={user.attributes?.profile_image_url?.url || UserProfile} title={user.name} />
                                <div style={webStyles.nurseDetails}>
                                  <CardContent style={webStyles.nurseContent}>
                                    <div style={webStyles.nurseBox}>
                                      <div>
                                        <Typography style={webStyles.nurseName} component="h5" variant="h5">
                                          {user.attributes?.name}
                                        </Typography>
                                        {this.renderPriceShift(webStyles, user)}
                                        <Typography variant="body2" style={webStyles.nurseExperience}>
                                          <span style={webStyles.experienceLabel}>Experience: </span> <span style={webStyles.experienceValue}>{user.attributes?.experience} years </span>
                                        </Typography>

                                      </div>
                                      <div style={webStyles.nurseRating}>
                                        <StarIcon />
                                        <Typography variant="body2" style={{ marginLeft: '4px' }}>
                                          <span style={webStyles.userRating}>{user?.rating || "4.2"}</span><span style={webStyles.userRatingOutOf}> / 5 </span>
                                        </Typography>
                                      </div>
                                    </div>
                                  </CardContent>
                                </div>
                              </div>
                              <Box style={webStyles.btnContainer}>
                                <Button
                                  variant="contained"
                                  data-test-id={`bookNow`}
                                  color="primary"
                                  style={webStyles.BookNowBtn}
                                  onClick={() => this.getSelectedBookingDetails(user.id,"Reservations2ViewNurseBooking")}
                                >
                                  Book Now
                                </Button>
                              </Box>
                            </Box>
                          ))}
                        </div>
                      )
                        :
                        (
                          <>
                            {
                              this.state.loading ? (
                                <>
                                  <Box data-test-id="nurseListLoaderTest" id="nurseListLoader" style={webStyles.loadingText}>{configJSON.Loading}</Box>
                                </>) : (
                                <>
                                  <Box data-test-id="noDataBoxNurse" id="noDataBox" style={webStyles.noDataContainer}>
                                    <img
                                      src={NoData}
                                      width={60}
                                      height={60}
                                      style={webStyles.noDataIcons}
                                    />
                                    <Box style={webStyles.noDataText}>No results found</Box>
                                    <Box style={webStyles.subText} >Try adjusting or clearing your filtes to</Box>
                                    <Box style={webStyles.subText} >display better results.</Box>
                                  </Box>
                                </>)
                            }</>
                        )}

                    </div>
                        {this.state.nurseList?.length > 0 && this.state.loading && (
                          <Box id="nurseListLoader" style={webStyles.loadingText}>Loading...</Box>
                        )}
                  </Box>
                </Box>
            </SidebarNotificationWrapper>
          </Box>
          <Dialog id="filterDialogBox" disableScrollLock={true} open={this.state.showDialog} classes={{ paper: classes.dialogpaper }} style={{ textAlign: "center" }}>
              <DialogContent>
                <p className={classes.dialogTextt}>
                 {this.state.bookingErrorMessage}
                </p>
              </DialogContent>
              <DialogActions className={classes.dialogActions}>
                <Button
                  data-test-id="closePopUp"
                  onClick={this.handleDialogClose}
                  variant="contained"
                  color="primary"
                  className={classes.dialogButtonn}
                  style={{ textTransform: "capitalize" }}
                >
                  Okay
                </Button>
              </DialogActions>
            </Dialog>
        </ThemeProvider>
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyles = {
  container: {
    display: "flex",
    height: "100vh",
    width:"auto",
    overflowX: "hidden" as "hidden",
  },
  availbleContainer: {
      padding: "40px 50px",
      width: "100%",
      minHeight: "100vh",
      display: "flex",
      flexDirection: "column" as FlexDirectionProperty,
      justifyContent: "flex-start",
      alignItems: "flex-start",
      gap: "20px",
      boxSizing:'border-box' as const
  },
  bannerHolder: {
    margin: "0px 0 20px",
    width: "100%",
    borderRadius: "14px",
    position: "relative" as PositionValue,
    top: "-25px",
    left: 0,
    bottom: 0,
    right: 0,
    objectFit: "contain" as "contain",
    '@media (min-width: 1051px)': {
      height: "250px",
    },
    '@media (max-width: 1050px)': {
      height: "220px",
      marginBottom: 0,
    },
    '@media (max-width: 950px)': {
      height: "280px",
      marginBottom: 0,
    },
  },
  bannerImg: {
    position: 'absolute' as PositionValue,
    left: 0,
    bottom: 0,
    right: 0,
    width: "100%",
    height: "100%",
    borderRadius: "8px"
  },
  bannerTextDiv: {
    position: 'absolute' as PositionValue,
    left: '22px',
    color: 'white',
    zIndex: 1,
    '@media (min-width: 1050px)': {
      top: '52%',
      },
    '@media (max-width: 1050px)': {
      top: "48%",
      },
  },
   bannerTitle: {
    fontFamily: 'SF Pro Text Bold',
    fontSize: '22px',
    fontWeight: 600,
    lineHeight: '26.25px',
    marginTop: 0,
    marginBottom: "10px",
    letterSpacing: '-0.6285714507102966px',
  },
  bannerDescription: {
    fontFamily: 'SF Pro Text Regular',
    fontSize: '18px',
    fontWeight: 400,
    lineHeight: '24px',
    margin: 0,
    letterSpacing: '-0.5142855048179626px',
    textAlign: 'left' as 'left',
    maxWidth: "80%",
    '@media (max-width: 1050px)': {
      maxWidth: "60%",
      },
  },
  availableNurseList: {
    display: "flex",
    flexDirection: "column" as FlexDirectionProperty,
    gap: "25px",
    minHeight: "300px",
    maxHeight: "500px",
    '@media (min-width: 1051px)': {
      width: "calc(100% - 100px) !important",
      },
      '@media (max-width: 1050px)': {
        width: '100% !important',
      },
  },
  searchBarHolder: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%"
  },
  searchNurses: {
    width: "93.5%",
    margin: "15px 0px",
    "& .MuiInputBase-root": {
      fontFamily: "SF Pro Text Regular !important",
      fontSize: "16px !important",
      color: "#292929 !important",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "8px !important",
    },
    '&::placeholder': {
      fontFamily: "SF Pro Text Regular !important",
      fontSize: "16px !important",
      color: '#999999 !important',
      opacity: 1,
    },
    '&::-webkit-input-placeholder': {
      fontFamily: "SF Pro Text Regular !important",
      fontSize: "16px !important",
      color: '#999999 !important',
      opacity: 1,
    },
  },
  searchingIcon: {
    width: "25px",
    height: "25px",
    objectFit: "cover" as objectFitType,
    display: "flex",
    justifyContent: "center",
    margin: "0px 15px 0px 0px"
  },
  selectedIcon: {
    width: "18px",
    height: "18px",
    display: "flex",
    justifyContent: "center",
    margin: "20px auto 0px -45px",
    backgroundColor: "white",
    zIndex: 1000,
    padding: "13px",
    borderRadius: "8px",
    position:"absolute" as PositionValue,
    caretColor:"white"
  },
  settingIcon: {
    width: "18px",
    height: "18px",
    display: "flex",
    justifyContent: "center",
    margin: "20px auto 0px -45px",
    backgroundColor: "white",
    zIndex: 1000,
    padding: "13px",
    borderRadius: "8px",
    caretColor:"white"
  },
  upcommingBookingText: {
    fontSize: "20px",
    color: "#4C4C4C",
    fontFamily: "SF Pro Text Semibold",
    fontWeight: 600,
    marginBottom: "12px",
    caretColor:"transparent"
  },
  subDescText: {
    fontFamily: 'SF Pro Text Regular',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '19.09px',
    color: '#808080',
  },
  BookNowBtn: { 
    caretColor:"transparent",
    backgroundColor: "#014866",
    color: "#fff",
    border: "none",
    borderRadius: "8px",
    width:"170px",
    height:"52px",
    cursor: "pointer",
    fontSize: "18px",
    fontFamily:"SF Pro Text Bold",
    marginRight: "20px",
    "&:hover": {
      backgroundColor: "#014866",
      color: "#fff"
    },
    textTransform: "capitalize" as textTransform,
    float:"right" as floatType,
    '@media (max-width:1250px)': {
      width: "160px !important",
    },
  },
  chip: 
  {
    "& .MuiChip-label":{
      paddingLeft: "8px !important",
      paddingRight: "8px !important"
    },
    margin: "2px 25px 5px 0px",
    alignItems: "center",
    padding: "20px 12px",
    fontSize: "14px",
    fontWeight: 400,
    fontFamily:"SF Pro Text Regular",
    background: "#FF0000",
    color: "white",
    borderRadius: "7px",
    height:"34px",
    minWidth: "124px",
    caretColor:"transparent",
    lineHeight: "16.71px",
    "& .MuiChip-deletable:focus":{
        color: "white",
        borderRadius: "7px",
        caretColor:"transparent",
        backgroundColor: "#FF0000",
    },
    "& .MuiChip-deleteIcon":{
      width:"20px",
      height:"20px",
      fontFamily:"SF Pro Text Regular",
    },
  },
  noDataContainer:{
    width:"362px",
    minHeight: "200px",
    display:"block",
    margin:"40px auto 0px"
  },
  noDataIcons:{
    display:"flex",
    margin:"auto"
  },
  noDataText:{
    fontSize:"20px",
    fontFamily:"SF Pro Text Medium",
    fontWeight: 500,
    color:"#808080", 
    width:"100%",
    display:"flex",
    justifyContent:"center",
    margin:"8px auto",
  },
  loadingText: {
    fontSize:"20px",
    fontFamily:"SF Pro Text Medium",
    fontWeight: 500,
    color:"#808080", 
    width:"100%",
    display:"flex",
    justifyContent:"center",
    margin:"10px auto 0",
  },
  subText:{
    fontSize:"20px",
    fontFamily:"SF Pro Text Medium",
    color:"#808080", 
    width:"100%",
    display:"flex",
    justifyContent:"center", 
    margin:"auto",
    opacity: "0.29"
  },
  btnContainer:{
    marginLeft:"40px",
  },
  hpDownArrowIcon: {
    width: "18px",
    height: "14px",
    objectFit: "cover" as objectFitType,
    cursor: "pointer",
    transform: 'translate(-6px, -1px)'
  },
  selectMenu: {
    width: '140px',
    height: '52px',
    border: '1px solid #808080',
    color: '#808080',
    borderRadius: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0 15px',
    fontFamily: 'SF Pro Text Regular',
    fontSize: '18px',
    fontWeight: 400,
    lineHeight: '21.48px',
    cursor: 'pointer'
  },
  selectParentContainer: {
    position: 'relative' as PositionValue,
  },
  selectFilterHolder: {
    lineHeight: '26.8px',
    margin: "25px 0px 10px",
    display: 'flex',
    alignItems: 'center',
    gap: '30px',
    padding: "10px 0px"
  },
  flexBox: {
    display: 'flex',
    boxShadow: '0px 0px 40px #B3B3B31F',
    border: '1px solid #80808021',
    borderRadius: "10px",
    alignItems: 'center',
    padding: "5px 24px",
    '@media (min-width:950px)': {
      width: '100%',
    },
  },
  nurseRoot: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    boxShadow: 'none',
    maxWidth: '758px',
    cursor: 'pointer',
    '@media (max-width:950px)': {
      flexDirection: "column",
      alignItems: "start",
      gap: "20px"
    },
  },
  nurseDetails: {
    display: 'flex',
    flexDirection: 'column' as FlexDirectionProperty,
    width: "100%",
    justifyContent: 'center',
  },
  nurseContent: {
    display: 'flex',
    width: "100%"
  },
  nurseCover: {
    maxWidth: "85px",
    minWidth: "85px",
    maxHeight: "85px",
    minHeight: "85px",
    borderRadius: '50%',
    margin: theme.spacing(1),
  },
  nurseName: {
    fontFamily: 'SF Pro Text Bold',
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: '23.87px',
    letterSpacing: '-0.48235294222831726px',
    color: '#292929',
    textTransform: "capitalize" as "capitalize",
  },
  nurseFees: {
    fontFamily: 'SF Pro Text Bold',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '19.09px',
    letterSpacing: '-0.38588234782218933px',
    color: '#191B1A',
    marginRight: "5px"
  },
  nurseShift: {
    fontFamily: 'SF Pro Text Regular',
    fontSize: '16px',
    fontWeight: 300,
    lineHeight: '19.09px',
    letterSpacing: '-0.38588234782218933px',
    color: '#191B1A',
    opacity: 0.5
  },
  experienceLabel: {
    fontFamily: 'SF Pro Text Regular',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '16.71px',
    letterSpacing: '-0.34px',
    color: '#191B1A',
  },
  experienceValue: {
    fontFamily: 'SF Pro Text Medium',
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '16.71px',
    letterSpacing: '-0.34px',
    color: '#191B1A',
  },
  nurseRating: {
    display: 'flex',
    alignItems: 'center',
    color: '#4CAF50',
    fontWeight: 600,
  },
  nurseExperience: {
    marginTop: "15px",
    color: '#808080',
  },
  dropdown: {
    display: 'none',
  },
  shiftsDropdownVisible: {
    display: 'block',
    position: 'absolute' as PositionValue,
    top: '70px',
    left: '0',
    width: '138px',
    height: 'fit-content',
    background: 'white',
    zIndex: 10,
    boxShadow: '0px 2px 8px 0px #80808021',
    border: '1px solid #80808021',
    borderRadius: "12px",
    padding: "0px 16px"
  },
  dateDropdownVisible: {
    display: 'block',
    position: 'absolute' as PositionValue,
    top: '70px',
    left: '0',
    width: '138px',
    height: 'fit-content',
    minHeight: "50px",
    background: 'white',
    zIndex: 10,
    boxShadow: '0px 2px 8px 0px #80808021',
    border: '1px solid #80808021',
    borderRadius: "12px",
    padding: "16px 16px"
  },
  nurseButton: {
    alignSelf: 'center',
    backgroundColor: '#00678F',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#00506F',
    },
  },
  nursePriceShift: {
    display: 'flex',
    alignItems: 'center',
    marginTop: "15px",
  },
  nurseBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    width: "calc(100% - 25px)",
  },
  checkbox: {
    padding: "5px 25px 5px 0px",
    color: "white !important",
    "& .MuiCheckbox-colorSecondary .Mui-checked":{
      color: "white !important",
    },
    transform: "translateX(-10px)"
  },
  userRating: {
    fontFamily: 'SF Pro Text Medium',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '16.71px',
    letterSpacing: '-0.02800000086426735px',
    color: '#014866',
    marginLeft: '8px',
  },
  userRatingOutOf: {
    fontFamily: 'SF Pro Text Medium',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '16.71px',
    letterSpacing: '-0.02800000086426735px',
    color: '#3780E0',
  },
  hpFilterIconSelected: {
    width: "18px",
    height: "18px",
    display: "flex",
    justifyContent: "center",
    margin: "20px auto 0px -45px",
    backgroundColor: "white",
    zIndex: 1000,
    padding: "13px",
    borderRadius: "8px",
    position:"absolute" as PositionValue,
    caretColor:"white"
  },
  shiftSortBox: {
    display: 'flex', 
    flexDirection: 'column' as FlexDirectionProperty, 
    alignItems: 'flex-start', 
    gap: '5px'
  },
  shiftSortOptionDiv:{
    display: 'flex', 
    justifyContent: 'space-between', 
    alignItems: 'center',
    borderBottom: "1px solid #f2f2f2",
    '&:first-of-type': {
      marginTop: "5px"
    },
    '&:last-of-type': {
      borderBottom: "none !important",
      marginBottom: "5px"
    }
  },
  hpFilterIcon: {
    width: "18px",
    height: "18px",
    display: "flex",
    justifyContent: "center",
    margin: "20px auto 0px -45px",
    backgroundColor: "white",
    zIndex: 1000,
    padding: "13px",
    borderRadius: "8px",
    caretColor:"white"
  },
  scrollContainer: {
    "-ms-overflow-style": "none",
    scrollbarColor: "#d9d5d5 #fff",
    scrollbarWidth: "thin" as const,
    "&& ::-webkit-scrollbar": {
      display: "none"
    },
  },
  calendarLabel: {
    fontFamily: 'SF Pro Text Medium',
    fontSize: '18px',
    fontWeight: 500,
    lineHeight: '21.48px',
    letterSpacing: '-0.5142857432365417px',
    color: '#292929',
    display: 'block',
    width: '100%',
    marginBottom: '5px'
  },
  calendarVal: {
    fontFamily: 'SF Pro Text Regular',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '19.09px',
    letterSpacing: '-0.4571428596973419px',
    color: '#717171',
  },
  iconBtn: {
    '&:hover': {
      background: "transparent",
      scale: 1.2,
      cursor: "pointer"
    }
  },
  dialogpaper: {
    borderRadius: "20px",
    maxWidth: "450px",
    padding: "10px 40px 50px",
    marginLeft: "13vw"
  },
  dialogTextt: {
    fontSize: "20px",
    color: "#383838",
    fontFamily: "SF Pro Text Medium",
    fontWeight: 500,
    lineHeight: "26px",
    caretColor: "transparent !important"
  },
  dialogButtonn: {
    width: "100%",
    height:"65px",
    backgroundColor: "#014866",
    color: "#fff",
    border: "none",
    borderRadius: "10px",
    padding: "10px",
    cursor: "pointer",
    fontFamily:"SF Pro Text Bold",
    fontWeight: 600,
    fontSize: "18px",
    margin: "20px auto 10px",
    "&:hover": {
      backgroundColor: "#014866",
      color: "#fff"
    },
    caretColor: "transparent"
  },
  customDateInputsName: {
    "& ::-webkit-input-placeholder": {
      color: "#565454",
    },
    width: "187px",
    borderRadius: "8px",
    "&:focus": {
      border: "1px solid blue",
    },
    "& input": {
      color: "#808080",
      backgroundPosition: "144px",
      backgroundSize: "22px",
      fontSize: "18px",
      width: "187px",
      height: "58px",
      display: "flex",
      margin: "auto",
      borderRadius: "8px",
      paddingLeft:10,
      "&::placeholder": {
        opacity: 1,
        fontSize: "18px",
        color: "#808080 !important",
        fontFamily: "SF Pro Text Regular",
      },
      fontFamily: "SF Pro Text Regular",
      caretColor: "transparent",
      border: "1px solid #808080",
      background: `url(${DownArrow}) left / contain no-repeat`,
    },
  },
  datespickerClasses:{
    "& .react-datepickerClass__month-container":{
      margin: "0px 0px 5px !important"
    },

    "& .react-datepickerClass__month":{ margin: "0px !important"},

    "& .react-datepickerClass-wrapper":{display: "flex !important",},

    "& .react-datetime-picker__wrapper":{ border: "none"},

    "& .react-datepickerClass__day-name":{
      width: "1.3rem !important", height: "1rem !important"
    },

    "& .react-datepickerClass-popper":{transform: "translate(90px, 209px) !important"},

    "& .react-datepickerClass__day-names":{
      marginBottom: "-1px !important", caretColor: "transparent"
    },
    
    "& .react-datepickerClass__day--keyboard-selected":{
      color: "#000 !important",
      caretColor: "transparent",
      backgroundColor: "white !important",
    },


    "& .react-datepickerClass__day":{
      height: "1rem !important", width: "1.3rem !important",
    },

    "& .react-datepickerClass__day:hover":{
      color: "#ccc !important",
      caretColor: "transparent",
      backgroundColor: "white !important",
    },

    "& .react-datepickerClass__navigation":{
      margin: "5px -2px !important",
      caretColor: "transparent",
      height: "22px !important",
      width: "22px !important",
    },

    "& .react-datepickerClass__day--today":{
      fontWeight: "bold !important",
      caretColor: "transparent",
      color: "#1d5d90 !important",
      backgroundColor: "white !important",
    }, 
  },
};

export default withStyles(webStyles)(Reservations2NurseBooking);
// Customizable Area End

